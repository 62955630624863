import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-brandedit',
  templateUrl: './brandedit.component.html',
  styleUrls: ['./brandedit.component.scss']
})
export class BrandeditComponent extends BaseComponent implements OnInit,OnChanges {
  @Input('brandid') brandid;
  @Output('callback') cb = new EventEmitter();
  brand: any = {};
  companies: any = [];
  companyid = 0;
  companyname = '';
  showCompanyAdd = false;

  constructor(private injector: Injector) {super(injector) }

  async ngOnInit() {
    this.companies = await this.db.get("brand", "getCompanies");

  }

  async ngOnChanges() {
    if (this.brandid == 0) {
      this.brand = {
        id: 0,
        name: "",
        description: "",
        companyId: 0,
        website: ""
      }
    } else {
      this.brand = await this.db.get("brand", "getBrand", { id: this.brandid });
      let a = 5;
    }
  }

  async submit() {
    let brandSubmit = await this.db.post("brand", "postBrand", this.brand);
    this.cb.emit(brandSubmit);
  }

  async addCompany() {
    let comp = { id: 0, name: this.companyname, companytypeid: 1, isActive: true };
    let c2 = await this.db.post("company", "postCompany", comp);
    this.companies = await this.db.get("brand", "getCompanies");
    this.brand.companyId = c2.id;
    this.companyname = "";
    this.showCompanyAdd = false;
  }
}
