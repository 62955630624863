import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';



@Component({
  selector: 'app-takesurvey',
  templateUrl: './takesurvey.component.html',
  styleUrls: ['./takesurvey.component.scss']
})
export class TakesurveyComponent extends BaseComponent implements OnInit {
  @Input('ideaid') ideaid;
  @Input('templateid') templateid;
  @Input('usertoken') usertoken;

  idea: any = {};
  user: any = {};
  surveyData: any = [];
  surveyTemplate: any = {};
  survey: any = {};
  json = {};

  constructor(private injector: Injector) { super(injector) }

  async ngOnInit() {
    if (this.ideaid == null || this.ideaid == 0) return;
    if (this.usertoken == null || this.usertoken == 0) return;
    if (this.templateid == null || this.templateid == 0) return;
    this.loader.show();
    await this.getData();
    this.loader.hide();
  }

  async getData() {
    const query = { ideaid: this.ideaid, surveytemplateId: this.templateid, token: this.usertoken }
   const resp = await this.db.get("survey", "getSurveyIdeaUser",query);
    this.idea = resp.idea;
    this.user = resp.user;
    this.surveyData = resp.surveyData;
    this.surveyTemplate = resp.surveyTemplate;

    this.json = this.getDataOrTemplate();
  }

  getDataOrTemplate() {
    let jsonTemp = {};
    if (this.surveyData.length > 0) { // Edit the existing survey
      this.survey = this.surveyData[0];
    } else { // Create a new survey to submit
      this.survey = { Id: 0, SurveytemplateId: this.templateid, UserProfileId: this.user.Id, RefId: this.ideaid, DataJson: this.surveyTemplate.Templatejson };
    }
    jsonTemp = JSON.parse(this.survey.DataJson);
    return jsonTemp;
  }

  async refresh($event) {
    this.loader.show();
    this.survey.DataJson = JSON.stringify(this.json);
    const resp = await this.db.post("survey", "postSurvey", this.survey, { id: this.survey.Id });
    this.survey = resp;
    this.json = JSON.parse(this.survey.DataJson);
    this.loader.hide();
  }
}
