import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'survey-radio',
  templateUrl: './survey-radio.component.html',
  styleUrls: ['../../style.css']
})
export class SurveyRadioComponent implements OnInit {
  @Input() element: any;
  @Input() index: any;
  @Output() registerEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.registerEvent.emit(this);
  }
  toggle(cb) {
    this.element.choices.forEach(x => {
      x.isSet = cb.id == x.id ? true : false;
    })
  }
}
