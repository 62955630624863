export class CampaignSurveyData {
  private _rows: any = []; //the items in the survey
  private _columns: any = []; //the ideas
  private _data: any = [];//array of array of averages by surveyitem and idea
  private _count: any = [];//array of array of counts by surveyitem and idea
  private hasAveraged = false;

  //add all columns and rows first

  constructor() {
    this._rows = [];
    this._columns = [];
    this._data = [];
    this._count = [];
  }

  addColumn(column) {
    if (this._data.length > 0) {
      alert("Don't add columns after data!");
      return;
    }
    this._columns.push(column);
  }
  addRow(row) {
    if (this._data.length > 0) {
      alert("Don't add rows after data!");
      return;
    }
    this._rows.push(row);
  }
  addData(row, columns, value) {
    if (this._data.length == 0) { //init data with 0s
      this._rows.forEach(r => {
        let row = [];
        let rowCount = [];
        this._columns.forEach(c => {
          row.push(0);
          rowCount.push(0);
        })
        this._data.push(row);
        this._count.push(rowCount);
      })
    }
    let iRow = this._rows.findIndex(x => x == row);
    let iCol = this._columns.findIndex(x => x == columns);
    if (iRow < 0 || iCol < 0) {
      alert("campaignSurveeyData.addData: iRow or iCol < 0");
      return
    }
    this._data[iRow][iCol] += value;
    this._count[iRow][iCol] += 1;
  }
  getRows() {
    return this._rows;
  }
  getColumns() {
    return this._columns;
  }
  getData() {
    if (this.hasAveraged == false) {
      this._data.forEach((row, iRow) => {
        row.forEach((col, iCol) => {
          if (this._count[iRow][iCol] > 0) {
            this._data[iRow][iCol] = this._data[iRow][iCol] / this._count[iRow][iCol]
          }
        });
      })
    }
    return this._data;
  }
  getCount() {
    return this._count;
  }

}
