import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-ideafields',
  templateUrl: './ideafields.component.html',
  styleUrls: ['./ideafields.component.scss']
})
export class IdeafieldsComponent extends BaseComponent implements OnInit {
  ideas: any = [];
  ideasDisplay: any = [];
  dosageForms: any = [];
  impacts: any = [];
  fields: any = [];
  field: any = {};
  fieldId = 1;
  constructor(private injector: Injector) { super(injector)}

  async ngOnInit() {
    this.loader.show();
    this.loadFields();
    const resp = await this.db.get("idea", "ideafieldusage");
    this.ideas = resp.ideas;
    this.impacts = resp.impacts;
    this.dosageForms = resp.dosageForms;

    this.show();
    this.loader.hide();
  }

  loadFields() {
    this.fields = [
      { id: 1, name: 'Impact', update: true },
      { id: 2, name: 'Dosage Form', update: false },
      { id: 3, name: 'Technical Feasability / Product Impact', update: false },
      { id: 4, name: 'Sustainability', update: false },
      { id: 5, name: 'Platform', update: false },
      { id: 6, name: 'Key Resources', update: false },
      { id: 7, name: 'Key Activitities', update: false },
      { id: 8, name: 'Market Access', update: false },
      { id: 9, name: 'SWOT', update: false },
    ]
  }


  show() {
    this.field = this.fields.find(x => x.id == this.fieldId);
  }

  goto(id) {
    this.router.navigate(['/dashboard/ideaedit/'], { queryParams: { 'IdeaId': id } });
  }
  save(p) {
    this.loader.show();
    if (this.field.id == 2) {
      this.db.get("idea", "updateIdeaDosageform", { ideaId: p.id, dosageFormId: p.dosageFormId})
    }
    if (this.field.id == 1) {
      this.db.get("idea", "updateIdeaimpact", { ideaId: p.id, impactId: p.impactId })
    }
    this.loader.hide();
    
  }
}
