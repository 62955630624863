import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-packaging-systems',
  templateUrl: './packaging-systems.component.html',
  styleUrls: ['./packaging-systems.component.scss']
})
export class PackagingSystemsComponent implements OnInit {
  systems: any[];
  syst: any;
  constructor(private db: DbServiceService,
    private alert: snaAlertService,
    private loader: LoaderService
  ) { }

  async ngOnInit() {
    this.loader.show();
    this.systems = await this.db.get("packagingtechnology", "getPackagingTechnologies");
    this.syst = this.systems[0];
    this.loader.hide();
  }
  selectSystem(system) {
    this.syst = system;
  }
  formValidate($event) {
    //this.isFormValid = true;
    //if (this.Name.isValid == false) { this.isFormValid = false; }

  }

  async submit() {
    this.loader.show();
    if (this.syst.Id > 0) {
      let form = new FormData();
      let sys = this.syst;
      form.append("Id", sys.Id);
      form.append("Name", sys.Name);
      form.append("Description", sys.Description);
      await this.db.post("packagingtechnology", "updatePackagingTechnology", form, { Id: sys.Id });
      this.systems = await this.db.get("packagingtechnology", "getPackagingTechnologies");

      this.loader.hide();
    } else {
      this.syst = await this.db.post("packagingtechnology", "createPackagingTechnology", this.syst);
      this.systems = await this.db.get("packagingtechnology", "getPackagingTechnologies");
    }
    this.loader.hide();
  }


  async addPackSystem() {
    this.syst = {
      Id: 0,
      Name: "",
      Description: ""
    }
  }
}
