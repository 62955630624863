import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';
import { ConfirmService } from '../../../modules/confirm/confirm.service';
import { Idea } from '../../../models/IdeaCluster';

@Component({
  selector: 'app-projectedit',
  templateUrl: './projectedit.component.html',
  styleUrls: ['./projectedit.component.scss']
})
export class ProjecteditComponent extends BaseComponent implements OnInit {
  IdeaId = 0;
  categories: any = [];
  Idea: any = {}; // record in P.S DB Table Idea
  IdeaType: any = {};
  IdeaIdeaCatRel: any = []; // P.S DB Table IdeaIdeaCatRel
  IdeaCategories: any = []; // P.S. subset of global.categories having the seleted ideacategories
  IdeaMaterialAdjectivesRef: any = []; // DB P.S Table IdeaMaterialAdjectivesRef
  IdeaMaterialAdjectives: any = []; // subset of global.materialadjectives having the selected  IdeaMaterialAdjectivesRef
  EconomyCycleIdeaRels: any = [];
  IdeaRelationships: any = [];

  Url = "";
  Tasks: any = [];
  Task: any = {};
  TaskStatus: any = [];
  showTask = false;

  constructor(injector: Injector,private confirmService: ConfirmService) { super(injector); }

  async ngOnInit() {
    this.loader.show();

    if (this.global.IdeaTypes == null) {
      await this.global.getLookups();
    }

    this.IdeaId = this.getIntQueryParamOrDefault('IdeaId', 0);
    await this.getData();
    this.loader.hide();
  }

  async getData() {
    if (this.IdeaId > 0) {
      const ret: any = await this.db.get('idea', 'getidea', { Id: this.IdeaId });
      this.Idea = ret.Idea;
      this.Url = ret.PicUrl == null ? "" : ret.PicUrl;
      this.Tasks = ret.Tasks;
      this.TaskStatus = ret.TaskStatus;
    } else {
      this.createNewIdea(3);
    }
  }

  selectTask(item) {
    if (item == null) {
      this.Task = { Id: 0, StatusId: 1, IdeaId: this.Idea.Id, Description: "", DateStart: "", DateFinish: "" };
    } else {
      this.Task = item;
    }
    $("#editTaskTag").show();
  }
  closeTaskModal() {
    $("#editTaskTag").hide();
  }
  async saveTaskModal() {
    this.loader.show();
    const resp = await this.db.post("task", "postTask", this.Task);
    this.Tasks = resp.Tasks;
    this.loader.hide();
    $("#editTaskTag").hide();
  }
  clearTask() {
    this.Task.StatusId = 1;
    this.Task.Description = "";
    this.Task.DateStart = null;
    this.Task.DateFinish = null;
  }
  deleteTask(item) {
    this.confirmService.confirm("Do you want to delete this task?", async (answer) => {
      if (answer == "Yes") {
        this.loader.show();
        const resp = await this.db.delete("task", "deleteTask", { id: item.Id });
        await this.getData();
        this.loader.hide();
      }
    });
  }
  createNewIdea(IdeaTypeId: number) {
    this.Idea = new Idea();
    this.Idea.Id = 0;
    this.Idea.TypeId = IdeaTypeId; // type idea
    this.Idea.Name = '';
    this.Idea.Description = '';
    this.Idea.TypeId = IdeaTypeId; // type idea
    this.Idea.IsPublic = true;

    this.IdeaType = this.global.IdeaTypes.find(x => x.Id === IdeaTypeId);
    // P.S. reference tables
    this.IdeaIdeaCatRel = [];
    this.IdeaMaterialAdjectivesRef = [];
    this.EconomyCycleIdeaRels = [];

    // P.S. reference tables for child components
    this.IdeaRelationships = [];
    this.IdeaMaterialAdjectives = [];
    this.IdeaCategories = [];

    //this.global.IdeaType.Id = IdeaTypeId;
    this.global.IdeaId = 0;

    if (IdeaTypeId === 1) { this.global.IdeaType.Name = 'Idea'; }
    if (IdeaTypeId === 2) { this.global.IdeaType.Name = 'Concept'; }
    if (IdeaTypeId === 3) { this.global.IdeaType.Name = 'Project'; }
  }
  async changeStatus($event, item) {
    this.Task = item;
    await this.saveTaskModal();
    this.Task = null;
  }

  async save() {
    this.loader.show();
    const IdeaTemp = await this.db.post('idea', 'submitidea', this.Idea);

    await this.getData();
    this.loader.hide();
  }

  hasCategory(catId) {
    const cat = this.categories.find(x => x.Id === catId);
    return cat ? cat.isChecked : false;
  }

  changeCategory(categories) { // callback from app-ideacategories
    this.categories = this.deepCopy(categories);
  }
  gotoCharter(){
    this.router.navigate(['/dashboard/projectcharter'], { queryParams: { 'IdeaId': this.Idea.Id } });
  }
}
