import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-activeingredience',
  templateUrl: './activeingredience.component.html',
  styleUrls: ['./activeingredience.component.scss']
})
export class ActiveingredienceComponent extends BaseComponent  implements OnInit {
  activeingrediences: any = [];
  activeingredience: any = {};
  showDetail = false;

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.add();
    this.loader.hide();
  }
  async getData() {
    const resp = await this.db.get("lookup", "getActiveIngredients");
    this.activeingrediences = resp;
  }
  selectActiveingredience(matg) {
    this.activeingredience = matg;
    this.showDetail = true;
  }
  async saveActiveingredience() {
    this.loader.show();
    await this.db.post("lookup", "postActiveIngredient", this.activeingredience);
    await this.getData();
    this.loader.hide();
  }
  closemodal() {
    $('#changeTag').hide();
  }
  add() {
    this.activeingredience = {
      id: 0,
      name: "",
      description: ""
    };
  }
}
