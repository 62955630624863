import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-printitem',
  templateUrl: './printitem.component.html',
  styleUrls: ['./printitem.component.scss']
})
export class PrintitemComponent implements OnInit {
  @Input() text = "";
  constructor() { }

  ngOnInit() {
  }

}
