import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sa-select',
  templateUrl: './sa-select.component.html',
  styleUrls: ['../formfields.css']
})
export class SaselectComponent implements OnInit {
  @Input() Id: number;
  @Input() title: string;
  @Input() required: string;
  @Input() options: any;
  @Output() IdChange = new EventEmitter();
  @Output() hasChanged = new EventEmitter();
  isValid = true;
  tiClass = { 'border': 'solid 1px lightgray' };

  constructor() { }
  ngOnInit(): void {
    this.doValidation(this.Id);
  }

  doValidation = function (newValue) {
    //P.S. implement your validation rules here
    if (this.required == 'required') {
      if (!newValue || newValue == undefined) {
        this.isValid = false;
        this.tiClass = { 'border': 'solid 2px red' };
      } else {
        this.isValid = true;
        this.tiClass = { 'border': 'solid 1px gray' };
      }
    }
  };

  change(newValue) {
    this.Id = newValue;
    this.doValidation(newValue);
    this.IdChange.emit(newValue);
    this.hasChanged.emit(this.isValid);
  };

}
