import { Component, OnInit } from '@angular/core';
import { UsermanagerService } from './usermanager.service'
import { Router } from '@angular/router';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../../modules/loader/loader.service';
import { GlobalService } from '../../../services/global.service';
import { DbServiceService } from '../../../services/db-service.service';

@Component({
  selector: 'app-usermanager',
  templateUrl: './usermanager.component.html',
  styleUrls: ['./usermanager.component.scss']
})
export class UsermanagerComponent implements OnInit {

  users: any = [];
  usersAll: any = [];
  showActive = true;
  constructor(private UsermanagerService: UsermanagerService,
    private router: Router,
    private loader: LoaderService,
    private db: DbServiceService,
    private alert: snaAlertService,
  private global: GlobalService) { }

  ngOnInit() {
    this.getUsers();
  }

  submituser(operation: number) {
    this.router.navigate(['dashboard/usermodification/' + operation]);
  }

  lockupdate(userid: number, operation: boolean) {
    this.UsermanagerService.lockupdate(userid,operation)
      .subscribe(
      res => {
        this.refresh();
          this.alert.success(res.message);
        },
        err => {
          console.log("err", err);
          this.alert.warning(err.error.message);
        }
      );
  }

  createuser() {
    this.router.navigate(['dashboard/newuser']);
  }

  refresh() {
    this.UsermanagerService.getUsers()
      .subscribe(
        res => {
          this.users = res;
        },
        err => {
          console.log("err", err);
          this.alert.danger(err.error.message);
        }
      );
  }

  getUsers() {
    this.loader.show();
    this.UsermanagerService.getUsers()
      .subscribe(
        res => {
          this.usersAll = res;
          this.toggleActive(null);
          this.loader.hide();
        },
        err => {
          console.log("err", err);
          this.alert.danger(err.error.message);
          this.loader.hide();
        }
      );
  }
  //P.S. used in component to lookup a value from a lookup table
  public lookup(id: any, array: any[], idField = "id", nameField = "name") {
    let ret = null; //default return
    if (id == null || array == null) return ret;
    array.forEach(item => {
      if (item[idField] == id) {
        ret = item[nameField];
      }
    });
    return ret;
  }

  toggleActive($event) {
    if (this.showActive) {
      this.users = [];
      this.usersAll.forEach(u => {
        if (u.Inactive == false) {
          this.users.push(u);
        }
      });
    } else {
      this.users = this.usersAll;
    }
  }
  async changeActive(b) {
    await this.db.get("user", "makeActiveInactive", { userprofileid: b.Id, inactive: b.Inactive });
    //this.getUsers();
  }
}
