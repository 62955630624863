import { SurveytakeinComponent } from './components/survey/surveytakein/surveytakein.component';
import { TestangComponent } from './components/menu-organize/testang/testang.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// components
import { MasterComponent } from './components/master/master.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth-guard.service';
import { HomeComponent } from './components/home/home.component';
import { UsermanagerComponent } from './components/menu-dev/usermanager/usermanager.component';
import { UsereditComponent } from './components/menu-dev/useredit/useredit.component';
import { NewuserComponent } from './components/menu-dev/newuser/newuser.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { IdealistComponent } from './components/menu-explore/idealist/idealist.component';
import { ConceptlistComponent } from './components/menu-explore/conceptlist/conceptlist.component';
import { ProjectlistComponent } from './components/menu-explore/projectlist/projectlist.component';
import { DocumentationComponent } from './components/menu-organize/documentation/documentation.component';
import { DevelopmentComponent } from './components/menu-dev/development/development.component';
import { CircecondashComponent} from './components/menu-explore/circecondash/circecondash.component';
import { PackagingSystemsComponent } from './components/menu-organize/packaging-systems/packaging-systems.component';
import { PackSystemShowComponent } from './components/menu-explore/pack-system-show/pack-system-show.component';
import { TestComponent } from './components/menu-dev/test/test.component';
import { IdeaeditComponent } from './components/menu-create/ideaedit/ideaedit.component';
import { ProductlistComponent } from './components/menu-explore/productlist/productlist.component';
import { FormulaComponent } from './components/menu-explore/formula/formula.component';
import { TopideasComponent } from './components/menu-explore/topideas/topideas.component';
import { TakephotoComponent } from './components/takephoto/takephoto.component';
import { DevtasksComponent } from './components/menu-dev/devtasks/devtasks.component';
import { SurveyTemplateManagerComponent } from './components/survey/surveytemplatemanager/surveytemplatemanager.component';
import { SPICodesComponent } from './components/menu-explore/spicodes/spicodes.component';
import { MaterialAdjectiveComponent } from './components/menu-explore/material-adjective/material-adjective.component';
import { LookupManagerComponent } from './components/menu-dev/lookup-manager/lookup-manager.component';
import { SearchComponent } from './components/menu-explore/search/search.component';
import { ConcepteditComponent } from './components/menu-create/conceptedit/conceptedit.component';
import { ProjecteditComponent } from './components/menu-create/projectedit/projectedit.component';
import { ProducteditComponent } from './components/menu-create/productedit/productedit.component';
import { RelationshipComponent } from './components/menu-organize/relationship/relationship.component';
import { WorldmapComponent } from './components/worldmap/worldmap.component';
import { RemoveIdeaComponent } from './components/menu-dev/remove-idea/remove-idea.component';
import { CanvasComponent } from './components/canvas/canvas.component';
import { S3testComponent } from './components/menu-dev/s3test/s3test.component';
import { CalculatorComponent } from './components/menu-explore/calculator/calculator.component';
import { PunchComponent } from './components/menu-explore/punch/punch.component';
import { SiegelhofComponent } from './components/menu-explore/siegelhof/siegelhof.component';
import { WvtrComponent } from './components/menu-explore/wvtr/wvtr.component';
import { FormulasComponent } from './components/menu-explore/formulas/formulas.component';
import { MagnesiumcontentComponent } from './components/menu-explore/calculator/magnesiumcontent/magnesiumcontent.component';
import { MaxcompressionComponent } from './components/menu-explore/calculator/maxcompression/maxcompression.component';
import { PressingspeedComponent } from './components/menu-explore/calculator/pressingspeed/pressingspeed.component';
import { PressureresidenceComponent } from './components/menu-explore/calculator/pressureresidence/pressureresidence.component';
import { TableperformanceComponent } from './components/menu-explore/calculator/tableperformance/tableperformance.component';
import { RecordprintComponent } from './components/menu-create/recordprint/recordprint.component';
import { CampaignmenagementComponent } from './components/menu-organize/campaignmenagement/campaignmenagement.component';
import { IdeasurveyComponent } from './components/survey/ideasurvey/ideasurvey.component';
import { FillsurveyComponent } from './components/survey/fillsurvey/fillsurvey.component';
import { SurveyresultsComponent } from './components/survey/surveyresults/surveyresults.component';
import { GraphtestComponent } from './components/menu-dev/graphtest/graphtest.component';
import { XfactortestComponent } from './components/menu-dev/xfactortest/xfactortest.component';
import { XfactorComponent } from './components/menu-explore/xfactor/xfactor.component';
import { CompanylistComponent } from './components/menu-organize/companies/companylist/companylist.component';
import { CompanyeditComponent } from './components/menu-organize/companies/companyedit/companyedit.component';
import { FillcircComponent } from './components/survey/fillcirc/fillcirc.component';
import { CompanyoverviewComponent } from './components/admin/company/companyoverview/companyoverview.component';
import { CompanydetailsComponent } from './components/admin/company/companydetails/companydetails.component';
import { CompanyrelsComponent } from './components/admin/company/companyrels/companyrels.component';
import { SurveyoverviewComponent } from './components/admin/surveyoverview/surveyoverview.component';
import { SurveygroupresultsComponent } from './components/admin/surveyoverview/surveygroupresults/surveygroupresults.component';
import { CircEconomyComponent } from './components/circ-economy/circ-economy.component';
import { TestsurveyComponent } from './components/survey/testsurvey/testsurvey.component';
import { DynformtestComponent } from './components/menu-dev/dynformtest/dynformtest.component';
import { ManagedosageformComponent } from './components/admin/managedosageform/managedosageform.component';
import { ManagelookupsComponent } from './components/admin/managelookups/managelookups.component';
import { RelideacompanyComponent } from './components/menu-explore/relideacompany/relideacompany.component';
import { ReclistComponent } from './components/menu-user/reclist/reclist.component';
import { IdeafieldsComponent } from './components/admin/ideafields/ideafields.component';
import { ManagematerialComponent } from './components/admin/managematerial/managematerial.component';
import { IdeafieldlistComponent } from './components/menu-dev/ideafieldlist/ideafieldlist.component';
import { BrandlistComponent } from './components/menu-organize/brandlist/brandlist.component';
import { DosageformshowComponent } from './components/menu-explore/dosageformshow/dosageformshow.component';
import { ManageprocessComponent } from './components/admin/manageprocess/manageprocess.component';
import { MaterialshowComponent } from './components/menu-explore/materialshow/materialshow.component';
import { ProcessshowComponent } from './components/menu-explore/processshow/processshow.component';
import { UserproductlistComponent } from './components/menu-user/userproductlist/userproductlist.component';
import { UserideaeditComponent } from './components/menu-user/userideaedit/userideaedit.component';
import { UserproducteditComponent } from './components/menu-user/userproductedit/userproductedit.component';
import { MaterialgroupComponent } from './components/admin/materialgroup/materialgroup.component';
import { MaterialgroupshowComponent } from './components/menu-explore/materialgroupshow/materialgroupshow.component';
import { GsearchComponent } from './components/menu-explore/gsearch/gsearch.component';
import { SustopicsComponent } from './components/admin/sustopics/sustopics.component';
import { ManagesustopicsComponent } from './components/admin/managesustopics/managesustopics.component';
import { InnpoproductsComponent } from './components/admin/innpoproducts/innpoproducts.component';
import { ManageinnoproductsComponent } from './components/admin/manageinnoproducts/manageinnoproducts.component';
import { ExternalReferencesComponent } from './components/menu-explore/external-references/external-references.component';
import { ActiveingredienceComponent } from './components/admin/activeingredience/activeingredience.component';
import { ProductprintComponent } from './components/print/productprint/productprint.component';
import { TechnologylistComponent } from './components/menu-explore/technologylist/technologylist.component';
import { TechnologyeditComponent } from './components/menu-create/technologyedit/technologyedit.component';
import { BtobproductlistComponent } from './components/menu-explore/btobproductlist/btobproductlist.component';
import { B2bproducteditComponent } from './components/menu-create/b2bproductedit/b2bproductedit.component';
import { Co2impactlistComponent } from './components/menu-organize/co2impactlist/co2impactlist.component';
import { ExploreComponent } from './components/menu-explore/explore/explore.component';
import { PrinttechdataComponent } from './components/print/printtechdata/printtechdata.component';
import { MaterialtreeComponent } from './components/admin/materialtree/materialtree.component';
import { CertificationsComponent } from './components/menu-organize/certifications/certifications.component';
import { MaterialsustainComponent } from './components/menu-explore/materialsustain/materialsustain.component';
import { CampaignreportComponent } from './components/survey/campaignreport/campaignreport.component';
import { IdeacompareComponent } from './components/menu-explore/ideacompare/ideacompare.component';
import { ReearchComponent } from './components/menu-explore/reearch/reearch.component';
import { IdeasusgoallistComponent } from './components/menu-explore/ideasusgoallist/ideasusgoallist.component';
import { ProjectcharterComponent } from './components/menu-create/projectedit/projectcharter/projectcharter.component';


const routes: Routes = [
  { path: '', redirectTo: '/dashboard/home', pathMatch: 'full' },
  // { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'survey', component: IdeasurveyComponent },
  {
    path: 'dashboard',
    // canActivate: [AuthGuard],
    component: MasterComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'canvas', component: CanvasComponent },
      { path: 'circeconomy', component: CircecondashComponent },
      { path: 'conceptedit', component: ConcepteditComponent },
      { path: 'conceptlist', component: ConceptlistComponent },
      { path: 'development', component: DevelopmentComponent },
      { path: 'devtasks', component: DevtasksComponent },
      { path: 'documentation', component: DocumentationComponent },
      { path: 'home', component: HomeComponent },
      { path: 'ideaedit', component: IdeaeditComponent },
      { path: 'idealist', component: IdealistComponent },
      { path: 'lookupmanager', component: LookupManagerComponent },
      { path: 'materialadjective', component: MaterialAdjectiveComponent },
      { path: 'newuser', component: NewuserComponent },
      { path: 'packagingsystemshow', component: PackSystemShowComponent },
      { path: 'packagingtechnology', component: PackagingSystemsComponent },
      { path: 'productedit', component: ProducteditComponent },
      { path: 'productlist', component: ProductlistComponent },
      { path: 'projectedit', component: ProjecteditComponent },
      { path: 'projectlist', component: ProjectlistComponent },
      { path: 'relationship', component: RelationshipComponent },
      { path: 'removeidea', component: RemoveIdeaComponent },
      { path: 's3test', component: S3testComponent },
      { path: 'search', component: SearchComponent },
      { path: 'spicodes', component: SPICodesComponent },
      { path: 'surveytakein', component: SurveytakeinComponent },
      { path: 'surveytemplatemanager', component: SurveyTemplateManagerComponent },
      { path: 'fillsurvey', component: FillsurveyComponent },
      { path: 'takeaphoto', component: TakephotoComponent },
      { path: 'test', component: TestComponent },
      { path: 'testangularfoobar', component: TestangComponent },
      { path: 'topideas', component: TopideasComponent },
      { path: 'usermanager', component: UsermanagerComponent },
      { path: 'usermodification/:id', component: UsereditComponent },
      { path: 'worldmap', component: WorldmapComponent },
      { path: 'formulas', component: FormulasComponent },
      { path: 'formula', component: FormulaComponent },
      { path: 'calculator', component: CalculatorComponent },
      { path: 'punch', component: PunchComponent },
      { path: 'siegelhof', component: SiegelhofComponent },
      { path: 'wvtr', component: WvtrComponent },
      { path: 'magnesiumcontent', component: MagnesiumcontentComponent },
      { path: 'maxcompression', component: MaxcompressionComponent },
      { path: 'pressingspeed', component: PressingspeedComponent },
      { path: 'pressureresidence', component: PressureresidenceComponent },
      { path: 'tabletperformance', component: TableperformanceComponent },
      { path: 'print', component: RecordprintComponent },
      { path: 'campaign', component: CampaignmenagementComponent },
      { path: 'surveyresults', component: SurveyresultsComponent },
      { path: 'graphtest', component: GraphtestComponent },
      { path: 'xfactortest', component: XfactortestComponent },
      { path: 'xfactor', component: XfactorComponent },
      { path: 'companies', component: CompanylistComponent },
      { path: 'companyedit', component: CompanyeditComponent },
      { path: 'fillcirc', component: FillcircComponent },        
      { path: 'companyoverview', component: CompanyoverviewComponent },        
      { path: 'companydetails', component: CompanydetailsComponent },        
      { path: 'companyrels', component: CompanyrelsComponent },        
      { path: 'surveyoverview', component: SurveyoverviewComponent },        
      { path: 'surveygroupresults', component: SurveygroupresultsComponent },        
      { path: 'testsurvey', component: TestsurveyComponent },        
      { path: 'testdynform', component: DynformtestComponent },        
      { path: 'managedosageform', component: ManagedosageformComponent },        
      { path: 'managelookups', component: ManagelookupsComponent },        
      { path: 'relideacompany', component: RelideacompanyComponent },
      { path: 'recordlist', component: ReclistComponent },
      { path: 'ideafields', component: IdeafieldsComponent },
      { path: 'managematerial', component: ManagematerialComponent },
      { path: 'ideafieldlist', component: IdeafieldlistComponent },
      { path: 'brandlist', component: BrandlistComponent },
      { path: 'dosageformshow', component: DosageformshowComponent },
      { path: 'manageprocess', component: ManageprocessComponent },
      { path: 'materialshow', component: MaterialshowComponent },
      { path: 'processshow', component: ProcessshowComponent },
      { path: 'userproductlist', component: UserproductlistComponent },
      { path: 'userideaedit', component: UserideaeditComponent },
      { path: 'userproductedit', component: UserproducteditComponent },
      { path: 'materialgroup', component: MaterialgroupComponent },
      { path: 'materialgroupshow', component: MaterialgroupshowComponent },
      { path: 'gsearch', component: GsearchComponent },
      { path: 'sustopics', component: SustopicsComponent },
      { path: 'managesustopics', component: ManagesustopicsComponent },
      { path: 'innoproducts', component: InnpoproductsComponent },
      { path: 'manageinnoproducts', component: ManageinnoproductsComponent },
      { path: 'externalreferences', component: ExternalReferencesComponent },
      { path: 'activeingredience', component: ActiveingredienceComponent },
      { path: 'productprint', component: ProductprintComponent },
      { path: 'technologylist', component: TechnologylistComponent },
      { path: 'technologyedit', component: TechnologyeditComponent },
      { path: 'btobproductlist', component: BtobproductlistComponent },
      { path: 'b2bproductedit', component: B2bproducteditComponent },
      { path: 'co2impactlist', component: Co2impactlistComponent },
      { path: 'explore', component: ExploreComponent },
      { path: 'printtechdata', component: PrinttechdataComponent },
      { path: 'materialtree', component: MaterialtreeComponent },
      { path: 'certifications', component: CertificationsComponent },
      { path: 'materialsustain', component: MaterialsustainComponent },
      { path: 'campaignreport', component: CampaignreportComponent },
      { path: 'ideacompare', component: IdeacompareComponent },
      { path: 'research', component: ReearchComponent },
      { path: 'susgoals', component: IdeasusgoallistComponent },
      { path: 'projectcharter', component: ProjectcharterComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
