// use html
// <pre [innerHTML]="res | prettyprint"></pre>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyprint'
})
export class PrettyprintPipe implements PipeTransform {

  transform(val) {
    var s = JSON.stringify(val, null, 2);
    return s;
  }
}
