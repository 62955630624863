import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { GlobalService } from '../../../services/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from '../../../modules/loader/loader.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';

@Component({
  selector: 'app-recordprint',
  templateUrl: './recordprint.component.html',
  styleUrls: ['./recordprint.component.scss']
})
export class RecordprintComponent implements OnInit {
  IdeaId = 0;
  Idea: any = {};
  IdeaType: any = {};
  IdeaIdeaCatRel: any = [];
  IdeaCategories: any = [];


  EconomyCycleIdeaRels: any = [];
  IdeaMaterialAdjectivesRef: any = [];
  IdeaMaterialAdjectives: any = [];
  IdeaRelationships: any = [];
  Url = "";
  Tasks: any = [];
  TaskStatus: any = [];
  showStepStrechLeap = false;

  constructor(private db: DbServiceService,
    public global: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private modal: NgbModal,
    private alert: snaAlertService) { }

  async ngOnInit() {
    if (this.global.hasDataLoaded == false) {
      await this.global.getLookups();
    }
    this.IdeaId = this.getIntQueryParamOrDefault('IdeaId', 0);
    if (this.IdeaId == 0) {
      this.alert.warning("No idea identifyer provided.")
    } else {
      await this.getIdea(this.IdeaId);
    }
  }
  getIntQueryParamOrDefault(sQuery: string, iDefault: number) {
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString !== null) { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }

  async getIdea(IdeaId) {
    const ret: any = await this.db.get('idea', 'getidea', { Id: IdeaId });
    this.Idea = ret.Idea;
    this.IdeaType = this.global.IdeaTypes.find(x => x.Id === this.Idea.TypeId);
    this.IdeaIdeaCatRel = ret.IdeaIdeaCatRel;
    this.IdeaCategories = this.getIdeaCategories(this.IdeaIdeaCatRel);

    this.EconomyCycleIdeaRels = ret.EconomyCycleIdeaRels;
    //this.IdeaRelationships = ret.IdeaRelationships;
    this.Url = ret.PicUrl == null ? "" : ret.PicUrl;
    this.Tasks = ret.Tasks;
    this.TaskStatus = ret.TaskStatus;

  }


  private getIdeaCategories(IdeaIdeaCatRel: any) {
    // P.S. Find all entries in global.Categories
    // that have IdeaCategoryId in relationship table IdeaIdeaCatRel
    // and return this subset
    const list: any = [];
    IdeaIdeaCatRel.forEach(cat => {
      const category = this.global.Categories.find(x => x.Id === cat.IdeaCategoryId);
      list.push(category);
    });
    return list;
  }

}
