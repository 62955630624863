import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {
  ref;
  constructor() { }

  public registerComponent(ref) {
    this.ref = ref;
  }

  public confirm(question, callback) {
    this.ref.confirm(question, callback)
  }
}
