import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-graphtest',
  templateUrl: './graphtest.component.html',
  styleUrls: ['./graphtest.component.scss']
})
export class GraphtestComponent implements OnInit {
  testdata: any = [];
  constructor() { }

  ngOnInit() {
    this.testdata = [
      { key: "peter", value: 4 },
      { key: "mary", value: 1 },
      { key: "june", value: 2 },
      { key: "john", value: 3 },
      { key: "jennifer", value: 4 },
      { key: "maria", value: 15 }
    ]
  }

}
