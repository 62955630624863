// use html
// <pre [innerHTML]="res | lowerInitial"></pre>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowerInitial'
})
export class LowerInitialPipe implements PipeTransform {

  transform(value: any): any {
    let s = '';
    if (value === undefined) {return s; }
    if (typeof value !== 'string')  {s = ''; }
    s = value.charAt(0).toLowerCase() + value.slice(1);
    return s;
  }

}
