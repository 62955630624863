import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-ideasusgoallist',
  templateUrl: './ideasusgoallist.component.html',
  styleUrls: ['./ideasusgoallist.component.scss']
})
export class IdeasusgoallistComponent extends BaseComponent implements OnInit {
  ideas: any = [];
  goals: any = [];

  constructor(injector: Injector) {super(injector) }

  async ngOnInit() {
    this.loader.show();
    this.ideas = await this.db.get("idea", "getIdeaSusGoals");
    this.goals = await this.db.get("idea", "getSusGoals");
    this.loader.hide();
  }

}
