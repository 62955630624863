import { snaAlertService } from './../../../modules/sna-alert/sna-alert.service';
import { IdeaType } from './../../../models/IdeaCluster';
import { Component, OnInit, OnChanges, ViewChild, Input, Output } from '@angular/core';
import { Idea, Impact, Reach, Length, Sustainability, IdeaLookups } from '../../../models/IdeaCluster';
import { GlobalService } from '../../../services/global.service';
import { SainputhtmlComponent } from '../../shared/formfields/sa-input-html/sa-input-html.component';
import { SaselectComponent } from '../../shared/formfields/sa-select/sa-select.component';
import { SainputtextComponent } from '../../shared/formfields/sa-input-text/sa-input-text.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { DbServiceService } from '../../../services/db-service.service';
import { LoaderService } from '../../../modules/loader/loader.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-productprint',
  templateUrl: './productprint.component.html',
  styleUrls: ['./productprint.component.scss']
})
export class ProductprintComponent implements OnInit {
  IdeaId = 0;
  IdeaTypeId = 4; // P.S. Product

  Idea: any = {}; // record in P.S DB Table Idea
  IdeaType: any = {};
  IdeaIdeaCatRel: any = []; // P.S DB Table IdeaIdeaCatRel
  IdeaCategories: any = []; // P.S. subset of global.categories having the seleted ideacategories
  EconomyCycleIdeaRels: any = [];
  IdeaRelationships: any = [];
  Tasks: any = [];
  TaskStatus: any = [];

  observations: any = [];

  relatedRecords: any = []; // P.S. all concepts for ideas, projects for conepts, etc
  relatedRecName = '';
  newRecordTypeName = '';
  showErrorMessage = false;
  errorMessage = '';

  isFormValid = true;
  showDocu = false;
  spinner: boolean;
  Relationships: any;
  closeResult: string;
  testBoolean = true;
  headerBackgroundColor: any;
  nextStep: any = {};
  Url = "";
  brand: any = {};

  type10templateid = 31;
  token = "";

  showHelpDescription = false;
  showHelpDosageForm = false;
  showHelpFeasibility = false;
  showHelpProductionImpact = false;
  showHelpKeyResources = false;
  showHelpKeyActivitities = false;
  showHelpSWOT = false;
  showStepStrechLeap = false;
  showHelpMaterialGroup = false;
  showHelpMaterialForm = false;
  lpackform: any = [];
  lpackformFirstChecked: any = {};
  lsecpackform: any = [];
  lsecpackformFirstChecked: any = {};
  IdeaTouchAndFeelPrim: any = [];
  IdeaTouchAndFeelSec: any = [];
  lDosageForm: any = [];
  lDosageFormFirstChecked: any = {};
  lPrintingEffects: any = [];
  lMaterialGroups: any = [];
  lMaterialGroupsSec: any = [];
  lPackPerception: any = [];

  distributions: any = [];

  showTabs: any = [];

  printProductForm = true;
  printPrimaryPackaging = true;
  printSecondaryPackaging = true;
  printObservations = true;
  printDistribution = true;

  constructor(
    private db: DbServiceService,
    public global: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private modal: NgbModal,
    private alert: snaAlertService) { }

  async ngOnInit() {
    this.IdeaId = this.getIntQueryParamOrDefault('IdeaId', 0);
    if (this.global.hasDataLoaded == false) {
      await this.global.getLookups();
    }
    await this.getIdea(this.IdeaId);
  }

  async getIdea(IdeaId) {
    const ret: any = await this.db.get('idea', 'getidea', { Id: IdeaId });
    this.Idea = ret.Idea;
    this.Url = ret.PicUrl == null ? "" : ret.PicUrl;
    await this.getIdeaDosageFormRel();
    await this.getIdeaPackagingSystemRelationship();
    await this.getIdeaTouchAndFeelRel();
    await this.getPrintingEffectRel();
    await this.getMaterialGroupRel();
    await this.getMaterialGroupSec();
    await this.getIdeaSecTouchAndFeelRel();
    await this.getPackPerceptionRel();
    await this.getObservations();
    await this.getDistributions();
    await this.getIdeaPackSystemsSec();
  }

  getIntQueryParamOrDefault(sQuery: string, iDefault: number) {
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString !== null) { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }

  async getPackPerceptionRel() {
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getIdeaSecPackPerceptionRel", { ideaId: this.Idea.Id });
      this.lPackPerception = [];
      this.global.PackagePerception.forEach(x => {
        let ldf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        };
        let rel = resp2.find(r => r.packagingPerceptionId == x.Id);
        if (rel != null) {
          ldf.isChecked = true;
          ldf.refId = rel.id;
          this.lPackPerception.push(ldf);
        }
        
      });
    }
    let a = 5;
  }

  async getIdeaSecTouchAndFeelRel() {
    if (this.Idea.Id > 0) {
      let tfs = this.global.TouchAndFeels;
      let tfRels = await this.db.get("lookup", "getIdeaSecTouchAndFeelRel", { IdeaId: this.Idea.Id })
      this.IdeaTouchAndFeelSec = [];
      tfs.forEach(x => {
        let tf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        }
        let tfRel = tfRels.find(x => x.touchAndFeelId === tf.id)
        if (tfRel != null) {
          tf.isChecked = true;
          tf.refId = tfRel.id;
          this.IdeaTouchAndFeelSec.push(tf);
        }
        
      })
    }
  }

  async getMaterialGroupRel() {
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getMaterialGroupRel", { ideaId: this.Idea.Id });
      this.lMaterialGroups = [];
      this.global.MaterialGroups.forEach(x => {
        let ldf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        };
        let rel = resp2.find(r => r.materialGroupId == x.Id);
        if (rel != null) {
          ldf.isChecked = true;
          ldf.refId = rel.id;
          this.lMaterialGroups.push(ldf);
        }
      });
    }
    let a = 5;
  }
  async getIdeaDosageFormRel() {
    this.lDosageFormFirstChecked = { name: "" };
    let foundChecked = false;
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getIdeaDosageFormRel", { ideaId: this.Idea.Id });
      this.lDosageForm = [];
      this.global.DosageForms.forEach(x => {
        let ldf = {
          id: x.Id,
          name: x.Name,
          description: x.Description,
          isChecked: false,
          refId: 0
        };
        let rel = resp2.find(r => r.dosageFormId == x.Id);
        if (rel != null) {
          ldf.isChecked = true;
          ldf.refId = rel.id;
          if (foundChecked == false) {
            foundChecked = true;
            this.lDosageFormFirstChecked = ldf;
          }
          this.lDosageForm.push(ldf);
        }
        
      });
    }
    let a = 5;
  }

  async getMaterialGroupSec() {
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getMaterialGroupsSecRel", { ideaId: this.Idea.Id });
      this.lMaterialGroupsSec = [];
      this.global.MaterialGroups.forEach(x => {
        let ldf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        };
        let rel = resp2.find(r => r.materialGroupId == x.Id);
        if (rel != null) {
          ldf.isChecked = true;
          ldf.refId = rel.id;
          this.lMaterialGroupsSec.push(ldf);
        }
        
      });
    }
    let a = 5;
  }

  async getIdeaPackSystemsSec() {
    this.lsecpackformFirstChecked = { name: "" };
    let foundFirstChecked = false;
    if (this.Idea.Id > 0) {
      let tfs = this.global.PackagingTechnologies;
      let tfRels = await this.db.get("lookup", "getIdeaSecPackagingSystemRel", { IdeaId: this.Idea.Id })
      this.lsecpackform = [];
      tfs.forEach(x => {
        let tf = {
          id: x.Id,
          name: x.Name,
          description: x.Description,
          isChecked: false,
          refId: 0
        }
        let tfRel = tfRels.find(x => x.packagingTechnologyId === tf.id)
        if (tfRel != null) {
          tf.isChecked = true;
          tf.refId = tfRel.id
          if (foundFirstChecked == false) {
            foundFirstChecked = true;
            this.lsecpackformFirstChecked = tf;
          }
          this.lsecpackform.push(tf);
        }
        
      })
    }
  }

  async getIdeaTouchAndFeelRel() {
    if (this.Idea.Id > 0) {
      let tfs = this.global.TouchAndFeels;
      let tfRels = await this.db.get("lookup", "getTouchAndFeelRel", { IdeaId: this.Idea.Id })
      this.IdeaTouchAndFeelPrim = [];
      tfs.forEach(x => {
        let tf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        }
        let tfRel = tfRels.find(x => x.touchAndFeelId === tf.id)
        if (tfRel != null) {
          tf.isChecked = true;
          tf.refId = tfRel.id
          this.IdeaTouchAndFeelPrim.push(tf);
        }
        
      })
    }
  }
  async getPrintingEffectRel() {
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getPrintingEffectRel", { ideaId: this.Idea.Id });
      this.lPrintingEffects = [];
      this.global.PrintingEffects.forEach(x => {
        let ldf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        };
        let rel = resp2.find(r => r.printEffectId == x.Id);
        if (rel != null) {
          ldf.isChecked = true;
          ldf.refId = rel.id;
          this.lPrintingEffects.push(ldf);
        }
        
      });
    }
    let a = 5;
  }

  async getIdeaPackagingSystemRelationship() {
    this.lpackformFirstChecked = { name: "" };
    let foundFirstChecked = false;
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getPackagingSystemsForIdea", { ideaId: this.Idea.Id });
      this.lpackform = [];
      resp2.forEach(x => {
        let lpf = {
          id: x.id,
          name: x.name,
          description: x.description,
          isChecked: false,
          refId: 0
        }
        if (x.ideaPackRel != null) {
          lpf.isChecked = true;
          lpf.refId = x.ideaPackRel.id //primary key in table CompanyPackTechRel
          if (foundFirstChecked == false) {
            foundFirstChecked = true;
            this.lpackformFirstChecked = lpf;
          }
          this.lpackform.push(lpf);
        }
        
      })
    }
  }

  public lookup(id: any, array: any[], idField = "id", nameField = "name") {
    let ret = null; //default return
    if (id == null || array == null) return ret;
    array.forEach(item => {
      if (item[idField] == id) {
        ret = item[nameField];
      }
    });
    return ret;
  }
  dummy($event) {
    alert("Nothing to do here");
  }

  async getObservations() {
    if (this.Idea.Id > 0) {
      const resp = await this.db.get("lookup", "getObservations2", { ideaId: this.Idea.Id });
      this.observations = resp.observations;
      let pics = resp.pics;
      this.observations.forEach(o => {
        let pic = pics.find(p => p.id == o.documentId);
        if (pic != null) {
          o.url = pic.url;
        } else {
          o.url = "";
        }
      });
    }
    let a = 5;
  }
  async getDistributions() {
    if (this.Idea.Id > 0) {
      const resp = await this.db.get("lookup", "getDistributions", { ideaId: this.Idea.Id });
      this.distributions = resp.distributions;
      let pics = resp.pics;
      this.distributions.forEach(o => {
        let pic = pics.find(p => p.id == o.documentId);
        if (pic != null) {
          o.url = pic.url;
        } else {
          o.url = "";
        }
      });
    }
    let a = 5;
  }
}
