import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchresulttable',
  templateUrl: './searchresulttable.component.html',
  styleUrls: ['./searchresulttable.component.scss']
})
export class SearchresulttableComponent implements OnInit,OnChanges {
  @Input() table: any;
  @Input() tableName: "";

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    //alert("On Changes");
  }
}
