import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { GlobalService } from '../../../services/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topideas',
  templateUrl: './topideas.component.html',
  styleUrls: ['./topideas.component.scss']
})
export class TopideasComponent implements OnInit {
  ideas: any;
  constructor(private db: DbServiceService,
    private router: Router,
    private global: GlobalService) { }

  async ngOnInit() {
    this.ideas = await this.db.get("idea", "gettopecologyideas");
  }
  goto(idea) {
    this.global.IdeaId = idea.Id;
    this.router.navigate(['/dashboard/ideaedit']);
  }

}
