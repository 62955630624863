import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-ideacompare',
  templateUrl: './ideacompare.component.html',
  styleUrls: ['./ideacompare.component.scss']
})
export class IdeacompareComponent extends BaseComponent  implements OnInit {
  ideas: any = [];
  constructor(private injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    if (this.global.Selectids.length == 0) this.global.Selectids = [362, 363, 364];
    await this.getData();
    this.loader.hide();
  }

  async getData() {
    const resp = await this.db.post("Idea", "getIdeaCompare", this.global.Selectids);
    this.ideas = resp.ideas;
  }

}
