import { LoaderService } from './../modules/loader/loader.service';
// P.S. Serves as a repository for global variables
// in particular, it can store "querystring" variables from one page to the next
import { Injectable, Output, EventEmitter } from '@angular/core';
import { IdeaType } from '../models/IdeaCluster';
import { DbServiceService } from './db-service.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private subject = new Subject<any>();
  public hasLoaded = false;

  public IdeaId = 0; // What is the current idea
  public IdeaType: IdeaType; // Do we talk about a Idea, Concept, or Projects
  public IsPublic: boolean; // Do we show public or private records

  // tslint:disable-next-line: comment-format
  // All lookup vlues are available
  public ChangeTypes: any;
  public EconomyCycleStages: any;
  public Categories: any;
  public Brands: any;
  public Stores: any;
  public IdeaTypes: IdeaType[];
  public Impacts: any;
  public Status: any;
  public PackagingTechnologies: any;
  public SPICodes: any;

  public DosageForms: any;
  public Companies: any;
  public MaterialGroups: any;
  public MaterialForms: any;
  
  public CompanyIndustry: any;
  public TouchAndFeels: any;
  public YesNos: any;
  public EaseOfOpenings: any;
  public PackagingSpace: any;
  public ShelfPlacement: any;
  public BusinessArea: any;
  public TreatmentField: any;
  public Flavor: any;
  public Taste: any;
  public Color: any;
  public HowFullIsPack: any;
  public PrintingEffects: any;
  public PackagingStructures: any;
  public PackagePerception: any;
  public CompanySizes: any;
  public ExtRefTopics: any;
  public Selectids: any = []; //list of idea ids to send form one page to another

  public hasDataLoaded = false;


  constructor(private db: DbServiceService) { }

  // can be called for new ideas or concepts
  public initialize(IdeaId: number, IdeaTypeId: number) {
    console.log("global:initilaize");
    this.IdeaId = IdeaId; // Default is to create a new Idea
    this.IdeaType = this.IdeaTypes[IdeaTypeId];
    this.IsPublic = true; // Default are public records
  }

  public async getLookups() {
    console.log("global:getLookups");
    const response = await this.db.get('idea', 'getalllookups');
    //this.ChangeTypes = response.ChangeTypes;
    this.Brands = response.Brands;
    this.Stores = response.Stores;
    this.EconomyCycleStages = response.EconomyCycleStages;
    this.Categories = response.Categories;
    this.Impacts = response.Impacts;
    this.Status = response.Status;
    this.IdeaTypes = response.IdeaTypes;
    this.PackagingTechnologies = response.PackagingTechnologies;
    this.SPICodes = response.SPICodes;
    this.DosageForms = response.DosageForms;
    this.Companies = response.Companies;
    this.MaterialGroups = response.MaterialGroups;
    this.MaterialForms = response.MaterialForms;
    this.CompanyIndustry = response.CompanyIndustry;
    this.TouchAndFeels = response.TouchAndFeels;
    this.YesNos = response.YesNos;
    this.EaseOfOpenings = response.EaseOfOpenings;
    this.PackagingSpace = response.PackagingSpace;
    this.ShelfPlacement = response.ShelfPlacement;
    this.BusinessArea = response.BusinessArea;
    this.TreatmentField = response.TreatmentField;
    this.Flavor = response.Flavor;
    this.Taste = response.Taste;
    this.Color = response.Color;
    this.HowFullIsPack = response.HowFullIsPack;
    this.PrintingEffects = response.PrintingEffects;
    this.PackagingStructures = response.PackagingStructures;
    this.PackagePerception = response.PackagePerception;
    this.ExtRefTopics = response.ExtRefTopics;

    this.hasDataLoaded = true;
    this.subject.next({ text: 'all global lookups are loaded' });
    this.initialize(0, 1);
    console.log('global all lookups have loaded');
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
