import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Idea } from '../../../models/IdeaCluster';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { LoaderService } from '../../../modules/loader/loader.service';
import { DbServiceService } from '../../../services/db-service.service';

@Component({
  selector: 'app-btobproductlist',
  templateUrl: './btobproductlist.component.html',
  styleUrls: ['./btobproductlist.component.scss']
})
export class BtobproductlistComponent implements OnInit, OnChanges {
  Ideas: any = []
  IdeasDisplay: any = []
  ideatags1: any = []
  ideatags2: any = []
  ideatags3: any = []
  ideataglist1: any = []
  ideataglist2: any = []
  ideataglist3: any = []
  tag1 = "";
  tag2 = "";
  tag3 = "";
  showTagList1 = false
  showTagList2 = false
  showTagList3 = false
  constructor(private db: DbServiceService,
    public global: GlobalService,
    private loader: LoaderService,
    private router: Router) { }

  async ngOnChanges(changes: SimpleChanges){


  }

  async ngOnInit() {

  }
  async ngAfterViewInit() {

    this.submit();
  }

  async submit() {
    this.loader.show();
    this.Ideas = await this.db.get("idea", "getPublicIdeas", { type: "B-to-B Product" });

    this.Ideas.forEach(c => {
      c.ideatags1 = []
      c.ideatags2 = []
      c.ideatags3 = []
    })

    this.ideatags1 = await this.db.get("idea", "getAllTagsByCatAndIdeatype", { ideatypeid: 7, catid: 1 })
    this.ideataglist1 = await this.db.get("idea", "getAllTagsGroupedByCatandIdeatype", { ideatypeid: 7, catid: 1 })

    this.ideatags2 = await this.db.get("idea", "getAllTagsByCatAndIdeatype", { ideatypeid: 7, catid: 2 })
    this.ideataglist2 = await this.db.get("idea", "getAllTagsGroupedByCatandIdeatype", { ideatypeid: 7, catid: 2 })

    this.ideatags3 = await this.db.get("idea", "getAllTagsByCatAndIdeatype", { ideatypeid: 7, catid: 3 })
    this.ideataglist3 = await this.db.get("idea", "getAllTagsGroupedByCatandIdeatype", { ideatypeid: 7, catid: 3 })

    this.Ideas.forEach(idea => {
      this.ideatags1.forEach(t => {
        if (t.ideaId == idea.Id) idea.ideatags1.push(t)
      })
      this.ideatags2.forEach(t => {
        if (t.ideaId == idea.Id) idea.ideatags2.push(t)
      })
      this.ideatags3.forEach(t => {
        if (t.ideaId == idea.Id) idea.ideatags3.push(t)
      })
    })


    this.IdeasDisplay = this.Ideas
    this.loader.hide();

  }
  async checkChanged() {
    this.submit();
  }
  navigate(item) {
    this.global.IdeaId = item.Id;
    this.router.navigate(['/dashboard/productedit'], { queryParams: { 'IdeaId': item.Id } });
  }
  newRecord() {
    this.global.IdeaId = 0;
    this.router.navigate(['/dashboard/b2bproductedit'], { queryParams: { 'IdeaId': 0 } });
  }

  filter() {

    this.IdeasDisplay = this.Ideas
    // filter by tag
    if (this.tag1.length > 0) {
      let temp = this.IdeasDisplay;
      this.IdeasDisplay = [];

      temp.forEach(c => {
        let pushMaterial = false
        c.ideatags1.forEach(t => {
          if (t.tag == this.tag1) pushMaterial = true
        });
        if (pushMaterial) this.IdeasDisplay.push(c)
      });
    }
    if (this.tag2.length > 0) {
      let temp = this.IdeasDisplay;
      this.IdeasDisplay = [];

      temp.forEach(c => {
        let pushMaterial = false
        c.ideatags2.forEach(t => {
          if (t.tag == this.tag2) pushMaterial = true
        });
        if (pushMaterial) this.IdeasDisplay.push(c)
      });
    }
    if (this.tag3.length > 0) {
      let temp = this.IdeasDisplay;
      this.IdeasDisplay = [];

      temp.forEach(c => {
        let pushMaterial = false
        c.ideatags3.forEach(t => {
          if (t.tag == this.tag3) pushMaterial = true
        });
        if (pushMaterial) this.IdeasDisplay.push(c)
      });
    }
  }
  clear() {
    this.IdeasDisplay = this.Ideas;
    this.tag1 = ""
    this.tag2 = ""
    this.tag3 = ""
  }
}
