import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'survey-editor',
  templateUrl: './survey-editor.component.html',
  styleUrls: ['./survey-editor.component.scss']
})
export class SurveyEditorComponent implements OnInit {
  @Input() surveydata: any;
  @Output() surveydataChange = new EventEmitter();
  @Output() submitSurvey = new EventEmitter();
  element: any = {};
  hasTypeBeenSelected = false;
  elementIdBefore = 0;
  closeResult: string;
  content: NgbModalRef;
  modalTitle = '';
  confirmTitle = '';
  confirmText = '';
  types = [{ Id: 1, Name: 'rating' }, { Id: 2, Name: 'checkbox' }, { Id: 3, Name: 'radiogroup' }, { Id: 4, Name: 'comment' }];

  sourcetest = [{ Id: 1, Name: 'rating' }, { Id: 2, Name: 'checkbox' }, { Id: 3, Name: 'radiogroup' }, { Id: 4, Name: 'comment' }];
  confirmedtest = [{ id: 2, title: 'select1' }, { id: 1, title: 'select2' }];

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }
  edit(el, modalId) {
    this.hasTypeBeenSelected = true;
    this.modalTitle = 'Update this Question';
    this.element = el;
    this.content = this.modalService.open(modalId, { windowClass: 'dark-modal', size: 'lg' });
  }

  insert(el, modalId) {
    this.hasTypeBeenSelected = false;
    this.elementIdBefore = el.id;
    this.modalTitle = 'Insert a new Question';
    this.content = this.modalService.open(modalId, { windowClass: 'dark-modal', size: 'lg' });
    // P.S do type selection first and then call typeHasChanged
  }
  modalInsertConfirm(response) {
    if (response === 'Confirm') {
      if (this.element.id === 0) {
        this.surveydata.elements.splice(this.elementIdBefore, 0, this.element);
        this.surveydata.elements.forEach((x, i) => x.id = i + 1);
      }
    }
    this.surveydataChange.emit(this.surveydata);
    this.submitSurvey.emit(this.surveydata);
    this.content.close();
  }
  deleteElement(el, modalId) {
    this.element = el;
    this.confirmTitle = 'Confirm Deletion';
    this.confirmText = 'Do you want to delete the question?';
    this.content = this.modalService.open(modalId, { windowClass: 'dark-modal', size: 'sm' });
  }
  modalConfirmResponse(response: string) {
    if (response === 'Yes') {
      this.surveydata.elements = this.surveydata.elements.filter(item => item.id !== this.element.id);
      this.surveydata.elements.forEach((el, i) => { el.id = i + 1; }); // P.S. Renumber ids 1 to n
    }
    this.content.close();
  }
  updateList($event) {
    // P.S. Choices have ideas in the right order
    $event.forEach((item, i) => {
      item['id'] = i + 1;
      item['isSet'] = null;
    });
  }
  moveup(element) {
    const arr = this.surveydata.elements;
    const id = element.id - 1;
    if (id > 0) {
      const elementitem = arr[id];
      arr.splice(id, 1);
      arr.splice(id - 1, 0, elementitem);
    }
    arr.forEach((x, i) => x.id = i + 1);
  }
  movedown(element) {
    const arr = this.surveydata.elements;
    const id = element.id - 1;
    if (id < arr.length - 1) {
      const elementitem = arr[id];
      arr.splice(id, 1);
      arr.splice(id + 1, 0, elementitem);
    }
    arr.forEach((x, i) => x.id = i + 1);
  }
  typeHasChanged($event, type) {
    this.element.type = this.types.find(x => x.Id == this.element.type).Name;
    this.createNewElement(this.element.type);
    this.hasTypeBeenSelected = true;
  }

  createNewElement(type) {
    this.element = {};
    this.element.id = 0;
    this.element.type = type;
    this.element.name = 'new name';
    this.element.title = 'new title';

    if (this.element.type === 'checkbox' || this.element.type === 'radiogroup') {
      this.element.choices = [];
    }

    if (this.element.type === 'rating') {
      this.element.rateMin = 0;
      this.element.rateMax = 10;
      this.element.minRateDescription = 'Most unlikely';
      this.element.maxRateDescription = 'Most unlikely';
      this.element.result = '';
    }

    if (this.element.type === 'comment') {
      this.element.result = '';
    }
  }
}
