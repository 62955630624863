import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/shared/basecomponent';

@Component({
  selector: 'app-projectcharter',
  templateUrl: './projectcharter.component.html',
  styleUrls: ['./projectcharter.component.scss']
})
export class ProjectcharterComponent  extends BaseComponent implements OnInit {
  IdeaId = 0
  Idea: any = {}
  Url = ""
  Tasks: any = []
  TaskStatus: any = []
  constructor(injector: Injector) { super(injector); }

  async ngOnInit() {
    this.IdeaId = this.getIntQueryParamOrDefault('IdeaId',0)
    await this.getData()
  }
  async getData() {
    if (this.IdeaId > 0) {
      const ret: any = await this.db.get('idea', 'getidea', { Id: this.IdeaId });
      this.Idea = ret.Idea;
      this.Url = ret.PicUrl == null ? "" : ret.PicUrl;
      this.Tasks = ret.Tasks;
      this.TaskStatus = ret.TaskStatus;
    } 
  }
}
