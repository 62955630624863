import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-materialcompanyrel',
  templateUrl: './materialcompanyrel.component.html',
  styleUrls: ['./materialcompanyrel.component.scss']
})
export class MaterialcompanyrelComponent extends BaseComponent implements OnInit {
  @Input() materialId = 0;
  companies: any = [];
  companyMaterials: any = []; //list of materials augmented by checked is rel exist

  constructor(private injector: Injector) { super(injector) }

  ngOnInit() {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.materialId > 0) {
      await this.getData();
    }
  }

  async addCompany(company) {
    let rel = this.companyMaterials.find(x => x.companyId == company.id && x.materialId == this.materialId);
    if (rel == null) {
      await this.db.post("material", "postCompanyMaterialRels", null, { companyId: company.id, materialId: this.materialId });
      await this.getData();
    }
    $('#companyTag').hide();
  }
  async getData() {
    const resp = await this.db.get("company", "getCompanies");
    this.companies = resp.companies;
    this.companyMaterials = await this.db.get("material", "getCompanyMaterialRels2", { materialId: this.materialId });
    

  }
  add() {
    $('#companyTag').show();
  }

  closeCompanyModal() {
    $('#companyTag').hide();
  }

  async remove(m) {
    await this.db.delete("material", "deleteCompanyMaterialRels", { id: m.id });
    await this.getData();
  }
}
