import { Component} from '@angular/core';

@Component({
  selector: 'app-idealist',
  templateUrl: './idealist.component.html',
  styleUrls: ['./idealist.component.scss']
})
export class IdealistComponent{
  constructor() {}
}
