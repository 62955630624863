import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-materialgroup',
  templateUrl: './materialgroup.component.html',
  styleUrls: ['./materialgroup.component.scss']
})
export class MaterialgroupComponent extends BaseComponent implements OnInit {
  materialgroups: any = [];
  materialgroup: any = {};
  showDetail = false;

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.add();
    this.loader.hide();
  }
  async getData() {
    const resp = await this.db.get("lookup", "getMaterialGroups");
    this.materialgroups = resp;
  }
  selectMaterialgroup(matg) {
    this.materialgroup = matg;
    this.showDetail = true;
  }
  async saveMaterial() {
    this.loader.show();
    await this.db.post("lookup", "postMaterialGroup", this.materialgroup);
    await this.getData();
    this.loader.hide();
  }
  closemodal() {
    $('#changeTag').hide();
  }
  add() {
    this.materialgroup = {
      id: 0,
      name: "",
      description: ""
    };
  }
}
