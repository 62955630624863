import { Component, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-co2analyzer',
  templateUrl: './co2analyzer.component.html',
  styleUrls: ['./co2analyzer.component.scss']
})
export class Co2analyzerComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() ideaId = 0;
  @Input() weight = 1000; // in grams

  ideaMaterials: any = []; //list of materials associated with the idea
  totalco2 = 0;

  constructor(private injector: Injector) { super(injector) }

  async ngOnChanges(changes: SimpleChanges){
    await this.getData();
  }

  async ngOnInit() {
    await this.getData();
  }

  async getData() {
    this.ideaMaterials = await this.db.get("material", "getIdeaMatRelationships", { ideaId: this.ideaId });
    this.totalco2 = 0;
    this.ideaMaterials.forEach(m => {
      if (this.weight != null) {
        m.co2 = this.weight * m.percentByWeight * m.co2perKg;
      } else {
        m.co2 = 0;
      }
      this.totalco2 += m.co2;
    });

  }
  async refresh() {
    await this.getData();
  }
}
