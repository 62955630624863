import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
var href;

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.scss']
})
export class FormulaComponent implements OnInit {

  constructor() { }
  table: any = [];
  ngOnInit() {
    href = this;

    this.table = [];
    this.addRow(4, 1, 0.6, 0.4);
    this.addRow(5, 1.6, 1.0, 0.6);
    this.addRow(6, 2.3, 1.4, 0.9);
    this.addRow(7, 3.1, 1.9, 1.2);
    this.addRow(8, 4.0, 2.5, 1.5);
    this.addRow(9, 5.1, 3.2, 1.9);
    this.addRow(10, 6.3, 3.9, 2.4);
    this.addRow(11, 7.6, 4.8, 2.9);
    this.addRow(12, 9.0, 5.7, 3.4);
    this.addRow(13, 10.6, 6.6, 4.0);
    this.addRow(14, 12.3, 7.7, 4.6);
    this.addRow(15, 14.1, 8.8, 5.3);
    this.addRow(16, 16.1, 10.1, 6.0);
    this.addRow(17, 18.2, 11.4, 6.8);
    this.addRow(18, 20.4, 12.7, 7.6);
    this.addRow(19, 22.7, 14.2, 8.5);
    this.addRow(20, 25.7, 15.7, 9.4);
    this.addRow(25, 39.3, 24.5, 14.7);

    this.testSvg();
  }
  addRow(d: number, a: number, b: number, c: number) {
    let row = { d: d, a: a, b: b, c: c };
    this.table.push(row);
  }
  testSvg() {
    let margin = { top: 30, right: 20, bottom: 70, left: 50 },
      width = 800 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;

    // Set the ranges
    let x = d3.scaleLinear().domain([0, 30]).range([0, width]);
    let y = d3.scaleLinear().domain([0, 40]).range([height, 0]);

    // Define the axes
    var xAxis = d3.axisBottom(x).tickFormat(function (d) { return d['d']; });
    var yAxis = d3.axisLeft(y);


    // Define the line
    var line1 = d3.line()
      .x(function (d, i) { return x(d['d']); }) // set the x values for the line generator
      .y(function (d) { return y(d['a']); }) // set the y values for the line generator 
      .curve(d3.curveMonotoneX) // apply smoothing to the line

    var line2 = d3.line()
      .x(function (d, i) { return x(d['d']); }) // set the x values for the line generator
      .y(function (d) { return y(d['b']); }) // set the y values for the line generator 
      .curve(d3.curveMonotoneX) // apply smoothing to the line

    var line3 = d3.line()
      .x(function (d, i) { return x(d['d']); }) // set the x values for the line generator
      .y(function (d) { return y(d['c']); }) // set the y values for the line generator 
      .curve(d3.curveMonotoneX) // apply smoothing to the line
    let vis = d3.select("#graph");


    // 1. Add the SVG to the page and employ #2
    vis
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // 3. Call the x axis in a group tag
    vis.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x)); // Create an axis component with d3.axisBottom

    // 4. Call the y axis in a group tag
    vis.append("g")
      .attr("transform", "translate(30,0)")
      .call(d3.axisLeft(y)); // Create an axis component with d3.axisLeft

    // 9. Append the path, bind the data, and call the line generator 
    vis.append("path")
      .datum(href.table) // 10. Binds data to the line 
//      .attr("class", "path") // Assign a class for styling
      .style("stroke", "steelblue")
      .style("stroke-width", "2")
      .style("fill", "none")
      .attr("d", line1); // 11. Calls the line generator
    vis.append("path")
      .datum(href.table) // 10. Binds data to the line 
      //      .attr("class", "path") // Assign a class for styling
      .style("stroke", "orange")
      .style("stroke-width", "2")
      .style("fill", "none")
      .attr("d", line2); // 11. Calls the line generator
    vis.append("path")
      .datum(href.table) // 10. Binds data to the line 
      //      .attr("class", "path") // Assign a class for styling
      .style("stroke", "green")
      .style("stroke-width", "2")
      .style("fill", "none")
      .attr("d", line3); // 11. Calls the line generator 
  }

}
