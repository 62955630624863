import { YesnodialogComponent } from '../../shared/yesnodialog/yesnodialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SurveyComponent } from '../survey/survey.component';
import { DbServiceService } from 'src/app/services/db-service.service';

let href: any;

@Component({
  selector: 'app-surveytemplatemanager',
  templateUrl: './surveytemplatemanager.component.html',
  styleUrls: ['./surveytemplatemanager.component.scss']
})
export class SurveyTemplateManagerComponent implements OnInit {
  @ViewChild('survey1') survey1: SurveyComponent;
  @ViewChild('YesNo') yesno: YesnodialogComponent;

  showDebug = false;
  json = {};
  jsonDisplay = '';
  templates: any;
  template: any = {};
  hasValidTemplate = false;

  constructor(private db: DbServiceService) { }

  async ngOnInit() {
    href = this;
    this.refreshData();
  }
  refresh($event) {
    // alert('Survey submit is pressed, no action taken!')
    this.survey1.refresh(this.json);
    this.jsonDisplay = JSON.stringify(this.json, null, 2);
  }

  deleteTemplate(templ) {
    if (templ.Id > 1) {
      this.template = templ;
      this.yesno.show(
        'Confirm Dialog',
        'Please, confirm that you want to delete the template ' + this.template.Name,
        async () => {
          await href.db.delete('survey', 'deleteTemplate', { id: href.template.Id });
          href.templates = await href.db.get('survey', 'getTemplates');
          href.json = null;
        }
        , null);
    } else {
      alert('Let\'s keep at least the first template as a reference for the survey object');
    }
  }

  async saveTemplate() {
    if (this.template.Name === '') {
      alert('Please enter a template name');
    } else {
      this.template.RecordType = this.template.RecordType == "M" ? "M" : "I";
      this.template.Templatejson = JSON.stringify(this.json);
      const temp = await this.db.post('survey', 'postTemplate', this.template, { id: this.template.Id });
    }
  }

  async createTemplate() {
    this.template.Id = 0;
    this.template.Templatejson = JSON.stringify(this.json);
    const temp = await this.db.post('survey', 'postTemplate', this.template, { id: this.template.Id });
    this.refreshData();
  }

  selectTemplate(templ) {
    this.template = templ;
    this.template.RecordType = this.template.RecordType == "M" ? "M" : "I";

    this.json = JSON.parse(this.template.Templatejson);
    this.jsonDisplay = JSON.stringify(this.json, null, 2);
    this.hasValidTemplate = true;
  }

  async refreshData() {
    this.templates = await this.db.get('survey', 'getTemplates');
    this.json = null;
    this.hasValidTemplate = false;
    this.jsonDisplay = JSON.stringify(this.json, null, 2);
  }
}
