import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent extends BaseComponent implements OnInit {
  materials: any = [];
  materialsDisplay: any = [];
  material: any = {};
  materialsubgroupid = 0;
  materialsubgroups: any = [];
  matsysrel: any = [];
  materialsources: any = [];
  materialrecycabilities: any = [];
  subgroupId = 0;

  materialtags1: any = []
  materialtags2: any = []
  materialtags3: any = []
  materialtaglist1: any = []
  materialtaglist2: any = []
  materialtaglist3: any = []
  tag1 = "";
  tag2 = "";
  tag3 = "";
  showTagList1 = false
  showTagList2 = false
  showTagList3 = false

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    this.materialtags1 = await this.db.get("material", "getAllTagsByCat", { catid: 1 })
    this.materialtaglist1 = await this.db.get("material", "getAllTagsGroupedByCat", { catid: 1 })
    this.materialtags2 = await this.db.get("material", "getAllTagsByCat", { catid: 2 })
    this.materialtaglist2 = await this.db.get("material", "getAllTagsGroupedByCat", { catid: 2 })
    this.materialtags3 = await this.db.get("material", "getAllTagsByCat", { catid: 3 })
    this.materialtaglist3 = await this.db.get("material", "getAllTagsGroupedByCat", { catid: 3 })

    await this.getData();
    this.add();

    let matid = this.getIntQueryParamOrDefault('Id', 0);
    if (matid > 0) {
      let mat = this.materials.find(x => x.id == matid);
      this.selectMaterial(mat);
    }



    this.loader.hide();
  }

  async getData() {
    this.add(); //initial material
    const resp = await this.db.get("lookup", "getMaterials");
    await this.getMaterialSysRel();
    this.materials = resp.materials;
    this.materials.forEach(c => {
      c.tags1 = []
      c.tags2 = []
      c.tags3 = []
    })

    this.materialtags1.forEach(t => {
      let material = this.materials.find(x => x.id == t.materialId)
      if (material != null) material.tags1.push(t)
    })
    this.materialtags2.forEach(t => {
      let material = this.materials.find(x => x.id == t.materialId)
      if (material != null) material.tags2.push(t)
    })
    this.materialtags3.forEach(t => {
      let material = this.materials.find(x => x.id == t.materialId)
      if (material != null) material.tags3.push(t)
    })

    this.materialsubgroups = resp.materialsubgroups;
    this.materialrecycabilities = await this.db.get("lookup", "getMaterialRecycabilities");
    this.materialsources = await this.db.get("lookup", "getMaterialSources");
    this.materialsDisplay = this.materials;
  }

  async getMaterialSysRel() {
    if (this.material.id > 0) {
      const rels = await this.db.get("lookup", "getPackagingSystemsForMaterial", { materialId: this.material.id });
      const resp = await this.db.get("idea", "getallpackagingtechnologies");
      this.matsysrel = resp.packagingtechnologies;
      this.matsysrel.forEach(x => {
        x.id = x.Id;
        x.name = x.Name;
        x.description = x.Description;
        x.isChecked = false;
        x.refId = 0;
        let rel = rels.find(r => r.packsystemId == x.Id);
        if (rel != null) {
          x.isChecked = true;
          x.refId = rel.id;
        }
      });
    }
  }

  public async selectMaterial(mat) {
    this.material = mat;
    await this.getMaterialSysRel();
  }
  async saveMaterial() {
    this.loader.show();
    await this.db.post("lookup", "postMaterial", this.material);
    await this.getData();
    this.loader.hide();
  }
  closemodal() {
    $('#changeTag').hide();
  }
  async addPackSysRel(df) {
    this.loader.show();
    let newRel = { MaterialId: this.material.id, PacksystemId: df.id };
    await this.db.post("lookup", "postMaterialPackTechRel", newRel);
    await this.getMaterialSysRel();
    this.loader.hide();
  }
  async removePackSysRel(pf) {
    this.loader.show();
    await this.db.delete("lookup", "deleteMaterialPackTechRel", { id: pf.refId });
    await this.getMaterialSysRel();
    this.loader.hide();
  }
  add() {
    this.material = {
      id: 0,
      name: "",
      description: "",
      subgroupId: 2
    };
  }
  filter() {

    if (this.subgroupId > 0) {
      this.materialsDisplay = this.materials.filter(x => x.subgroupId == this.subgroupId);
    } else {
      this.materialsDisplay = this.materials
    }

    // filter by tag
    if (this.tag1.length > 0) {
      let temp = this.materialsDisplay;
      this.materialsDisplay = [];

      temp.forEach(c => {
        let pushMaterial = false
        c.tags1.forEach(t => {
          if (t.tag == this.tag1) pushMaterial = true
        });
        if (pushMaterial) this.materialsDisplay.push(c)
      });
    }
    if (this.tag2.length > 0) {
      let temp = this.materialsDisplay;
      this.materialsDisplay = [];

      temp.forEach(c => {
        let pushMaterial = false
        c.tags2.forEach(t => {
          if (t.tag == this.tag2) pushMaterial = true
        });
        if (pushMaterial) this.materialsDisplay.push(c)
      });
    }
    if (this.tag3.length > 0) {
      let temp = this.materialsDisplay;
      this.materialsDisplay = [];

      temp.forEach(c => {
        let pushMaterial = false
        c.tags3.forEach(t => {
          if (t.tag == this.tag3) pushMaterial = true
        });
        if (pushMaterial) this.materialsDisplay.push(c)
      });
    }
  }
  clear() {
    this.materialsDisplay = this.materials;
    this.tag1 = ""
    this.tag2 = ""
    this.tag3 = ""
    this.subgroupId = 0
  }
}
