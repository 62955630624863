import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wvtr',
  templateUrl: './wvtr.component.html',
  styleUrls: ['./wvtr.component.scss']
})
export class WvtrComponent implements OnInit {
  Layers = [];
  Thickness = 0;
  Pressure = 35.0;
  WVTR = 0;
  WVTRTab = 0;
  ReqAbsorbtion = 0;
  LimitDays = 0;
  FormFactorId = 0;

  Materials = [
    { 'Id': 1, 'Name': 'please pick', 'Perm': 0, 'Corr': 0 },
    { 'Id': 2, 'Name': 'COC', 'Perm': 1.684, 'Corr': 0.47 },
    { 'Id': 3, 'Name': 'EVOH', 'Perm': 6.381, 'Corr': 0.32 },
    { 'Id': 4, 'Name': 'PCTFE', 'Perm': 0.135, 'Corr': 1 },
    { 'Id': 5, 'Name': 'PE(HD)', 'Perm': 2.653, 'Corr': 0.41 },
    { 'Id': 6, 'Name': 'PE(LD)', 'Perm': 4.327, 'Corr': 0.36 },
    { 'Id': 7, 'Name': 'PP', 'Perm': 4.322, 'Corr': 0.37 },
    { 'Id': 8, 'Name': 'PVC', 'Perm': 16.875, 'Corr': 0.24 },
    { 'Id': 9, 'Name': 'PVdC', 'Perm': 0.402, 'Corr': 0.72 }];

  FormFactors = [
    { 'Id': 1, 'Name': 'please pick' },
    { 'Id': 2, 'Name': 'Flat film' },
    { 'Id': 3, 'Name': 'SCT' },
    { 'Id': 4, 'Name': 'FCT' }];

  FormFactor = this.FormFactors[0];
  Temperature = 40;
  Humidity = 75;

  SCT_AREA = 0.00008609;
  FCT_AREA = 0.00008638;
  SCT_CORR = 0.5367;
  FCT_CORR = 0.6158;
  PermeabilityCoeff = 0;
  Permeability = 0;
  CorrThickness = 1;
  NMonths = 0;
  H2OAbsorbtion = 0;

  showMaterials = false;
  showDocu = false;

  constructor() { }

  ngOnInit() {
    for (var i = 0; i < 2; i++) {
      var layer:any = {};
      layer.Id = i + 1;
      layer.Material = this.Materials[0];
      layer.MaterialId = 1;
      layer.Thickness = 0;
      this.Layers.push(layer);
    }
  }
  addLayer() {
    var layer: any = {};
    layer.Id = this.Layers.length
    layer.Material = this.Materials[0];
    layer.MaterialId = 1;
    layer.Thickness = 0;
    this.Layers.push(layer);
  }
  changeLayer() {

    this.Thickness = 0;
    this.CorrThickness = 0;
    this.PermeabilityCoeff = 0;
    let PermDen = 0; //temp variable for denominator of permeability calc
    this.FormFactor = this.FormFactors.find(x => x.Id == this.FormFactorId);
    this.Layers.forEach(layer => {
      this.Thickness += layer.Thickness; //Add Thicknesses
      if (this.FormFactor.Name === "SCT") this.CorrThickness += this.SCT_CORR * layer.Thickness;
      if (this.FormFactor.Name === "FCT") this.CorrThickness += this.FCT_CORR * layer.Thickness;
      //set the material 
      layer.Material = this.Materials.find(function (m) { return layer.MaterialId == m.Id; });
      //set the Flat Film row
      layer.FlatFilm = this.FormFactor.Name === "Flat film" ? layer.Material.Perm * this.Pressure / layer.Thickness : 0;

      layer.SCT = 0;//SCT
      if (this.FormFactor.Name === "SCT") {
        layer.SCT = 1000 * layer.Material.Perm * Math.pow(this.SCT_CORR * layer.Thickness / layer.Thickness, -layer.Material.Corr);
        layer.SCT *= this.SCT_AREA * this.Pressure / this.SCT_CORR / layer.Thickness;
      }

      layer.FCT = 0;
      if (this.FormFactor.Name === "FCT") {
        layer.FCT = 1000 * layer.Material.Perm * Math.pow(this.FCT_CORR * layer.Thickness / layer.Thickness, -layer.Material.Corr);
        layer.FCT *= this.FCT_AREA * this.Pressure / this.FCT_CORR / layer.Thickness;
      }

      layer.PSCT = 0.000000000001;
      layer.PFCT = 0.000000000001;
      if (layer.Thickness >= 1) {
        layer.PSCT = layer.Material.Perm * Math.pow(this.SCT_CORR * layer.Thickness / layer.Thickness, -layer.Material.Corr);
        layer.PFCT = layer.Material.Perm * Math.pow(this.FCT_CORR * layer.Thickness / layer.Thickness, -layer.Material.Corr);
      }
      //permeability
      if (this.FormFactor.Name === "Flat film") {
        if (layer.Material.Perm > 0) {
          PermDen += layer.Thickness / layer.Material.Perm;
        }
      }
      if (this.FormFactor.Name === "SCT") {
        if (layer.Material.Perm > 0) {
          PermDen += this.SCT_CORR * layer.Thickness / layer.PSCT;
        }
      }
      if (this.FormFactor.Name === "FCT") {
        if (layer.Material.Perm > 0) {
          PermDen += this.FCT_CORR * layer.Thickness / layer.PFCT;
        }
      }
    });
    if (this.FormFactor.Name === "Flat film") {
      this.PermeabilityCoeff = this.Thickness / PermDen;
    }
    if (this.FormFactor.Name === "SCT") {
      this.PermeabilityCoeff = this.CorrThickness / PermDen;
    }
    if (this.FormFactor.Name === "FCT") {
      this.PermeabilityCoeff = this.CorrThickness / PermDen;
    }


    //calculate the pressure from temperature and humidity
    this.Pressure = 4.5959 * Math.exp(17.5043 * this.Temperature / (this.Temperature + 241.2)) * this.Humidity / 100.0;


    this.WVTR = this.PermeabilityCoeff * this.Pressure / this.Thickness;
    if (this.FormFactor.Name === "SCT") this.WVTR *= 11.616;
    if (this.FormFactor.Name === "FCT") this.WVTR *= 11.577;
    this.Permeability = this.Thickness * this.WVTR;

    this.WVTRTab = 1000 * this.PermeabilityCoeff * this.Pressure / this.CorrThickness;
    if (this.FormFactor.Name === "SCT") this.WVTRTab *= this.SCT_AREA;
    if (this.FormFactor.Name === "FCT") this.WVTRTab *= this.FCT_AREA;
    if (this.FormFactor.Name === "Flat film") this.WVTRTab = 0;

    this.ReqAbsorbtion = this.WVTRTab * this.NMonths * 30;
    this.LimitDays = this.H2OAbsorbtion / this.WVTRTab;
    if (this.FormFactor.Name === "Flat film") {
      this.ReqAbsorbtion = 0;
      this.LimitDays = 0;
    }
  };

}
