import { snaAlertService } from './../../../modules/sna-alert/sna-alert.service';
import { Component, OnInit } from '@angular/core';
import { DbServiceService } from 'src/app/services/db-service.service';

@Component({
  selector: 'app-testang',
  templateUrl: './testang.component.html',
  styleUrls: ['./testang.component.scss']
})
export class TestangComponent implements OnInit {
  idea: any = {};
  cat: any = [];
  showDocu = false;

  sTest = 'Hwllo';
  constructor(private db: DbServiceService,
    private alert: snaAlertService) { }

 async ngOnInit() {
    const resp = await this.db.get('idea','getidea', {id: 5});
    this.idea = resp.Idea;
    this.cat = resp.IdeaIdeaCatRel;
  }
  childHasSelectedRow(row){
    alert("Parent got called from child: "+row);
  }
  testDialog() {
    this.alert.success('Success')
  }
}
