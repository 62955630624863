import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'survey-checkbox',
  templateUrl: './survey-checkbox.component.html',
  styleUrls: ['../../style.css']
})
export class SurveyCheckboxComponent implements OnInit {
  @Input() element: any;
  @Input() index: any;
  @Output() registerEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.registerEvent.emit(this);
  }
  toggle(cb) {
    if (cb.isSet == null) {
      cb.isSet = true;
    } else {
      cb.isSet = !cb.isSet;
    }
  }
}
