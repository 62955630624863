import { DbServiceService } from "../../services/db-service.service";

// A list of methods to do survey analysis
export class SurveyAnalysis {

  constructor(private db: DbServiceService) { }

  public async analyse10TypeOfIdeas() {
    const surveyData = await this.db.get("survey", "getSurveyResultsIdeas", { templateid: 31 });
    surveyData.forEach(sd => {sd.json = JSON.parse(sd.DataJson)})
    const sdByIdeas = this.groupBy(surveyData, sd => sd.RefId);
    let data = this.getData(sdByIdeas);
    return data; // returns key = ideaid, idea name and value = average
  }

  getData(sdByIdeas) {
    let data: any = [];
    sdByIdeas.forEach(g => { //loop over ideas
      let result = { key: g.key, ideaid: 0, name: "", description: "", url: "", value: 0, sum: 0, count: 0 }
      g.forEach(sd => { //loop over surveys
        result.ideaid = sd.idea.Id;
        result.key = sd.idea.Name;
        result.name = sd.idea.Name;
        result.description = sd.idea.Description;
        result.url = sd.url;
        let value = this.getNumCheckboxYes(sd.json.elements);
        result.sum += value;
        result.count += 1;
      });
      result.value = result.sum / result.count;
      data.push(result);
    });
    data = data.sort((a, b) => { return b.value - a.value; } );
    return data;
  }

  getNumCheckboxYes(elements) {
    let value = 0;
    elements.forEach(el => {
      if (el.type == "checkbox") {
        el.choices.forEach(c => {
          if (c.title == "Yes" || c.title == "yes") {
            if (c.isSet == true) value += 1;
          }
        });
      }
    });
    return value;
  }

  /**************************************************************************************************************************************
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
  //export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  //    const map = new Map<K, Array<V>>();
  public groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
}
