import { LoaderService } from './../../../modules/loader/loader.service';
import { DbServiceService } from '../../../services/db-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import * as $ from 'jquery';

@Component({
  selector: 'app-campaignmenagement',
  templateUrl: './campaignmenagement.component.html',
  styleUrls: ['./campaignmenagement.component.scss']
})
export class CampaignmenagementComponent implements OnInit {
  campaigns: any = [];
  campaign: any = {};
  campaignIdeas: any = [];
  campaignMaterials: any = [];
  campaignIdea: any = {};
  campaignUserSurveys: any = [];
  campaignUserSurvey: any = {};
  status: any = [];
  surveyTemplates: any = [];
  ideas: any = [];
  ideaTypes: any = [];
  users: any = [];
  materials: any = [];
  template: any = {};


  constructor(
    private db: DbServiceService,
    public global: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private loader: LoaderService,
    public modal: NgbModal,
    private alert: snaAlertService) { }

  async ngOnInit() {
    this.loader.show();
    const lookups = await this.db.get("campaign", "getLookups");
    this.status = lookups.status;
    this.surveyTemplates = lookups.surveyTemplates;
    this.ideas = lookups.ideas;
    this.materials = lookups.materials;
    this.ideaTypes = lookups.ideaTypes;
    this.users = lookups.users;

    await this.getData();
    let campaignid = this.getIntQueryParamOrDefault("campaignid", 0);
    if (campaignid > 0) {
      let campaign = this.campaigns.find(c => c.id == campaignid);
      if (campaign != null) {
        this.selectCampaign(campaign)
      }
    }
    
    this.loader.hide();
  }

  getIntQueryParamOrDefault(sQuery: string, iDefault: number) {
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString != null) { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }

  async selectCampaign(campaign) {
    this.loader.show();
    const ret = await this.db.get("campaign", "getCampaign", { id: campaign.id });
    this.campaign = ret.campaign;
    this.template = ret.template;
    this.campaignIdeas = ret.campaignIdeas;
    this.campaignUserSurveys = ret.campaignUserSurveys;
    this.campaignUserSurveys.forEach(cus => cus.isSelected = false);
    this.loader.hide();
  }

  openCampaign(campaign) {
    this.campaign = campaign == null ? { id: 0, name: "", surveyTemplateId: 0 } : campaign;
    $("#campaigntag").show();
  }
  async submitCampaign() {
    this.loader.show();
    this.campaign = await this.db.post("campaign", "postCampaign", this.campaign);
    this.getData();
    this.loader.hide();
    this.closeModal("campaigntag");
  }
  async sendEmail() {
    let sub:any = [];
    this.campaignUserSurveys.forEach(cus => {
      if (cus.isSelected == true) {
        sub.push(cus);
      }
    });

    if (sub.length > 0) {
      await this.db.post("campaign", "sendSurveys", sub);
      await this.selectCampaign(this.campaign);
    } 
  }

  openCampaignIdea(campaignIdea) {
    this.campaignIdea = campaignIdea == null ? { id: 0, CampaignId: this.campaign.id, ideaId: 0 } : campaignIdea;
    $("#campaignideatag").show();
  }
  async removeIdea(campaignIdea) {
    this.loader.show();
    await this.db.delete("campaign", "deleteCampaignIdea", { id: campaignIdea.id });
    await this.getData();
    this.loader.hide();
    await this.selectCampaign(this.campaign);
  }

  async submitCampaignIdea(idea) {
    this.loader.show();
    this.campaignIdea = { id: 0, campaignId: this.campaign.id, ideaId: idea.id };
    await this.db.post("campaign", "postCampaignIdea", this.campaignIdea);
    await this.getData();
    this.loader.hide();
    await this.selectCampaign(this.campaign);

    this.closeModal("campaignideatag");
  }
  async getData() {
    const resp = await this.db.get("campaign", "getCampaigns");
    this.campaigns = resp.campaigns;
  }
  openUser() {
    $("#campaignusertag").show();
  }
  async impersonate(cus) {
    const u:any = await this.db.get("user", "getUSer", { id: cus.userprofileId })
    const query = { IdeaId: cus.ideaId, surveytemplateId: cus.surveytemplateId, token: u.RegToken }
    this.router.navigate(['/dashboard/fillsurvey/'], { queryParams: query });
  }
  async circularEcon(cus) {
    const u: any = await this.db.get("user", "getUSer", { id: cus.userprofileId })
    const query = { IdeaId: cus.ideaId, surveytemplateId: cus.surveytemplateId, token: u.RegToken }
    this.router.navigate(['/dashboard/fillcirc/'], { queryParams: query });
  }

  async submitUser(user) {
    this.loader.show();
    const cus: any = [];
    this.campaignIdeas.forEach(idea => {
      const cu:any = {};
      cu.userprofileId = user.id;
      cu.ideaId = idea.ideaId;
      cu.campaignId = this.campaign.id;
      cu.surveytemplateId = this.campaign.surveyTemplateId;
      cu.statusId = 1;
      cus.push(cu);
    })

    await this.db.post("campaign", "postCampaignUserSurveys", cus);
    await this.getData();
    this.loader.hide();
    await this.selectCampaign(this.campaign);

    this.closeModal("campaignusertag");
  }
  async removeUser(u) {
    this.loader.show();
    await this.db.delete("campaign", "deleteCampaignUserSurvey", { id: u.id });
    await this.getData();
    this.loader.hide();
    await this.selectCampaign(this.campaign);
  }
  closeModal(tag) { $("#" + tag).hide(); }

  removeMaterial(m) {

  }
  openCampaignMaterial(material) {
    $("#campaignmaterialtag").show();
  }
  submitCampaignMaterial(m) {

  }
}
