import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-dosageformshow',
  templateUrl: './dosageformshow.component.html',
  styleUrls: ['./dosageformshow.component.scss']
})
export class DosageformshowComponent implements OnInit {
  dosforms: any[];
  dosform: any;
  image: any;
  constructor(private db: DbServiceService,
    private alert: snaAlertService,
    private loader: LoaderService
  ) { }

  async ngOnInit() {
    this.loader.show();
    this.dosforms = await this.db.get("dosageform", "getDosageforms");
    this.dosform = this.dosforms[0];
    if (this.dosform.Images.length > 0) {
      this.image = await this.db.get("document", "getImage", { Id: this.dosform.Images[0].Id })
    }
    this.loader.hide();
  }

  async selectDosform(dosform) {
    this.image = null;
    if (dosform.Images.length > 0) {
      this.image = await this.db.get("document", "getImage", { Id: dosform.Images[0].Id })
    }
    this.dosform = dosform;
  }

}
