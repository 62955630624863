import { Router, ActivatedRoute } from "@angular/router";
import { Injector } from "@angular/core";
import { Location } from '@angular/common';
import { LoaderService } from "../../modules/loader/loader.service";
import { snaAlertService } from "../../modules/sna-alert/snaAlertModule";
import { DbServiceService } from "../../services/db-service.service";
import { GlobalService } from "../../services/global.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

export class BaseComponent {
  public loader: LoaderService;
  protected alert: snaAlertService;
  protected db: DbServiceService;
  protected router: Router;
  protected route: ActivatedRoute;
  protected location: Location;
  public global: GlobalService;
  public modal: NgbModal;

  constructor(injector: Injector) {
    this.loader = injector.get(LoaderService);
    this.alert = injector.get(snaAlertService);
    this.db = injector.get(DbServiceService);
    this.global = injector.get(GlobalService);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.location = injector.get(Location);
    this.modal = injector.get(NgbModal);
  }

  //P.S. used in component to lookup a value from a lookup table
  public lookup(id: any, array: any[], idField = "id", nameField = "name") {
    let ret = null; //default return
    if (id == null || array == null) return ret;
    array.forEach(item => {
      if (item[idField] == id) {
        ret = item[nameField];
      }
    });
    return ret;
  }

  public loadCsv(data: any) {
    let csv = [];
    if (data.length > 0) {
      const keys = Object.keys(data[0]);
      const header = {};
      keys.forEach(x => {
        header[x] = x;
      });
      csv.push(header);
      data.forEach(d => {
        let recPush = {};
        keys.forEach(x => {
          recPush[x] = d[x] == null ? " " : d[x];
          if (d[x] === 0) recPush[x] = "0";
          if (d[x] === "") recPush[x] = " ";
        });
        csv.push(recPush);
      });
    }
    return csv;
  }
  checkRole(role) {
    //if (this.global.roles.length == 0) return false;
    //const index = this.global.roles.indexOf(role);
    //return index > -1 ? true : false;
  }
  getDateString(d = new Date(), offsetyears = 0, offsetmonths = 0, offsetdays = 0) {
    if (d == null) d = new Date();
    d.setDate(d.getDate() + offsetdays);
    d.setMonth(d.getMonth() + offsetmonths);
    d.setFullYear(d.getFullYear() + offsetyears);
    const dobj = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    return dobj;
  }
getIntQueryParamOrDefault(sQuery: string, iDefault: number) {
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString != null) { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }
  getStringQueryParamOrDefault(sQuery: string, sDefault: string) {
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = sDefault;
    if (QueryString != null) { // P.S. called with non-empty IdeaId
      ret = QueryString;
    }
    return ret;
  }

  shallowCopy(o) {
    return Object.assign({}, o);
  }
  deepCopy(o) {
    return JSON.parse(JSON.stringify(o));
  }

  compareValues = function (key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }
  getFileExtension(s) {
    let index = s.lastIndexOf(".");
    if (index < 0) return "";
    let sRet = s.slice(index+1).toLowerCase();
    return sRet;
  }
  groupBy(array, keyname) {
    //returns an array of keys and values
    //where values is an array of elements of array with key
    let result = array.reduce((prev, cur) => {
      let existing = prev.find(x => x.key === cur[keyname]);

      if (existing)
        existing.values.push(cur)
      else
        prev.push({
          key: cur[keyname],
          values: [cur]
        });
      return prev;
    }, []);
    return result;
  }
  addHttp(url: string) {
    let ret = url;
    if (url && url.length > 4 && url.substring(0, 4).toLowerCase() != "http") {
      ret = "http://" + url;
    }
    return ret;
  }
}
