import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sa-select-multiple',
  templateUrl: './sa-select-multiple.component.html',
  styleUrls: ['../formfields.css']
})
export class SaSelectMultipleComponent implements OnInit {
  @Input() title: string;
  @Input() required: string;
  @Input() options: any;
  @Output() hasChanged = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  change(newValue) {
    this.hasChanged.emit(this.options);
  };
}
