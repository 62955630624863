import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-sustopics',
  templateUrl: './sustopics.component.html',
  styleUrls: ['./sustopics.component.scss']
})
export class SustopicsComponent implements OnInit {
  topics: any[];
  topic: any;

  image: any;
  constructor(private db: DbServiceService,
    private alert: snaAlertService,
    private loader: LoaderService) { }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.loader.hide();
  }

  async getData() {
    const resp = await this.db.get("sustainability", "getSustainabilityTopics");
    this.topics = resp.sustopics;
    this.topics.forEach(c => {
      let url = c.website;
      if (url && url.length > 4 && url.substring(0, 4).toLowerCase() != "http") {
        c.url = "http://" + c.website;
      } else {
        c.url = c.website;
      }
    });
    await this.selectTopic(this.topics[0]);

  }

  async selectTopic(top) {
    this.image = null;
    if (top.images.length > 0) {
      this.image = await this.db.get("document", "getImage", { Id: top.images[0].id });
    }
    this.topic = top;
  }
}
