import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-manageinnoproducts',
  templateUrl: './manageinnoproducts.component.html',
  styleUrls: ['./manageinnoproducts.component.scss']
})
export class ManageinnoproductsComponent extends BaseComponent implements OnInit {
  innoproducts: any = [];
  innoproduct: any = {};

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.add();
    this.loader.hide();
  }

  async getData() {
    const resp = await this.db.get("innoproduct", "getInnoProducts");
    this.innoproducts = resp.innoproducts;
  }

  add() {
    this.innoproduct = {
      id: 0,
      name: "",
      description: "",
      website: ""
    };
  }

  selectTopic(proc) {
    this.innoproduct = proc;
  }

  async saveTopic() {
    this.loader.show();
    if (this.innoproduct.name.length < 1) {
      this.alert.warning("Please, enter a name");
      this.loader.hide();
      return;
    }

    await this.db.post("innoproduct", "postInnoProduct", this.innoproduct);
    await this.getData();
    this.loader.hide();
  }
}
