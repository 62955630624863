import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import * as $ from 'jquery';

@Component({
  selector: 'app-siegelhof',
  templateUrl: './siegelhof.component.html',
  styleUrls: ['./siegelhof.component.scss']
})
export class SiegelhofComponent implements OnInit {
  Height = 5;
  HDiameter = 20;
  VDiameter = 20;
  Seal = 5;
  SiegelhofH = 0;
  SiegelhofV = 0;
  SiegelhofHArr: any = [];
  SiegelhofVArr: any = [];

  examples = [
    { Id: 0, "Name": "None", Height: 0, Diameter: 0, Seal: 0 },
    { Id: 1, "Name": "Example 1", Height: 8.2, HDiameter: 25.4, VDiameter: 25.4, Seal: 4 },
    { Id: 2, "Name": "Example 2", Height: 6.2, HDiameter: 21.2, VDiameter: 21.2, Seal: 3 }
  ];

  example = { Id: 0 };

  showDocu = false;

  constructor() { }

  ngOnInit() {
  }
  loadExample() {
    var d: any = this.examples.find(x => x.Id == this.example.Id);
    this.Height = d.Height;
    this.HDiameter = d.HDiameter;
    this.VDiameter = d.VDiameter;
    this.Seal = d.Seal;
  }
  calculate() {
    this.SiegelhofH = (this.Height / 0.354) + this.HDiameter + (this.Seal * 2);
    this.SiegelhofV = (this.Height / 0.354) + this.VDiameter + (this.Seal * 2);
    this.SiegelhofHArr = [

      { id: 1, name: "1. Seal Start", x: 0 },
      { id: 2, name: "1. Seal End", x: this.Seal },
      { id: 3, name: "2. Seal Start", x: this.Seal + this.HDiameter + (this.Height / 0.354) },
      { id: 4, name: "2. Seal End", x: 2 * this.Seal + this.HDiameter + (this.Height / 0.354) }
    ];
    this.SiegelhofVArr = [

      { id: 1, name: "1. Seal Start", y: 0 },
      { id: 2, name: "1. Seal End", y: this.Seal },
      { id: 3, name: "2. Seal Start", y: this.Seal + this.VDiameter + (this.Height / 0.354) },
      { id: 4, name: "2. Seal End", y: 2 * this.Seal + this.VDiameter + (this.Height / 0.354)}
    ];

    this.createGraph();
  };

  createGraph() {
    d3.select("svg").remove();
    let heightApp = this.Height / 0.354;
    var margin = 40; //{ top: 30, right: 40, bottom: 30, left: 40 };
    let width = Math.round(Number(d3.select("#container").style('width').slice(0, -2)) - 2 * margin);
    let ratio = width / (this.VDiameter + 2 * (this.Seal + heightApp));
    let height = Math.round(Number((this.HDiameter + 2 * (this.Seal + heightApp)) * ratio));
    let seal = Math.round(Number(this.Seal * ratio));
    let ha = Math.round(Number(heightApp * ratio));
    let vd = Math.round(Number(this.VDiameter * ratio));
    let hd = Math.round(Number(this.HDiameter * ratio));

    let xScale = d3.scaleLinear()
      .domain([0, this.SiegelhofHArr[3].x])
      .range([0, width]);

    let yScale = d3.scaleLinear()
      .domain([0, this.SiegelhofVArr[3].y])
      .range([0, height]);

    var svg = d3.select("#container").append("svg")
      .attr("width", width + 2 * margin)
      .attr("height", height + 2 * margin)
      .style("border","solid 1px black")
      .append("g")
      .attr("transform", "translate(" + margin + "," + margin + ")");

    //P.S. Debugging
    svg.append("text").attr("x", 10).attr("y", -10).text("Width: " + width + " Height: " + height + " ha: "+ha+" vd: "+vd+" hd: "+hd);

    svg.append('rect')
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height)
      .style("fill", "#ddd");

    svg.append("ellipse")
      .attr("cx", 1 + width / 2)
      .attr("cy", 1 + height / 2)
      .attr("rx", xScale(this.HDiameter / 2))
      .attr("ry", yScale(this.VDiameter / 2))
      .style("fill", "#ff6a00");


    this.SiegelhofHArr.forEach(a => {
      this.drawLine(svg, xScale(a.x), 0, xScale(a.x), height).attr("stroke-width", 2).attr("stroke", "gray").attr("stroke-dasharray","5, 5");
    })
    this.SiegelhofVArr.forEach(a => {
      this.drawLine(svg, 0, yScale(a.y), width, yScale(a.y)).attr("stroke-width", 2).attr("stroke", "gray").attr("stroke-dasharray", "5, 5");
    })

    //let haround = Number.parseFloat(heightApp.toString()).toFixed(2);
    //let vData = ['', this.Seal, '', haround, '', this.VDiameter, '', haround, '', this.Seal, ''];
    //let x = d3.scaleThreshold()
    //  .domain([0, seal / 2, seal, seal + ha / 2, seal + ha, width / 2, seal + ha + vd, width - seal - ha / 2, width - seal, width - seal / 2, width])
    //  .range([0, 0, seal / 2, seal, seal + ha / 2, seal + ha, width / 2, seal + ha + vd, width - seal - ha / 2, width - seal, width - seal / 2, width]);

    //var x_axis = d3.axisBottom(x)
    //  .tickSizeOuter(0)
    //  .tickFormat();

    //svg.append("g")
    //  .attr("transform", "translate(0, " + (height + 5) + ")")
    //  //.call(x_axis)
    //  .selectAll(".tick line")
    //  .classed("grid", function (d, i) {
    //    if (i > 0 && i < 10 && i % 2 === 0) return true;
    //    else return false;
    //  })
    //  .attr("y2", function (d, i) {
    //    if (i === 0 || i === 10) return -5;
    //    else if (i % 2 === 0) return -height - 5;
    //    else return 6;
    //  });

    //var w_axis = d3.axisTop()
    //  .tickSizeOuter(0)
    //  .tickFormat(function (d, i) { if (i % 2 === 1) return Number.parseFloat(this.SiegelhofV).toFixed(2); })
    //  .scale(d3.scaleThreshold().domain([0, width / 2, width]).range([0, 0, width / 2, width]));
    //svg.append("g")
    //  .attr("transform", "translate(0, -6)")
    //  .call(w_axis)
    //  .selectAll(".tick line")
    //  .attr("y2", function (d, i) {
    //    if (i % 2 === 0) return 6;
    //    else return -5;
    //  });

    //var hData = ['', this.Seal, '', haround, '', this.HDiameter, '', haround, '', this.Seal, ''];
    //let y = d3.scaleThreshold()
    //  .domain([0, seal / 2, seal, seal + ha / 2, seal + ha, height / 2, seal + ha + hd, height - seal - ha / 2, height - seal, height - seal / 2, height])
    //  .range([0, 0, seal / 2, seal, seal + ha / 2, seal + ha, height / 2, seal + ha + hd, height - seal - ha / 2, height - seal, height - seal / 2, height]);
    //var y_axis = d3.axisRight()
    //  .tickSizeOuter(0)
    //  .tickFormat(function (d, i) { return hData[i]; })
    //  .scale(y);
    //svg.append("g")
    //  .attr("transform", "translate(" + (width + 5) + ", 0)")
    //  .call(y_axis)
    //  .selectAll(".tick line")
    //  .classed("grid", function (d, i) {
    //    if (i > 0 && i < 10 && i % 2 === 0) return true;
    //    else return false;
    //  })
    //  .attr("x2", function (d, i) {
    //    if (i === 0 || i === 10) return -5;
    //    else if (i % 2 === 0) return -width - 5;
    //    else return 6;
    //  });

    //var l_axis = d3.axisLeft()
    //  .tickSizeOuter(0)
    //  .tickFormat(function (d, i) { if (i % 2 === 1) return Number.parseFloat(this.SiegelhofH).toFixed(2); })
    //  .scale(d3.scaleThreshold().domain([0, height / 2, height]).range([0, 0, height / 2, height]));
    //svg.append("g")
    //  .attr("transform", "translate(-6, 0)")
    //  .call(l_axis)
    //  .selectAll(".tick line")
    //  .attr("x2", function (d, i) {
    //    if (i % 2 === 0) return 6;
    //    else return -5;
    //  });
  };
  drawLine(svg, x1, y1, x2, y2) {
    return svg.append("line")
      .attr("x1", x1).attr("y1", y1).attr("x2", x2).attr("y2", y2);
  }
}
