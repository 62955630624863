import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HtmlEditorService } from './html-editor.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getRenderedText } from '@angular/core/src/render3';

@Component({
  selector: 'html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {
  public show = false;
  private saHtmlEditorSubscription: Subscription;
  Editor = ClassicEditor;
  text = "";
  title = "";

  constructor(private htmleditor: HtmlEditorService) { }

  ngOnInit() {
    this.saHtmlEditorSubscription = this.htmleditor.saHtmlEditorState
      .subscribe(
        (state) => {
          this.show = state.show;
          this.text = state.text;
          this.title = state.title;
        });
  }
  ngOnDestroy(): void {
    this.saHtmlEditorSubscription.unsubscribe();
  }
  close() {
    this.htmleditor.close(this.text,this.title);
    this.show = false;
  }
}
