import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-devtasks',
  templateUrl: './devtasks.component.html',
  styleUrls: ['./devtasks.component.scss']
})
export class DevtasksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let a = 5;
  }

}
