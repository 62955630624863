import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tableperformance',
  templateUrl: './tableperformance.component.html',
  styleUrls: ['./tableperformance.component.scss']
})
export class TableperformanceComponent implements OnInit {
  performance = 0;
  npunches = 1;
  pressingpt = 0;
  circledia = 0;
  surface = 0;
  dwelltime = 0;
  speed = 0;

  exampleid = 1;
  examples = [
    { id: 1, name: 'Example 1' }, { id: 2, name: 'Example 2' }
  ]
  constructor() { }

  ngOnInit() {
    this.hasChanged(null);
  }
  calculate() {
    this.dwelltime = (this.surface * this.pressingpt * this.npunches) / (this.performance * this.circledia) * 3600 / Math.PI * 1000;
    this.speed = (this.circledia * Math.PI * this.performance) / (this.npunches * this.pressingpt * 3600) / 1000;
  }
  clear() {
    this.performance = 0;
    this.npunches = 0;
    this.pressingpt = 0;
    this.circledia = 0;
    this.surface = 0;
    this.dwelltime = 0;
    this.speed = 0;
  }

  hasChanged($event) {
    if (this.exampleid == 1) { this.performance = 40000; this.npunches = 19, this.pressingpt = 1; this.circledia = 265; this.surface = 22 }
    if (this.exampleid == 2) { this.performance = 40000; this.npunches = 49, this.pressingpt = 2; this.circledia = 680; this.surface = 27 }
    this.calculate();
  }
}
