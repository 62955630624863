import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sa-list-picker',
  templateUrl: './sa-list-picker.component.html',
  styleUrls: ['../formfields.css']
})
export class SaListPickerComponent implements OnChanges {
  @Input() options: any; // P.S. list of objects that are or could be selected
  @Input() selected: any = []; // P.S. subset of options that are selected
  @Input() fieldName: string; // P.S. Name of the field to be displayed
  @Input() title: string;
  @Output() selectedChange = new EventEmitter();
  @Output() hasChanged = new EventEmitter();
  constructor() { }

  ngOnChanges() {
    if (this.options){
      this.options.forEach(o => {
        o['isChecked'] = this.selected.some(x => x === o);
      });
    }
  }


  changeCheckBox($event, o: any) {
    if (o['isChecked'] === true) {
      this.selected.push(o);
    } else {
      const index = this.selected.findIndex(obj => obj === o);
      this.selected.splice(index, 1);
    }

    // P.S. Synchronize the text with the model of the parent
    this.selectedChange.emit(this.selected);
    // P.S. send the parent the message whether validation is true or false
    this.hasChanged.emit(this.selected);
  }
}
