import { Component, OnInit, ViewChild } from '@angular/core';
import { DbServiceService } from 'src/app/services/db-service.service';
import { LoaderService } from '../../../modules/loader/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { YesnodialogComponent } from '../../shared/yesnodialog/yesnodialog.component';
import { Route } from '@angular/compiler/src/core';
let href: any;

@Component({
  selector: 'app-surveytakein',
  templateUrl: './surveytakein.component.html',
  styleUrls: ['./surveytakein.component.scss']
})

export class SurveytakeinComponent implements OnInit {
  @ViewChild('YesNo') yesno: YesnodialogComponent;
  idea: any = {};
  surveyRecord: any = {};
  survey: any = {};
  href = this;
  constructor(
    private db: DbServiceService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private router: Router) { }

  async ngOnInit() {

    const TemplateId = this.route.snapshot.queryParams['TemplateId'];
    const RefId = this.route.snapshot.queryParams['RefId'];
    if (TemplateId === null || RefId === null) {
      alert('no query parameters');
    } else {
      this.loader.show();
      const ret = await this.db.get('survey', 'getSurveyForIdea', { TemplateId: TemplateId,RefId: RefId });
      this.idea = ret.Record;
      this.surveyRecord = ret.Template;
      this.survey = JSON.parse(this.surveyRecord[0].Templatejson);
      this.survey.surveyTitle = this.idea.Name;
      this.survey.RefId = RefId;
      this.loader.hide();
    }
  }
  async submit($event){
    const surveyDataRecord = {
      Id: 0,
      RefId : this.idea.Id,
      SurveytemplateId: this.surveyRecord[0].Id,
      DataJson: JSON.stringify(this.survey)
    };
    await this.db.post('survey', 'postSurvey', surveyDataRecord, {Id: 0});
    this.yesno.show(
      'Confirm Dialog',
      'Thank you very much for submitting this survey',
      () => { this.router.navigate(['/dashboard/home']); } , null);
  }

}
