import { Component, OnInit, Injector } from '@angular/core';
import { LoaderService } from '../../../modules/loader/loader.service';
import { DbServiceService } from '../../../services/db-service.service';



@Component({
  selector: 'app-surveyresults',
  templateUrl: './surveyresults.component.html',
  styleUrls: ['./surveyresults.component.scss']
})
export class SurveyresultsComponent implements OnInit {
  templates: any = [];
  template: any = {};
  result: any = [];
  resp: any = [];
  csvData: any = [];
  isDebug = false;
  graphdata: any = [];
  surveys: any = [];
  xFactor: any = [];

  constructor(private loader: LoaderService, private db: DbServiceService) {} // P.S get all the basic services

  async ngOnInit() {
    this.loader.show();
    this.templates = await this.db.get('survey', 'getTemplates');
    this.loader.hide();
  }

  async getResults() {
    this.loader.show();
    this.resp = await this.db.get('survey', 'getSurveyResults', { templateid: this.template.Id });
    // parse the list of objects into a table tha can be displayed
    this.result = [];
    this.surveys = [];
    this.resp.forEach(r => {
      const row = {}; // A new row in the table
      row['RefId'] = r.RefId;
      row['Email'] = r.Email;
      row['CreatedDate'] = r.CreatedDate;
      const sr = JSON.parse(r.DataJson); // one whole survey parsed
      const propkeys = Object.keys(sr.elements); // each questions has a key
      propkeys.forEach(key => {
        const obj = sr.elements[key];
        const colName = "(" + obj['id'] + ") " + obj['title'];
        const val = this.getValue(obj); // take care of different question types
        row[colName] = val;
      });
      const survey = {};
      survey['RefId'] = r.RefId;
      survey['Email'] = r.Email;
      survey['CreatedDate'] = r.CreatedDate;
      survey['jsondata'] = JSON.parse(r.DataJson);
      this.surveys.push(survey);

      if (this.isDebug) { row['DataJson'] = r.DataJson; }
      this.result.push(row);
    });

    // P.S make the same array but add as first row the questions so csv has a header row
    if (this.result.length > 0) {
      this.csvData = [];
      const keys = Object.keys(this.result[0]);
      const header = {};
      keys.forEach(x => {
        header[x] = x;
      });
      this.csvData.push(header);
      this.result.forEach(x => { this.csvData.push(x); });
    }
    this.loadgraphdata();
    this.loader.hide();
  }

  loadgraphdata() {
    if (this.surveys.length > 0) {
      this.graphdata = [];
      // get all the ratings first
      const jsondata = this.surveys[0].jsondata
      jsondata.elements.forEach(el => {
        if (el.type == "rating") {
          var gd: any = {};
          gd.title = el.title
          gd.id = el.id;
          gd.data = this.initData(el.rateMax)
          this.graphdata.push(gd);
        }
      })

      this.surveys.forEach(survey => {
        const jsondata = survey.jsondata;
        jsondata.elements.forEach(el => {
          this.graphdata.forEach(g => {
            if (g.id == el.id) {
              var result = el.result;
              if (result != "") {
                if (result >= 1 && result <= 10) {
                  g.data[result-1].value += 1;
                }
              }
            }
          })
        })
      });
    }
  }

  initData(n) {
    const arr: any = [];
    for (var i = 0; i <n; i++) {
      var kv:any = {};
      kv.key = i+1;
      kv.value = 0;
      arr.push(kv);
    }
    return arr;
  }
  getValue(obj: any) {
    let ret = '';
    if (obj.type == 'comment') { ret = obj.result; }
    if (obj.type == 'rating') { ret = obj.result; }
    if (obj.type == 'radiogroup') {
      if (obj.choices != null) {
        obj.choices.forEach(c => {
          if (c.isSet == true) { ret = c.title; }
        });
      } else { ret = 'null'; }
    }

    if (obj.type == 'checkbox') {
      ret = '';
      if (obj.choices != null) {
        obj.choices.forEach(c => {
          if (c.isSet == true) { ret += c.title + ', '; }
        });
      } else { ret = 'null'; }
    }
    if (this.isDebug) { ret += ' ------- ' + JSON.stringify(obj); }


    return ret;
  }

  buildXfactor() {

  }
  selectRow($event) {

  }
  downloadCSV() {

  }

}
