import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-managedosageform',
  templateUrl: './managedosageform.component.html',
  styleUrls: ['./managedosageform.component.scss']
})
export class ManagedosageformComponent extends BaseComponent implements OnInit {
  data: any = [];
  dosform: any = {};

  fieldDefinition: any = [];
  constructor(private injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    this.fieldDefinition = [
      { column: 'id', type: 'id', displayName: 'Id' },
      { column: 'name', type: 'string', displayName: 'Name' },
    ]
    await this.getData();
    this.loader.hide();
  }
  async getData() {
    this.data = await this.db.get("dosageform", "getDosageforms");
  }

  selectDosageform(df) {
    if (df == null) {
      this.dosform = {
        Id: 0,
        Name: "",
        Description: ""
      }
    } else {
      this.dosform = df;
    }
  }
  async postData(record) {
    this.loader.show();
    await this.db.post("dosageform", "postDosageform", record);
    await this.getData();
    this.loader.hide();
  }
  async deleteData(record) {
    this.loader.show();
    //await this.db.delete("lookup", "deleteDosageform", { id: record.id });
    await this.getData();
    this.loader.hide();
  }
}
