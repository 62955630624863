import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sa-list2-picker',
  templateUrl: './sa-list2-picker.component.html',
  styleUrls: ['../formfields.css'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .tooltip-inner {
    background: white !important;
    color:black;
    width:1000px !important;
      }
    .tooltip{
    background: white !important;
    }
  `]
})
export class SaList2PickerComponent {
  @Input() list: any; // P.S. list of objects that are or could be selected
  // {id:1, name="Item1", isChecked= false, ...+other fields like refNo}
  @Input() title = "";
  @Output() add = new EventEmitter();
  @Output() remove = new EventEmitter();

  changeCheckBox($event, o) {
    if (o.isChecked) {
      this.add.emit(o);
    } else {
      this.remove.emit(o);
    }
  }
}


