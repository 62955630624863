import { Component, Injector, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../../modules/loader/loader.service';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-external-references',
  templateUrl: './external-references.component.html',
  styleUrls: ['./external-references.component.scss']
})
export class ExternalReferencesComponent extends BaseComponent implements OnInit {
  refsDB: any= [];
  refs: any= [];
  ref: any = {};
  topics: any = [];
  selectedTopicId = 0;

  constructor(private injector: Injector) {super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.loader.hide();
  }

  async getData() {

    this.topics = await this.db.get("lookup", "getExtRefTopics");
    const resp = await this.db.get("lookup", "getExternalReferences");
    this.refsDB = resp;
    
    this.refsDB.forEach(c => {
      let url = c.url;
      if (url && url.length > 4 && url.substring(0, 4).toLowerCase() != "http") {
        c.url = "http://" + c.url;
      } else {
        c.url = c.url;
      }
    });
    this.filter();
    //this.ref = this.refs[0];
  }

  filter() {
    if (this.selectedTopicId == 0) {
      this.refs = this.refsDB;
    } else {
      this.refs = [];
      this.refsDB.forEach(r => {
        if (r.topicAreaId == this.selectedTopicId) {
          this.refs.push(r);
        }
      })
    }
  }
  filterAll() {
    this.selectedTopicId = 0;
    this.refs = this.refsDB;
  }

  selectRef(r) {
    if (r == null) {
      this.ref = { id: 0, name: "", description: "", url: "" }
    } else {
      this.ref = r;
    }
    $('#refTag').show();
  }

  closeRef() {
    $('#refTag').hide();
  }

  async saveRef() {
    this.loader.show();
    await this.db.post("lookup", "postExternalReferences", this.ref);
    await this.getData();
    $('#refTag').hide();
    this.loader.hide();
  }
}
