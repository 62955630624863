//core imports
import { Injectable, Output, EventEmitter, TemplateRef } from '@angular/core';
import 'rxjs/add/operator/toPromise';

//component imports
import { Alert } from './alert';

@Injectable()
export class snaAlertService {
  alert: Alert = new Alert();
  @Output() createAlert: EventEmitter<Alert> = new EventEmitter();

  constructor() { }


  public success(message: string) { this.sendAlert(message, 'success'); };
  public danger(message: string) { this.sendAlert(message, 'danger'); };
  public warning(message: string) { this.sendAlert(message, 'warning'); };
  public info(message: string) { this.sendAlert(message, 'info'); };

  sendAlert(message: string, type: string) {
    this.alert.message = message;
    this.alert.type = type;
    this.createAlert.emit(this.alert);
  }
  // Alert(message: string, timeout?: number) {
  //  this.warning(message, timeout);
  //}

  getAlertInfo() {
    //console.log(this.createAlert);
    return this.createAlert;
  }
}
