import { Component, OnInit } from '@angular/core';
import { ConfirmService } from './confirm.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  showConfirm = false;
  question = "";
  callback;

  constructor(private confirmService: ConfirmService) { }

  ngOnInit() {
    // Register the component with the service
    //so the service cann call confirm
    this.confirmService.registerComponent(this);
  }
 
  confirm(question, callback) {
    this.question = question;
    this.callback = callback;
    this.showConfirm = true;
  }

  answer(sWhat) {
    this.callback(sWhat);
    this.showConfirm = false;
  }
}
