import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { DbServiceService } from '../../../services/db-service.service';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-docusingle',
  templateUrl: './docusingle.component.html',
  styleUrls: ['./docusingle.component.scss']
})
export class DocusingleComponent implements OnInit {
  @Input() Id: number; //record number of idea, concept or project
  @Input() tableName: string;
  @Input() refObject: any;
  @Output() emitSaveImage = new EventEmitter();

  Image: any = {};
  croppedImage: any = '';
  file: any = { 'name': 'undefined' };
  hasFile = false;

  constructor(private db: DbServiceService,
    private alert: snaAlertService,
    private loader: LoaderService) { }

  ngOnInit() {
  }

  openModalUploadImage() {
    this.hasFile = true;
    $('#myModalImage2').show();
  }
  closeModal = function () {
    this.hasFile = false;
    $('#myModalImage2').hide();
  };
  async saveImage($event) {
    this.croppedImage = $event.img;
    this.file = $event.file;

    let input = new FormData();
    input.append('file', $event.img);
    input.append('fileName', $event.file.name);
    input.append('thumbnail', $event.thumbnail);
    input.append('Id', this.Id.toString());
    input.append('Table', this.tableName);
    // P.S. in this case defined trhough the cropper
    input.append('FileType', $event.file.name.substr($event.file.name.lastIndexOf('.') + 1));
    input.append('Size', $event.img.length); // Size of base64 image

    let img = await this.db.post('document', 'uploadImage', input, { Id: this.Id, TableName: 'observation' });
    img.refObj = this.refObject; //the reference object handed in to this component, give it back
    this.emitSaveImage.emit(img);

    $('#myModalImage2').hide();
  }
  closeModalImageDetail = function () {
    $('#myModalImage2').hide();
  };
}
