import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-managesustopics',
  templateUrl: './managesustopics.component.html',
  styleUrls: ['./managesustopics.component.scss']
})
export class ManagesustopicsComponent extends BaseComponent implements OnInit {
  topics: any = [];
  topic: any = {};

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.add();
    this.loader.hide();
  }

  async getData() {
    const resp = await this.db.get("sustainability", "getSustainabilityTopics");
    this.topics = resp.sustopics
  }

  add() {
    this.topic = {
      Id: 0,
      Name: "",
      Description: "",
    };
  }

  selectTopic(proc) {
    this.topic = proc;
  }

  async saveTopic() {
    this.loader.show();
    await this.db.post("sustainability", "postSustainabilityTopic", this.topic);
    await this.getData();
    this.loader.hide();
  }
}
