import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-printhtml',
  templateUrl: './printhtml.component.html',
  styleUrls: ['./printhtml.component.scss']
})
export class PrinthtmlComponent implements OnInit {
  @Input() title = "";
  @Input() description = "";
  constructor() { }

  ngOnInit() {
  }

}
