import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Idea } from '../../../models/IdeaCluster';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { LoaderService } from '../../../modules/loader/loader.service';
import { DbServiceService } from '../../../services/db-service.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sa-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.scss']
})
export class GenericListComponent implements OnInit, AfterViewInit {
  @Input() IdeaTypeName: string;

  Ideas: Idea[];
  IdeasDB: Idea[];
  spinner = true;
  businessAreaId = 0;
  treatmentFieldId = 0;
  dosageFormId = 0;
  packagingSystemId = 0;

  constructor(
    private db: DbServiceService,
    public global: GlobalService,
    private loader: LoaderService,
    private router: Router) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.submit();
  }
  async submit() {
    this.loader.show();
    
    this.IdeasDB = await this.db.get('idea', 'getIdeasByType', { type: this.IdeaTypeName });
    this.Ideas = this.IdeasDB;
    //if (this.global.IsPublic) {
    //  this.Ideas = await this.db.get('idea', 'getPublicIdeas', { type: this.IdeaTypeName });
    //} else {
    //  this.Ideas = await this.db.get('idea', 'getPrivateIdeas', { type: this.IdeaTypeName });
    //  let a = 5;
    //}
    this.loader.hide();

  }
  async checkChanged() {
    this.submit();
  }
  navigate(item) {
    let sRoute = "ideaedit";
    if (this.IdeaTypeName == "Project") sRoute = "projectedit";
    this.router.navigate(['/dashboard/'+sRoute], { queryParams: { 'IdeaId': item.Id } });
  }

  circEconomy(item) {
    this.global.IdeaId = item.Id;
    this.router.navigate(['/dashboard/circeconomy'], { queryParams: { 'IdeaId': item.Id } });
  }
  newRecord() {
    let sRoute = "ideaedit";
    if (this.IdeaTypeName == "Concept") sRoute = "conceptedit";
    if (this.IdeaTypeName == "Project") sRoute = "projectedit";
    if (this.IdeaTypeName == "Consumer Product") sRoute = "productedit";
    if (this.IdeaTypeName == "Technology") sRoute = "technologyedit";
    if (this.IdeaTypeName == "B-to-B Product") sRoute = "b2bproductedit";
    this.router.navigate(['/dashboard/' +sRoute], { queryParams: { 'IdeaId': 0 } });
  }
  takesurvey(item,templateId) {
    this.router.navigate(['/dashboard/surveytakein'], { queryParams: { 'TemplateId': templateId, 'RefId': item.Id } });
  }
  filter() {
    this.Ideas = [];
    this.IdeasDB.forEach(d => {
      let takeIt = false;
      if (this.businessAreaId > 0 && d.BusinessAreaId == this.businessAreaId) takeIt = true;
      if (this.treatmentFieldId > 0 && d.TreatmentFieldId == this.treatmentFieldId) takeIt = true;
      d.packtechs.forEach(pt => {
        if (this.packagingSystemId > 0 && pt.PachTechId == this.packagingSystemId) takeIt = true;
      })
      d.dosageforms.forEach(pt => {
        if (this.dosageFormId > 0 && pt.DosageFormId == this.dosageFormId) takeIt = true;
      })
      if (this.dosageFormId == 0 && this.businessAreaId == 0 && this.treatmentFieldId == 0 && this.packagingSystemId == 0) takeIt = true;
      
      if (takeIt == true) this.Ideas.push(d);
    });
    let a = 5;
  }
  clearFilter() {
    this.businessAreaId = 0;
    this.treatmentFieldId = 0;
    this.dosageFormId = 0;
    this.packagingSystemId = 0;
    this.Ideas = this.IdeasDB;
  }
  compare() {
    this.global.Selectids = [];
    this.Ideas.forEach(c => {
      this.global.Selectids.push(c.Id);
    })
    this.router.navigate(['/dashboard/ideacompare']);

  }
}
