import { Component, OnInit, Input } from '@angular/core';
import { DbServiceService } from '../../services/db-service.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'product-criteria',
  templateUrl: './product-criteria.component.html',
  styleUrls: ['./product-criteria.component.scss']
})
export class ProductCriteriaComponent implements OnInit {
  @Input() IdeaId: number;
  ProductCriterias: any;
  Relationships: any;

  constructor(private db: DbServiceService) { }

  ngOnInit() {
    forkJoin(
      this.db.get("idea", "getIdeaCriteriasByType", { typeId: 1 }),
      this.db.get("idea", "getProductCriterias", { IdeaId: this.IdeaId })
    ).subscribe(ret => {
      this.Relationships = ret[1];
      this.ProductCriterias = [];
      let crs = ret[0];
      crs.forEach(cr => {
        let c = {};
        c['Name'] = cr.Name;
        c['Description'] = "";
        let rel = this.Relationships.find(x => x.IdeaCriteriaId == cr.Id);
        if (rel != undefined) {
          c['Descripiton'] = rel['Description'];
        }
        this.ProductCriterias.push(c);
      });
    })
  }

}
