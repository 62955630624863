import { Component, OnInit, Input } from '@angular/core';
import * as d3 from 'd3';
import { SimulationLinkDatum } from 'd3';
import * as $ from 'jquery';

@Component({
  selector: 'app-graph-forced',
  templateUrl: './graph-forced.component.html',
  styleUrls: ['./graph-forced.component.scss']
})
export class GraphForcedComponent implements OnInit {
  @Input() nodes: any;
  @Input() links: any;

  constructor() { }

  ngOnInit() {
  }

  //P.S. this depends on d3 and jquery
  createGraph = function (links: Link[],nodes:Node[],  callbackNodeSelect) {

    d3.selectAll("g").remove(); //clear old graph on reload

    var svg = d3.select("#fgId");

    var svgEl = $("#fgId");
    var width = svgEl.width();
    var height = svgEl.height();

    var color2 = d3.schemeRdYlGn[6];

    var simulation = d3.forceSimulation()
      .force("charge", d3.forceManyBody())
      .force("center", d3.forceCenter(width / 2, height / 2));

    simulation.force("link",
      d3.forceLink()
        .id(function (d: any) {
          return d.id;
        })
        .distance(200)
        .strength(2));

    var link = svg.append("g")
      .attr("class", "links svg-content")
      .selectAll("line")
      .data(links)
      .enter().append("line")
      .attr("stroke-width", function (d) { return 1; });

    var node = svg.append("g")
      .attr("class", "nodes")
      .selectAll("g")
      .data(nodes)
      .enter().append("g")

    var circles = node.append("circle")
      .attr("r", function (d: Node) {
        return d.radius;
      })
      .attr("fill", function (d: Node) {
        return d.color;
      })
      .attr("stroke", "gainsboro")
      .attr("stroke-width", "3")
      .call(d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended));



    node.append("title")
      .text(function (d) {
        let s = d['type'] + " " + d['name']+" (Id:"+d['refId']+")";
        return s;
      });

    node.append("text")
      .attr("dx", function (d) { return 20 })
      .text(function (d) { return d.name })

    node.append("image")
      //.attr("xlink:href", "assets/strech-removebg-preview.png")
      .attr("xlink:href", function (d) {
        let s = "assets/step-removebg-preview.png";
        if (d.stepstretchseap == 1) s = "assets/step-removebg-preview.png";
        if (d.stepstretchseap == 2) s = "assets/strech-removebg-preview.png";
        if (d.stepstretchseap == 3) s = "assets/leap-removebg-preview.png";
        return s;
      })
      .attr("x", "-15")
      .attr("y", "-15")
      .attr("width", 30)
      .attr("height", 30)
      .call(d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended));

    let nodesAny: any = nodes;
    let nodesSim = <d3.SimulationNodeDatum[]>nodesAny;
    simulation
      .nodes(nodesSim)
      .on("tick", ticked);

    node.on("click", function (d) { selectNode(d); });

    //simulation.force('link', d3.forceLink().links(links));

    function selectNode(d) {
      callbackNodeSelect(d)
    };

    function ticked() {
      link
        .attr("x1", function (d: any) {
          return nodes[d.source].x;
        })
        .attr("y1", function (d) { return nodes[d.source].y; })
        .attr("x2", function (d) { return nodes[d.target].x; })
        .attr("y2", function (d) { return nodes[d.target].y; })
        .attr("stroke", "gray")
        .attr("stroke-width", 1);

      node
        .attr("transform", function (d) {
          return "translate(" + d['x'] + "," + d['y'] + ")";
        })
    };

    function dragstarted(d) {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragended(d) {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
  }
}
// define class outside of component
export class Node {
  // needed internally by forced graph
  index: string;
  x: number;
  y: number;
  vx: number;
  vy: number;

  constructor(
    public id: string,
    public refId: string,
    public name: string,
    public radius: number,
    public color: string,
    public description: string,
    public group: string,
    public stepstretchseap: number,
    public type: string
  ) { }
}

export class Link {
  constructor(
    public source: string,
    public target: string,
    public sourceRefId: string,
    public targetRefId: string,
    public sourceName: string,
    public targetName: string,
    public description: string,
    public displayName: string
  ) { }
}
