import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-dynformtest',
  templateUrl: './dynformtest.component.html',
  styleUrls: ['./dynformtest.component.scss']
})
export class DynformtestComponent extends BaseComponent implements OnInit {
  data: any = [];
  columns: any = [];
  types: any = [];
  fieldDefinition: any = [];
constructor(private injector: Injector) { super(injector)}
  async ngOnInit() {
    this.loader.show();
    this.fieldDefinition = [
      { column: 'id', type: 'id', displayName: 'Id' },
      { column: 'name', type: 'string', displayName: 'Name' },
    ]
    await this.getData();
    this.loader.hide();
  }

  async getData() {
    this.data = await this.db.get("lookup", "getDosageforms");
  }
  async postData(record) {
    await this.db.post("lookup", "postDosageform", record);
    await this.getData();
  }
  async deleteData(record) {
    await this.db.delete("lookup", "deleteDosageform", { id: record.id });
    await this.getData();
  }
}
