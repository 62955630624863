import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import * as $ from 'jquery';
import { Node } from '../../../models/Node';
import { Link } from '../../../models/Link';
import { DbServiceService } from '../../../services/db-service.service';
import * as d3graph from './d3ForceGraph';


@Component({
  selector: 'app-relationship',
  templateUrl: './relationship.component.html',
  styleUrls: ['./relationship.component.scss']
})
export class RelationshipComponent implements OnInit {
  nodes: any = [];
  links: any = [];
  nodesAll: any = [];
  linksAll: any = [];

  relationshipStatus = -1;
  node1 = '-1';
  nodeName1 = '';
  node2 = '-2';
  nodeName2 = '';

  graphUpdate: any;
  addLink: any;
  selectedLink: any;
  ideaTypes = [{ id: 0, name: "All Links" },{ id: 1, name: "Idea" }, { id: 2, name: "Concept" }, { id: 3, name: "Project" },];
  ideaTypeId = 0;

  constructor(private db: DbServiceService) { }

  async ngOnInit() {

    const ret = await this.db.get('Idea', 'getrelationshipnodesandlinks');
    this.mapReltionships(ret);
    d3graph.initialize('#fgId');
    d3graph.render(this.nodes, this.links);
  }

  async submitData() {
    const newLinks = d3graph.getLiinks();
    const rels: any = [];
    newLinks.forEach(x => {
      const rel = {
        IdeaSourceId : x.source.id,
        IdeaTargetId : x.target.id
      }
      rels.push(rel);
    });
    const ret = await this.db.post('Idea', 'submitRelationships', rels);
    alert('Links updated!');
  }

  async getData() {
    const ret = await this.db.get('Idea', 'getrelationshipnodesandlinks');
    this.mapReltionships(ret);
  }

  mockData() {
    this.nodes = [
      { id: 1, name: 'alice', dscription: 'alice', type: 'Idea', color: 'orange', radius: 15 },
      { id: 2, name: 'bob', dscription: 'bob', type: 'Idea', color: 'lightgreen', radius: 15 },
      { id: 3, name: 'clara', dscription: 'clara', type: 'Idea', color: 'red', radius: 15 },
      { id: 4, name: 'monique', dscription: 'monique', type: 'Idea', color: 'blue', radius: 15 },
      { id: 5, name: 'black', dscription: 'black', type: 'Idea', color: 'black', radius: 15 },
    ];
    this.links = [{ source: 1, target: 2 }, { source: 2, target: 3 }, { source: 2, target: 5 },]
  }

  onlyLinks() {
    this.nodes = [];
    this.links = this.linksAll;
    this.links.forEach(l => {
      this.addNodeToArray(this.nodes, l.source);
      this.addNodeToArray(this.nodes, l.target);
    });
    d3graph.render(this.nodes, this.links);
  }

  filter() {
    let ideatype = this.ideaTypes.find(x => x.id == this.ideaTypeId);
    this.nodes = [];
    this.links = [];
    if (this.ideaTypeId == 0) {
      this.onlyLinks();
    } else {
      this.linksAll.forEach(l => {
        if (l.source.type == ideatype.name || l.target.type == ideatype.name) {
          this.links.push(l);
        }
      });
      this.links.forEach(l => {
        this.addNodeToArray(this.nodes, l.source);
        this.addNodeToArray(this.nodes, l.target);
      });
      d3graph.render(this.nodes, this.links);
    }
  }
  addNodeToArray(array, node) {
    let nTest = array.find(x => x.id == node.id);
    if (nTest == null) {
      array.push(node);
    }
  };

  mapReltionships(ret: any) {
    this.nodes = [];
    this.links = [];
    this.nodesAll = [];
    this.linksAll = [];
    for (const n of ret.nodes) {
      const typeSetting = this.getColor(n.IdeaType);
      const color = typeSetting.color;
      const letter = typeSetting.letter;

      const node = { id: n.Id, name: n.Name, description: n.Description, type: n.IdeaType, color: color, radius: 15, letter: letter, stepStretchLeap: n.StepStretchLeap};
      this.nodes.push(node);
      this.nodesAll.push(node);
    }
    for (const l of ret.links) {
      const link = { source: l.SourceId, target: l.TargetId };
      this.links.push(link);
      this.linksAll.push(link);
    }
  }

  getColor(type) {
    let ret = 'black';
    let letter = 'Z';
    if (type === 'Idea') { ret = 'orange'; letter = 'I'; }
    if (type === 'Concept') { ret = 'green'; letter = 'C'; }
    if (type === 'Project') { ret = 'blue'; letter = 'P'; }
    if (type === 'Product') { ret = 'yellow'; letter = 'D'; }
    if (type === 'Technology') { ret = 'purple'; letter = 'T'; }
    return ( { color: ret , letter: letter} );
  }
}


