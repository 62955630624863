import { snaAlertService } from './../modules/sna-alert/sna-alert.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

@Injectable({
  providedIn: 'root'
})
export class DbServiceService {

  constructor(
    private http: HttpClient,
    private alert: snaAlertService) { }

  // P.S. a generic get controller for any get
  async get(controller: string, method: string, queryParam?: any): Promise<any> {
    try {
      const url = (queryParam) ? this.getUrl(controller, method, queryParam) : this.getUrl(controller, method);
      return await this.http.get<any>(url).toPromise();
    } catch (error) {
      console.log('Error in database.service.get: ' + error.message);
      this.alert.danger(error.message);
    }
  }
  async delete(controller: string, method: string, queryParam: any): Promise<any> {
    try {
      const url = this.getUrl(controller, method, queryParam);
      return await this.http.delete<any>(url).toPromise();
    } catch (error) {
      console.log('Error in database.service.get: ' + error.message);
      this.alert.danger(error.message);
    }
  }

  async post(controller: string, method: string, body: any, queryParam?: any): Promise<any> {
    try {
      const url = (queryParam) ? this.getUrl(controller, method, queryParam) : this.getUrl(controller, method);
      return await this.http.post<any>(url, body).toPromise();
    } catch (error) {
      console.log('Error in database.service.get: ' + error.message);
      this.alert.danger(error.message);
    }
  }

  private getUrl(controller: string, method: string, queryParam?: any): string {
    let url = 'api/' + controller + '/' + method;
    if (queryParam) {
      url += '?';
      for (const key in queryParam) {
        if (queryParam.hasOwnProperty(key)) {
          url += key + '=' + queryParam[key] + '&';
        }
      }
      return  url.substr(0, url.length - 1);
    };
    return url;
  }
}
