// P.S. This class is what is needed on the fron end
// and delivered by controller getIdea
export class Idea {
  constructor() {
    // P.S. so not to get an undefined error when loading a page
    this.Impact = new Impact();
    this.Length = new Length();
    this.Reach = new Reach();
    this.Sustainability = new Sustainability();
    this.Type = { 'Id': 1, 'Name': 'Idea' };
  }
  Id: number;
  Name: string;
  Description: string;
  Group: string;
  Type: any; // just id and name
  TypeId: number;
  BusinessAreaId: number;
  TreatmentFieldId: number;
  dosageforms: any;
  packtechs: any;
  Reach: Reach;
  Length: Length;
  Impact: Impact;
  Sustainability: Sustainability;
  Author: string;
  CreatedAt: Date;
  Realization: string;
  NextSteps: string;
  ProjectStart: Date;
  Status: any; //just id and name
  IsPublic: boolean;
  CompanyId: number; //can be 0 which is null
  pics: any;
}
export class Impact {
  Id: number;
  Name: string;
  SeqNo: number;
  Value: number;
}
export class Length {
  Id: number;
  Name: string;
  SeqNo: number;
  Value: number;
}
export class Reach {
  Id: number;
  Name: string;
  SeqNo: number;
  Value: number;
}
export class Sustainability {
  Id: number;
  Name: string;
  SeqNo: number;
  Value: number;
}
export class IdeaLookups {
  Lengths: Length[];
  Impacts: Impact[];
  Reaches: Reach[];
  Sustainabilities: Sustainability[];
  IdeaRelationshipStrengths: RelationshipStrength[];
  IdeaCategories: any[];
  PackagingTechnologies: any[];
}
export class IdeaRelationship {
  Id: number;
  SourceId: number;
  TargetId: number;
  Name: string;
  Description: string;
  RelationshipStrength: RelationshipStrength;
}
export class RelationshipStrength {
  Id: number;
  Name: string;
}
export class IdeaType {
  Id: number;
  Name: string;
}
export class IdeaStatus {
  Id: number;
  Name: string;
}
