import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/basecomponent';

@Component({
  selector: 'app-companyoverview',
  templateUrl: './companyoverview.component.html',
  styleUrls: ['./companyoverview.component.scss']
})
export class CompanyoverviewComponent extends BaseComponent implements OnInit {
  companies: any = [];
  constructor(injector: Injector) { super(injector); }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.loader.hide();
  }
  async getData() {
    const resp = await this.db.get('company', 'getCompanyOverview');
    this.companies = resp.companies;
  }
  goto(company) {
      this.router.navigate(['/dashboard/companydetails'], { queryParams: { 'id': company.id } });
  }
  gotorel(company) {
    this.router.navigate(['/dashboard/companyrels'], { queryParams: { 'id': company.id } });
  }
}
