import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pressureresidence',
  templateUrl: './pressureresidence.component.html',
  styleUrls: ['./pressureresidence.component.scss']
})
export class PressureresidenceComponent implements OnInit {
  surface = 0;
  npunches = 1;
  ppoints = 0;
  performance = 0;
  die = 0;
  factor = 0;
  dwell = 0;


  exampleid = 1;
  examples = [
    { id: 1, name: 'Example 1' }, { id: 2, name: 'Example 2' }
  ]
  constructor() { }

  ngOnInit() {
    this.hasChanged(null);
  }
  calculate() {
    this.dwell = (this.surface * this.npunches * this.ppoints) / (this.performance * this.die) * 3600 / Math.PI  * 1000;
  }
  clear() {
    this.surface = 0;
    this.npunches = 0;
    this.ppoints = 0;
    this.performance = 0;
    this.die = 0;
    this.factor = 0;
    this.dwell = 0;
  }

  hasChanged($event) {
    if (this.exampleid == 1) { this.surface = 27; this.npunches = 53; this.ppoints = 2; this.performance = 300000; this.die = 680; this.factor = 1145.92; }
    if (this.exampleid == 2) { this.surface = 20; this.npunches = 30; this.ppoints = 1; this.performance = 125000; this.die = 480; this.factor = 1145.92; }
    this.calculate();
  }
}

