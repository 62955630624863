import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-subgroups',
  templateUrl: './subgroups.component.html',
  styleUrls: ['./subgroups.component.scss']
})
export class SubgroupsComponent extends BaseComponent implements OnInit {
  materialsubgroups: any = [];
  materialsubgroup: any = {};
  showDetail = false;
  groups: any = [];
  materialsources: any = [];
  materialrecycabilities: any = [];

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.add();
    this.loader.hide();
  }

  async getData() {
    const resp = await this.db.get("lookup", "getMaterialSubGroups");
    this.materialsubgroups = resp.msgs;
    this.groups = resp.groups;
    this.materialrecycabilities = await this.db.get("lookup", "getMaterialRecycabilities");
    this.materialsources = await this.db.get("lookup", "getMaterialSources");

  }

  selectMaterialsubgroup(matg) {
    this.materialsubgroup = matg;
    this.showDetail = true;
  }

  async saveMaterial() {
    this.loader.show();
    await this.db.post("lookup", "postMaterialSubGroup", this.materialsubgroup);
    await this.getData();
    this.loader.hide();
  }

  add() {
    this.materialsubgroup = {
      id: 0,
      name: "",
      description: "",
      groupid: 1
    };
    this.showDetail = true;
  }
}
