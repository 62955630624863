import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HtmlEditorService {
  textChange: EventEmitter<any> = new EventEmitter();

  private saHtmlEditorSubject = new Subject<any>();
  public saHtmlEditorState = this.saHtmlEditorSubject.asObservable();

  constructor() { }
  show(title:string,text:string) {
    this.saHtmlEditorSubject.next({
      show: true,
      text: text,
      title: title
    });
  }
  
  close(text:string,title: string) {
    //alert("Service.close: " + text);
    let ret = { text: text, title:title}
    this.textChange.emit(ret);
  }

  hide() {
    this.saHtmlEditorSubject.next({
      show: false,
      text: "",
      title:""
    });
  }
}
