import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import * as $ from 'jquery';

@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss']
})
export class GanttComponent implements OnInit {
  @Input() tasks: any;
  dateMinMax: any = {};
  constructor() { }

  ngOnInit() {
    this.createGraph(this.tasks);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createGraph(this.tasks);
  }
  redraw() {
    this.createGraph(this.tasks);
  }
  createGraph = function (tasks) {
    if (tasks.length == 0) {
      return;
    }
    d3.selectAll("g").remove(); //clear old graph on reload

    let dateMinMax = this.getMinMaxDate(this.tasks, "DateStart", "DateFinish");
    if (dateMinMax.min == null) return;
    if (dateMinMax.min == null || dateMinMax.max == null) {
      alert("No Dates provided for Gantt chart");
    }

    let svg = d3.select("#GanttId");
    let svgEl = $("#GanttId");
    let width = svgEl.width();
    let verticalOffset = 30;
    let barHeight = 25;
    let barSpace = 10;
    let height = verticalOffset + tasks.length * (barHeight + barSpace);
    svg.style("height", height + "px");
    let dMin = new Date(dateMinMax.min);
    let dMax = new Date(dateMinMax.max);
    let timeScale = d3.scaleTime().range([10, width-20]).domain([dMin, dMax]);
    let axisx = d3.axisBottom(timeScale).ticks(5);



    let gAxis = svg.append("g");
    gAxis.attr("transform", "translate(0,0)").call(axisx);

    var gtasks = svg.append("g")
      .attr("class", "nodes")
      .selectAll("g")
      .data(tasks)
      .enter().append("g");

    this.tasks.forEach(task => {
      task.x = task.DateStart != null ? timeScale(new Date(task.DateStart)) : 0;
      task.width = task.DateFinish != null ? timeScale(new Date(task.DateFinish)) : 10;
    });

    var bars = gtasks.append("rect")
      .attr("x", (d: any) => { return d.x })
      .attr("y", function (d: any, i) { return i * (barHeight + barSpace) + verticalOffset; })
      .attr("width", (d: any) => { return d.width })
      .attr("height", barHeight)
      .style("fill", function (d: any) {
        let color = d.StatusId == 1 ? "#e6e6e6" : "#69b3a2";
        return color
      });
    var texts = gtasks.append("text")
      .text((d: any) => { return d.Name })
      .attr("x", (d: any) => { return d.x + 5 })
      .attr("y", function (d: any, i) { return i * (barHeight + barSpace) + verticalOffset + 18; });

    let todayX = timeScale(new Date());
    var line = svg.append("line")
      .attr("x1", todayX)
      .attr("y1", 0)
      .attr("x2", todayX)
      .attr("y2", height)
      .attr("stroke", "gray");

  }
  getMinMaxDate(array, minField, maxField) {
    let date = array.find(x => x[minField] != null);
    if (date == null) return { min: null, max: null };

    let dateMin = this.tasks.reduce((acc, val) => {
      let ret = val[minField] < acc ? val[minField] : acc;
      return ret;
    }, date[minField]);

    let dateMax = this.tasks.reduce((acc, val) => {
      let ret = val[maxField] > acc ? val[maxField] : acc;
      return ret;
    }, date[maxField]);

    return { min: dateMin, max: dateMax }
  }

}
