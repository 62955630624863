import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit, OnChanges {
  @Input() min: 0;
  @Input() max: 100;
  @Input() value: 50;
  @Input() bgcolor: "green";
  width = "50%";

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {
    this.width = (this.value / (this.max - this.min) * 100)+"%";
  }

}
