import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/basecomponent';

@Component({
  selector: 'app-surveygroupresults',
  templateUrl: './surveygroupresults.component.html',
  styleUrls: ['./surveygroupresults.component.scss']
})
export class SurveygroupresultsComponent extends BaseComponent implements OnInit {
  id = 0;
  group = "";
  surveydata: any = [];
  surveydataselect: any = {};
  survey: any = {};
  constructor(injector: Injector) { super(injector); }

  async ngOnInit() {
    this.id = this.getIntQueryParamOrDefault("id", 0);
    this.group = this.getStringQueryParamOrDefault("group", "");
    this.surveydata = await this.db.get("survey", "getSurveyResultsForGroup", { group: this.group, id: this.id })
    this.surveydata.forEach(sd => {
      sd.survey = JSON.parse(sd.DataJson);
    });
  }

  showResult(item) {
    this.surveydataselect = item
    this.survey = item.survey;
  }
  submit($event) {
    alert("No update can be done from here!");
  }

}
