import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';
@Component({
  selector: 'app-userproductlist',
  templateUrl: './userproductlist.component.html',
  styleUrls: ['./userproductlist.component.scss']
})
export class UserproductlistComponent extends BaseComponent implements OnInit {
  products: any = [];

  constructor(private injector: Injector) { super(injector) }
  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.loader.hide();

  }
  async getData() {
    const resp = await this.db.get("idea", "getProductsForUser");
    this.products = resp;
  }
  goto(id) {
    this.router.navigate(['/dashboard/userproductedit'], { queryParams: { 'Id': id } });
  }
}
