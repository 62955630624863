import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'sa-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss']
})
export class TogglerComponent implements OnInit {
  @Input() value = false;
  @Input() texttrue = "";
  @Input() textfalse = "";
  title = "Test";

  @Output() sendValue = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.title = this.value ? this.texttrue : this.textfalse;
  }

  change() {
    this.value = !this.value;
    this.title = this.value ? this.texttrue : this.textfalse;
    this.sendValue.emit(this.value.toString());
    this.valueChange.emit(this.value);
  }
}
