import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import * as d3 from 'd3';
import * as $ from 'jquery';
import { DbServiceService } from '../../services/db-service.service';
import { GlobalService } from '../../services/global.service';
import { snaAlertService } from '../../modules/sna-alert/snaAlertModule';
import { Router } from '@angular/router';


var href;
//const colorsDark = ['#2864ae', '#e04040', '#faf40c', '#5bce2c', '#d74ce7', '#eaab4e'];
//const colorsLight = ['#a1f0f0', '#ef7f7f', '#f6f882', '#7fe88d', '#f7c0f7', '#f7e2c0'];
const colorsDark = ['#2196F3', '#FF9800', '#4CAF50', '#673AB7', '#FFEB3B', '#607D8B'];
const colorsLight = ['#BBDEFB', '#FFE0B2', '#C8E6C9', '#D1C4E9', '#FFF9C4', '#CFD8DC'];

@Component({
  selector: 'circ-economy',
  templateUrl: './circ-economy.component.html',
  styleUrls: ['./circ-economy.component.scss']
})
export class CircEconomyComponent implements OnInit {
  @Input() EconomyCycleIdeaRels: any;
  @Input() Idea: any;
  @Output() EconomyCycleIdeaRelsChange = new EventEmitter();
  @ViewChild('circcontainer') circcontainer: ElementRef;
  showInfo = false;
  elements: any; //P.S. not needed any more
  //P.S. Extended local array of global.EconomyCycleStages
  //augmentd by information from EconomyCycleIdeaRels
  EconomyCycleStages: any;
  EconomyCycleStage = {
    Id: 0,
    Name: "",
    RelName: "",
    RelDescription: "",
    RelStatusQuo: "",
    RelImprovement: "",
    RelScale: 0,
    result: {}
  }

  EconomyCycleIdeaRel = {};
  sizing = {
    size: 800,
    radius: 600,
    center: 400,
    innerRadius: 300,
    width: 300, //radius - innerRadius
  }
  //Defines the templates for the economy cycles
  arrSurveyIds = [18, 1, 29, 13, 30, 27];
  surveydata: any = {}; //selected survey data
  surveydatajson: any = {};
  surveydatalist: any = [];

  constructor(private db: DbServiceService,
    private global: GlobalService,
    private router: Router,
    private alert: snaAlertService) { }

  async ngOnInit() {
    href = this;
    let sz = this.sizing; //just to write less
    sz.size = this.circcontainer.nativeElement.offsetWidth - 30;
    sz.size = sz.size * 1.0;
    sz.center = sz.size / 2;
    sz.radius = sz.size * 0.4
    sz.innerRadius = sz.radius * 0.5;
    sz.width = sz.radius - sz.innerRadius;
    if (this.Idea.Id > 0) {

    let utresp = await this.db.get("user", "gettokenofloggedinuser");
    const sQuery = { IdeaId: this.Idea.Id, token: utresp.token, Tid1: this.arrSurveyIds[0], Tid2: this.arrSurveyIds[1], Tid3: this.arrSurveyIds[2], Tid4: this.arrSurveyIds[3], Tid5: this.arrSurveyIds[4], Tid6: this.arrSurveyIds[5] };
    const resp = await this.db.get("survey", "getSurveyCicrEcon", sQuery);
    this.surveydatalist = resp.surveydatalist;

    //deep copy via json
    this.EconomyCycleStages = JSON.parse(JSON.stringify(this.global.EconomyCycleStages));

    this.EconomyCycleStages.forEach((ecs, i) => {
      ecs.surveyTemplId = this.arrSurveyIds[i];
      ecs.surveydata = this.getSurveydata(this.arrSurveyIds[i]);
      let rel = null;
      if (this.EconomyCycleIdeaRels) {
        rel = this.EconomyCycleIdeaRels.find(x => x.EconomyStageId == ecs.Id);
      }
      rel = rel ? rel : this.newRel(ecs.Id);
      this.addToStage(ecs, rel);
      //now add x and y for position of score
      this.setxy(ecs, i, this.sizing.size); //800 is size of circle svg
    });
    this.EconomyCycleStage = this.EconomyCycleStages[0];

    this.surveydata = this.surveydatalist[0];
    this.surveydatajson = JSON.parse(this.surveydata.DataJson);
    this.EconomyCycleStages.forEach((ecs, i) => { this.refreshScores(ecs); });
      this.drawCircEcon();
    }


  }
  getSurveydata(index) {
    this.surveydata = this.surveydatalist.find(x => x.SurveytemplateId == index);
    return this.surveydata;
  };

  refreshScores(ecs) { //refresh score for sereydata
    var sdJO = JSON.parse(ecs.surveydata.DataJson);
    let result = { totalScore: 0, sumScores: 0, sumWeights: 0, nElements: 0, }

    sdJO.elements.forEach(el => {
      if (el.type == "rating") {
        if (el.result != "") {
          if (el.weight != null) {
            result.nElements += 1;
            result.sumScores += el.result * el.weight
            result.sumWeights += el.weight * 1;
          }
        }
      }
    });
    if (result.sumWeights > 0) result.totalScore = result.sumScores / result.sumWeights;
    ecs.result = result;
    ecs.RelScale = result.totalScore / 2;
  }


  setxy(ecs, i, size) {
    let radius = size * 0.35;
    let angleStart = i * Math.PI / 3 + Math.PI / 7; //A little offset
    let x = radius * Math.sin(angleStart) + size / 2 - 100;
    let y = (- radius * Math.cos(angleStart)) + size / 2;
    ecs['x'] = x + "px";
    ecs['y'] = y + "px";
  }

  //P.S. adds the relationship information to the economy stages
  addToStage(stage: any, rel: any) {
    stage['RelId'] = rel['Id'];
    stage['RelName'] = rel['Name'];
    stage['RelDescription'] = rel['Description'];
    stage['RelIdeaId'] = rel['IdeaId'];
    stage['RelEconomyStageId'] = rel['EconomyStageId'];
    stage['RelStatusQuo'] = rel['StatusQuo'];
    stage['RelImprovement'] = rel['Improvement'];
    stage['RelScale'] = rel['Scale'];
  }

  newRel(EconomyCycleStageId) {
    let r = {};
    r['Id'] = 0;
    r['Name'] = "";
    r['Description'] = "";
    r['IdeaId'] = this.global.IdeaId;
    r['EconomyStageId'] = 0;
    r['StatusQuo'] = "";
    r['Improvement'] = "";
    r['Scale'] = 0;
    return r;
  }
  ngOnChanges() {
    let a = this.EconomyCycleIdeaRels;
  }
  getSum() {
    let ret = 0;
    if (this.EconomyCycleStages) {
      this.EconomyCycleStages.forEach(x => {
        ret += x.RelScale;
      });
    }
    return ret;
  }
  gotoIdea() {
    this.router.navigate(['/dashboard/ideaedit']);
  }
  formValidate($event, name) {

  }
  changeScore(el) {
    //alert(el.RelScale);
    this.EconomyCycleStage = this.EconomyCycleStages.find(x => x.Id == el.Id);
    this.drawCircEcon();

  }
  select(el) {
    this.EconomyCycleStage = el;
    this.surveydata = el.surveydata;
    this.surveydatajson = JSON.parse(this.surveydata.DataJson);
  }
  async saveEcoCycle() {
    if (this.global.IdeaId == 0) {
      this.alert.warning("No Idea created, yet. Cannot save ecology data.")
    } else {
      let rels = [];
      this.EconomyCycleStages.forEach(x => {
        if (x.RelId > 0 || x.RelScale > 0) {
          let r = {};
          r['Id'] = x.RelId;//can be zero or greater
          r['Name'] = x.RelName;
          r['Description'] = x.RelDescription;
          r['IdeaId'] = this.global.IdeaId;
          r['EconomyStageId'] = x.Id;
          r['StatusQuo'] = x.RelStatusQuo;
          r['Improvement'] = x.RelImprovement;
          r['Scale'] = x.RelScale;
          rels.push(r);
        }

      });
      await this.db.post("Idea", "saveEcoCycleRelationships", rels);
      this.alert.success("Relationships updated");
    }
  }


  async refreshSurvey($event) {
    //alert($event);
    this.refreshScores(this.EconomyCycleStage);

    let result = { totalScore: 0, sumScores: 0, sumWeights: 0, nElements: 0, }
    $event.elements.forEach(el => {
      if (el.type == "rating") {
        if (el.result != "") {
          if (el.weight != null) {
            result.nElements += 1;
            result.sumScores += el.result * el.weight
            result.sumWeights += el.weight * 1;
          }
        }
      }
    });
    if (result.sumWeights > 0) result.totalScore = result.sumScores / result.sumWeights;
    this.EconomyCycleStage.result = result;
    this.EconomyCycleStage.RelScale = result.totalScore / 2;
    this.surveydata.DataJson = JSON.stringify($event);
    await this.db.post("survey", "postSurvey", this.surveydata, { id: this.surveydata.Id });
  }

  drawCircEcon() { //P.S. for later


    let vis = d3.select("#circ");
    vis.attr("width", href.sizing.size).attr("height", href.sizing.size); // Added height and width so arc is visible

    for (var i = 0; i < href.EconomyCycleStages.length; i++) {
      let angleStart = i * Math.PI / 3;
      let angleEnd = angleStart + Math.PI / 3;
      var arc = d3.arc()
        .innerRadius(href.sizing.innerRadius)
        .outerRadius(href.sizing.radius)
        .startAngle(angleStart) //converting from degs to radians
        .endAngle(angleEnd) //just radians

      let color = href.EconomyCycleStages[i].RelScale > 0 ? colorsDark[i] : colorsLight[i];
      vis.append("path")
        .attr("id", "1")
        .attr("class", "arc")
        .attr("d", arc)
        .attr("fill", color)
        .attr("transform", "translate(" + href.sizing.center + "," + href.sizing.center + ")");

    };
    for (var i = 0; i < href.EconomyCycleStages.length; i++) {
      let angleStart = i * Math.PI / 3;
      let angleEnd = angleStart + Math.PI / 3;
      var arc = d3.arc()
        .innerRadius(href.sizing.innerRadius)
        .outerRadius(href.sizing.radius)
        .startAngle(angleStart) //converting from degs to radians
        .endAngle(angleEnd) //just radians

      let color = href.EconomyCycleStages[i].RelScale > 0 ? colorsDark[i] : colorsLight[i];
      vis.append("circle")
        .attr("cx", (href.sizing.radius - href.sizing.width / 2) * Math.sin(angleStart))
        .attr("cy", - (href.sizing.radius - href.sizing.width / 2) * Math.cos(angleStart))
        .attr("r", href.sizing.width / 2)
        .attr("fill", color)
        .attr("transform", "translate(" + href.sizing.center + "," + href.sizing.center + ")");

    };
  }
}

