import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UsermanagerService, NewUser, Roles } from '../usermanager/usermanager.service';
import { concat } from 'rxjs';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { DbServiceService } from '../../../services/db-service.service';

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.scss']
})
export class NewuserComponent implements OnInit {

  customer: any;
  newuser: NewUser = new NewUser();
  newpass = "";
  checkroleid = [];
  roles: any = [];

  constructor(
    private UsermanagerService: UsermanagerService,
    private db: DbServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private alert: snaAlertService) { }

  ngOnInit() {


    this.getroles();
    this.newuser = new NewUser();
    this.newuser.Email = "aaa";
    this.newuser.FirstName = "";
    this.newuser.LastName = "";
    this.newuser.PhoneNumber = "";
    this.newuser.Password = "";
  }

  back(): void {
    window.history.back();
  }

  getroles() {
    this.UsermanagerService.getRoles()
      .subscribe(
        res => {
          this.roles = res;
        },
        err => {
          console.log("err", err);
        }
      );
  }


  createUser(form: NgForm) {
    this.newuser.rolestring = [];
    if (form.valid) {
      for (let i in this.roles) {
        if (this.roles[i].Checked) {
          this.newuser.rolestring.push(this.roles[i].Name);
        }
      }
      this.UsermanagerService.create_user(this.newuser)
        .subscribe(
          res => {
            console.log(res);
            form.reset();
            this.alert.success(res.message);
          },
          err => {
            console.log(err);
            this.alert.danger(err.error.message);
          });
    }
    else {
      this.alert.warning("Please Enter Valid Information.");
    }
  }

  createRandomUsers() {
    alert("Disabled");
    if (5 == 5) return;

    const nus = [
      { "Email": "Celeste.Bullock@yahootest17.com", "FirstName": "Celeste", "LastName": "Bullock", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Mervin.Wade@yahootest17.com", "FirstName": "Mervin", "LastName": "Wade", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Lee.Carroll@yahootest17.com", "FirstName": "Lee", "LastName": "Carroll", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Jimmy.Bates@yahootest17.com", "FirstName": "Jimmy", "LastName": "Bates", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Mohammed.Eaton@yahootest17.com", "FirstName": "Mohammed", "LastName": "Eaton", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Lou.Chapman@yahootest17.com", "FirstName": "Lou", "LastName": "Chapman", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Donnell.Duffy@yahootest17.com", "FirstName": "Donnell", "LastName": "Duffy", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Malik.Mckay@yahootest17.com", "FirstName": "Malik", "LastName": "Mckay", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Derrick.Schultz@yahootest17.com", "FirstName": "Derrick", "LastName": "Schultz", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Shirley.White@yahootest17.com", "FirstName": "Shirley", "LastName": "White", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Earnest.Nelson@yahootest17.com", "FirstName": "Earnest", "LastName": "Nelson", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Gerry.Shaw@yahootest17.com", "FirstName": "Gerry", "LastName": "Shaw", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Marcella.Lynn@yahootest17.com", "FirstName": "Marcella", "LastName": "Lynn", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Angel.Perez@yahootest17.com", "FirstName": "Angel", "LastName": "Perez", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Betty.Osborn@yahootest17.com", "FirstName": "Betty", "LastName": "Osborn", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Hal.Zhang@yahootest17.com", "FirstName": "Hal", "LastName": "Zhang", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Berta.Edwards@yahootest17.com", "FirstName": "Berta", "LastName": "Edwards", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Thomas.Rojas@yahootest17.com", "FirstName": "Thomas", "LastName": "Rojas", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Alfredo.Sloan@yahootest17.com", "FirstName": "Alfredo", "LastName": "Sloan", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Ariel.Walsh@yahootest17.com", "FirstName": "Ariel", "LastName": "Walsh", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Ahmad.Pugh@yahootest17.com", "FirstName": "Ahmad", "LastName": "Pugh", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Kenya.Lambert@yahootest17.com", "FirstName": "Kenya", "LastName": "Lambert", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Lina.Peterson@yahootest17.com", "FirstName": "Lina", "LastName": "Peterson", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Janine.Blackwell@yahootest17.com", "FirstName": "Janine", "LastName": "Blackwell", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Claud.Huynh@yahootest17.com", "FirstName": "Claud", "LastName": "Huynh", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Marco.Roberson@yahootest17.com", "FirstName": "Marco", "LastName": "Roberson", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Terry.Fernandez@yahootest17.com", "FirstName": "Terry", "LastName": "Fernandez", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
      { "Email": "Ronda.Mendez@yahootest17.com", "FirstName": "Ronda", "LastName": "Mendez", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" }
    ];

    const nus2 = [
      { "Email": "Elisha.Herring@yahootest17.com", "FirstName": "Elisha", "LastName": "Herring", "Password": "Pass123 ", "PhoneNumber": "999 999 9999", "rolestring": "['Evaluator']" },
    ];

    nus.forEach(nu => {
      this.newuser = new NewUser();
      this.newuser.Email = nu.Email;
      this.newuser.FirstName = nu.FirstName;
      this.newuser.LastName = nu.LastName;
      this.newuser.PhoneNumber = nu.PhoneNumber;
      this.newuser.Password = "Pass123!";
      this.newuser.rolestring = [];

      for (let i in this.roles) {
        if (this.roles[i].Checked) {
          this.newuser.rolestring.push(this.roles[i].Name);
        }
      }
      this.UsermanagerService.create_user(this.newuser)
        .subscribe(
          res => {
          },
          err => {
            console.log(err);
            this.alert.danger(err.error.message);
          });
    });
  }

  async addregtokens() {
    await this.db.get("user", "getregtoken");
  }

}
