import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-storeedit',
  templateUrl: './storeedit.component.html',
  styleUrls: ['./storeedit.component.scss']
})
export class StoreeditComponent extends BaseComponent implements OnInit{
  @Input('storeid') storeid;
  @Output('callback') cb = new EventEmitter();
  store: any = {};

  constructor(private injector: Injector) { super(injector) }

  ngOnInit() {
  }
  async submit() {
    let storeSubmit = await this.db.post("lookup", "postStore", this.store);
    this.cb.emit(storeSubmit);
  }
}
