import { IdeaType } from './../../../models/IdeaCluster';
import * as d3 from 'd3';
import * as $ from 'jquery';
import { d } from '@angular/core/src/render3';

let svg;
let nodes: any = [];
let links: any = [];
let width = 0;
let height = 0;
let allLinks: any = [];
let allGNodes: any = [];
let simulation: any;
let addNodeStatus = -1;
let node1;
let link1;

export function getLiinks() {
  return links;
}

export function initialize(svgId) {
  svg = d3.select(svgId);
  width = $(svgId).width();
  height = $(svgId).height();

  svg.selectAll('g').remove();

  simulation = d3.forceSimulation(nodes)
    .force('link', d3.forceLink(links)
      .id(function (d: any) { return d.id; })
      .distance(50).strength(+0.01)) // standard distance and slight attraction
    .force('charge', d3.forceManyBody().strength(-10))
    .force('center', d3.forceCenter(width / 2, height / 2))
    .force('bounds', boxingForce)
    .on('tick', ticked);
}
export function render(nodesIn, linksIn) {
  links = linksIn;
  nodes = nodesIn; 
  d3.selectAll('g').remove();

  const gLink = svg.append('g').attr('class', 'links');
  allLinks = gLink.selectAll('.link')
    .data(links).enter().append('line').attr('class', 'link')
    .attr('stroke', 'gray')
    .attr('stroke-width', '3');

  const gNode = svg.append('g').attr('class', 'nodes');
  const allNodes = gNode.selectAll('.node')
    .data(nodes, d => d.id);
  const nodeEnter = allNodes.enter().append('g').attr('class', 'node').attr('id', d => 'N' + d.id);

  allGNodes = nodeEnter;
  nodeEnter.append('circle')
    .merge(allNodes.select('circle'))
    .attr('r', function (d: any) { return d.radius; })
    .attr('fill', function (d: any) { return d.color; })
    .attr('stroke', function (d: any) {
      let ret = "black";
      if (d.stepStretchLeap == 3) ret = "green";
      return ret;
    })
    .attr('stroke-width', function (d: any) {
      let ret = 0;
      if (d.stepStretchLeap == 2) ret = 1;
      if (d.stepStretchLeap == 3) ret = 3;
      return ret;
    })
    .on('mouseover', handleMouseOver)
    .on('mouseout', handleMouseOut);

  nodeEnter.append('text')
    .merge(allNodes.select('text'))
    .text(d => {
      return d.name;
    }
    )
    .attr('x', 15).attr('y', 5).attr('class', 'text-normal');
  nodeEnter.append('text')
    .merge(allNodes.select('text'))
    .text(d => d.letter).attr('x', -5).attr('y', 6).attr('class', 'text-letter');

  allNodes.exit().remove();

  d3.selectAll('.node').call(d3.drag()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended))
    .on('click', function (d) { selectNode(d); });

  d3.selectAll('.link').on('click', function (d) { selectLink(d); });

  // Update and restart the simulation.
  simulation.nodes(nodes);
  simulation.force('link', d3.forceLink(links)
    .id(function (d: any) { return d.id; })
    .distance(50).strength(+0.01));
  simulation.alpha(0.5).restart();

}
function selectNode(d) { // clicked twice on same node
  if (node1 === d) {
    d.radius = 15;
    addNodeStatus = -1;
    node1 = {};
  } else { // new node selected
    if (addNodeStatus === -1) { // no other node selected
      node1 = d;
      d.radius = 25;
      addNodeStatus = 1;
    } else { // other node selected, create reltionship
      links.push({ source: node1, target: d });
      addNodeStatus = -1;
      node1 = {};
      nodes.forEach(x => x.radius = 15);
    }
  }
  render(nodes, links);
  // alert('node');
}
function selectLink(d) {
  link1 = d;
  const width2 = 100; const height2 = 50;
  const posx1 = width / 2 - width2 / 2;
  const posy1 = height / 2 - height2 / 2;
  const width3 = 600; const height3 = 150;
  const posx3 = width / 2 - width3 / 2;
  const posy3 = height / 2 - height3 / 2;

  const modal = svg.append('g').attr('class', 'modalsvg');

  // Modal Background
  modal.append('rect').attr('width', width).attr('height', height)
    .attr('x', 0).attr('y', 0).attr('fill', '#F4F7C4').attr('fill-opacity', '75%'); // F4F7C4
  // dialog box
  modal.append('rect').attr('width', width3).attr('height', height3)
    .attr('x', posx3).attr('y', posy3).attr('rx', 5).attr('ry', 5).attr('fill', '#FFF').attr('stroke', 'gray');
  // Dialog Text
  modal.append('text').text('Do you want to remove this relationship?').attr('x', posx1 - 100).attr('y', height / 2 - 50);
  // Yes button
  modal.append('rect').attr('width', width2).attr('height', height2).attr('x', posx1 - 100).attr('y', posy1).attr('class', 'modal-button')
    .attr('fill', '#CBCCC8').on('click', () => clickModal('Yes')).attr('stroke', 'black');
  modal.append('text').text('Yes').attr('x', posx1 - 70).attr('y', height / 2 + 5).on('click', () => clickModal('Yes'));
  // No button
  modal.append('rect').attr('width', width2).attr('height', height2).attr('x', posx1 + 100).attr('y', posy1).attr('class', 'modal-button')
    .attr('fill', '#CBCCC8').on('click', () => clickModal('No')).attr('stroke', 'black');
  modal.append('text').text('No').attr('x', posx1 + 140).attr('y', height / 2 + 5).on('click', () => clickModal('No'));
  // alert('link');
}
function clickModal(res) {
  // alert('modal clicked ' + res + ' and link ' + link1.source.name);
  if (res === 'Yes') {
    const index = links.findIndex(x => x === link1);
    links.splice(index, 1);
  }

  d3.selectAll('.modalsvg').remove();
  render(nodes, links);
}
function dragstarted(d) {
  if (!d3.event.active) { simulation.alphaTarget(0.7).restart(); }
  d.fx = d.x;
  d.fy = d.y;
}

function dragged(d) {
  d.fx = d3.event.x;
  d.fy = d3.event.y;
}

function dragended(d) {
  if (!d3.event.active) { simulation.alphaTarget(0); }
  d.fx = null;
  d.fy = null;
}

function boxingForce() {
  for (const node of nodes) {
    node.x = Math.min(Math.max(node.x, 20), width - 100);
    node.y = Math.min(Math.max(node.y, 20), height - 20);
  }
}
function ticked() {
  allLinks
    .attr('x1', function (d: any) { return d.source.x; })
    .attr('y1', function (d: any) { return d.source.y; })
    .attr('x2', function (d: any) { return d.target.x; })
    .attr('y2', function (d: any) { return d.target.y; });

  allGNodes
    .attr('transform', function (d) {
      return 'translate(' + d['x'] + ',' + d['y'] + ')';
    });
}
function handleMouseOver(d, i) {
  // alert('Mouse Over ' + d.name + ' ' + i);
  d3.select(this).attr('r', d.radius * 2);
  const sId = '#N' + d.id;
  d3.select(sId).append('text').text((n: any) => {
    let ret = " (Step)";
    if (d.stepStretchLeap == 2) ret = " (Stretch)";
    if (d.stepStretchLeap == 3) ret = " (Leap)";
    return d.type + ret;
  }).attr('x', 20).attr('y', -30).attr('class', 'text-mouse');
  // d3.select(sId).append('text').text( (n: any) => n.description).attr('x', -20).attr('y', 50).attr('class', 'mouse');
}
function handleMouseOut(d, i) {
  d3.select(this).attr('r', d.radius);
  // tslint:disable-next-line: quotemark
  const sId = '#N' + d.id + " > text.text-mouse";
  d3.selectAll(sId).remove();
}
