import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { BaseComponent } from '../../shared/basecomponent';
import * as d3 from 'd3';
import { CampaignSurveyData } from '../campaignSurveyData';

@Component({
  selector: 'app-campaignreport',
  templateUrl: './campaignreport.component.html',
  styleUrls: ['./campaignreport.component.scss']
})
export class CampaignreportComponent extends BaseComponent implements OnInit {
  campaignId = 0;
  campaign: any = {};
  campaignideas: any = [];
  campaignusersurvey: any = [];
  template: any = {};

  users: any = [];
  showUsers = false;
  campaignSurveyData: CampaignSurveyData;

  campaigns: any = [];
  surveyData: any = [];
  surveys: any = [];
  surveyItems: any = [];
  graphConfig = { width: 500, height: 300, left: 40, right: 40,top:20,bottom:20}
  rows: any = [];
  columns: any = [];
  data: any = [];
  colors = [
    "#82cbe6",
    "#6bf6e8",
    "#FFCB77",
    "#FEF9EF",
    "#FE6D73",
    "#ff2c51",
    "#5fddff",
    "#6a0036",
    "#0044a9",
    "#362900"
  ];
  showResults = false;
  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    //const lookups = await this.db.get("campaign", "getLookups");
    const resp = await this.db.get("campaign", "getCampaigns");
    this.campaigns = resp.campaigns;
    this.loader.hide();
  }
  viewsummary() {
    let ddy = 20; //height of one horizontal bar
    let dy = this.campaignideas.length * ddy + 2 * ddy; //height of one group of bars
    let top = 20;
    let bottom = 20;
    let height = this.data.length * dy + top + bottom;
    $("#graph2").html("");
    let svg = d3.select("#graph2").append("svg");
    svg.attr("id", "graph2").attr("width", 800).attr("height", height);
    svg.style("border", "solid gray 1px").style("margin", "20px");

    let xScale = d3.scaleLinear().domain([0, 10]).range([0, 800]);


    this.data.forEach((d,ir) => {
      d.forEach((e, ie) => {
        let el = svg.append("rect");
        el.attr("x", 0).attr("y", ir * dy + ie * ddy+45).attr("width", xScale(e)).attr("height", ddy).style("fill", this.colors[ie]);
      })
      d.forEach((e, ie) => {
        let p = svg.append("text");
        let se = e.toFixed(1);
        p.text(se).attr("x", 10).attr("y", ir * dy + 60 + ie * ddy);
      })

      let r = svg.append("text");
      r.text(this.rows[ir]).attr("x", 0).attr("y", ir * dy + 40);
    });

    let xAxis = d3.axisBottom(xScale);
    let axisarea = svg.append("g").attr("transform", "translate(0,3)");
    axisarea.call(xAxis);
  }
  async viewResults() {
    const resp = await this.db.get("campaign", "getCampaignUserSurvey", { campaignId: this.campaignId });
    this.campaign = resp.campaign;
    this.campaignideas = resp.campaignideas;
    this.campaignusersurvey = resp.campaignusersurvey;
    this.template = resp.template;
    this.template.survey = JSON.parse(this.template.templatejson);

    let a = 5;
    // take the first IdeaId for now
    if (this.campaignusersurvey.length > 0) {
      let surveyTemplateId = this.campaignusersurvey[0].surveytemplateId
      this.surveyData = await this.db.get("survey", "getSurveyByTemplate", { templateId: surveyTemplateId });
      this.surveys = [];
      this.surveyData.forEach(sd => {
        let survey = JSON.parse(sd.DataJson);
        this.surveys.push(survey)
      });
      // Now average over the various rating
      // build object with {id:"1",title: "",type: 'rating",nEntries: 25,values:[{key:"1",value:5},{key:"2",value:2.8}]}
      // Step 1, create empty objects for each question
      this.surveyItems = [];
      this.surveys.forEach(s => {
        s.elements.forEach(e => {
          if (e.type == "rating") {
            let id = e.id;
            let surveyItem = this.surveyItems.find(si => si.id == id);
            if (surveyItem == null) {
              surveyItem = { id: id, type: e.type, title: e.title, nEntries: 0, values: [] };
              for (let i = e.rateMin; i <= e.rateMax; i++) {
                surveyItem.values.push({ key: i.toString(), value: 0 });
              }
              this.surveyItems.push(surveyItem);
            }
            surveyItem.nEntries += 1;
            let result = e.result.toString();
            let entry = surveyItem.values.find(siv => siv.key == result);
            if (entry != null) entry.value += 1;
          }
        });
      });
      this.groupcampaignusersurvey();
      this.buildCampaignSurveyData();
      this.viewsummary();
      this.showResults = true;
      //now we can graph each surveyItem
      //this.surveyItems.forEach(si => {
      //  this.draw(si)
      //});
    }
  }
  groupcampaignusersurvey() {
    this.users = this.groupBy(this.campaignusersurvey, "userprofileId");
    this.users.forEach(user => {
      user.userprofileId = user.values[0].userprofileId;
      user.username = user.values[0].name;
      

      user.ideas = this.groupBy(user.values, "ideaId");
      delete user.values; //clean up of not needed

      user.ideas.forEach(idea => {
        //lift properties from last values entry
        let lastIdea = idea.values[idea.values.length - 1];
        idea.ideaId = lastIdea.ideaId
        idea.ideaName = lastIdea.ideaName;
        idea.dateEmailSubmit = lastIdea.dateEmailSubmit

        delete idea.values;
        //now map the surveys into the idea object
        idea.surveyResults = [];
        this.surveyData.forEach(surveyResult => {
          if (surveyResult.UserProfileId == user.userprofileId && surveyResult.RefId == idea.ideaId) {
            surveyResult.survey = JSON.parse(surveyResult.DataJson);
            idea.surveyResults.push(surveyResult);

          }
        });
        idea.submitDate = idea.surveyResults.reduce((prev, cur) => {
          if (cur.CreatedDate > prev || prev == null) {
            prev = cur.CreatedDate;
          }
          return prev;
        },null);
      });
    });
  }
  buildCampaignSurveyData() {
    this.campaignSurveyData = new CampaignSurveyData();

    //add coumns
    this.campaignideas.forEach(i => { 
      this.campaignSurveyData.addColumn(i.ideaname);
    });

    //add rows
    this.template.survey.elements.forEach(el => {
      if (el.type == "rating") {
        this.campaignSurveyData.addRow(el.title);
      }
    });

    //add data
    this.users.forEach(user => {
      user.ideas.forEach(idea => {
        idea.surveyResults.forEach(surveyResult => {
          let survey = surveyResult.survey;
          survey.elements.forEach(el => {
            if (el.type == "rating") {
              this.campaignSurveyData.addData(el.title, idea.ideaName, el.result * 1);
            }
          })
        })
      })
    });
    this.rows = this.campaignSurveyData.getRows();
    this.columns = this.campaignSurveyData.getColumns();
    this.data = this.campaignSurveyData.getData();
  }

  draw(surveyItem) {
    let si = surveyItem; //just to type less
    //this.graphConfig.height = this.campaignideas.length * 
    let pwidth = this.graphConfig.width - this.graphConfig.left - this.graphConfig.right;
    let pheight = this.graphConfig.height - this.graphConfig.top - this.graphConfig.bottom;

    // now lets do d3.js graphs
    
    let titleId = "title" + si.id;
    d3.select("#graph").append("div").attr("id", titleId).style("max-width",pwidth);
    $("#" + titleId).html(si.title);

    let svg = d3.select("#graph").append("svg");
    svg.attr("id", "graph" + si.id).attr("width", this.graphConfig.width).attr("height", this.graphConfig.height);
    svg.style("border", "solid gray 1px").style("margin","20px");

    let plotarea = svg.append("g").attr("transform", "translate(" + this.graphConfig.left + "," + this.graphConfig.top + ")");
    //plotarea.append("rect").attr("x", 0).attr("y", 0).attr("width", pwidth).attr("height", pheight).style("stroke", "gray").style("fill", "none").style("stroke-width", "2");

    let xScale = d3.scaleLinear().domain([0, si.values.length]).range([0, pwidth]);
    let numbers = si.values.map(x => { return x.value });
    let max = numbers.reduce((a, b) => { return Math.max(a,b) });
    let yScale = d3.scaleLinear().domain([0, max]).range([pheight,0])

    let bars = plotarea.selectAll("bars").data(si.values).enter();
    let barwidth = pwidth / si.values.length - 3;
    bars.append("rect").attr("x", (d, i) => { return xScale(i) }).attr("y", (d: any, i) => { return pheight - yScale(d.value) })
      .attr("width", barwidth).attr("height", (d: any, i) => { return yScale(d.value) })
      .style("fill", "orange");

    const yAxisTicks = yScale.ticks().filter(tick => Number.isInteger(tick));
    let yAxis = d3.axisLeft(yScale).tickValues(yAxisTicks)
      .tickFormat(d3.format('d'));

    plotarea.call(yAxis);
    let xAxis = d3.axisBottom(xScale);
    let axisarea = svg.append("g").attr("transform", "translate(" + this.graphConfig.left + "," + (this.graphConfig.height - this.graphConfig.bottom) + ")");
    axisarea.call(xAxis);

  }
}
