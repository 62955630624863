import { Component, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { GlobalService } from '../../../../services/global.service';
import { LoaderService } from '../../../../modules/loader/loader.service';
import { DbServiceService } from '../../../../services/db-service.service';

@Component({
  selector: 'app-companylist',
  templateUrl: './companylist.component.html',
  styleUrls: ['./companylist.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .tooltip-inner {
    background: white !important;
    color:black;
    width:1000px !important;
      }
    .tooltip{
    opacity: 1.0 !important;
    }
  `]
})
export class CompanylistComponent implements OnInit {
  @Input() IdeaTypeName: string;

  companies: any = [];
  companiesDisplay: any = [];
  companytypes: any = [];
  businessareas: any = [];
  businessareaId = 0;

  companyTypeId: any = 0;
  industries: any = [];
  industryId = 0;
  packtechs: any = [];
  packtechId = 0;
  showActiveOnly = true;
  roles: any = [];
  priorities: any[];
  isAdmin = false;

  companytags1: any = []
  companytags2: any = []
  companytags3: any = []
  companytaglist1: any = []
  companytaglist2: any = []
  companytaglist3: any = []
  tag1 = "";
  tag2 = "";
  tag3 = "";
  showTagList1 = false
  showTagList2 = false
  showTagList3 = false

  constructor(
    private db: DbServiceService,
    public global: GlobalService,
    private loader: LoaderService,
    private router: Router) {
  }

  async ngOnInit() {
    this.loader.show();
    let st = sessionStorage.getItem('roles');
    if (st != null) {
      this.roles = st.split(",");
      this.isAdmin = this.roles.some(x => x = "Adminitrator");
    }
    
    this.priorities = await this.db.get('lookup', 'getCompanyPriorites');
    this.businessareas = await this.db.get('lookup', 'getBusinessAreas');
    this.companytags1 = await this.db.get("company", "getAllTagsByCat", {catid: 1})
    this.companytaglist1 = await this.db.get("company", "getAllTagsGroupedByCat", { catid: 1 })
    this.companytags2 = await this.db.get("company", "getAllTagsByCat", { catid: 2 })
    this.companytaglist2 = await this.db.get("company", "getAllTagsGroupedByCat", { catid: 2 })
    this.companytags3 = await this.db.get("company", "getAllTagsByCat", { catid: 3 })
    this.companytaglist3 = await this.db.get("company", "getAllTagsGroupedByCat", { catid: 3 })

    let resp = await this.db.get('company', 'getCompanies');
    this.companies = resp.companies;
    this.companies.forEach(c => {
      c.tags1 = []
      c.tags2 = []
      c.tags3 = []
      let url = c.website;
      if (url && url.length > 4 && url.substring(0, 4).toLowerCase() != "http") {
        c.url = "http://" + c.website;
      } else {
        c.url = c.website;
      }
      
      let surl = c.susWebsite;
      if (surl && surl.length > 4 && surl.substring(0, 4).toLowerCase() != "http") {
        c.surl = "http://" + c.susWebsite;
      } else {
        c.surl = c.susWebsite;
      }

      let gurl = c.goalWebsite;
      if (gurl && gurl.length > 4 && gurl.substring(0, 4).toLowerCase() != "http") {
        c.gurl = "http://" + c.goalWebsite;
      } else {
        c.gurl = c.goalWebsite;
      }
    });
    this.companytags1.forEach(t => {
      let company = this.companies.find(x => x.id == t.companyId)
      if (company != null) company.tags1.push(t)
    })
    this.companytags2.forEach(t => {
      let company = this.companies.find(x => x.id == t.companyId)
      if (company != null) company.tags2.push(t)
    })
    this.companytags3.forEach(t => {
      let company = this.companies.find(x => x.id == t.companyId)
      if (company != null) company.tags3.push(t)
    })
    this.companiesDisplay = this.companies;
    this.companytypes = resp.companytypes;
    this.companytypes.splice(0, 0, { id: 0, name: "all" });
    this.industries = resp.industries;
    this.industries.splice(0, 0, { id: 0, name: "all" });
    this.packtechs = resp.packtechs;
    this.packtechs.splice(0, 0, { id: 0, name: "all" });
    this.businessareas.splice(0, 0, { id: 0, name: "all" });
    this.filter();

    this.loader.hide();
  }
  navigate(item) {
    this.router.navigate(['/dashboard/companyedit'], { queryParams: { 'Id': item.id } });
  }
  filterAll() {
    this.companiesDisplay = this.companies;
    this.tag1 = ""
    this.tag2 = ""
    this.tag3 = ""
  }
  filter() {
    this.companiesDisplay = this.companies;

    //filter active
    if (this.showActiveOnly) {
      let temp = this.companiesDisplay;
      this.companiesDisplay = [];
      temp.forEach(t => {
        if (t.isActive) this.companiesDisplay.push(t);
      })
    }

    // filter type
    if (this.companyTypeId > 0) {
      let temp = this.companiesDisplay;
      this.companiesDisplay = [];
      temp.forEach(c => {
        if (c.companyTypeId == this.companyTypeId) {
          this.companiesDisplay.push(c);
        }
      });
    }

    // businessarea
    if (this.businessareaId > 0) {
      let temp = this.companiesDisplay;
      this.companiesDisplay = [];
      temp.forEach(c => {
        if (c.businessAreaId == this.businessareaId) {
          this.companiesDisplay.push(c);
        }
      });
    }

    // filter industry
    if (this.industryId > 0) {
      let temp = this.companiesDisplay;
      this.companiesDisplay = [];
      temp.forEach(c => {
        if (c.industryId == this.industryId) {
          this.companiesDisplay.push(c);
        }
      });
    }

    // filter packaging systems
    if (this.packtechId > 0) {
      let temp = this.companiesDisplay;
      this.companiesDisplay = [];
      temp.forEach(c => {
        c.packtechs.forEach(pt => {
          if (pt.packagingTechnologyId == this.packtechId) {
            this.companiesDisplay.push(c);
          }
        });
      });
    }
    // filter by tag
    if (this.tag1.length > 0) {
      let temp = this.companiesDisplay;
      this.companiesDisplay = [];
      
      temp.forEach(c => {
        let pushCompany = false
        c.tags1.forEach(t => {
          if (t.tag == this.tag1) pushCompany = true
        });
        if (pushCompany) this.companiesDisplay.push(c)  
      });
    }

    if (this.tag2.length > 0) {
      let temp = this.companiesDisplay;
      this.companiesDisplay = [];

      temp.forEach(c => {
        let pushCompany = false
        c.tags2.forEach(t => {
          if (t.tag == this.tag2) pushCompany = true
        });
        if (pushCompany) this.companiesDisplay.push(c)
      });
    }

    if (this.tag3.length > 0) {
      let temp = this.companiesDisplay;
      this.companiesDisplay = [];

      temp.forEach(c => {
        let pushCompany = false
        c.tags3.forEach(t => {
          if (t.tag == this.tag3) pushCompany = true
        });
        if (pushCompany) this.companiesDisplay.push(c)
      });
    }
  }
  newCompany() {
    this.router.navigate(['/dashboard/companyedit'], { queryParams: { 'Id': 0 } });
  }
  public lookup(id: any, array: any[], idField = "id", nameField = "name") {
    let ret = null; //default return
    if (id == null || array == null) return ret;
    array.forEach(item => {
      if (item[idField] == id) {
        ret = item[nameField];
      }
    });
    return ret;
  }
}
