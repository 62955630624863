import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-lookup-manager',
  templateUrl: './lookup-manager.component.html',
  styleUrls: ['./lookup-manager.component.scss']
})
export class LookupManagerComponent implements OnInit {
  lookups: any = [];
  lookup: any = {};
  lookupCategories: any = [];
  lookupCategory: any = {};
  selectedLookupCategoryId: number = 0;
  selectedLookups: any = [];
  showCategoryManage = false;
  showAllLookups = false;
  constructor(private db: DbServiceService,
    private loader: LoaderService) { }

  ngOnInit() {
    this.loader.show();
    this.getData();
    this.loader.hide();
  }
  async getData() {
    let response = await this.db.get("lookup", "getAllLookupWithCategories");
    this.lookups = response.lookups;
    this.lookupCategories = response.lookupCategories;

    this.lookup = this.getNewLookup();
    this.lookupCategory = this.getNewLookupCategory();
    if (this.selectedLookupCategoryId > 0) {
      this.selectLookupsForCategory(this.selectedLookupCategoryId);
    }
  }

  selectLookupsForCategory($event) {
    this.lookupCategory = this.lookupCategories.find(x => x.id == this.selectedLookupCategoryId);
    this.lookup.lookupCategoryId = this.selectedLookupCategoryId;
    this.selectedLookups = this.lookups.filter(x => x.lookupcategoryid == this.selectedLookupCategoryId);
  }
  selectLookup(l) {
    this.lookup = l;
  };
  selectLookupCategory(lc) {
    this.lookupCategory = lc;
  };
  async deleteLookupCategory(l) {
    this.loader.show();
    await this.db.delete("lookup", "DeleteLookupCategory", { Id: l.id });
    this.getData();
    this.loader.hide();
  }
  async deleteLookup(l) {
    this.loader.show();
    await this.db.delete("lookup", "DeleteLookup", { Id: l.id });
    this.getData();
    this.loader.hide();
  }
  async submitCategory() {
    this.loader.show();
    await this.db.post("lookup", "PostLookupCateogry", this.lookupCategory);
    this.getData();
    this.loader.hide();
  };
  clearCategories() {
    this.lookupCategory = this.getNewLookupCategory();
  };
  clearLookup() {
    this.lookup = this.getNewLookup();
    this.lookup.lookupCategoryId = this.selectedLookupCategoryId;
  };
  async submitLookup() {
    this.loader.show();
    await this.db.post("lookup", "PostLookup", this.lookup);
    this.getData();
    this.loader.hide();
  }
  getNewLookup() {
    return { id: 0, name: "", description : "", lookupCategoryId: 0, seqNo: 10, weight: 0 , isActive: true, picUrl : ""};
  }
  getNewLookupCategory() {
    return { id: 0, name: "", documentation: "", seqNo: 10, weight: 0 };
  }
}
