import { Component, OnInit } from '@angular/core';
import { LoaderService } from './../../../modules/loader/loader.service';
import { GlobalService } from '../../../services/global.service';
import { DbServiceService } from '../../../services/db-service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fillsurvey',
  templateUrl: './fillsurvey.component.html',
  styleUrls: ['./fillsurvey.component.scss']
})
export class FillsurveyComponent implements OnInit {
  ideaId = 0;
  surveytemplateId = 0;
  token = "";

  idea: any = {};
  user: any = {};
  surveyData: any = [];
  surveyTemplate: any = {};
  survey: any = {};

  json = {};

  constructor(private loader: LoaderService,
    private db: DbServiceService,
    public global: GlobalService,
    private route: ActivatedRoute,
    private router: Router) { }

  async ngOnInit() {
    this.ideaId = this.getIntQueryParamOrDefault('IdeaId', 0);
    this.surveytemplateId = this.getIntQueryParamOrDefault('surveytemplateId', 0);
    this.token = this.getStringQueryParamOrDefault('token', "");

    const resp = await this.db.get("survey", "getSurveyIdeaUser", { ideaid: this.ideaId, surveytemplateId: this.surveytemplateId, token: this.token });
    this.idea = resp.idea;
    this.user = resp.user;
    this.surveyData = resp.surveyData;
    this.surveyTemplate = resp.surveyTemplate;

    this.json = this.getDataOrTemplate();
  }

  getDataOrTemplate() {
    let jsonTemp = {};
    if (this.surveyData.length > 0) { // Edit the existing survey
      this.survey = this.surveyData[0];
    } else { // Create a new survey to submit
      this.survey = { Id: 0, SurveytemplateId: this.surveytemplateId, UserProfileId: this.user.Id, RefId: this.ideaId, DataJson: this.surveyTemplate.Templatejson};
    }
    jsonTemp = JSON.parse(this.survey.DataJson);
    return jsonTemp;
  }

  getIntQueryParamOrDefault(sQuery: string, iDefault: number) {
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString !== null) { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }
  getStringQueryParamOrDefault(sQuery: string, sDefault: string) {
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = sDefault;
    if (QueryString !== null) { // P.S. called with non-empty IdeaId
      ret = QueryString;
    }
    return ret;
  }
  async refresh($event) {
    this.loader.show();
    this.survey.DataJson = JSON.stringify(this.json);
    const resp = await this.db.post("survey", "postSurvey", this.survey, { id: this.survey.Id });
    this.survey = resp;
    this.json = JSON.parse(this.survey.DataJson);
    this.loader.hide();
  }
}
