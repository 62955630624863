import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

var ref: any;

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  hasFile = false;
  thumbnail: any = '';

  @Output() emitSaveImage = new EventEmitter();

  localFile: any;
  constructor() { }

  ngOnInit() {
    ref = this;
  }

  test() {
    alert("Orginial: "+this.croppedImage.length);
    this.thumbnailify(this.croppedImage, 100, (x) => {
      ref.thumbnail = x;
      alert("Cropped: "+x.length);
    }); 
  }
  fileChangeEvent(event: any): void {
    this.hasFile = true;
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  saveImage() {
    //Create thumbnail first
    this.hasFile = false;
    ref.croppedImage = this.croppedImage;
    ref.imageChangedEvent = this.imageChangedEvent;
    ref.thumbnail = this.thumbnail;
    this.thumbnailify(this.croppedImage, 100, (x) => {
      ref.thumbnail = x;
      var ret = {
        'img': ref.croppedImage,
        'file': ref.imageChangedEvent.srcElement.files[0],
        'thumbnail': ref.thumbnail
      }
      ref.emitSaveImage.emit(ret);
      ref.croppedImage = "";
      ref.imageChangedEvent = null;
      this.localFile = "";
      //alert("Cropped: " + x.length);
    });
  }

  //P.S. converts stringbase64 to thumbnail string
  //https://jsfiddle.net/wunderbart/hnj5vrf0/
  thumbnailify(base64Image, targetSize, callback) {
    var img = new Image();

    img.onload = function () {
      var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");

      canvas.width = canvas.height = targetSize;

      ctx.drawImage(
        img,
        width > height ? (width - height) / 2 : 0,
        height > width ? (height - width) / 2 : 0,
        width > height ? height : width,
        width > height ? height : width,
        0, 0,
        targetSize, targetSize
      );

      callback(canvas.toDataURL());
    };

    img.src = base64Image;
  };
}
