import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-gsearch',
  templateUrl: './gsearch.component.html',
  styleUrls: ['./gsearch.component.scss']
})
export class GsearchComponent extends BaseComponent implements OnInit {
  tables: any = [];
  ideas: any = [];
  concepts: any = [];
  projects: any = [];
  products: any = [];
  technologies: any = [];
  companies: any = [];
  materials: any = [];

  packsystems: any = [];
  brands: any = [];
  processes: any = [];
  isCompanyOnly = false;

  searchObj = { searchString: "" };
  result: any = [];

  lcompanies: any = [];
  companiesDisplay: any = [];
  industries: any = [];
  industryId = 0;
  packtechs: any = [];
  packtechId = 0;
  constructor(private injector: Injector) { super(injector) }

  async ngOnInit() {
    this.initTables();
    let resp = await this.db.get('company', 'getCompanies');
    this.lcompanies = resp.companies;
    this.lcompanies.forEach(c => {
      let url = c.website;
      if (url && url.length > 4 && url.substring(0, 4).toLowerCase() != "http") {
        c.url = "http://" + c.website;
      } else {
        c.url = c.website;
      }
    });

    this.industries = resp.industries;
    this.industries.splice(0, 0, { id: 0, name: "all" });
    this.packtechs = resp.packtechs;
    this.packtechs.splice(0, 0, { id: 0, name: "all" });
  }

  initTables() {
    this.tables = [
      { id: 1, name: "Ideas", isChecked: true },
      { id: 2, name: "Concepts", isChecked: true },
      { id: 3, name: "Projects", isChecked: true },
      { id: 4, name: "Products", isChecked: true },
      { id: 5, name: "Companies", isChecked: true },
      { id: 6, name: "Materials", isChecked: true },
      { id: 7, name: "Packaging Systems", isChecked: true },
      { id: 8, name: "Brands", isChecked: true },
      { id: 9, name: "Processes", isChecked: true },
      { id: 10, name: "Technologies", isChecked: true },
    ]
  }

  async search() {
    this.loader.show();
    this.ideas = [];
    this.concepts = [];
    this.projects = [];
    this.products = [];
    this.companies = [];
    this.materials = [];
    this.packsystems = [];
    this.technologies = [];
    this.brands = [];
    this.processes = [];

    let ICPP = await this.db.post("reporting", "searchICPP", this.searchObj);
    ICPP.forEach(r => { if (r.typeId == 1) this.ideas.push(r); });
    ICPP.forEach(r => { if (r.typeId == 2) this.concepts.push(r); });
    ICPP.forEach(r => { if (r.typeId == 3) this.projects.push(r); });
    ICPP.forEach(r => { if (r.typeId == 4) this.products.push(r); });
    ICPP.forEach(r => { if (r.typeId == 5) this.technologies.push(r); });

    if (this.tables[4].isChecked == true) {
      this.companies = await this.db.post("reporting", "searchCompanies", this.searchObj);
    }

    if (this.tables[5].isChecked == true) {
      const resp = await this.db.post("reporting", "searchMaterials", this.searchObj);
      this.materials = resp.materials;
    }

    if (this.tables[6].isChecked == true) {
      this.packsystems = await this.db.post("reporting", "searchPackagingSystems", this.searchObj);
    }

    if (this.tables[7].isChecked == true) {
      const resp2 = await this.db.post("reporting", "searchBrands", this.searchObj);
      this.brands = resp2.brands;
    }

    if (this.tables[8].isChecked == true) {
      const resp3 = await this.db.post("reporting", "searchProcesses", this.searchObj);
      this.processes = resp3.processes;
    }

    this.loader.hide();
  }
  async searchCompanies() {
    this.filter();
  }

  filter() {
    this.companiesDisplay = this.lcompanies;


    // filter industry
    if (this.industryId > 0) {
      let temp = this.companiesDisplay;
      this.companiesDisplay = [];
      temp.forEach(c => {
        if (c.industryId == this.industryId) {
          this.companiesDisplay.push(c);
        }
      });
    }

    // filter packaging systems
    if (this.packtechId > 0) {
      let temp = this.companiesDisplay;
      this.companiesDisplay = [];
      temp.forEach(c => {
        c.packtechs.forEach(pt => {
          if (pt.packagingTechnologyId == this.packtechId) {
            this.companiesDisplay.push(c);
          }
        });
      });
    }

  }

  clear() {
    this.tables.forEach(t => t.isChecked = false);
    this.isCompanyOnly = false;
  }

  all() {
    this.tables.forEach(t => t.isChecked = true);
    this.isCompanyOnly = false;
  }
  changeSel() {
    this.isCompanyOnly = false;
    this.tables.forEach(t => {
      if (t.id == 5 && t.isChecked == true) this.isCompanyOnly = true;
      if (t.id != 5 && t.isChecked == true) {
        this.isCompanyOnly = false;
      } 
    });
  }
  getBackgroundColor(idea) {
    let ret = '#c6eac6';
    if (idea.typeId !== undefined) {
      if (idea.typeId === 2) { ret = '#a4a4f5'; }
      if (idea.typeId === 3) { ret = '#efefb0'; }
      if (idea.typeId === 4) { ret = 'rgb(207, 158, 207)'; }
    }
    return ret;
  }
  navigate(item) {
    this.global.IdeaId = item.id;
    if (item.typeId == 3) {
      this.router.navigate(['/dashboard/projectedit/'], { queryParams: { 'IdeaId': item.id } });
    } else {
      this.router.navigate(['/dashboard/ideaedit/'], { queryParams: { 'IdeaId': item.id } });
    }
  }

  gotoCanvas(idea) {
    this.router.navigate(['/dashboard/canvas/'], { queryParams: { 'IdeaId': idea.id } });
  }

  circEconomy(item) {
    this.global.IdeaId = item.id;
    this.router.navigate(['/dashboard/circeconomy'], { queryParams: { 'IdeaId': item.id } });
  }
  navigatec(item) {
    this.router.navigate(['/dashboard/companyedit'], { queryParams: { 'Id': item.id } });
  }
  selectMaterial(m) {

  }
  goto(path) {
    this.router.navigate([path]);
  }
}
