import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sa-input-boolean',
  templateUrl: './sa-input-boolean.component.html',
  styleUrls: ['../formfields.css']
})
export class SainputbooleanComponent implements OnInit {
  @Input() checked: string;
  @Input() title: string;
  @Input() required: string; //P.S. can be 'text' (if required) or 'none' if not required

  @Output() checkedChange = new EventEmitter();
  @Output() hasChanged = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  change(newValue) { //P.S. newValue has the changed text

    this.checked = newValue;

    //P.S. Synchronize the text with the model of the parent
    this.checkedChange.emit(newValue);
    this.hasChanged.emit(newValue);
  };
}
