import { LoaderService } from './../../../modules/loader/loader.service';
import { DbServiceService } from '../../../services/db-service.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { Router } from '@angular/router';
import { Xfactor2 } from '../../../modules/xfactor2';
import { Hbar2Component } from '../../shared/graph/hbar2/hbar2.component';

var xFactor3ref;
var href;

@Component({
  selector: 'app-xfactor',
  templateUrl: './xfactor.component.html',
  styleUrls: ['./xfactor.component.scss']
})
export class XfactorComponent implements OnInit {
  @ViewChild("xFactor2") xFactor2c: Hbar2Component;
  @ViewChild("xFactor3") xFactor3c: Hbar2Component;

  templateId = 0;
  templates: any = [];
  template: any = {};
  xFactors: any = [];
  histograms: any = [];
  ideas: any = [];
  ideaId: number;
  ideaName = "";


  constructor(private loader: LoaderService,
    private db: DbServiceService,
    public global: GlobalService,
    private router: Router) { }

  async ngOnInit() {
    this.loader.show();
    this.templates = await this.db.get("survey", "getTemplates");
    xFactor3ref = this.xFactor3c;
    href = this;
    this.loader.hide();
    let a = 5;
  }

  async getIdeas() {
    this.loader.show();
    this.template = this.templates.find(x => x.Id == this.templateId)
    let xFactor2 = new Xfactor2();
    let resp = await xFactor2.init(this.templateId, this.db);
    this.xFactors = resp.xFactors;
    this.histograms = resp.xFactors[0].histograms;
    this.ideas = resp.ideas;

    this.xFactor2c.draw(this.xFactors, "xFactor2", this.selectIdea);
    //this.xFactor2.draw(this.xFactors);
    this.loader.hide();
  }

  getXFactor() {
    let xt = this.xFactors.find(x => x.ideaId == this.ideaId);
    this.histograms = xt.histograms;
  }
  selectIdea($event) {
    href.ideaName = $event.key;
    let hist = [];
    $event.histograms.forEach(h => {
      var hentry = {key : h.info.name, value:h.stats.average };
      hist.push(hentry);
    });
    xFactor3ref.draw(hist, "xFactor3", null);

  }
}
