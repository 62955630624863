import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/basecomponent';

@Component({
  selector: 'app-companydetails',
  templateUrl: './companydetails.component.html',
  styleUrls: ['./companydetails.component.scss']
})
export class CompanydetailsComponent extends BaseComponent implements OnInit {
  id = 0;
  company: any = {};
  ideas: any = [];
  concepts: any = [];
  projects: any = [];
  products: any = [];
  technologies: any = [];
  services: any = [];
  users: any = [];

  constructor(injector: Injector) {super(injector) }

  async ngOnInit() {
    this.id = this.getIntQueryParamOrDefault("id", 0);
    this.loader.show();
    await this.getData(); 
    this.loader.hide();
  }
  async getData() {
    const resp = await this.db.get("company", "getCompanyDetails", { id: this.id });
    this.company = resp.company;
    this.ideas = resp.ideas;
    this.concepts = resp.concepts;
    this.projects = resp.projects;
    this.products = resp.products;
    this.technologies = resp.technologies;
    this.services = resp.services;
    this.users = resp.users;
  }
  goto(rec, iType) {
    this.global.IdeaId = rec.id;
    this.router.navigate(['/dashboard/ideaedit'], { queryParams: { 'IdeaId': rec.id } });
  }
}
