import { Component, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-techdata',
  templateUrl: './techdata.component.html',
  styleUrls: ['./techdata.component.scss']
})
export class TechdataComponent extends BaseComponent implements OnInit {
  @Input() ideaId = 0;
  techdatas: any = [];
  techdata: any = {};
  showTable = false;
  messageNew = "";
  constructor(private injector: Injector) { super(injector) }

  async ngOnInit() {
    if (this.ideaId > 0) {
      this.messageNew = "";
      this.loader.show();
      this.techdatas = await this.db.get("idea", "getTechnicalData", { IdeaId: this.ideaId })
      this.loader.hide();
    } else {
      this.messageNew = "Please, save a new record before adding technical data!";
    }
    let a = 5;
  }

  openModal(item) {
    if (item == null) {
      this.techdata = { id: 0, ideaId: this.ideaId, name: "", description: "", seqNo: 10 };
    } else {
      this.techdata = item;
    }
    $('#techTag').show();
  }

  async saveData() {
    $('#techTag').hide();
    this.loader.show();
    await this.db.post("idea", "postTechnicalData", this.techdata);
    this.techdatas = await this.db.get("idea", "getTechnicalData", { IdeaId: this.ideaId })
    this.loader.hide();
  }
  closeModal() {
    $('#techTag').hide();
  }
  async deletedata(t) {
    this.loader.show();
    await this.db.delete("idea", "deleteTechnicalData", { id: t.id });
    this.techdatas = await this.db.get("idea", "getTechnicalData", { IdeaId: this.ideaId })
    this.loader.hide();
  }
  print() {
    this.router.navigate(['/dashboard/printtechdata'], { queryParams: { 'ideaId': this.ideaId } });
  }

}
