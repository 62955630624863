import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sa-radio',
  templateUrl: './sa-radio.component.html',
  styleUrls: ['../formfields.css']
})
export class SaRadioComponent implements OnInit {
  @Input() title: string;
  @Input() options: any;
  @Output() hasChanged = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  change(newValue,o) {
    const option = this.options.find(x => x.id == o.id);
    this.options.forEach(x => x.isChecked = false);
    option.isChecked = true;
    this.hasChanged.emit(this.options);
  }
}
