import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'saLoader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  public show = false;
  private saLoaderSubscription: Subscription;

  constructor(private loader: LoaderService) { }

  ngOnInit() {
    this.saLoaderSubscription = this.loader.saLoaderState
      .subscribe(
      (state) => {
        this.show = state.show;
      });
  };

  ngOnDestroy(): void {
    this.saLoaderSubscription.unsubscribe();
  }

}
