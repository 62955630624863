export class Histogram {
  public id = ""; //unique string to identify
  public info: any = {}; // Use it to put more info into the historgram, for example, title opr weight
  public hist = new Map<number, number>(); //key, value
  public stats: any = {}; //contains count 

  isInitialized = false;

  public initialize(id: string, info : any,min: number, max: number) {
    this.id = id;
    this.info = info;
    this.isInitialized = true;
    this.stats = {
      min: min,
      max: max,
      count: 0,
      countNulls: 0,
      countOutside: 0,
      sum: 0,
      average: 0
    };

    this.hist = new Map<number, number>();

    for (var i = min; i <= max; i++) {
      this.hist.set(i, 0);
    }
  }
  public add(value: number) {
    if (!this.isInitialized) {
      alert("Histogram is not initialized!");
      return;
    }
    if (value == null) {
      this.stats.countNulls += 1;
    } else {
      if (value < this.stats.min || value > this.stats.max) {
        this.stats.countOutside += 1;
      } else {
        const index = Math.floor(value);
        const val = this.hist.get(index);
        this.hist.set(index,val+1);
        this.stats.count += 1;
        this.stats.sum += index;
        this.stats.average = this.stats.sum / this.stats.count;
      }
    }
  }
  public getData() {
    //key value pairs for data array
    let data = [];
    this.hist.forEach((value, key) => {
      data.push({ key: key, value: value });
    });
    return data;
  }
}
