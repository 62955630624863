import { Component, OnInit } from '@angular/core';
import { AccountService } from '../login/account.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { DbServiceService } from '../../services/db-service.service';


@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit {
  nIdeas = 0;
  nConcepts = 0;
  nProjects = 0;
  nProducts = 0;
  nTechnology = 0;
  nService = 0;
  nPictures = 0;
  nRelationships = 0;
  nMaterials = 0;
  nCompanies = 0;

  constructor(private router: Router,
    private accountService: AccountService,
    public global: GlobalService,
    private db: DbServiceService
  ) { }

  showGlobals = false;
  logedinuser: string;
  customer: string;
  companyName = "";
  companyId = "";
  roles: any = [];

  isCollapsed: boolean;
  isUserCollapsed: boolean;

  async ngOnInit() {
    this.logedinuser = sessionStorage.getItem('firstname');
    if (this.logedinuser != null) { //we have a logged in user
      this.customer = sessionStorage.getItem('customer');
      this.companyName = sessionStorage.getItem('companyName');
      this.companyId = sessionStorage.getItem('companyId');
      let st = sessionStorage.getItem('roles');
      this.roles = st.split(",");
    }

    await this.global.getLookups();
    const resp = await this.db.get("idea", 'getsummary');
    this.nIdeas = resp.nIdeas;
    this.nConcepts = resp.nConcepts;
    this.nProjects = resp.nProjects;
    this.nProducts = resp.nProducts;
    this.nTechnology = resp.nTechnology;
    this.nService = resp.nService;
    this.nPictures = resp.nPictures;
    this.nRelationships = resp.nRelationships;
    this.nMaterials = resp.nMaterials;
    this.nCompanies = resp.nCompanies;

  }

  logout() {
    this.accountService.logout();
    // this.router.navigate(['/dashboard/home']);
    this.router.navigate(['/login']);
  }

  login() {
    // this.AccountService.logout();
    this.router.navigate(['/login']);
  }

  goto(path) {
    this.router.navigate([path]);
  }
  gotoList(type: string, route: string){
    this.global.IdeaType = this.global.IdeaTypes.find(x => x.Name === type);
    this.router.navigate([route]);
  }

  gotoCreate(type: string, route: string) {
    this.router.navigate([route], { queryParams: { 'IdeaId': 0 } });
  }

  debug() {
    //alert("Debug");
    this.accountService
      .login("peter.schmitt@s5-tech.com", "Test123!")
      .subscribe(
        res => {
          console.log("Fetched token");
          window.location.reload();
          //this.router.navigate(['/dashboard/home']);

        },
        err => {
          console.log(err);
          alert("Email or Password Incorrect");
        });
  }
}
