import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sa-2list-manager',
  templateUrl: './sa-2list-manager.component.html',
  styleUrls: ['./sa-2list-manager.component.scss']
})
export class Sa2ListManagerComponent implements OnInit {
  @Input() options: any;
  @Input() selected: any;
  @Output() selectedChange = new EventEmitter();
  @Input() fieldName: string;

  constructor() { }

  ngOnInit() {

    this.options.forEach(o => o['isSelected'] = false);
    this.selected.forEach(o => o['isSelected'] = false);
  }
  selectOption(option) {
    option['isSelected'] = !option['isSelected']
  }
  selectAllOptions(value) {
    this.options.forEach(o => o['isSelected'] = value);
  }
  selectAllSelected(value) {
    this.selected.forEach(o => o['isSelected'] = value);
  }
  selectSelected(option) {
    option['isSelected'] = !option['isSelected']
  }
  moveToSelect() {
    this.options.forEach(o => {
      if (o['isSelected']) {
        let oFound = this.selected.find(x => x[this.fieldName] == o[this.fieldName]);
        if (oFound == null) {this.selected.push(o)}
      }
    })
    this.selectedChange.emit(this.selected);
  }
  removeFromSelect() {
    let selectedtemp = [];
    this.selected.forEach(s => {
      if (!s['isSelected']) { selectedtemp.push(s); }
    })
    this.selected = selectedtemp;
    this.selectedChange.emit(this.selected);
  }
}
