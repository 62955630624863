import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/components/shared/basecomponent';

@Component({
  selector: 'app-companytags',
  templateUrl: './companytags.component.html',
  styleUrls: ['./companytags.component.scss']
})
export class CompanytagsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() companyid = 0;
  @Input() catid = 1;
  category="General"
  tag = ""
  tags: any = []
  taglist: any = []
  showlist = false

  constructor(public injector: Injector) { super(injector) }


  ngOnInit() {
    if (this.catid == 1) this.category = "General" 
    if (this.catid == 2) this.category = "Technology and Processes" 
    if (this.catid == 3) this.category = "Material" 
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.companyid.currentValue > 0) {
      await this.getData()

    }
  }
  async getData() {
    this.tags = await this.db.get("company", "getAllTagsForCompanyByCat", { id: this.companyid,catid: this.catid })
    this.taglist = await this.db.get("company", "getAllTagsGroupedByCat", {catid: this.catid})
  }

  async add() {
    if (this.tag.length > 0) {
      let tagobj = { id: 0, companyid: this.companyid, tag: this.tag,catid: this.catid }
      await this.db.post("company", "postTagWithCat", tagobj)
      await this.getData()
      this.showlist = false
    }
  }
  async remove(tag: any) {
    await this.db.delete("company", "deleteTag", { id: tag.id })
    await this.getData()
  }
}
