import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../shared/basecomponent';
import * as d3 from 'd3';
import * as $ from 'jquery';

var href: any;

@Component({
  selector: 'app-companyrels',
  templateUrl: './companyrels.component.html',
  styleUrls: ['./companyrels.component.scss']
})
export class CompanyrelsComponent extends BaseComponent implements OnInit {
  @ViewChild('graphname') graphname;

  id = 0;
  company: any = {};
  ideas: any = [];
  concepts: any = [];
  projects: any = [];
  products: any = [];
  technologies: any = [];
  services: any = [];
  users: any = [];
  links: any = [];
  nodes: any = [];

  idea: any = {};
  showIdea = false;
  ideasSelect: any = [];

  constructor(injector: Injector) { super(injector) }

  async ngOnInit() {
    href = this;
    this.id = this.getIntQueryParamOrDefault("id", 0);
    this.loader.show();
    await this.getData();
    this.loader.hide();
  }
  async getData() {
    const resp = await this.db.get("company", "getCompanyDetails", { id: this.id });
    this.company = resp.company;
    this.ideas = resp.ideas;
    this.concepts = resp.concepts;
    this.projects = resp.projects;
    this.products = resp.products;
    this.technologies = resp.technologies;
    this.services = resp.services;
    this.users = resp.users;
    this.links = resp.links;
    this.nodes = [];
    this.addNodes(this.products, 0, "Aquamarine")
    this.addNodes(this.technologies, 0, "AliceBlue")
    this.addNodes(this.services, 0, "Moccasin")
    this.addNodes(this.ideas, 1, "LemonChiffon")
    this.addNodes(this.concepts, 2, "LightSkyBlue")
    this.addNodes(this.projects, 3, "LightGreen")

  }
  addNodes(nodes: any, col: number, color: string) {
    nodes.forEach(n => {
      n.col = col;
      n.color = color;
      this.nodes.push(n);
    });
  }

  async addRelationship() {
    $('#selectIdea').show();
  }
  closeSelectIdea() {
    $('#selectIdea').hide();
  }
  async selectTarget(item) {
    const resp = await this.db.get("idea", "addSingleRelationship", { sourceid: href.idea.id, targetid: item.id });
    await href.getData();
    href.drawGraph();
    $('#selectIdea').hide();
  }
  switchIdea() {
      href.router.navigate(['/dashboard/ideaedit'], { queryParams: { 'IdeaId': href.idea.id } });
  }

  drawGraph() {
    var width = this.graphname.nativeElement.offsetWidth;
    var height = this.graphname.nativeElement.offsetHeight;
    const margin = { top: 60, left: 30, bottom: 30, right: 30 };
    var boxHeight = 30; //height of boxes
    var boxDist = 80; //vertical distance between boxes
    var textPadding = 5;

    this.addTextLengthToNodes(); //create all texts, get their width, then remove
    var maxHeight = this.setNodeCoordinates(width, margin, boxDist);
    this.setLinkCoordinates();
    height = maxHeight + margin.bottom;


    d3.selectAll("svg").remove();
    var graphdiv = d3.select("#graphid");
    var svg = graphdiv.append("svg").attr("width", width).attr("height", height);

    var link = svg.selectAll('.links').data(this.links).enter().append("line")
      .attr("x1", (d: any) => { return d.x1; })
      .attr("y1", (d: any) => { return d.y1; })
      .attr("x2", (d: any) => { return d.x2; })
      .attr("y2", (d: any) => { return d.y2; })
      .attr("stroke", "gray").attr("stroke-width", 3).on("click", clickLink);

    var g1s = svg.selectAll('.pts').data(this.nodes).enter().append("g")
      .attr("id", function (d: any, i) { return "id" + d.id }).attr("class", "node col1").on("click", clickg);

    g1s.append("rect")
      .attr("x", (d: any) => { return d.x - textPadding })
      .attr("y", (d: any) => { return d.y - textPadding - boxHeight / 2 })
      .attr("width", (d: any) => { return d.width + 2 * textPadding })
      .attr("height", (d: any) => { return boxHeight })
      .attr("fill", (d: any) => { return d.color })
      .attr("stroke", "gray").attr("stroke-width", 1).attr("fill-opacity", 1);

    g1s.append("text")
      .attr("x", (d: any) => { return d.x })
      .attr("y", (d: any) => { return d.y })
      .text((d: any) => { return d.name })
      .attr("class", "nodetext").style("font-size", 18)
      .attr("id", (d: any) => { return "textid" + d.id });

    g1s.append("text")
      .attr("x", (d: any) => { return d.x })
      .attr("y", (d: any) => { return d.y-25 })
      .text((d: any) => { return d.type })
      .attr("class", "nodetexth").style("font-size", 18)
      .attr("id", (d: any) => { return "textid" + d.id });

    async function clickg(d, i) {
      href.idea.id = d.id;
      href.idea.name = d.name;
      href.idea.description = d.description;
      href.showIdea = true;
      href.global.IdeaId = d.id;
      const resp = await href.db.get("Idea", "getDefaultImage", { IdeadId: d.id });
      href.idea.imgUrl = resp.Url;

      href.ideasSelect = [];
      href.nodes.forEach(n => {
        if (n.id != href.idea.id) {
          href.ideasSelect.push(n)
        }
      });
    }

    async function clickLink(d, i) {
      alert(d.ideaSourceId + " to " + d.ideaTargetId);
      const resp = await href.db.delete("idea", "deleteSingleRelationship", { sourceid: d.ideaSourceId, targetid: d.ideaTargetId });
      await href.getData();
      href.drawGraph();
    }

    //var drag_handler = d3.drag()
    //  .on("drag", function (d: any) {
    //    d3.select(this)
    //      .attr("x", d.x = d3.event.x)
    //      .attr("y", d.y = d3.event.y);
    //  })
    //  .on("end", function (d: any) {
    //    var id1 = d.id;
    //    var id2 = -1;
    //    var dist = 2000 ** 2;
    //    var node: any = {}
    //    href.nodes.forEach(n => {
    //      if (d.id != n.id) {
    //        var distTemp = (n.x - d.x) ** 2 + (n.y - d.y) ** 2;
    //        if (distTemp < dist) {
    //          dist = distTemp;
    //          id2 = n.id;
    //          node = n;
    //        }
    //      }

    //    });
    //    alert("dragged " + d.name + " to " + node.name);
    //    href.drawGraph();
    //  });
    //drag_handler(d3.selectAll("rect"));
  }

  setLinkCoordinates() {
    this.links.forEach(l => {
      var node1 = this.nodes.find(x => x.id == l.ideaSourceId);
      var node2 = this.nodes.find(x => x.id == l.ideaTargetId);
      l.x1 = node1.x;
      l.y1 = node1.y;
      l.x2 = node2.x;
      l.y2 = node2.y;
    })
  }

  setNodeCoordinates(width: number, margin: any, boxDist: number) {
    var maxHeight = 0;
    var xColIndices = [0, 0, 0, 0];
    const xf = (width - margin.left - margin.right) / 4;
    this.nodes.forEach(n => {
      var cindex = xColIndices[n.col];
      n.x = margin.left + n.col * xf;
      n.y = boxDist * cindex + margin.top + n.col * 10;
      maxHeight = Math.max(n.y, maxHeight);
      xColIndices[n.col] += 1;
    })
    return maxHeight;
  }

  addTextLengthToNodes() {
    d3.selectAll("svg").remove();
    var svg = d3.select("#graphid").append("svg");
    svg.selectAll('#nodetext').data(this.nodes).enter().append("text")
      .attr("x", (d: any) => { return d.x })
      .attr("y", (d: any) => { return d.y })
      .text((d: any) => { return d.name })
      .attr("class", "nodetext").style("font-size", 18)
      .attr("id", (d: any) => { return "textid" + d.id });
    //      .style("font-size", 14);

    var texts = d3.selectAll(".nodetext");
    texts.nodes().forEach((t: any) => {
      var el: any = document.getElementById(t.id);
      var l = el.getBBox().width;
      //var l3 = el.getComputedTextLength();
      let id = t.id.substr(6);
      var node = this.nodes.find(x => x.id == id);
      node.width = l;
      let a = 5;
    });
    d3.selectAll("svg").remove();
  }
}
