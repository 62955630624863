import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maxcompression',
  templateUrl: './maxcompression.component.html',
  styleUrls: ['./maxcompression.component.scss']
})
export class MaxcompressionComponent implements OnInit {
  diameter = 0;
  area = 1;
  curvature = 0;
  facet = 0;
  convex = 0;


  exampleid = 1;
  examples = [
    { id: 1, name: 'Example 1' }, { id: 2, name: 'Example 2' }
  ]
  constructor() { }

  ngOnInit() {
    this.hasChanged(null);
  }
  calculate() {
    this.area = this.diameter * this.diameter * Math.PI / 4;
    this.curvature = this.area * 0.3;
    this.facet = this.area * 0.45;
    this.convex = this.area * 0.8;
  }
  clear() {
    this.diameter = 0;
    this.area = 0;
    this.curvature = 0;
    this.facet = 0;
    this.convex = 0;
  }

  hasChanged($event) {
    if (this.exampleid == 1) { this.diameter = 10 }
    if (this.exampleid == 2) { this.diameter = 20 }
    this.calculate();
  }
}
