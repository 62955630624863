import { Component, OnInit } from '@angular/core';
import { Xfactor } from '../../../modules/xfactor';
import { DbServiceService } from '../../../services/db-service.service';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-xfactortest',
  templateUrl: './xfactortest.component.html',
  styleUrls: ['./xfactortest.component.scss']
})
export class XfactortestComponent implements OnInit {
  templateId = 0;
  templates: any = [];
  data: any = [];
  surveyresults: any = [];
  xFactor: Xfactor;
  showsurveyresults = false;
  surveys: any = [];

  constructor(private db: DbServiceService,private loader: LoaderService) { this.xFactor = new Xfactor(); }

  async ngOnInit() {
    this.loader.show();
    this.templates = await this.db.get("survey", "getTemplates");
    this.loader.hide();
    let a = 5;
  }

  async getsurveydata() {
    this.loader.show();
    this.surveyresults = await this.db.get("survey", "getSurveyResults", { templateid: this.templateId });
    this.surveys = [];
    this.surveyresults.forEach(r => {
      const survey = {};
      survey['RefId'] = r.RefId;
      survey['Email'] = r.Email;
      survey['CreatedDate'] = r.CreatedDate;
      survey['jsondata'] = JSON.parse(r.DataJson);
      this.surveys.push(survey);
    });
    let a = 5;
    this.loader.hide();
  }

  init() {
    this.xFactor = new Xfactor();
    this.data = [
      { itemId: 6, itemName: "First Idea for Survey", ref: 1, answers: [{ id: 1, value: 3 }, { id: 2, value: 3 }, { id: 3, value: null }] },
      { itemId: 7, itemName: "Second Idea for Survey", ref: 1, answers: [{ id: 1, value: 0 }, { id: 2, value: 6 }, { id: 3, value: 5 }] },
      { itemId: 8, itemName: "Third Idea for Survey", ref: 1, answers: [{ id: 1, value: 1 }, { id: 2, value: 2 }, { id: 9, value: 3 }] },
      { itemId: 6, itemName: "First Idea for Survey", ref: 2, answers: [{ id: 1, value: 3 }, { id: 2, value: 3 }, { id: 3, value: 5 }] },
      { itemId: 7, itemName: "Second Idea for Survey", ref: 2, answers: [{ id: 1, value: 2 }, { id: 2, value: 6 }, { id: 3, value: 5 }] },
      { itemId: 8, itemName: "Third Idea for Survey", ref: 2, answers: [{ id: 1, value: 4 }, { id: 2, value: 2 }, { id: 3, value: 3 }] },
      { itemId: 6, itemName: "First Idea for Survey", ref: 3, answers: [{ id: 1, value: 5 }, { id: 2, value: 0 }, { id: 3, value: 5 }] },
      { itemId: 7, itemName: "Second Idea for Survey", ref: 3, answers: [{ id: 1, value: 6 }, { id: 2, value: 6 }, { id: 3, value: 5 }] },
      { itemId: 8, itemName: "Third Idea for Survey", ref: 3, answers: [{ id: 1, value: 11 }, { id: 2, value: 2 }, { id: 3, value: 3 }] },
      { itemId: 9, itemName: "Forth Idea for Survey", ref: 3, answers: [{ id: 1, value: 11 }, { id: 2, value: 2 }, { id: 3, value: 3 }] }
    ]

    this.xFactor.addConfigItem({ id: 1, name: 'First Question', weight: 1 });
    this.xFactor.addConfigItem({ id: 2, name: 'Second Question', weight: 2 });
    this.xFactor.addConfigItem({ id: 3, name: 'Third Question', weight: 5 });
    this.data.forEach(d => {
      this.xFactor.addItem(d.itemId, d.ref, d.itemName);
      d.answers.forEach(a => {
        this.xFactor.addValue(d.itemId, d.ref, a.id, a.value);
      });
    });
    this.xFactor.calculateResult();

  }
}
