import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-brandlist',
  templateUrl: './brandlist.component.html',
  styleUrls: ['./brandlist.component.scss']
})
export class BrandlistComponent extends BaseComponent implements OnInit {
  brands: any = [];
  brandid = 0;
  constructor(private injector: Injector) { super(injector)}

  async ngOnInit() {
    this.loader.show()
    await this.getData();
    this.loader.hide();
  }
  async getData() {
    const resp = await this.db.get("brand", "getAllBrands");
    this.brands = resp.brands;
  }
  addBrand() {
    this.brandid = 0;
    $('#brandTag').show();
  }
  closeModal() {
    $('#brandTag').hide();
  }
  editBrand(brand) {
    this.brandid = brand.id;
    $('#brandTag').show();
  }
  async brandSubmitted($event) {
    this.loader.show();
    await this.getData();
    $('#brandTag').hide();
    this.loader.hide();
  }
}
