import { Component, OnInit, Input, ElementRef, AfterViewInit} from '@angular/core';
import * as d3 from 'd3';
import * as $ from 'jquery';
import { schemeCategory10, schemeSet2 } from 'd3';

@Component({
  selector: 'vbar',
  templateUrl: './vbar.component.html',
  styleUrls: ['./vbar.component.scss']
})
export class VbarComponent implements OnInit {
  @Input() data: any;
  @Input() id: any;
  @Input() width: any;
  @Input() height: any;
  constructor(private hostElement: ElementRef) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.id == "xFactor") {
      let b = 5;
    }
    var margin = { "top": 20, "right": 10, "bottom": 20, "left": 30 };
    let width = this.width;
    var minmax = this.getMax();
    var height = this.height;

    var z = d3.scaleSequential(d3.interpolateViridis);
    z.interpolator(d3["interpolateViridis"]);
    z.domain([0, this.data.length]);

    var xScale = d3.scaleLinear()
      .domain([0, this.data.length])
      .range([margin.left, width - margin.right]);

    var yScale = d3.scaleLinear()
      .domain([minmax.min, minmax.max])
      .range([0, height - margin.top - margin.bottom]);

    var yScaleTick = d3.scaleLinear()
      .domain([minmax.min, minmax.max])
      .range([height-margin.bottom, margin.top]);

    var svg = d3.select('#' + this.id).attr("width",width).attr("height",height);
    // bars 
    var rect = svg.selectAll('rect')
      .data(this.data)
      .enter().append('rect')
      .attr('x', function (d, i) { return xScale(i); })
      .attr('y', function (d) { return height - margin.bottom - yScale(d['value']) })
      .attr('width', xScale(1)*.6)
      .attr('height', function (d) { return yScale(d['value']) })
      .attr('fill', function (d, i) { return z(i) })
      .attr('margin', 0).attr('index', function (d, i) { return i; }).attr('value', function (d, i) { return d['value']; });

    var b = svg.selectAll('text').data(this.data)
      .enter().append('text')
      .text(function (d, i) { return d['key']; }).attr("x", function (d, i) { return xScale(i)+5; }).attr("y", height-3)
      .attr("font-family", "arial").attr("font-size", "14px").attr("font-weight", "normal").attr("stroke", "darkgray").attr("stroke-width", "1");

    var yAxis = d3.axisLeft(yScaleTick)
      .tickFormat(d3.format('d'));

    svg.append('g')
      .attr('transform', 'translate(' + [margin.left, 0] + ')')
      .call(yAxis);
  }

  getMax() {
    let max = this.data[0].value;
    let min = this.data[0].value;
    this.data.forEach(x => {
      if (x.value > max) { max = x.value; }
      if (x.value < min) { min = x.value; }
    });
    return { max: max, min: min };
  }
  dummy() {
    var interpolators = [

      // These are from d3-scale.
      "Viridis",
      "Inferno",
      "Magma",
      "Plasma",
      "Warm",
      "Cool",
      "Rainbow",
      "CubehelixDefault",

      // These are from d3-scale-chromatic
      "Blues",
      "Greens",
      "Greys",
      "Oranges",
      "Purples",
      "Reds",
      "BuGn",
      "BuPu",
      "GnBu",
      "OrRd",
      "PuBuGn",
      "PuBu",
      "PuRd",
      "RdPu",
      "YlGnBu",
      "YlGn",
      "YlOrBr",
      "YlOrRd"
    ];
  }

}
