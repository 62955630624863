import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sa-input-date',
  templateUrl: './sa-input-date.component.html',
  styleUrls: ['../formfields.css']
})
export class SaInputDateComponent implements OnInit {
  @Input() text: string;
  @Input() title: string;
  @Input() required: string; //P.S. can be 'text' (if required) or 'none' if not required

  @Output() textChange = new EventEmitter();
  @Output() hasChanged = new EventEmitter();

  isValid = true;
  tiClass = { 'border': 'solid 1px gray' };//{'background-color':'green'};
  constructor() { }

  ngOnInit() {
    let a = 5;
  }
  change(newValue) { //P.S. newValue has the changed text
    //this.doValidation(newValue);
    this.text = newValue;

    //P.S. Synchronize the text with the model of the parent
    this.textChange.emit(newValue);
    //P.S. send the parent the message whether validation is true or false
    this.hasChanged.emit(this.isValid);
  };

  ngOnChanges(changes: SimpleChanges) {
    let s = changes.text.currentValue;
    if (s != null) {
      let dateArray = s.split("T");
      if (dateArray.length == 2) {
        this.text = dateArray[0];
      }
    }
    let a = 5;
  }
}
