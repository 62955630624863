import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-reclist',
  templateUrl: './reclist.component.html',
  styleUrls: ['./reclist.component.scss']
})
export class ReclistComponent extends BaseComponent implements OnInit {
  ideas: any = [];
  constructor(private injector: Injector) { super(injector) }
  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.loader.hide();

  }
  async getData() {
    const resp = await this.db.get("idea", "getIdeasForUser");
    this.ideas = resp;
  }
  goto(id) {
    this.router.navigate(['/dashboard/userideaedit'], { queryParams: { 'Id': id } });
  }
}
