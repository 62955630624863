import { LoaderService } from './../../../modules/loader/loader.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import * as $ from 'jquery';


@Component({
  selector: 'app-network-graph',
  templateUrl: './network-graph.component.html',
  styleUrls: ['./network-graph.component.scss']
})
export class NetworkGraphComponent implements OnInit {

  @Input() nodes: any;
  @Input() links: any;
  @Input() svgId: string;
  @Input() width: any;
  @Input() height: any;

  @Output() selectNode = new EventEmitter();
  @Output() debug = new EventEmitter();

  constructor(db: LoaderService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    // this.createGraph(this.callbackNodeSelect);
  }

  createGraph = function (nodes: Node[], links: Link[], callbackNodeSelect) {
    const svg = d3.select('#' + this.svgId)
      // P.S. reference all to a size of 400 by 300
      .attr('viewBox', '-400 -300 800 600')
      // Just for debugging
      .style('border', 'solid 1px green');
    svg.selectAll('g').remove(); // clear old graph on reload

    const simulation = d3.forceSimulation()
      .force('charge', d3.forceManyBody())
      .force('center', d3.forceCenter(0, 0));

    simulation.force('link',
      d3.forceLink()
        .id(function (d: any) { return d.id; })
        .distance(200)
        .strength(2));

    const link = svg.append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(links)
      .enter().append('line')
      .attr('stroke-width', function (d) { return 1; });

    const node = svg.append('g')
      .attr('class', 'nodes')
      .selectAll('g')
      .data(nodes)
      .enter().append('g');

    const circles = node.append('circle')
      .attr('r', function (d: Node) {
        return d.radius;
      })
      .attr('fill', function (d: Node) {
        return d.color;
      })
      .attr('stroke', 'gainsboro')
      .attr('stroke-width', '3')
      .call(d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended));

    const lables = node.append('text')
      .text(function (d: any) { return d.name; })
      .attr('x', 6)
      .attr('y', 3);

    let nodesAny: any = nodes;
    let nodesSim = <d3.SimulationNodeDatum[]>nodesAny;
    simulation
      .nodes(nodesSim)
      .on('tick', ticked);

    node.on('click', function (d) { selectNode(d); });

    function selectNode(d) {
      callbackNodeSelect(d)
    };

    function ticked() {
      link
        .attr('x1', function (d: any) {
          return nodes[d.source].x;
        })
        .attr('y1', function (d) { return nodes[d.source].y; })
        .attr('x2', function (d) { return nodes[d.target].x; })
        .attr('y2', function (d) { return nodes[d.target].y; })
        .attr('stroke', 'gray')
        .attr('stroke-width', 1);

      node
        .attr('transform', function (d) {
          return 'translate(' + d['x'] + ',' + d['y'] + ')';
        });
    }

    function dragstarted(d) {
      if (!d3.event.active) { simulation.alphaTarget(0.3).restart(); }
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragended(d) {
      if (!d3.event.active) { simulation.alphaTarget(0); }
      d.fx = null;
      d.fy = null;
    }

    this.debug.emit({ nodes: nodes, links: links });
  }
  callbackNodeSelect() {
    alert('CallBack');
  }
}

// define class outside of component
export class Node {
  // needed internally by forced graph
  index: string;
  x: number;
  y: number;
  vx: number;
  vy: number;

  constructor(
    public id: string, // P.S. Internal id, not necessarily the id of the underlying object
    public refId: string, // P.S. Id of the underlying object
    public name: string,
    public radius: number,
    public color: string,
    public description: string,
    public group: string,
    public picUrl // P.S. if defined can be displayed
  ) { }
}

export class Link {
  constructor(
    public source: string, // P.S. this has to be the same string as in the node
    public target: string,
    public description: string
  ) { }
}
