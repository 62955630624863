import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'survey-rating',
  templateUrl: './survey-rating.component.html',
  styleUrls: ['../../style.css']
})
export class SurveyRatingComponent implements OnInit {
  @Input() element: any;
  @Input() index: any;
  @Output() registerEvent = new EventEmitter();
  //@Output() elementChange = new EventEmitter();

  boxes: any = [];
  constructor() { }

  ngOnInit() {
    this.refresh();
    this.registerEvent.emit(this);
  }

  selectEventFired($event) {
    this.element['result'] = $event.value;
    //this.elementChange.emit(this.element);
    this.boxes.forEach(b => {
      b.isSet = b.value == $event.value ? true : false;
    });
  }

  //P.S can be called from parent
  refresh() {
    let iStart = this.element.rateMin;
    let iEnd = this.element.rateMax;
    this.boxes = [];
    for (var i = iStart; i <= iEnd; i++) {
      let b = { 'value': i, 'isSet': false };
      if (this.element.result != null && this.element.result != "") {
        if (this.element.result == i) { b.isSet = true;}
      }
      this.boxes.push(b);
    }
  }
}
