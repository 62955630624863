export class Xfactor {
  //see xfactor test on how to use this
  public config: any = []; //P.S. contains the weights
  public items: any = []; //Contains the Items
  public weightedaverages: any = [];

  constructor() { }

  init() {
    this.config = [];
  }

  addConfigItem(item: ConfigItem) {
    this.config.push(item);
  }

  addItem(id: number, ref: number, name: string) {
    var item = new Item();
    item.id = id;
    item.name = name;
    item.ref = ref;
    item.valueItems = [];
    this.items.push(item);
  }
  addValue(itemId: number, ref: number, valueId: number, value: number) {
    const item = this.items.find(x => x.id == itemId && x.ref == ref);
    if (item != null) {
      item.valueItems.push({ id: valueId, value: value });
    } else {
      alert("Xfactor.addValue: cannot find itemId " + itemId);
    }
  }

  calculateResult() {
    // Create a list of items (Ideas)
    this.items.forEach(item => {
      const iTemp = this.weightedaverages.find(x => x.id == item.id);
      if (iTemp == null) {
        var data = new Data();
        data.id = item.id;
        data.name = item.name;
        data.nSamples = 0;
        data.wa = 0;
        data.xFactor = 0;
        data.aggValueItems = [];
        this.weightedaverages.push(data);
      }
    });

    //Now loop through all items and aggregate
    this.items.forEach(item => {
      item.valueItems.forEach(vi => {
        if (vi.value != null) { // ignore values that are null
          var weightdaverage = this.weightedaverages.find(x => x.id == item.id);
          if (weightdaverage != null) {
            weightdaverage.nSamples += 1;
            var configItem = this.config.find(x => x.id == vi.id);
            if (configItem != null) {
              var aggVI = weightdaverage.aggValueItems.find(avi => avi.questionId == vi.id);
              if (aggVI == null) {
                aggVI = {};
                aggVI.questionId = vi.id;
                aggVI.sum = vi.value;
                aggVI.average = vi.value;
                aggVI.nSamples = 1;
                weightdaverage.aggValueItems.push(aggVI);
              } else {
                aggVI.nSamples += 1;
                aggVI.sum += vi.value;
              }
            }
          } else {
            alert("cannot find dataitem!")
          }
        }
      });
    });

    // Now get the averages
    this.weightedaverages.forEach(wa => {
      wa.aggValueItems.forEach(avi => {
        if (avi.nSamples > 0) { avi.average = avi.sum / avi.nSamples };
      })
    });

    // Now calculate the Xfacor (weighted average)
    this.weightedaverages.forEach(wa => {
      wa.xFactor = 0;
      var weight = 0;
      wa.aggValueItems.forEach(avi => {
        var config = this.config.find(x => x.id == avi.questionId);
        if (config == null) {
          // alert("xfactor.calculateResult: cannot find weight in config for id " + avi.id)
        } else {
          wa.xFactor += avi.average * config.weight;
          weight += config.weight;
        }
      });
      wa.xFactor = wa.xFactor / weight;
    });
  }
}

export class ConfigItem {//P.S. contains the weights for the value items
  public id: number;
  public name: string;
  public weight: number;
}

export class ValueItem {
  public id: number;
  public value: number;
}

export class Item {
  public id: number;
  public ref: number; //a refereccen, for example userid
  public name: string;
  public valueItems: ValueItem[];
}

export class Data { //contains analyzed data for every item
  public id: number;
  public name: string;
  public xFactor: number;
  public nSamples: number;
  public aggValueItems: ValueItem[];
  public wa: number;
}

