import { DbServiceService } from './../../../services/db-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remove-idea',
  templateUrl: './remove-idea.component.html',
  styleUrls: ['./remove-idea.component.scss']
})
export class RemoveIdeaComponent implements OnInit {
  ideas: any = [];
  constructor(private db: DbServiceService) { }

  async ngOnInit() {
    this.ideas = await this.db.get('idea', 'getallidealist');
  }

  async delete(idea) {
    await this.db.delete('idea', 'deleteIdea', {id: idea.Id});
    this.ideas = await this.db.get('idea', 'getallidealist');
  }

}
