import { Component, Injector, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../shared/basecomponent';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() Id: number; //record number of idea, concept or project
  @Input() tableName: string;
  localFile = ""
  documents: any = [];
  constructor(private injector: Injector) { super(injector) }

  ngOnInit() {
    this.getData();
  }
  async getData() {
    if (this.Id != null) {
      this.documents = await this.db.get("document", "getDocuments", { Id: this.Id, TableName: this.tableName });
      let a = 5;
    }
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (this.Id != null) {
      this.documents = await this.db.get("document", "getDocuments", { Id: this.Id, TableName: this.tableName });
      let a = 5;
    }
  }
  async fileChangeEvent(event) {
    this.loader.show();
    const fileTypes = ["txt", "pdf", "csv", "xls", "xlsm", "xlsx", "ppt", "pptx", "doc", "docx"];
    const file = event.target.files[0];
    const extension = this.getFileExtension(file.name);

    if (!fileTypes.some(x => x == extension)){
      let s = `Extension ${extension} is not one of the allowed document types. Allowed are:`;
      fileTypes.forEach(ft => { s += ft + ", "; });
      this.alert.danger(s);
      this.loader.hide();
      return;
    }


    let formData = new FormData();
    formData.append('file', file);
    formData.append('Id', this.Id.toString());
    formData.append('Table', this.tableName);
    formData.append('Extension', extension);

    await this.db.post('document', 'uploadDocument', formData);
    this.getData();
    this.loader.hide();
  }

  async delete(doc) {

    let resp = await this.db.delete('document', 'deletedocument', { Id: doc.Id });
    this.getData();
  }
}
