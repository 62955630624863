export function  createShallowCopy(array: any, map: any ) {
  // P.S. creates a new array as defined by map
  // example: const temp = util.createShallowMap(this.global.Categories, {'Id': 'id', 'Name': 'name'});

  const ret: any = [];
  array.forEach(a => {
    const na = {};
    for (const key of Object.keys(map)) {
      const value = map[key];
      na[value] = a[key];
    }
    ret.push(na);
  });
  return ret;
}

export function addProperty(array: any, fieldName: string, defaultValue: any) {
  array.forEach(a => { a[fieldName] = defaultValue; });
}
