import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'samenuitem',
  templateUrl: './menuitem.component.html',
  styleUrls: ['./menuitem.component.scss']
})
export class MenuitemComponent implements OnInit {
  @Input() url: string;
  @Input() title: string;
  @Input() linktoroute: string;
  @Input() height = '25px';

  @Output() callback = new EventEmitter();

  showRouterLink = false;
  showCallback = false;

  constructor() { }

  ngOnInit() {
    if (this.linktoroute != null){
      this.showRouterLink = true;
      this.showCallback = false;
    }
    if (this.callback != null) {
      this.showCallback = true;
      this.showRouterLink = false;
    }
  }
  submit() {
    this.callback.emit();
  }

}
