import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'survey-rating-checkbox',
  templateUrl: './survey-rating-checkbox.component.html',
  styleUrls: ['./survey-rating-checkbox.component.scss']
})
export class SurveyRatingCheckboxComponent implements OnInit {
  @Input() data: any;
  @Output() selecthaschanged = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  select() {
    this.selecthaschanged.emit(this.data);
  }
}
