import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-productedit',
  templateUrl: './productedit.component.html',
  styleUrls: ['./productedit.component.scss']
})
export class ProducteditComponent implements OnInit {
  IdeaId = 0;
  IdeaTypeId = 4; // P.S. Product
  constructor(    private route: ActivatedRoute) { }

  ngOnInit() {
    this.IdeaId = this.getIntQueryParamOrDefault('IdeaId', 0);
  }

  getIntQueryParamOrDefault(sQuery: string, iDefault: number){
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString !== null)  { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }
}
