import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'survey-comment',
  templateUrl: './survey-comment.component.html',
  styleUrls: ['../../style.css']
})
export class SurveyCommentComponent implements OnInit {
  @Input() element: any;
  @Input() index: any;
  @Output() registerEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.registerEvent.emit(this);
  }

}
