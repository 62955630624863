import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as $ from 'jquery';
import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HtmlEditorService } from '../../../../modules/html-editor/html-editor.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sa-input-html2',
  templateUrl: './sa-input-html2.component.html',
  styleUrls: ['../formfields.css']
})
export class SaInputHtml2Component implements OnInit, OnChanges {
  public Editor = ClassicEditor;
  @Input() text: string;
  @Input() title: string;
  @Input() height = 100;
  @Input() required: string; // P.S. can be 'text' (if required) or 'none' if not required

  @Output() textChange = new EventEmitter();
  @Output() hasChanged = new EventEmitter();

  isValid = true;
  tiClass = { 'border': 'solid 1px gray' }; // {'background-color':'green'};
  text2 = 'uyiuyiuyiy';
  constructor(private htmledit: HtmlEditorService) { }

  ngOnInit() {
    let a = this.htmledit.textChange
      .subscribe(ret => {
        if (this.title == ret.title) {
          this.text = ret.text;
          // P.S. Synchronize the text with the model of the parent
          this.textChange.emit(ret.text);
          // P.S. send the parent the message whether validation is true or false
          this.hasChanged.emit(this.isValid);
        }
      });
  }
  ngOnChanges() {
    this.text2 = this.text;
  }
  edit() {
    this.htmledit.show(this.title, this.text2);
  }

  change(newValue) { // P.S. newValue has the changed text
    this.doValidation(newValue);
    this.text = newValue;

    // P.S. Synchronize the text with the model of the parent
    this.textChange.emit(newValue);
    // P.S. send the parent the message whether validation is true or false
    this.hasChanged.emit(this.isValid);
  };

  doValidation = function (newValue) {
    // P.S. implement your validation rules here
    if (this.required == 'text') {
      if (newValue == '') {
        this.isValid = false;
        this.tiClass = { 'border': 'solid 2px red' };
      } else {
        this.isValid = true;
        this.tiClass = { 'border': 'solid 1px gray' };
      }
    }
  };

  closeModal() {
    this.textChange.emit(this.text);
    this.doValidation(this.text);
    this.hasChanged.emit(this.isValid);
  }
}
