import { snaAlertService } from './../../modules/sna-alert/sna-alert.service';
import { IdeaType } from './../../models/IdeaCluster';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Idea, Impact, Reach, Length, Sustainability, IdeaLookups } from '../../models/IdeaCluster';
import { GlobalService } from '../../services/global.service';
import { SainputhtmlComponent } from '../shared/formfields/sa-input-html/sa-input-html.component';
import { SaselectComponent } from '../shared/formfields/sa-select/sa-select.component';
import { SainputtextComponent } from '../shared/formfields/sa-input-text/sa-input-text.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { DbServiceService } from '../../services/db-service.service';
import { LoaderService } from '../../modules/loader/loader.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements OnInit {
  conceptId = 0;
  Idea: any = {}; // record in P.S DB Table Idea
  IdeaType: any = {};
  IdeaIdeaCatRel: any = []; // P.S DB Table IdeaIdeaCatRel
  IdeaCategories: any = []; // P.S. subset of global.categories having the seleted ideacategories
  IdeaMaterialAdjectivesRef: any = []; // DB P.S Table IdeaMaterialAdjectivesRef
  IdeaMaterialAdjectives: any = []; // subset of global.materialadjectives having the selected  IdeaMaterialAdjectivesRef
  EconomyCycleIdeaRels: any = [];
  IdeaRelationships: any = [];
  PicUrl = '';
  showHelpDescription = false;
  showFeasibility = false;
  showFeedback = false;
  showKeyResources = false;
  showKeyActivities = false;
  showMarket = false;
  showTarget = false;
  showCost = false;
  showMarketAccess = false;
  showSWOT = false;


  constructor(    private db: DbServiceService,
    public global: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private modal: NgbModal,
    private alert: snaAlertService) { }

  async ngOnInit() {
   this.conceptId = this.route.snapshot.queryParams['IdeaId'];
   this.loader.show();
   await this.global.getLookups();
   this.getIdea(this.conceptId);
   this.loader.hide();
  }

  async getIdea(conceptId) {
    const ret: any = await this.db.get('idea', 'getidea', { Id: conceptId });
    this.Idea = ret.Idea;
    // P.S temporary, need to be added to database
    // this.Idea.Feasibility = '';
    // this.Idea.UserFeedback = '';
    // this.Idea.MarketTarget = '';
    // this.Idea.Budget = '';
    // this.Idea.MarketAccess = '';
    // this.Idea.SWOT = '';

    this.IdeaType = this.global.IdeaTypes.find(x => x.Id === this.Idea.TypeId);
    this.IdeaIdeaCatRel = ret.IdeaIdeaCatRel;
    this.IdeaCategories = this.getIdeaCategories(this.IdeaIdeaCatRel);
    this.EconomyCycleIdeaRels = ret.EconomyCycleIdeaRels;
    this.IdeaMaterialAdjectivesRef = ret.IdeaMaterialAdjectiveRefs;
    this.IdeaRelationships = ret.IdeaRelationships;
    this.PicUrl = ret.PicUrl;

  }
  private getIdeaCategories(IdeaIdeaCatRel: any) {
    // P.S. Find all entries in global.Categories
    // that have IdeaCategoryId in relationship table IdeaIdeaCatRel
    // and return this subset
    const list: any = [];
    IdeaIdeaCatRel.forEach(cat => {
      const category = this.global.Categories.find(x => x.Id === cat.IdeaCategoryId);
      list.push(category);
    });
    return list;
  }
  async submit() {
    this.loader.show();
    const IdeaTemp = await this.db.post('idea', 'submitidea', this.Idea);
    this.loader.hide();
  }
  async htmlHasChanged($event) {

  }
  back() {
    this.router.navigate(['/dashboard/ideaedit'], { queryParams: { 'IdeaId': this.Idea.Id } });
  }

}
