import { Component, Injector, OnInit} from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-printtechdata',
  templateUrl: './printtechdata.component.html',
  styleUrls: ['./printtechdata.component.scss']
})
export class PrinttechdataComponent extends BaseComponent implements OnInit {
  IdeaId = 0;
  Idea: any = {};
  Url = "";
  techdatas: any = [];
  processes: any = [];

  constructor(private injector: Injector) { super(injector)}

 async  ngOnInit() {
    this.IdeaId = this.getIntQueryParamOrDefault('ideaId', 0);
    await this.getIdea();
  }

  getIntQueryParamOrDefault(sQuery: string, iDefault: number) {
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString !== null) { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }
  async getIdea() {
    const ret: any = await this.db.get('idea', 'getidea', { Id: this.IdeaId });
    this.Idea = ret.Idea;
    this.Url = ret.PicUrl == null ? "" : ret.PicUrl;
    this.techdatas = await this.db.get("idea", "getTechnicalData", { IdeaId: this.IdeaId });
    const ret2 = await this.db.get('process', 'getProcesses');
    this.processes = ret2.processes
  }
}
