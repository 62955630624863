import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'sa-table',
  templateUrl: './sa-table.component.html',
  styleUrls: ['./sa-table.component.scss']
})
export class SaTableComponent implements OnInit, OnChanges {
  @Input() table: any;
  @Input() title: string;
  @Input() columns?: any;
  @Input() link?: string;

  @Output() select = new EventEmitter();
  displayColumns: any = [];
  showId = false;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {


  }
  ngOnChanges(changes: any) {
    const attrShowId = this.elementRef.nativeElement.getAttribute('showId');
    if (attrShowId !== null) { this.showId = true;}
    if (this.table.length > 0) {
      const first = this.table[0];
      if (this.columns != null){
        this.displayColumns = this.columns;
      } else {
      this.displayColumns = Object.getOwnPropertyNames(first);
      }
      this.moveIdToFront();
      // remove id if attribute showId is not present
      if (this.showId === false){
        if (this.displayColumns[0].toLowerCase() === 'id'){
          this.displayColumns.splice(0, 1);
        }
      }
    }

  }
  moveIdToFront(){
    let ind = -1;
    this.displayColumns.forEach((c,i) => {
      if (c.toLowerCase() === 'id') { ind = i;}
    });

    if (ind > 0){
      const element = this.displayColumns[ind];
      this.displayColumns.splice(ind, 1);
      this.displayColumns.splice(0, 0, element);
    }
  }
  selectRow(row){
    this.select.emit(row);
  }
}
