import { Component, OnInit, Input } from '@angular/core';
import * as d3 from 'd3';
import * as $ from 'jquery';
import { geoNaturalEarth1 } from 'd3';
import { feature } from 'topojson';

let ref: any;

@Component({
  selector: 'app-worldmap',
  templateUrl: './worldmap.component.html',
  styleUrls: ['./worldmap.component.scss']
})
export class WorldmapComponent implements OnInit {
  climateZones: any;

  constructor() { }

  ngOnInit() {
    ref = this;
    this.setClimateZones();

    const svg = d3.select('#worldmap');
    const width = $('#worldmap').width();
    const height = $('#worldmap').height();

    svg.attr('width', width).attr('height', height);

    const proj = d3.geoNaturalEarth1();
    const pathGenerator = d3.geoPath().projection(proj);

    Promise.all([
      d3.json('https://unpkg.com/world-atlas@1.1.4/world/110m.json'),
      d3.tsv('https://unpkg.com/world-atlas@1.1.4/world/110m.tsv'),
      d3.csv('../../../assets/ClimateZones.csv')
    ]).then((data) => {
      console.log(data);
      const jsonData = data[0];
      const tsvData = data[1];
      const climateZones = data[2];
      const countryName = setCountryNames(tsvData, climateZones);


      const czColorScale = d3.scaleOrdinal()
        .domain(['I', 'II', 'III', 'IV', 'not found'])
        .range(['#2a8530', '#ded750', '#de7b4e', '#61bccf', 'white']);

      const countries = feature(jsonData, jsonData['objects'].countries);
      proj.fitExtent([[0, 0], [width, height]], countries);


      const path = svg.selectAll('path').data(countries.features);

      svg.append('path').attr('class', 'sphere')
        .attr('d', (d: any) => pathGenerator({ type: 'Sphere' }))
        .attr('fill', '#1e3057');

      path.enter().append('path').attr('d', (d: any) => pathGenerator(d))
        .attr('class', 'country').attr('stroke', 'black')
        .attr('fill', (d: any) => {
          const cz: string = countryName[d.id].defined;
          const col: string = czColorScale(cz) as string;
          return col;
        })
        .append('title').text((d: any) => countryName[d.id].name + ' \nClimate Zones: ' + countryName[d.id].zone);

      // Add a legend
      const czVertical = d3.scaleOrdinal()
        .domain(['I', 'II', 'III', 'IV', 'not found'])
        .range([80, 120, 160, 200, 240]);

      const legend = svg.append('g').attr('class', 'legend')
        .attr('transform', 'translate(20,500)');

      d3.select('.legend').append('rect')
        .attr('x', -10).attr('y', 0).attr('rx', 10)
        .attr('width', 300).attr('height', 230).attr('fill', 'lightgray');

      legend.selectAll('.legendItem').data(ref.climateZones)
        .enter().append('g').attr('class', 'legendItem');

      legend.append('text').text('Climate Zones').attr('font-size', '2em').attr('y', 40);

      legend.selectAll('.legendItem').append('circle')
        .merge(legend.selectAll('circle'))
        .attr('r', 15).attr('cx', 15)
        .attr('cy', (d: any) => czVertical(d['zone']) as string)
        .attr('fill', (d: any) => czColorScale(d['zone']) as string);
      legend.selectAll('.legendItem').append('text')
        .merge(legend.selectAll('text'))
        .text((d: any) => d['zone'] + ' ' + d['name']).attr('x', 40)
        .attr('y', (d: any) => czVertical(d['zone']) as string);
    });

    function render() {

    }
    function setCountryNames(tsvData, climateZones) {
      const countryName = tsvData.reduce((accumulator: any, d: any) => {
        const cZ = climateZones.find(x => x.Country === d.name);
        if (d.name === 'China') {
          const a = 5;
        }
        const zone = cZ === undefined ? 'not found' : cZ.Zones;
        const czDefined = cZ === undefined ? 'not found' : cZ.Defined;
        const c = {
          name: d.name,
          color: 'lightgreen',
          zone: zone,
          defined: czDefined
        };
        accumulator[d.iso_n3] = c;
        return accumulator;
      }, {});
      return countryName;
    }
  }
  setClimateZones() {
    this.climateZones = [
      { zone: 'I', name: 'Temperate zone' },
      { zone: 'II', name: 'Mediterranean/subtropical zone' },
      { zone: 'III', name: 'Hot dry zone' },
      { zone: 'IV', name: 'Hot humid/tropical zone' },
    ];
  }

}
