import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from './account.service';
import { TokenService } from './token.service';
import { snaAlertService } from '../../modules/sna-alert/snaAlertModule';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from '../../modules/loader/loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private AccountService: AccountService,
    private loader: LoaderService,
    private TokenService: TokenService,
    private router: Router,
    private alert: snaAlertService,
    private modalService: NgbModal
  ) { }

  femail: string = "";
  content: NgbModalRef;

  ngOnInit() {
  }

  open(content) {
    this.content = this.modalService.open(content, { windowClass: 'dark-modal', size: 'sm' });
  }

  onSubmit(f: NgForm) {
    if (f.valid) {
      this.loader.show();
      const { Email, Password } = f.value;

      this.AccountService
        .login(Email, Password)
        .subscribe(
          res => {
            console.log("Fetched token");
            this.router.navigate(['/dashboard']);
            this.loader.hide();
          },
          err => {
            console.log(err);
            this.loader.hide();
            this.alert.warning("Email or Password Incorrect");
          });

    }
  }

  onSubmitRecovery(form: NgForm) {
    if (form.valid) {
      this.AccountService.accountrecovery(this.femail)
        .subscribe(res => {
          this.alert.info(res.message);
          this.content.close();
        },
          err => {
            this.alert.warning(err.error);
            this.content.close();
          });
    }
  }
}
