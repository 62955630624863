import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-testsurvey',
  templateUrl: './testsurvey.component.html',
  styleUrls: ['./testsurvey.component.scss']
})
export class TestsurveyComponent extends BaseComponent implements OnInit {
  result: any = [];
  arrSurveyIds = [18, 1, 29, 13, 30, 27];

  constructor(private injector: Injector) { super(injector) }
  async ngOnInit() {
    await this.getData();
  }
  async getData() {
    this.loader.show();
    const sQuery = { Tid1: this.arrSurveyIds[0], Tid2: this.arrSurveyIds[1], Tid3: this.arrSurveyIds[2], Tid4: this.arrSurveyIds[3], Tid5: this.arrSurveyIds[4], Tid6: this.arrSurveyIds[5] };
    this.result = await this.db.get("survey", "getSurveyScoreCircEcon", sQuery)
    this.loader.hide();
  }
}
