import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-yesnodialog',
  templateUrl: './yesnodialog.component.html',
  styleUrls: ['./yesnodialog.component.scss']
})
export class YesnodialogComponent implements OnInit {
  showDialog = false;
  constructor() { }
  title;
  text;
  callbackconfirm: any; // callback function for confirm
  callbackdecline: any; // callback function for decline

  ngOnInit() {
  }

  show(title, text, callbackconfirm, callbackdecline) {
    this.title = title;
    this.text = text;
    this.showDialog = true;
    this.callbackconfirm = callbackconfirm;
    this.callbackdecline = callbackdecline;
  }
  yes () {
    this.showDialog = false;
    this.callbackconfirm();
  }
  no () {
    this.showDialog = false;
    this.callbackdecline();
  }
}
