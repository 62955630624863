import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit{
  @Input() surveydata: any;
  @Output() submitSurvey = new EventEmitter();
  childElements: any = []; //P.S. so we can call all children

  constructor() { }

  ngOnInit() {
  }

  // p.S. called from child, might not be needed
  saveResult($event, el) {
    let ell = this.surveydata.elements.find(x => x.id == el.id);

    // ell.result = $event.result;
    alert($event.result);
  }
  registerChild($event) {
    this.childElements.push($event);
  }
  // P.S. Called from component
  submitResult() {
    this.submitSurvey.emit(this.surveydata);
  }

  // P.S. called from parent to refresh data
  refresh(data) {
    this.surveydata = data;
    this.childElements.forEach(el => {
      if (el.element.type == 'rating') el.refresh();
    });
  }

}
