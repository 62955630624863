import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss']
})
export class ExploreComponent extends BaseComponent implements OnInit {

  ideas: any = [];

  ideatypes: any = [];
  typeId = 0;

  categories: any = [];
  categoryId = 0;

  packtechs: any = [];
  packtechId = 0;

  dosageforms: any = [];
  dosageformId = 0;

  treatmentfields: any = [];
  treatmentfieldId = 0;

  industries: any = [];
  industryId = 0;

  materialgroups: any = [];
  materialgroupId = 0;

  companies: any = [];
  materials: any = [];

  companyPackTech: any = [];
  materialPackSys: any = [];

  ideasdisplay: any = [];
  companiesdisplay: any = [];
  materialdisplay: any = [];

  showResult = false;

  constructor(private injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    const resp = await this.db.get("reporting", "getexplorerecords");
    this.ideas = resp.ideas;
    this.ideatypes = resp.ideatypes;
    this.categories = resp.categories;
    this.packtechs = resp.packtechs;
    this.dosageforms = resp.dosageforms;
    this.treatmentfields = resp.treatmentfields;
    this.materialgroups = resp.materialgroups;
    this.industries = resp.industries;
    this.companies = resp.companies;
    this.materials = resp.materials;
    this.companyPackTech = resp.companyPackTech;
    this.materialPackSys = resp.materialPackSys;

    this.ideas.forEach(i => { i.show = false; });
    this.loader.hide();

  }

  toggleVals(idea) {
    idea.show = !idea.show;
  }

  clear() {
    this.typeId = 0;
    this.categoryId = 0;
    this.packtechId = 0;
    this.dosageformId = 0;
    this.treatmentfieldId = 0;
    this.industryId = 0;
    this.materialgroupId = 0;
    this.showResult = false;
  }
  explore() {
    this.loader.show();
    this.ideas.forEach(x => {
      x.valType = this.getValType(x);
      x.valPackSys = this.getValPackSys(x);
      x.valCategory = this.getValCategory(x);
      x.valDosageForm = this.getValDosageForm(x);
      x.valTreatmentField = this.getValTreatmentField(x);

      this.getTotal(x);
    })

    this.ideas.sort((a, b) => { return b.total - a.total })

    this.ideasdisplay = [];
    this.ideas.forEach(i => {
      if (i.total > 0) this.ideasdisplay.push(i);
    });

    this.companiesdisplay = [];
    this.companies.forEach(c => {
      this.companyPackTech.forEach(cpt => {
        if (cpt.companyId == c.id && cpt.packagingTechnologyId == this.packtechId) {
          this.companiesdisplay.push(c);
        }
      })
    });



    this.materialdisplay = [];
    this.materials.forEach(m => {
      this.materialPackSys.forEach(mps => {
        if (m.id == mps.materialId && this.packtechId == mps.packsystemId) {
          this.materialdisplay.push(m);
        }
      })
    });
    this.loader.hide();
    this.showResult = true;
  }

  getValTreatmentField(x) {
    if (this.treatmentfieldId == 0) return 0;
    let valTreatmentField = 0;
    x.ideaTreatmentFieldRel.forEach(r => {
      if (r.treatmentfieldId == this.treatmentfieldId) valTreatmentField++;
    })
    return valTreatmentField;
  }
  getValDosageForm(x) {
    if (this.dosageformId == 0) return 0;
    let valDosageForm = 0;
    x.ideaDosageFormRel.forEach(r => {
      if (r.dosageFormId == this.dosageformId) valDosageForm++;
    })
    if (x.dosageFormId == this.dosageformId) valDosageForm++;
    return valDosageForm;
  }

  getValCategory(x) {
    if (this.categoryId == 0) return 0;
    let valCategory = 0;
    x.ideaIdeaCategoryRel.forEach(r => {
      if (r.ideaCategoryId == this.categoryId) valCategory++;
    })
    return valCategory;

  }
  getTotal(x) {
    x.total = x.valType * 100 + x.valPackSys + x.valCategory + x.valDosageForm + x.valTreatmentField;
  }
  getValType(x) {
    if (this.typeId == 0) return 0;
    return this.typeId == x.typeId ? 1 : 0;
  }

  getValPackSys(x) {
    if (this.packtechId == 0) return 0;
    let valPackSys = 0;
    x.ideaPackTechRel.forEach(r => {
      if (r.pachTechId == this.packtechId) valPackSys++;
    })
    return valPackSys;
  }

  gotoIdea(idea) {
    let sRoute = "ideaedit";
    this.router.navigate(['/dashboard/' + sRoute], { queryParams: { 'IdeaId': idea.id } });
  }

  gotoCompany(company) {
    this.router.navigate(['/dashboard/companyedit'], { queryParams: { 'Id': company.id } });
  }
  goto(path) {
    this.router.navigate([path]);
  }
  compare() {
    this.global.Selectids = [];
    this.ideasdisplay.forEach(c => {
      this.global.Selectids.push(c.id);
    })
    this.router.navigate(['/dashboard/ideacompare']);
  }
}
