import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {

  private saLoaderSubject = new Subject<any>();
  public saLoaderState = this.saLoaderSubject.asObservable();

  constructor() { }
  show() {
    this.saLoaderSubject.next({
      show: true
    });
  }
  hide() {
    this.saLoaderSubject.next({
      show: false
    });
  }
}
