import { Component, OnInit } from '@angular/core';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';

@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss']
})
export class DevelopmentComponent implements OnInit {

  constructor(private alert: snaAlertService) { }

  ngOnInit() {
  }
}
