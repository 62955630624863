import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';
import { ConfirmService } from '../../../modules/confirm/confirm.service';
import { Idea } from '../../../models/IdeaCluster';

@Component({
  selector: 'app-userideaedit',
  templateUrl: './userideaedit.component.html',
  styleUrls: ['./userideaedit.component.scss']
})
export class UserideaeditComponent extends BaseComponent implements OnInit {
  IdeaId = 0;
  Idea: any = {}; // record in P.S DB Table Idea
  IdeaType: any = {};
  Url = "";

  constructor(injector: Injector, private confirmService: ConfirmService) { super(injector); }

  async ngOnInit() {
    this.loader.show();

    if (this.global.IdeaTypes == null) {
      await this.global.getLookups();
    }

    this.IdeaId = this.getIntQueryParamOrDefault('Id', 0);
    await this.getData();
    this.loader.hide();
  }

  async getData() {
    if (this.IdeaId > 0) {
      const ret: any = await this.db.get('idea', 'getidea', { Id: this.IdeaId });
      this.Idea = ret.Idea;
      this.Url = ret.PicUrl == null ? "" : ret.PicUrl;

    } else {
      this.createNewIdea(1);
    }
  }

  createNewIdea(IdeaTypeId: number) {
    this.Idea = new Idea();
    this.Idea.Id = 0;
    this.Idea.TypeId = IdeaTypeId; // type idea
    this.Idea.Name = '';
    this.Idea.Description = '';
    this.Idea.TypeId = IdeaTypeId; // type idea
    this.Idea.IsPublic = true;

    this.IdeaType = this.global.IdeaTypes.find(x => x.Id === IdeaTypeId);
    // P.S. reference tables

    // P.S. reference tables for child components

    //this.global.IdeaType.Id = IdeaTypeId;
    this.global.IdeaId = 0;

    if (IdeaTypeId === 1) { this.global.IdeaType.Name = 'Idea'; }
    if (IdeaTypeId === 2) { this.global.IdeaType.Name = 'Concept'; }
    if (IdeaTypeId === 3) { this.global.IdeaType.Name = 'Project'; }
  }

  async save() {
    this.loader.show();
    const IdeaTemp = await this.db.post('idea', 'submitidea', this.Idea);

    await this.getData();
    this.loader.hide();
  }
}
