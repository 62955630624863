import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-dynform',
  templateUrl: './dynform.component.html',
  styleUrls: ['./dynform.component.scss']
})
export class DynformComponent implements OnInit, OnChanges {
  @Input() data;
  @Input() title;
  @Input() fieldDefinition; //  { column: 'name', type: 'string', displayName: 'Name' }
  // type 'id' is special and is used as the Id of the record as integer !!!!!

  @Output() post = new EventEmitter();
  @Output() delete = new EventEmitter();

  columns: any = [];
  types: any = [];
  displayName: any = [];
  idField = "";

  selectedData: any = {};
  

  @ViewChild('editrecord') editrecord;
  @ViewChild('confirm') confirm;

  constructor() { }

  ngOnInit() {
    this.fieldDefinition.forEach(fd => { //just for convenience in the html
      this.columns.push(fd.column);
      this.types.push(fd.type);
      this.displayName.push(fd.displayName);
      if (fd.type == 'id') this.idField = fd.column;
    });
  }

  ngOnChanges(changes: any) {
    this.data = changes.data.currentValue;
  }
  select(d) {
    this.selectedData = d;
    $('#editrecord').show();
  }
  closeModal() {
    $('#editrecord').hide();
  }
  async postRecord() {
    $('#editrecord').hide();
    this.post.emit(this.selectedData)
  }
  async deleteRecord(record) {
    this.selectedData = record;
    $('#confirm').show();
  }

  async confirmDelete(result) {
    if (result == 'yes') {
      this.delete.emit(this.selectedData)
    }
    $('#confirm').hide();
  }

  add() {
    this.selectedData = {};
    this.selectedData[this.idField] = 0;
    $('#editrecord').show();
  }

}
