import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-testangchild',
  templateUrl: './testangchild.component.html',
  styleUrls: ['./testangchild.component.scss']
})
export class TestangchildComponent implements OnInit {
  @Input() table: any;
  @Input() title: string;
  @Output() rowSelected = new EventEmitter();
  constructor() { }

  ngOnInit() {
    alert("child created");
  }

  ngOnChanges(){
    alert("Child data has changed");
  }

  selectRow(row){
    this.rowSelected.emit(row);
  }


}
