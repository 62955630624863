import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { GraphForcedComponent, Node, Link } from '../graph-forced/graph-forced.component';
import { DbServiceService } from '../../services/db-service.service';
import { GlobalService } from '../../services/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
var ref: any; //put this into a global variable for callback functions

@Component({
  selector: 'app-rels',
  templateUrl: './rels.component.html',
  styleUrls: ['./rels.component.scss']
})
export class RelsComponent implements OnInit {
  @Input() Id;
  @Output() GotoId = new EventEmitter();

  @ViewChild(GraphForcedComponent) fg: GraphForcedComponent;

  Relationships: any = [];
  Records: any = [];
  dbNodes: any = [];
  dbLinks: any = [];
  recId = 0;
  recName = "";
  recDescription = "";
  recUrl = "";
  recType = "";
  ideas: any = [];
  ideasDisplay: any = [];
  types: any = [];
  searchString = "";
  typeid = 0;
  selectedRels: any = [];

  constructor(private db: DbServiceService, private global: GlobalService, private router: Router, private modal: NgbModal,) {
  }

  async ngOnInit() {
    ref = this; //put this into a global variable for callback functions
    ref.global = this.global;
    ref.router = this.router;
    ref.modal = this.modal;
    if (this.Id > 0) {
      await this.createGraph();
      const resp = await this.db.get("Idea", "getideasshort");
      this.ideas = resp.Ideas;
      this.ideasDisplay = resp.Ideas;
      await this.forcedgraphCallback({ refId: this.Id });
    }
  }

  navigate() {
    this.router.navigate(['/dashboard/ideaedit/'], { queryParams: { 'IdeaId': ref.global.IdeaId } });
  }
  async createGraph() {


    let response = await this.db.get("idea", "getrelationships", { Id: this.Id });
    this.Relationships = response.links;
    this.Records = response.nodes;

    let rels = response.links;
    let ideas = response.nodes;
    let nodes: Node[] = [];
    let links: Link[] = [];
    ideas.forEach((idea, i) => {
      let color = "red";
      let radius = 20;
      if (idea['type'] == "Project") { color = "lightblue"; }
      if (idea['type'] == "Idea") { color = "lightyellow"; }
      if (idea['type'] == "Concept") { color = "PeachPuff"; }
      if (idea['id'] == this.Id) { color = "lightgreen"; radius = 40; }

      let node = new Node(i, idea['id'], idea['name'], radius, color, idea['description'], "", idea['stepstretchseap'],idea['type']);
      nodes.push(node);
    });
    rels.forEach((rel, i) => {
      let n1 = nodes.find(x => x.refId == rel['IdeaSourceId']);
      let n2 = nodes.find(x => x.refId == rel['IdeaTargetId']);
      let link = new Link(n1.id, n2.id, n1.refId, n2.refId, n1.name, n2.name, rel['description'],"");
      links.push(link);
    });

    this.dbNodes = nodes;
    this.dbLinks = links;
    this.fg.createGraph(links, nodes, this.forcedgraphCallback);
  }
  reinit() {
    this.ngOnInit();
  }

  async forcedgraphCallback($event) {
    let refId = $event.refId;
    let record = ref.Records.find(x => x.id == refId);
    //alert(record['name'] + "\n" + record['description'] + "\n" + record['type']);
    //ref.global.IdeaId = record['id'];
    if (record != null) {
      ref.recId = record['id'];
      ref.recName = record['name'];
      ref.recDescription = record['description'];
       
      const d = await ref.db.get("Idea", "getDefaultImage", { IdeaId: ref.recId });
      ref.recUrl = d.Url;
      ref.recType = record['type']
    } else {
      const idea = await ref.db.get("Idea", "getideashort", { Id: refId });
      ref.recId = idea.id;
      ref.recName = idea.name;
      ref.recDescription = idea.description;
      ref.recUrl = idea.url;
      ref.recType = idea.type;
    }
    //ref.navigate();
  }
  async addRelationship() {
    const resp = await this.db.get("Idea", "getideasshort");
    ref.ideas = resp.Ideas;
    ref.ideasDisplay = resp.Ideas;
    ref.types = resp.Types;

    $('#selectIdea').show();
  }
  closeSelectIdea() {
    $('#selectIdea').hide();
  }
  filter() {
    this.ideasDisplay = [];
    const ss = ref.searchString.toLowerCase();
    if (ref.searchString.length > 1) {
      this.ideas.forEach(x => {
        if (x.Name.toLowerCase().includes(ss)) ref.ideasDisplay.push(x);
      });
    } else {
      this.ideas.forEach(x => {
        ref.ideasDisplay.push(x);
      });
    }
    if (ref.typeid != 0) {
      this.ideas.forEach(x => {
        if (x.TypeId == ref.typeid) ref.ideasDisplay.push(x);
      });
    }
  }
  clear() {
    ref.searchString = "";
    ref.typeid = 0;
    ref.ideasDisplay = ref.ideas;
  }
  async select(item) {
    ref.recId = this.Id;
    const resp = await this.db.get("idea", "addSingleRelationship", { sourceid: ref.recId, targetid: item.Id });
    $('#selectIdea').hide();
    ref.createGraph();
  }
  async removeRelationship() {
    // get all relationship for the selected idea
    // alert(ref.recId)
    ref.selectedRels = [];
    ref.dbLinks.forEach(l => {
      if (l.sourceRefId == ref.recId) {
        l.displayName = l.targetName,
        ref.selectedRels.push(l);
      }
      if (l.targetRefId == ref.recId) {
        l.displayName = l.sourceName,
        ref.selectedRels.push(l);
      }
    });
    $('#selectRelationships').show();
  }
  async selectForRemoval(item) {
    const resp = await this.db.delete("idea", "deleteSingleRelationship", { sourceid: item.sourceRefId, targetid: item.targetRefId });
    $('#selectRelationships').hide();
    ref.createGraph();
  }
  closeSelectRemovalIdea() {
    $('#selectRelationships').hide();
  }
  switchIdea() {
    this.global.IdeaId = ref.recId;
    this.GotoId.emit({ id: ref.recId, name: ref.recName });
    this.createGraph();
  }
}
