import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-materialshow',
  templateUrl: './materialshow.component.html',
  styleUrls: ['./materialshow.component.scss']
})
export class MaterialshowComponent implements OnInit {
  materials: any[];
  material: any;
  materialgroupid = 0;
  materialgroups: any = [];
  image: any;

  constructor(private db: DbServiceService,
    private alert: snaAlertService,
    private loader: LoaderService) { }

 async ngOnInit() {
   this.loader.show();
   const resp = await this.db.get("lookup", "getMaterials");

   this.materials = resp.materials;
   this.material = this.materials[0];
   this.materialgroups = resp.materialgroups;
   this.selectMaterial(this.materials[0]);
    this.loader.hide();
  }
  async selectMaterial(material) {
    this.image = null;
    if (material.images.length > 0) {
      this.image = await this.db.get("document", "getImage", { Id: material.images[0].id })
    }
    this.material = material;
  }
}
