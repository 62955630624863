import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent extends BaseComponent implements OnInit {
  certifications: any = [];
  certification: any = {};
  companies: any = [];

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.add();
    this.loader.hide();
  }
  async getData() {
    this.add(); //initial material
    const resp = await this.db.get("certification", "getAllCertifications");
    this.certifications = resp.certifications;
    this.certifications.forEach(c => {
      let url = c.website;
      if (url && url.length > 4 && url.substring(0, 4).toLowerCase() != "http") {
        c.url = "http://" + c.website;
      } else {
        c.url = c.website;
      }
    });
    this.companies = resp.companies;
  }

  async selectCertification(mat) {
    this.certification = mat;
  }

  async saveCertification() {
    this.loader.show();
    await this.db.post("certification", "postcertification", this.certification);
    await this.getData();
    this.loader.hide();
  }
  closemodal() {
    $('#changeTag').hide();
  }


  add() {
    this.certification = {
      id: 0,
      name: "",
      description: "",
      website: "",
    };
  }
}
