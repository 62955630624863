import { LoaderService } from './../../../modules/loader/loader.service';
import { IdeaType } from './../../../models/IdeaCluster';
import { DbServiceService } from '../../../services/db-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { NetworkGraphComponent } from '../../shared/network-graph/network-graph.component';
import { Router } from '@angular/router';
import * as util from '../../../modules/util';
import * as $ from 'jquery';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild('graph1') graph: NetworkGraphComponent;

  constructor(private loader: LoaderService,
    private db: DbServiceService,
    public global: GlobalService,
    private router: Router) { }
  ideas: any = [];
  idea: any = {};
  categories: any = [];
  stepStretchLeaps: any = [];
  sustainabilities: any = [];
  ideaTypes = [ {id: 0, name: 'All', isChecked : false}
              , {id: 1, name: 'Idea', isChecked : true}
              , {id: 2, name: 'Concept', isChecked : false}
    , { id: 3, name: 'Project', isChecked: false }
    , { id: 4, name: 'Product', isChecked: false }
    , { id: 5, name: 'Technology', isChecked: false }
    , { id: 6, name: 'Service', isChecked: false }
    , { id: 7, name: 'B2B Product', isChecked: false }
  ];

  ideasUnfiltered: any = [];
  ssl = [{Id: 1, Name: 'Step'}, {Id: 2, Name: 'Stretch'}, {Id: 3, Name: 'Leap'} ];

  searchObj = {
    categoryId: 0,
    horizonId: 0,
    sustainabilityId: 0,
    searchString: '',
    typeId: 1
  }

  showFavorites = false;

  async ngOnInit() {
    // P.S. the searchObj is ignored on the c# controller side for now
    this.loader.show();
    await this.global.getLookups();
    this.ideasUnfiltered = await this.db.post('idea', 'search', this.searchObj);
    this.ideas = this.ideasUnfiltered;
    this.groupBy();
    this.loader.hide();
  }

  async search() {
    let temp = this.ideasUnfiltered;
    if (this.searchObj.categoryId > 0 ) { temp = this.filterByCategory(temp); }
    if (this.searchObj.typeId > 0 ) { temp = this.filterByIdeaType(temp); }


    // P.S. search by searchstring
    if (this.searchObj.searchString.length > 0) {
      let t2 = [];
      const ss = this.searchObj.searchString.toLowerCase();
      temp.forEach(item =>{
        item.description = item.description === null ? '' : item.description;
        if (item.name.toLowerCase().indexOf(ss) > -1 || item.description.toLowerCase().indexOf(ss) > -1) {
          t2.push(item);
        }
      });
      temp = t2;
    }

    this.ideas = temp;
  }
  toggleFavorites() {
    if (this.showFavorites) {
      this.ideas = this.ideasUnfiltered;
    } else {
      let temp = [];
      this.ideas.forEach(idea => {
        if (idea.isFavorite) temp.push(idea);
      })
      this.ideas = temp;
    }
    this.showFavorites = !this.showFavorites;
  }
  filterByCategory(records) {
    const ret = [];
    records.forEach(item => {
      let bFound = false;
      item.categories.forEach(c => {
        if (c.id == this.searchObj.categoryId) {
          bFound = true;
        }
      });
      if (bFound) { ret.push(item);}
    });
    return ret;
  }

  filterByIdeaType(records){
    const ret = [];
    records.forEach(item => {
      if (item.typeId == this.searchObj.typeId)
        { ret.push(item); }
    });
    return ret;
  }

  hasCategory(x: any){
    const t = x.categories.filter(x => x.id == this.searchObj.categoryId);
    return t.length > 0 ? true : false;
  }

  clear() {
    this.searchObj.categoryId = 0;
    this.searchObj.horizonId = 0;
    this.searchObj.sustainabilityId = 0;
    this.searchObj.searchString = '';
    this.ideas = this.ideasUnfiltered;
  }
  navigate(item) {
    this.global.IdeaId = item.id;
    if (item.typeId == 3) {
      this.router.navigate(['/dashboard/projectedit/'], { queryParams: { 'IdeaId': item.id } });
    } else {
      this.router.navigate(['/dashboard/ideaedit/'], { queryParams: { 'IdeaId': item.id } });
    }
  }
  circEconomy(item) {
    this.global.IdeaId = item.id;
    this.router.navigate(['/dashboard/circeconomy'], {queryParams: { 'IdeaId' : item.id }});
  }
  selectRow($event) {
    alert($event);
  }
  selected($event, source) {

  }
  getBackgroundColor(idea) {
    let ret = '#c6eac6';
    if (idea.typeId !== undefined) {
      if (idea.typeId === 2) { ret = '#a4a4f5'; }
      if (idea.typeId === 3) { ret = '#efefb0'; }
      if (idea.typeId === 4) { ret = 'rgb(207, 158, 207)'; }
    }
    return ret;
  }
  groupBy(){
    this.categories = util.createShallowCopy(this.global.Categories, {'Id': 'id', 'Name': 'name'});
    util.addProperty(this.categories, 'count', 0 );

    this.ideas.forEach(idea => {
      idea.categories.forEach(c => {
        const cat = this.categories.find(x => x.id === c.id);
        if (cat != null) {
          cat.count += 1;
        }
      });
    });

    this.stepStretchLeaps = [{id: 1, name: 'Step', count: 0}, {id: 2, name: 'Strech', count: 0}, {id: 2, name: 'Leap', count: 0} ];
    this.ideas.forEach( x => {
      if (x.stepStretchLeap === 1) {this.stepStretchLeaps[0].count += 1; }
      if (x.stepStretchLeap === 2) {this.stepStretchLeaps[1].count += 1; }
      if (x.stepStretchLeap === 3) {this.stepStretchLeaps[2].count += 1; }
    })

    //this.sustainabilities = util.createShallowCopy(this.global.Sustainabilities, {'Id': 'id', 'Name': 'name'});
    //util.addProperty(this.sustainabilities, 'count', 0 );

    //this.ideas.forEach( idea  => {
    //  const sus = this.sustainabilities.find(x => x.id == idea.sustainabilityId);
    //  sus.count += 1;
    //});
  }
  openYesNoModal(item){
    this.idea = item;
    $('#myYesNoModal').show();
  }
  closeYesNoModal(){
    $('#myYesNoModal').hide();
  }
  async myYesNoModalSubmit(answer){
    if (answer === 'Yes') {
      const ret = await this.db.delete('idea', 'deleteIdea', {id: this.idea.id} );
      this.ideasUnfiltered = await this.db.post('idea', 'search', this.searchObj);
      this.ideas = this.ideasUnfiltered;
      this.groupBy();
    }
    $('#myYesNoModal').hide();
  }
  recordTypeChange($event) {
    this.ideaTypes.forEach(o => {
      if (o.isChecked === true) { this.searchObj.typeId = o.id; }
    })
  }
  gotoCanvas(idea) {
    this.router.navigate(['/dashboard/canvas/'], { queryParams: { 'IdeaId': idea.id } });
  }

  async updateFavorite($event, i) {
    i.isFavorite = $event;
    if ($event) {
      await this.db.get("idea", "setFavorite", { ideaId: i.id });
    } else {
      await this.db.get("idea", "removeFavorite", { ideaId: i.id });
    }
  }
  goto(path) {
    this.router.navigate([path]);
  }
}
