import { Component, OnInit, Injector,Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';
import * as d3 from 'd3';
import * as $ from 'jquery';
var href;

@Component({
  selector: 'app-materialsustain',
  templateUrl: './materialsustain.component.html',
  styleUrls: ['./materialsustain.component.scss']
})
export class MaterialsustainComponent extends BaseComponent implements OnInit {
  size = { width: 200, height: 100 };
  scalex;
  scaley;
  data = [
    { id: 1, name: "pet", x: 25, y: 25, r: 3},
    { id: 2, name: "bio pa", x: 17, y: 80, r: 5 },
    { id: 3, name: "pla", x: 50, y: 50, r: 5 },
    { id: 4, name: "pha", x: 80, y: 90, r: 3 },
    { id: 5, name: "Conventional Plastic", x: 16, y: 17, r: 7 },
    { id: 5, name: "pbat", x: 50, y: 17, r: 4 },
  ];
  constructor(private injector: Injector) { super(injector) }

  ngOnInit() {
    href = this;
  }

  draw() {
    var ct = document.getElementById("svgcontainer");
    this.size.width = ct.offsetWidth;
    this.size.height = this.size.width * 0.45;
    let svg = d3.select("#circ");
    svg.style("width", this.size.width+"px").style("height", this.size.height+"px");

    this.scalex = d3.scaleLinear().domain([0, 100]).range([0, this.size.width]);
    this.scaley = d3.scaleLinear().domain([0, 100]).range([this.size.height, 0]);


    this.appendDashedLine(svg, 5, 33, 95, 33, "lightgreen", 4);
    this.appendDashedLine(svg, 5, 66, 66, 66, "gray", 4);
    this.appendDashedLine(svg, 66, 66, 95, 66, "lightgreen", 4);

    this.appendLine(svg, 33, 5, 33, 95, "white", 8);
    this.appendLine(svg, 66, 5, 66, 95, "lightgreen", 8);



    for (let i = 1; i < 10; i++) {
      let x = Math.random() * 100;
      let y = Math.random() * 100;
      let r = Math.random() * 4+4;
      let circle = svg.append("circle").attr("fill", "none").attr("stroke", "white").attr("stroke-width", "2");
      circle.attr("cx", this.scalex(x)).attr("cy", this.scaley(y)).attr("r", r);
    }

    this.data.forEach(d => {
      let circle = svg.append("circle").attr("fill", "#A69886").attr("stroke", "white").attr("stroke-width", "4");
      circle.attr("cx", this.scalex(d.x)).attr("cy", this.scaley(d.y)).attr("r", this.scalex(d.r));
      svg.append("text").attr("x", this.scalex(d.x)).attr("y", this.scaley(d.y)).text(d.name)
        .attr("fill", "White").attr("font", "bold 30px sans-serif")
        .attr("text-anchor", "middle").attr("dominant-baseline", "middle");
    });

    svg.append("text").attr("x", this.scalex(33)).attr("y", this.scaley(88)).text("Biobased")
      .attr("fill", "lightgreen").attr("font-size","2rem").attr("font-weight","800")
      .attr("text-anchor", "middle").attr("dominant-baseline", "middle");
    svg.append("text").attr("x", this.scalex(33)).attr("y", this.scaley(79)).text("Residues")
      .attr("fill", "lightgreen").attr("font-size", "2rem").attr("font-weight", "800")
      .attr("text-anchor", "middle").attr("dominant-baseline", "middle");
  }

  appendLine(svg, x1, y1, x2, y2, stroke, stroke_width) {
    svg.append("line").attr("x1", this.scalex(x1)).attr("y1", this.scaley(y1))
      .attr("x2", this.scalex(x2)).attr("y2", this.scaley(y2))
      .attr("stroke", stroke).attr("stroke-width", stroke_width);
  }
  appendDashedLine(svg, x1, y1, x2, y2, stroke, stroke_width) {
    svg.append("line").attr("x1", this.scalex(x1)).attr("y1", this.scaley(y1))
      .attr("x2", this.scalex(x2)).attr("y2", this.scaley(y2))
      .attr("stroke", stroke).attr("stroke-width", stroke_width).attr('stroke-dasharray',"16")
  }

}
