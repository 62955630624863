import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-pack-system-show',
  templateUrl: './pack-system-show.component.html',
  styleUrls: ['./pack-system-show.component.scss']
})
export class PackSystemShowComponent implements OnInit {
  systems: any[];
  syst: any;
  image: any;
  constructor(private db: DbServiceService,
    private alert: snaAlertService,
    private loader: LoaderService
  ) { }

 async ngOnInit() {
    this.loader.show();
    this.systems = await this.db.get("packagingtechnology", "getPackagingTechnologies");
    this.syst = this.systems[0];
    if (this.syst.Images.length > 0) {
     this.image = await this.db.get("document", "getImage", { Id: this.syst.Images[0].Id })
   }
    this.loader.hide();
 }
  async selectSystem(system) {
    this.image = null;
    if (system.Images.length > 0) {
      this.image = await this.db.get("document", "getImage", { Id: system.Images[0].Id })
    }
    this.syst = system;
  }
}
