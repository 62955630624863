import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';

@Component({
  selector: 'app-printpics',
  templateUrl: './printpics.component.html',
  styleUrls: ['./printpics.component.scss']
})
export class PrintpicsComponent implements OnInit, OnChanges {
  @Input() Id: number; //record number of idea, concept or project
  @Input() tableName: string;
  @Input() columns = 2;

  Images: any = [];
  ImageGrid: any = [];

  constructor(private db: DbServiceService  ) { }

  async ngOnInit() {
    if (this.tableName == "ProductForm"){
      let a = 5
    }
    if (this.Id != null) {
      this.Images = await this.db.get("document", "getImagesL", { Id: this.Id, TableName: this.tableName });
      this.ImageGrid = this.split(this.Images, this.columns);
    }
  }
  async ngOnChanges() {
    if (this.tableName == "ProductForm"){
      let a = 5
    }
    if (this.Id != null) {
      this.Images = await this.db.get("document", "getImagesL", { Id: this.Id, TableName: this.tableName });
      this.ImageGrid = this.split(this.Images, this.columns);
    }
  }

  split(arr, n) {
    var res = [];
    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  }
}
