import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-managelookups',
  templateUrl: './managelookups.component.html',
  styleUrls: ['./managelookups.component.scss']
})
export class ManagelookupsComponent extends BaseComponent implements OnInit {

  dosageforms: any = [];
  dosageformfielddef: any = [];

  industries: any = [];
  industryfielddef: any = [];

  materialgroups: any = [];
  materialgroupdefs: any = [];

  materialForms: any = [];
  materialForm: any = {};

  impacts: any = [];
  impact: any = {};

  touchAndFeels: any = [];
  touchAndFeel: any = {};

  easeOfOpenings: any = [];
  easeOfOpening: any = {};

  packagingSpaces: any = [];
  packagingSpace: any = {};

  packagePerceptions: any = [];
  packagePerception: any = {};

  stores: any = [];
  store: any = {};

  shelfPlacements: any = [];
  shelfPlacement: any = {};

  businessAreas: any = [];
  businessArea: any = {};

  treatmentFields: any = [];
  treatmentField: any = {};

  flavors: any = [];
  flavor: any = {};

  tastes: any = [];
  taste: any = {};

  colors: any = [];
  color: any = {};

  howFullIsPacks: any = [];
  howFullIsPack: any = {};

  printingEffects: any = [];
  printingEffect: any = {};

  companySizes: any = [];
  companySize: any = {};

  companyCategories: any = [];
  companyCategory: any = {};

  activeIngredients: any = [];
  activeIngredient: any = {};

  extRefTopics: any = [];
  extRefTopic: any = {};

  constructor(private injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();

    this.dosageformfielddef = [
      { column: 'id', type: 'id', displayName: 'Id' },
      { column: 'name', type: 'string', displayName: 'Name' },
    ]
    this.industryfielddef = [
      { column: 'id', type: 'id', displayName: 'Id' },
      { column: 'name', type: 'string', displayName: 'Name' },
    ]
    this.materialgroupdefs = [
      { column: 'id', type: 'id', displayName: 'Id' },
      { column: 'name', type: 'string', displayName: 'Name' },
    ]
    await this.getData();

    this.loader.hide();
  }

  async getData() {
    this.dosageforms = await this.db.get("lookup", "getDosageforms");
    this.industries = await this.db.get("lookup", "getIndustries");
    this.materialgroups = await this.db.get("lookup", "getMaterialGroups");
    this.impacts = await this.db.get("lookup", "getImpacts");
    this.materialForms = await this.db.get("lookup", "getMaterialForms");
    this.touchAndFeels = await this.db.get("lookup", "getTouchAndFeel");
    this.easeOfOpenings = await this.db.get("lookup", "getEaseOfOpening");
    this.packagingSpaces = await this.db.get("lookup", "getPackagingSpace");
    this.packagePerceptions = await this.db.get("lookup", "getpackagePerception");
    this.stores = await this.db.get("lookup", "getStores");
    this.shelfPlacements = await this.db.get("lookup", "getshelfPlacements");
    this.businessAreas = await this.db.get("lookup", "getbusinessAreas");
    this.treatmentFields = await this.db.get("lookup", "gettreatmentFields");
    this.flavors = await this.db.get("lookup", "getflavors");
    this.tastes = await this.db.get("lookup", "gettastes");
    this.colors = await this.db.get("lookup", "getcolors");
    this.howFullIsPacks = await this.db.get("lookup", "gethowFullIsPacks");
    this.printingEffects = await this.db.get("lookup", "getPrintingEffects");
    this.companySizes = await this.db.get("lookup", "getCompanySizes");
    this.companyCategories = await this.db.get("lookup", "getCompanyCategories");
    this.activeIngredients = await this.db.get("lookup", "getActiveIngredients");
    this.extRefTopics = await this.db.get("lookup", "getExtRefTopics");

    let a = 5;
  }

  async postDosageForm(record) {
    this.loader.show();
    await this.db.post("lookup", "postDosageform", record);
    await this.getData();
    this.loader.hide();
  }

  async deleteDosageForm(record) {
    this.loader.show();
    await this.db.delete("lookup", "deleteDosageform", { id: record.id });
    await this.getData();
    this.loader.hide();
  }

  async postIndustry(record) {
    this.loader.show();
    await this.db.post("lookup", "postIndustry", record);
    await this.getData();
    this.loader.hide();
  }

  async deleteIndustry(record) {
    this.loader.show();
    await this.db.delete("lookup", "deleteIndustry", { id: record.id });
    await this.getData();
    this.loader.hide();
  }

  async postMaterialGroup(record) {
    this.loader.show();
    await this.db.post("lookup", "postMaterialGroup", record);
    await this.getData();
    this.loader.hide();
  }

  async deleteMaterialGroup(record) {
    this.loader.show();
    await this.db.delete("lookup", "deleteMaterialGroup", { id: record.id });
    await this.getData();
    this.loader.hide();
  }

  editImpact(im) {
    if (im == null) {
      this.impact = { Id: 0, Name: "", SeqNo: 0, Value: 0 }
    } else {
      this.impact = im;
    }
    $('#impactTag').show();
  }



  async saveImpact() {
    this.loader.show();
    await this.db.post("lookup", "postImpact", this.impact);
    await this.getData();
    this.loader.hide(); $('#impactTag').hide();
  }
  closeImpact() {
    $('#impactTag').hide();
  }
  editMaterialForm(mf) {
    if (mf == null) {
      this.materialForm = { id: 0, name: "", description: "", seqNo: 0 }
    } else {
      this.materialForm = mf;
    }
    $('#materialFormTag').show();
  }
  async saveMaterialForm() {
    this.loader.show();
    await this.db.post("lookup", "postMaterialForm", this.materialForm);
    await this.getData();
    this.loader.hide(); $('#materialFormTag').hide();
  }
  closeMaterialForm() {
    $('#materialFormTag').hide();
  }

  editTouchAndFeel(tf) {
    if (tf == null) {
      this.touchAndFeel = { id: 0, name: "", description: "", seqNo: 0 }
    } else {
      this.touchAndFeel = tf;
    }
    $('#touchAndFeelTag').show();
  }

  async saveTouchAndFeel() {
    this.loader.show();
    await this.db.post("lookup", "postTouchAndFeel", this.touchAndFeel);
    await this.getData();
    this.loader.hide(); $('#touchAndFeelTag').hide();
  }
  closeTouchAndFeel() {
    $('#touchAndFeelTag').hide();
  }

  editEaseOfOpening(tf) {
    if (tf == null) {
      this.easeOfOpening = { id: 0, name: "", seqNo: 0 }
    } else {
      this.touchAndFeel = tf;
    }
    $('#easeOfOpeningTag').show();
  }

  async saveEaseOfOpening() {
    this.loader.show();
    await this.db.post("lookup", "postEaseOfOpening", this.easeOfOpening);
    await this.getData();
    this.loader.hide(); $('#easeOfOpeningTag').hide();
  }

  closeEaseOfOpening() {
    $('#easeOfOpeningTag').hide();
  }

  editPackagingSpace(tf) {
    if (tf == null) {
      this.packagingSpace = { id: 0, name: "", seqNo: 0 }
    } else {
      this.packagingSpace = tf;
    }
    $('#packagingSpaceTag').show();
  }

  async savePackagingSpace() {
    this.loader.show();
    await this.db.post("lookup", "postPackagingSpace", this.packagingSpace);
    await this.getData();
    this.loader.hide();
    $('#packagingSpaceTag').hide();
  }

  closePackagingSpace() {
    $('#packagingSpaceTag').hide();
  }

  closePackagePerception() {
    $('#storeTag').hide();
  }

  editPackagePerception(tf) {
    if (tf == null) {
      this.packagePerception = { id: 0, name: "", seqNo: 0 }
    } else {
      this.packagePerception = tf;
    }
    $('#packagePerceptionTag').show();
  }

  async savePackagePerception() {
    this.loader.show();
    await this.db.post("lookup", "postPackagePerception", this.packagePerception);
    await this.getData();
    this.loader.hide();
    $('#packagePerceptionTag').hide();
  }

  closeStore() {
    $('#storeTag').hide();
  }

  editStore(tf) {
    if (tf == null) {
      this.store = { id: 0, name: "", seqNo: 0 }
    } else {
      this.store = tf;
    }
    $('#storeTag').show();
  }

  async saveStore() {
    this.loader.show();
    await this.db.post("lookup", "postStore", this.store);
    await this.getData();
    this.loader.hide();
    $('#storeTag').hide();
  }

  closeShelfPlacement() {
    $('#shelfPlacementTag').hide();
  }

  editShelfPlacement(tf) {
    if (tf == null) {
      this.shelfPlacement = { id: 0, name: "", seqNo: 0 }
    } else {
      this.shelfPlacement = tf;
    }
    $('#shelfPlacementTag').show();
  }

  async saveShelfPlacement() {
    this.loader.show();
    await this.db.post("lookup", "postshelfPlacement", this.shelfPlacement);
    await this.getData();
    this.loader.hide();
    $('#shelfPlacementTag').hide();
  }

  closeBusinessArea() {
    $('#businessAreatTag').hide();
  }

  editBusinessArea(tf) {
    if (tf == null) {
      this.businessArea = { id: 0, name: "", seqNo: 0 }
    } else {
      this.businessArea = tf;
    }
    $('#businessAreatTag').show();
  }

  async saveBusinessArea() {
    this.loader.show();
    await this.db.post("lookup", "postbusinessArea", this.businessArea);
    await this.getData();
    this.loader.hide();
    $('#businessAreatTag').hide();
  }

  closeTreatmentField() {
    $('#treatmentFieldTag').hide();
  }

  editTreatmentField(tf) {
    if (tf == null) {
      this.treatmentField = { id: 0, name: "", seqNo: 0 }
    } else {
      this.treatmentField = tf;
    }
    $('#treatmentFieldTag').show();
  }

  async saveTreatmentField() {
    this.loader.show();
    await this.db.post("lookup", "posttreatmentField", this.treatmentField);
    await this.getData();
    this.loader.hide();
    $('#treatmentFieldTag').hide();
  }

  closeFlavor() {
    $('#flavorTag').hide();
  }

  editFlavor(tf) {
    if (tf == null) {
      this.flavor = { id: 0, name: "", seqNo: 0 }
    } else {
      this.flavor = tf;
    }
    $('#flavorTag').show();
  }

  async saveFlavor() {
    this.loader.show();
    await this.db.post("lookup", "postflavor", this.flavor);
    await this.getData();
    this.loader.hide();
    $('#flavorTag').hide();
  }

  closeTaste() {
    $('#tasteTag').hide();
  }

  editTaste(tf) {
    if (tf == null) {
      this.taste = { id: 0, name: "", seqNo: 0 }
    } else {
      this.taste = tf;
    }
    $('#tasteTag').show();
  }

  async saveTaste() {
    this.loader.show();
    await this.db.post("lookup", "posttaste", this.taste);
    await this.getData();
    this.loader.hide();
    $('#tasteTag').hide();
  }

  closeColor() {
    $('#colorTag').hide();
  }

  editColor(tf) {
    if (tf == null) {
      this.color = { id: 0, name: "", seqNo: 0 }
    } else {
      this.color = tf;
    }
    $('#colorTag').show();
  }

  async saveColor() {
    this.loader.show();
    await this.db.post("lookup", "postcolor", this.color);
    await this.getData();
    this.loader.hide();
    $('#colorTag').hide();
  }

  closeHowFullIsPack() {
    $('#howFullIsPackTag').hide();
  }

  editHowFullIsPack(tf) {
    if (tf == null) {
      this.howFullIsPack = { id: 0, name: "", seqNo: 0 }
    } else {
      this.howFullIsPack = tf;
    }
    $('#howFullIsPackTag').show();
  }

  async saveHowFullIsPack() {
    this.loader.show();
    await this.db.post("lookup", "posthowFullIsPack", this.howFullIsPack);
    await this.getData();
    this.loader.hide();
    $('#howFullIsPackTag').hide();
  }

  closePrintingEffect() {
    $('#printingEffectTag').hide();
  }

  editPrintingEffect(tf) {
    if (tf == null) {
      this.printingEffect = { id: 0, name: "", seqNo: 0 }
    } else {
      this.printingEffect = tf;
    }
    $('#printingEffectTag').show();
  }

  async savePrintingEffect() {
    this.loader.show();
    await this.db.post("lookup", "postPrintingEffect", this.printingEffect);
    await this.getData();
    this.loader.hide();
    $('#printingEffectTag').hide();
  }

  editCompanySize(cs) {
    if (cs == null) {
      this.companySize = { id: 0, name: "", seqNo: 0 }
    } else {
      this.companySize = cs;
    }
    $('#companySizeTag').show();
  }
  async saveCompanySize() {
    this.loader.show();
    await this.db.post("lookup", "postCompanySize", this.companySize);
    await this.getData();
    this.loader.hide();
    $('#companySizeTag').hide();
  }

  closeCompanySize() {
    $('#companySizeTag').hide();
  }
  editCompanyCateogry(cs) {
    if (cs == null) {
      this.companyCategory = { id: 0, name: "", seqNo: 0 }
    } else {
      this.companyCategory = cs;
    }
    $('#companyCategoryTag').show();
  }
  async saveCompanyCategory() {
    this.loader.show();
    await this.db.post("lookup", "postCompanyCategory", this.companyCategory);
    await this.getData();
    this.loader.hide();
    $('#companyCategoryTag').hide();
  }
  closeCompanyCategory() {
    $('#companyCategoryTag').hide();
  }

  editActiveIngredient(cs) {
    if (cs == null) {
      this.activeIngredient = { id: 0, name: "", description: "" }
    } else {
      this.activeIngredient = cs;
    }
    $('#activeIngredientTag').show();
  }
  async saveActiveIngredient() {
    this.loader.show();
    await this.db.post("lookup", "postActiveIngredient", this.activeIngredient);
    await this.getData();
    this.loader.hide();
    $('#activeIngredientTag').hide();
  }
  closeActiveIngredient() {
    $('#activeIngredientTag').hide();
  }

  editExtRefTopic(cs) {
    if (cs == null) {
      this.extRefTopic = { id: 0, name: ""}
    } else {
      this.extRefTopic = cs;
    }
    $('#extRefTopicTag').show();
  }
  async saveExtRefTopic() {
    this.loader.show();
    await this.db.post("lookup", "postExtRefTopics", this.extRefTopic);
    await this.getData();
    this.loader.hide();
    $('#extRefTopicTag').hide();
  }
  closeExtRefTopic() {
    $('#extRefTopicTag').hide();
  }

}
