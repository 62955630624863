import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';

@Component({
  selector: 'app-material-adjective',
  templateUrl: './material-adjective.component.html',
  styleUrls: ['./material-adjective.component.scss']
})
export class MaterialAdjectiveComponent implements OnInit {
  materialadjectives: any;
  constructor(private db: DbServiceService) { }

  async ngOnInit() {
    this.materialadjectives = await this.db.get("Idea", "getmaterialadjectives");
  }

}
