import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/basecomponent';

@Component({
  selector: 'app-susgoal',
  templateUrl: './susgoal.component.html',
  styleUrls: ['./susgoal.component.scss']
})
export class SusgoalComponent extends BaseComponent implements OnInit {

  constructor(private injector: Injector) { super(injector) }
  @Input() Idea: any = {};
  susgoals: any = [];
  susgoalsmet = 0;

  async ngOnInit() {
    this.susgoals = await this.db.get("Idea", "getsusgoals");
    this.susgoals.forEach(s => {
      if (s.id == 1) s.isSet = this.Idea.hasGoalHunger;
      if (s.id == 2) s.isSet = this.Idea.hasGoalWater;
      if (s.id == 3) s.isSet = this.Idea.hasGoalEnergy;
      if (s.id == 4) s.isSet = this.Idea.hasGoalWork;
      if (s.id == 5) s.isSet = this.Idea.hasGoalIndustry;
      if (s.id == 6) s.isSet = this.Idea.hasGoalCities;
      if (s.id == 7) s.isSet = this.Idea.hasGoalConsumption;
      if (s.id == 8) s.isSet = this.Idea.hasGoalClimate;
      if (s.id == 9) s.isSet = this.Idea.hasGoalLifeWater;
      if (s.id == 10) s.isSet = this.Idea.hasGoalLifeLand;
      if (s.id == 11) s.isSet = this.Idea.hasGoalPartnership;
    });

    this.susgoalsmet = this.susgoals.filter(x => x.isSet == 1).length;
  }

  async setGoal(goal) {
    //if (goal.isSet == null) goal.isSet = 1;
    goal.isSet = goal.isSet == 1 ? 0 : 1;

    if (goal.id == 1) this.Idea.hasGoalHunger = goal.isSet;
    if (goal.id == 2) this.Idea.hasGoalWater = goal.isSet;
    if (goal.id == 3) this.Idea.hasGoalEnergy = goal.isSet;
    if (goal.id == 4) this.Idea.hasGoalWork = goal.isSet;
    if (goal.id == 5) this.Idea.hasGoalIndustry = goal.isSet;
    if (goal.id == 6) this.Idea.hasGoalCities = goal.isSet;
    if (goal.id == 7) this.Idea.hasGoalConsumption = goal.isSet;
    if (goal.id == 8) this.Idea.hasGoalClimate = goal.isSet;
    if (goal.id == 9) this.Idea.hasGoalLifeWater = goal.isSet;
    if (goal.id == 10) this.Idea.hasGoalLifeLand = goal.isSet;
    if (goal.id == 11) this.Idea.hasGoalPartnership = goal.isSet;
    this.susgoalsmet = this.susgoals.filter(x => x.isSet == 1).length;
  }

}
