import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-materialgroupshow',
  templateUrl: './materialgroupshow.component.html',
  styleUrls: ['./materialgroupshow.component.scss']
})
export class MaterialgroupshowComponent implements OnInit {
  materialgroups: any[];
  materialgroup: any;
  image: any;
  constructor(private db: DbServiceService,
    private alert: snaAlertService,
    private loader: LoaderService) { }

  async ngOnInit() {
    this.loader.show();
    const resp = await this.db.get("lookup", "getMaterialgroups");

    this.materialgroups = resp;
    this.materialgroup = this.materialgroups[0];

    this.selectMaterialGroup(this.materialgroups[0]);
    this.loader.hide();
  }
  async selectMaterialGroup(materialgroup) {
    this.image = null;
    if (materialgroup.images.length > 0) {
      this.image = await this.db.get("document", "getImage", { Id: materialgroup.images[0].id })
    }
    this.materialgroup = materialgroup;
  }

}
