import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-reearch',
  templateUrl: './reearch.component.html',
  styleUrls: ['./reearch.component.scss']
})
export class ReearchComponent extends BaseComponent implements OnInit {
  research: any = [];
  researchrec: any = {};

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.add();
    this.loader.hide();
  }

  async getData() {

    const resp = await this.db.get("research", "getallresearch");
    this.research = resp.research;
    let a = 5;
  }
  async selectResearch(rec) {
    this.researchrec = rec;
  }
  add() {
    this.researchrec = {
      id: 0,
      name: "",
      description: "",
    };
  }
  async saveResearch() {
    this.loader.show();
    await this.db.post("research", "postresearch", this.researchrec);
    await this.getData();
    this.loader.hide();
  }
}
