import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';
import * as d3 from 'd3';

var href;
const colorsDark = ['#2196F3', '#FF9800', '#4CAF50', '#673AB7', '#FFEB3B', '#607D8B'];
const colorsLight = ['#BBDEFB', '#FFE0B2', '#C8E6C9', '#D1C4E9', '#FFF9C4', '#CFD8DC'];

@Component({
  selector: 'app-circecondash',
  templateUrl: './circecondash.component.html',
  styleUrls: ['./circecondash.component.scss']
})
export class CircecondashComponent extends BaseComponent implements OnInit {
  ideaid = 0;
  idea: any = {};
  PicUrl = "";
  arrSurveyIds = [18, 1, 29, 13, 30, 27];
  surveys: any = [];
  svTempl: any = [];
  score = { weightedScore: 0, overallWeight: 0, finalScore: 0 };
  goodResp = true;
  respMessage = "";
  @ViewChild('circcontainer') circcontainer: ElementRef;

  EconomyCycleStages: any = [];
  ecsNames = ["Material + Develoment", "Production", "Transportation", "Market Entry + Sales", "Consumer Usage", "Recycling + Sustainability"];
  EconomyCycleStage = {
    Id: 0,
    Name: "",
    RelName: "",
    RelDescription: "",
    RelStatusQuo: "",
    RelImprovement: "",
    RelScale: 0,
    result: {}
  }

  sizing = {
    size: 800,
    radius: 600,
    center: 400,
    innerRadius: 300,
    width: 300, //radius - innerRadius
  }

  constructor(private injector: Injector) { super(injector)}

  async ngOnInit() {
    href = this;
    let sz = this.sizing; //just to write less
    sz.size = this.circcontainer.nativeElement.offsetWidth - 30;
    sz.size = sz.size * 1.0;
    sz.center = sz.size / 2;
    sz.radius = sz.size * 0.4
    sz.innerRadius = sz.radius * 0.5;
    sz.width = sz.radius - sz.innerRadius;

    this.loader.show();
    this.ideaid = this.getIntQueryParamOrDefault("IdeaId", 0);
    await this.getData();

    //this.EconomyCycleStages = JSON.parse(JSON.stringify(this.global.EconomyCycleStages));


    this.loader.hide();

  }
  async getData() {
    const sQuery = { IdeaId: this.ideaid, Tid1: this.arrSurveyIds[0], Tid2: this.arrSurveyIds[1], Tid3: this.arrSurveyIds[2], Tid4: this.arrSurveyIds[3], Tid5: this.arrSurveyIds[4], Tid6: this.arrSurveyIds[5] };
    const resp = await this.db.get("survey", "getSurveyCicrEconForIdea", sQuery);
    this.surveys = resp.surveys;
    this.goodResp = true;
    const ret: any = await this.db.get('idea', 'getidea', { Id: this.ideaid });
    this.idea = ret.Idea;
    this.PicUrl = ret.PicUrl;

    this.surveys.forEach((s,i) => {
      if (s.length == 0) {
        this.goodResp = false;
        this.respMessage += "No response for survey " + i + "<br>";
      }
    });
    if (this.goodResp) {
      this.addElementsToSvData();
      this.EconomyCycleStages = [];
      for (var i = 0; i < 6; i++) { this.EconomyCycleStages.push({ RelScale: this.surveys[i].score, Name: this.ecsNames[i] }) };

      this.drawCircEcon();
    }
  }

  addElementsToSvData() {
    // parses the json sting  in DataJson into survey
    // extracts elements into element array
    // 6 Survey Lists for each category of circ econ
    this.score = { weightedScore: 0, overallWeight: 0, finalScore: 0 };

    this.surveys.forEach(svs => { // List of surveys for this econ category
      let scoreAverage = 0;
      let scoreCount = 0;
      svs.forEach(d => {
        d.weightedSum = 0;
        d.totalWeight = 0
        d.Survey.elements.forEach(el => { //list each element and extract ratings
          if (el.type == "rating") {
            let weight = el.weight;
            let value = el.result;
            d.weightedSum += weight * value;
            d.totalWeight += weight * 1;
          }
        });
        d.score = d.weightedSum / d.totalWeight;
        scoreAverage += d.score;
        scoreCount += 1;
      });
      svs.score = scoreAverage / scoreCount;
      this.score.weightedScore += svs.score * svs[0].TemplateWeight;
      this.score.overallWeight += svs[0].TemplateWeight;
    });
    this.score.finalScore = this.score.weightedScore / this.score.overallWeight;

  }

  drawCircEcon() { //P.S. for later
    let vis = d3.select("#circ");
    vis.attr("width", href.sizing.size).attr("height", href.sizing.size); // Added height and width so arc is visible

    //For Now

    for (var i = 0; i < href.EconomyCycleStages.length; i++) {
      let angleStart = i * Math.PI / 3;
      let angleEnd = angleStart + Math.PI / 3;
      var arc = d3.arc()
        .innerRadius(href.sizing.innerRadius)
        .outerRadius(href.sizing.radius)
        .startAngle(angleStart) //converting from degs to radians
        .endAngle(angleEnd) //just radians

      let color = href.EconomyCycleStages[i].RelScale > 3 ? colorsDark[i] : colorsLight[i];
      vis.append("path")
        .attr("id", "1")
        .attr("class", "arc")
        .attr("d", arc)
        .attr("fill", color)
        .attr("transform", "translate(" + href.sizing.center + "," + href.sizing.center + ")");

    };
    for (var i = 0; i < href.EconomyCycleStages.length; i++) {
      let angleStart = i * Math.PI / 3;
      let angleEnd = angleStart + Math.PI / 3;
      var arc = d3.arc()
        .innerRadius(href.sizing.innerRadius)
        .outerRadius(href.sizing.radius)
        .startAngle(angleStart) //converting from degs to radians
        .endAngle(angleEnd) //just radians

      let color = href.EconomyCycleStages[i].RelScale > 3 ? colorsDark[i] : colorsLight[i];
      vis.append("circle")
        .attr("cx", (href.sizing.radius - href.sizing.width / 2) * Math.sin(angleStart))
        .attr("cy", - (href.sizing.radius - href.sizing.width / 2) * Math.cos(angleStart))
        .attr("r", href.sizing.width / 2)
        .attr("fill", color)
        .attr("transform", "translate(" + href.sizing.center + "," + href.sizing.center + ")");

      vis.append("text")
        .text(href.EconomyCycleStages[i].Name)
        .attr("x", (href.sizing.radius - href.sizing.width / 2) * Math.sin(angleStart))
        .attr("y", - (href.sizing.radius - href.sizing.width / 2) * Math.cos(angleStart))
        .attr("transform", "translate(" + href.sizing.center + "," + href.sizing.center + ")");
    };
  }

  filterRating(item) {
    return item.type == "rating";
  }

}
