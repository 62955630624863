import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-surveyoverview',
  templateUrl: './surveyoverview.component.html',
  styleUrls: ['./surveyoverview.component.scss']
})
export class SurveyoverviewComponent extends BaseComponent implements OnInit {
  sdideas: any = [];
  sdtempls: any = [];
  sdusers: any = [];

  showWhat = 1;
  constructor(injector: Injector) { super(injector); }

  async ngOnInit() {
    const resp = await this.db.get("survey", "getSurveyOverview");
    this.sdideas = resp.sdideas
    this.sdtempls = resp.sdtempls
    this.sdusers = resp.sdusers

    let a = 5;
  }
  showBy(sWhat) {
    if (sWhat == 'idea') this.showWhat = 1;
    if (sWhat == 'template') this.showWhat = 2;
    if (sWhat == 'user') this.showWhat = 3;
  }
  showDetail(sGroup, id) {
    this.router.navigate(['/dashboard/surveygroupresults'], { queryParams: { 'group': sGroup ,'id':id } });
  }
}
