import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-managematerial',
  templateUrl: './managematerial.component.html',
  styleUrls: ['./managematerial.component.scss']
})
export class ManagematerialComponent extends BaseComponent implements OnInit {
  materials: any = [];
  material: any = {};
  materialgroupid = 0;
  materialgroups: any = [];
  matsysrel: any = [];

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.add();
    this.loader.hide();
  }
  async getData() {
    this.add(); //initial material
    const resp = await this.db.get("lookup", "getMaterials");
    await this.getMaterialSysRel();
    this.materials = resp.materials;
    this.materialgroups = resp.materialgroups;
  }

  async getMaterialSysRel() {
    if (this.material.id > 0) {
      const rels = await this.db.get("lookup", "getPackagingSystemsForMaterial", { materialId: this.material.id });
      const resp = await this.db.get("idea", "getallpackagingtechnologies");
      this.matsysrel = resp.packagingtechnologies;
      this.matsysrel.forEach(x => {
        x.id = x.Id;
        x.name = x.Name;
        x.description = x.Description;
        x.isChecked = false;
        x.refId = 0;
        let rel = rels.find(r => r.packsystemId == x.Id);
        if (rel != null) {
          x.isChecked = true;
          x.refId = rel.id;
        }
      });
    }
  }

  async selectMaterial(mat) {
    this.material = mat;
    await this.getMaterialSysRel();
  }
  async saveMaterial() {
    this.loader.show();
    await this.db.post("lookup", "postMaterial", this.material);
    await this.getData();
    this.loader.hide();
  }
  closemodal() {
    $('#changeTag').hide();
  }
  async addPackSysRel(df) {
    this.loader.show(); 
    let newRel = { MaterialId: this.material.id, PacksystemId: df.id };
    await this.db.post("lookup", "postMaterialPackTechRel", newRel);
    await this.getMaterialSysRel();
    this.loader.hide();
  }
  async removePackSysRel(pf) {
    this.loader.show();
    await this.db.delete("lookup", "deleteMaterialPackTechRel", { id: pf.refId });
    await this.getMaterialSysRel();
    this.loader.hide();
  }
  add() {
    this.material = {
      id: 0,
      name: "",
      description: "",
      groupId: 0
    };
  }
}
