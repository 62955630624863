import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-printtext',
  templateUrl: './printtext.component.html',
  styleUrls: ['./printtext.component.scss']
})
export class PrinttextComponent implements OnInit {
  @Input() title = "";
  @Input() text = "";
  constructor() { }

  ngOnInit() {
  }

}
