import { Component, OnInit,Input } from '@angular/core';
import { DbServiceService } from '../../../../services/db-service.service';
import { SurveyAnalysis } from '../../../survey/surveyanalysis';

@Component({
  selector: 'app-typeofidea',
  templateUrl: './typeofidea.component.html',
  styleUrls: ['./typeofidea.component.scss']
})

export class TypeofideaComponent implements OnInit {
  @Input("callbackHome") callbackHome;

  surveyAnalysis: SurveyAnalysis;
  data: any = [];
  constructor(private db: DbServiceService) { }

  async ngOnInit() {
    this.surveyAnalysis = new SurveyAnalysis(this.db);
    this.data = await this.surveyAnalysis.analyse10TypeOfIdeas();
    this.data = this.data.slice(0, 5);
    let a = 5;
  }

  callback = function (record, type) {
    //alert("In typeofidea " + type);
    this.callbackHome(record, type);
  }.bind(this);
}
