import { Component, OnInit, Input, ElementRef, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import * as $ from 'jquery';
import { schemeCategory10, schemeSet2 } from 'd3';

@Component({
  selector: 'hbar2',
  templateUrl: './hbar2.component.html',
  styleUrls: ['./hbar2.component.scss']
})
export class Hbar2Component implements OnInit {

  @Input() width = 500;
  @Input() height = 500;
  @Input() isDebug = false;
  @Input() colorscale = "standard";

  data: any = [];
  id = "0";

  constructor(private hostElement: ElementRef) { }

  colorchoices = [
    { name: "standard", from: "#bdf0f2", to: "#7484a3" },
    { name: "green", from: "#009900", to: "#ccffcc" },
    { name: "red", from: "#ff5050", to: "#ffcccc" },
    { name: "blue", from: "#0066cc", to: "#99ccff" },
  ];

  ngOnInit() {
  }

  public draw = function(data,id="0",selectIdea) {
    this.data = data;
    this.id = id;

    if (this.data.length == 0) return;
    let colorsc = this.colorchoices.find(x => x.name == this.colorscale)
    if (colorsc == null) colorsc = this.colorchoices[0];

    var margin = { "top": 20, "right": 10, "bottom": 30, "left": 30 };
    let width = this.width;
    var minmax = this.getMax();
    if (minmax.min > 0) minmax.min = 0;
    var fontSize = 14;
    var dHeight = fontSize + 20; // The height of one bar including padding
    var textOffset = (dHeight + fontSize) / 2;
    var barPadding = 2;
    var height = dHeight * this.data.length + margin.top + margin.bottom;

    var z2 = d3.scaleSequential(d3.interpolate(colorsc.from, colorsc.to));
    z2.domain([0, this.data.length]);

    var xScale = d3.scaleLinear()
      .domain([0, minmax.max])
      .range([0, width - margin.right - margin.left]);

    var yScale = d3.scaleLinear()
      .domain([0, this.data.length])
      .range([0, height - margin.top - margin.bottom]); 

    var comp = d3.select('#' + this.id); //select the component by id
    var svg = comp.select("#localsvgid").attr("width", width).attr("height", height); //select the svg tag within
    svg.selectAll("*").remove();


    var g = svg.append("g");

    var rect = g.selectAll('rect')
      .data(this.data)
      .enter().append('rect')
      .attr('x', function (d, i) { return margin.left; })
      .attr('y', function (d, i) { return yScale(i) + margin.top + barPadding })
      .attr('width', function (d, i) { return xScale(d['value']); })
      .attr('height', function (d, i) { return dHeight - 2 * barPadding })
      .attr('fill', function (d, i) { return z2(i) })
      .attr('margin', 0).attr('index', function (d, i) { return i; }).attr('value', function (d, i) { return d['value']; })
      .on('click', function (d, i) {
        selectIdea(d); //callback to parent
      });

    var b = svg.selectAll('text').data(this.data)
      .enter().append('text')
      .text(function (d, i) { return d['key']; })
      .attr("x", function (d, i) { return margin.left + 10; })
      .attr("y", function (d, i) { return yScale(i) + textOffset + margin.top; })
      .attr("font-family", "arial").attr("font-size", fontSize + "px").attr("font-weight", "100")
      .attr("stroke", "black").attr("font-syle", "normal").attr("stroke-width", "1");

    var xScaleTick = d3.scaleLinear()
      .domain([minmax.min, minmax.max])
      .range([0, width - margin.right - margin.left]);
    var xAxis = d3.axisBottom(xScaleTick);

    svg.append('g')
      .attr('transform', 'translate(' + [margin.left, height - margin.bottom] + ')')
      .call(xAxis);
  };

  getMax() {
    if (this.data[0] == undefined) {
      let a = 5;
    }
    let max = this.data[0].value;
    let min = this.data[0].value;
    this.data.forEach(x => {
      if (x.value > max) { max = x.value; }
      if (x.value < min) { min = x.value; }
    });
    return { max: max, min: min };
  }
}
