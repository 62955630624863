import { Component, Injector, EventEmitter, Output, Input, OnInit, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-ideacategories',
  templateUrl: './ideacategories.component.html',
  styleUrls: ['./ideacategories.component.scss']
})
export class IdeacategoriesComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() ideaId = 0;
  @Input() title = "";
  @Output() categoryChange = new EventEmitter();

  public IdeaCatRel: any = [];
  public Categories: any = []; // array of {Id: 1, Name: "Technology", isChecked: true, relId: 1106}
  constructor(private injector: Injector) { super(injector) }
  
  async ngOnInit() {
    await this.getData();
  }
  async getData() {
    if (this.ideaId > 0) {
      const resp = await this.db.get("idea", "getideacategories", { id: this.ideaId });
      this.IdeaCatRel = resp.IdeaCatRel;
      this.Categories = resp.Categories;
      this.Categories.forEach(cat => {
        let ideaCatrel = this.IdeaCatRel.find(x => x.IdeaCategoryId === cat.Id);
        if (ideaCatrel == null) {
          cat['isChecked'] = false;
          cat['relId'] = 0;
        } else {
          cat['isChecked'] = true;
          cat['relId'] = ideaCatrel.Id;
        }
      })  
    }
    this.categoryChange.emit(this.Categories); //tell the parent
  }

  async ngOnChanges() {
    await this.getData();
  }
  async changeCheckBox($event, o) {
    this.loader.show();

    if (o.isChecked) {
      let rel = { id: o.relId, IdeaCategoryId: o.Id, IdeaId: this.ideaId };
      await this.db.post("idea", "addideacategory", null, { categoryId: o.Id, ideaId: this.ideaId})
      //await this.db.post("idea", "postideacategory", rel);
    } else {
      if (o.relId) {
        //await this.db.delete("idea", "deletetideacategory", { id: o.relId });
        await this.db.delete("idea", "deleteideacategory2", { categoryId: o.Id, ideaId: this.ideaId })
      }
    }
    await this.getData();
    this.categoryChange.emit(this.Categories);
    this.loader.hide();
  }

}
