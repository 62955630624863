import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sa-input-number',
  templateUrl: './sa-input-number.component.html',
  styleUrls: ['../formfields.css']
})
export class SaInputNumberComponent implements OnInit {
  ngOnInit(): void {

  }
  @Input() text: string;
  @Input() title: string;
  @Input() required: string; //P.S. can be 'text' (if required) or 'none' if not required

  @Output() textChange = new EventEmitter();
  @Output() hasChanged = new EventEmitter();

  isValid = true;
  tiClass = { 'border': 'solid 1px gray' };//{'background-color':'green'};
  constructor() { }


  ngAfterViewChecked() {
    this.doValidation(this.text);
  }
  change(newValue) { //P.S. newValue has the changed text
    this.doValidation(newValue);
    this.text = newValue;

    //P.S. Synchronize the text with the model of the parent
    this.textChange.emit(newValue);
    //P.S. send the parent the message whether validation is true or false
    this.hasChanged.emit(this.isValid);
  };

  doValidation = function (newValue) {
    //P.S. implement your validation rules here
    if (this.required == 'text') {
      if (!newValue || newValue == "") {
        this.isValid = false;
        this.tiClass = { 'border': 'solid 2px red' };
      } else {
        this.isValid = true;
        this.tiClass = { 'border': 'solid 1px gray' };
      }
    }
  };
}
