import { snaAlertService } from './../../../modules/sna-alert/sna-alert.service';
import { IdeaType } from './../../../models/IdeaCluster';
import { Component, OnInit, OnChanges, ViewChild, Input , Output} from '@angular/core';
import { Idea, Impact, Reach, Length, Sustainability, IdeaLookups } from '../../../models/IdeaCluster';
import { GlobalService } from '../../../services/global.service';
import { SainputhtmlComponent } from '../../shared/formfields/sa-input-html/sa-input-html.component';
import { SaselectComponent } from '../../shared/formfields/sa-select/sa-select.component';
import { SainputtextComponent } from '../../shared/formfields/sa-input-text/sa-input-text.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { DbServiceService } from '../../../services/db-service.service';
import { LoaderService } from '../../../modules/loader/loader.service';
import * as $ from 'jquery';
import { Co2analyzerComponent } from './co2analyzer/co2analyzer.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sa-recordedit',
  templateUrl: './recordedit.component.html',
  styleUrls: ['./recordedit.component.scss']
})
export class RecordeditComponent implements OnInit,OnChanges {
  @Input() IdeaId: number;
  @Input() IdeaTypeId: number;

  @ViewChild('Name') Name: SainputtextComponent;
  @ViewChild('Description') Description: SainputhtmlComponent;
  @ViewChild('Impact') Impact: SaselectComponent;
  @ViewChild('Reach') Reach: SaselectComponent;
  @ViewChild('Length') Length: SaselectComponent;
  @ViewChild('Sustainability') Sustainability: SaselectComponent;
  @ViewChild('changeTypeTag') changeTypeTag;

  Idea: any = {}; // record in P.S DB Table Idea
  IdeaType: any = {};
  IdeaIdeaCatRel: any = []; // P.S DB Table IdeaIdeaCatRel
  IdeaCategories: any = []; // P.S. subset of global.categories having the seleted ideacategories
  EconomyCycleIdeaRels: any = [];
  IdeaRelationships: any = [];
  Tasks: any = [];
  TaskStatus: any = [];
  relatedRecords: any = []; // P.S. all concepts for ideas, projects for conepts, etc
  relatedRecName = '';
  newRecordTypeName = '';
  showErrorMessage = false;
  errorMessage = '';

  isFormValid = true;
  showDocu = false;
  spinner: boolean;
  Relationships: any;
  closeResult: string;
  testBoolean = true;
  headerBackgroundColor: any;
  nextStep: any = {};
  Url = "";
  brand: any = {};

  type10templateid = 31;
  token = "";

  showHelpDescription = false;
  showHelpDosageForm = false;
  showHelpFeasibility = false;
  showHelpProductionImpact = false;
  showHelpKeyResources = false;
  showHelpKeyActivitities = false;
  showHelpSWOT = false;
  showStepStrechLeap = false;
  showHelpMaterialGroup = false;
  showHelpMaterialForm = false;
  lpackform: any = [];
  lpackformFirstChecked: any = {};
  lsecpackform: any = [];
  lsecpackformFirstChecked: any = {};
  IdeaTouchAndFeelPrim: any = [];
  IdeaTouchAndFeelSec: any = [];
  lDosageForm: any = [];
  lDosageFormFirstChecked: any = {};
  lPrintingEffects: any = [];
  lMaterialGroups: any = [];
  lMaterialGroupsSec: any = [];
  lPackPerception: any = [];
  showTabs: any = [];
  observations: any = [];
  observation: any = {};
  distributions: any = [];
  distribution: any = {};
  primpackrelcompanies: any = [];
  processes: any = [];

  campaignname = "";
  surveytemplateid = 0;
  surveytemplates: any = [];

  @ViewChild(Co2analyzerComponent) co2analyser: Co2analyzerComponent;
  constructor(
    private db: DbServiceService,
    public global: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private modal: NgbModal,
    private alert: snaAlertService) { }


  async ngOnChanges() {
    if (this.global.hasDataLoaded == false) {
      await this.global.getLookups();
    }
  }
  async ngOnInit() {
    //init show all category tabs with false
    this.loader.show();
    for (let i = 1; i <= 6; i++) this.showTabs.push({ id: i, show: false });

    if (this.global.hasDataLoaded == false) {
      await this.global.getLookups();
    }
    const resp = await this.db.get('account', "getRegToken");
    this.token = resp.regToken;

    this.setEmptyIdea(); // P.S. initial all fields to avoid error in html

    if (this.IdeaId > 0) {
      
      await this.getIdea(this.IdeaId);
      await this.getIdeaPackagingSystemRelationship();
      await this.getIdeaDosageFormRel();
      await this.getPrintingEffectRel();
      await this.getMaterialGroupRel();
      await this.getMaterialGroupSec();
      await this.getPackPerceptionRel();
      await this.getObservations();
      await this.getDistributions();
    } else {
      this.createNewIdea(this.IdeaTypeId);
    }
    this.selectNextStep(null);
    this.loader.hide();
  }

  showWithIdeaTypes(IdeaTypeNames: any) {
    let ret = false;
    IdeaTypeNames.forEach(name => {
      if (this.IdeaType.Name === name) { ret = true; }
    });
    return ret;
  }

  getBackgroundColor() {
    let ret = '#c6eac6';
    if (this.Idea.TypeId !== undefined) {
      if (this.Idea.TypeId === 2) { ret = '#a4a4f5'; }
      if (this.Idea.TypeId === 3) { ret = '#efefb0'; }
      if (this.Idea.TypeId === 4) { ret = 'rgb(207, 158, 207)'; }
      if (this.Idea.TypeId === 5) { ret = 'rgb(207, 207, 207)'; }
      if (this.Idea.TypeId === 7) { ret = 'rgb(133, 146, 158)'; }
    }
    return ret;
  }

  setEmptyIdea() {
    this.Idea.Id = 0;
    this.Idea.TypeId = 1;
    this.Idea = new Idea();
    this.Idea.Id = 0;
    this.Idea.Name = '';
    this.Idea.Description = '';
    this.Idea.IsPublic = true;
    this.IdeaIdeaCatRel = [];
    this.Relationships = [];
    this.EconomyCycleIdeaRels = [];
    this.IdeaRelationships = [];
    this.IdeaCategories = [];
    this.Idea.ReachId = 1;
    this.Idea.LengthId = 1;
    this.Idea.SustainabilityId = 1;
  }

  // P.S. Internally called when page is called with IdeaId = 0
  createNewIdea(IdeaTypeId: number) {
    this.Idea = new Idea();

    this.Idea.Author = sessionStorage.getItem('firstname') + " " + sessionStorage.getItem('lastname');

    this.Idea.CompanyId = sessionStorage.getItem('companyId');
    this.Idea.ImpactId = 7;
    this.Idea.ProductBusinessAreaId = 
    this.Idea.Id = 0;
    this.Idea.TypeId = IdeaTypeId; // type idea
    this.Idea.Name = '';
    this.Idea.Description = '';
    this.Idea.TypeId = IdeaTypeId; // type idea
    this.Idea.IsPublic = true;

    this.IdeaType = this.global.IdeaTypes.find(x => x.Id === IdeaTypeId);
    // P.S. reference tables
    this.IdeaIdeaCatRel = [];
    this.Relationships = [];
    this.EconomyCycleIdeaRels = [];

    // P.S. reference tables for child components
    this.IdeaRelationships = [];
    this.IdeaCategories = [];
    


    this.global.IdeaType = this.global.IdeaTypes.find(x => x.Id === IdeaTypeId);

  }

  // P.S. Open Modal with options to create new idea
  async createNewIdeaWithCopy(newTypeId: number) {
    this.newRecordTypeName = 'Concept';
    if (this.Idea.TypeId === 2) { this.newRecordTypeName = 'Project'; }
    this.relatedRecords = await this.db.get('idea', 'getRelatedRecords', { IdeaId: this.Idea.Id });
    $('#selectNewRecord').show();
  }
  closeSelectNewRecordModal() {
    $('#selectNewRecord').hide();
  }
  async submitSelectNewRecord() {
    let bFound = false;
    this.showErrorMessage = false; // P.S. clear error message first as default
    // P.S check whether name is the same as any related record
    this.relatedRecords.forEach(rec => {
      if (rec.targetName === this.relatedRecName) { bFound = true; }
    });

    if (this.relatedRecName.length === 0) {
      this.showError('The name cannot be empty. Please, enter a valid name.')
      return;
    }

    if (bFound) {
      this.showError('Please, enter a unique name.');
      return;
    }

    const newRec = await this.db.post('idea', 'createNewRecord?IdeaId=' + this.Idea.Id,
      { name: this.relatedRecName });

    this.setEmptyIdea();
    this.global.IdeaId = newRec.Id;
    this.getIdea(newRec.Id);
    alert('new record created');
    $('#selectNewRecord').hide();
  }
  formValidate(event: any, name: string) {
    this.isFormValid = true;
    // P.S. verify all required fields (see table in db)
    if (this.Idea.Name === '') { this.isFormValid = false; }
    if (this.Idea.Description === '') { this.isFormValid = false; }
    if (this.Idea.ImpactId === undefined) { this.isFormValid = false; }
    // if (this.Idea.ReachId === undefined) { this.isFormValid = false; }
    // if (this.Idea.LengthId === undefined) { this.isFormValid = false; }
    //  if (this.Idea.SustainabilityId === undefined) { this.isFormValid = false; }
  }

  async setShowTabs(catId, bValue) {
    let index = this.showTabs.findIndex(x => x.id == catId);
    if (index >= 0) this.showTabs[index].show = bValue;
    //if (catId > 0) {
    //  if (bValue == true) {
    //    await this.db.post("idea", "addideacategory", null, { categoryId: catId, ideaId: this.Idea.Id })
    //  } else {
    //    await this.db.delete("idea", "deleteideacategory2", { categoryId: catId, ideaId: this.Idea.Id })
    //  }
    //} else {
    //  let a = 5;
    //}

  }

  changeCategory(cats) {
    cats.forEach(ii => this.setShowTabs(ii.Id, ii.isChecked));
    let a = 5;
  }
  async getIdea(IdeaId) {
    const ret: any = await this.db.get('idea', 'getidea', { Id: IdeaId });
    this.Idea = ret.Idea;
    this.IdeaType = this.global.IdeaTypes.find(x => x.Id === this.Idea.TypeId);
    this.IdeaIdeaCatRel = ret.IdeaIdeaCatRel;

    this.IdeaIdeaCatRel.forEach(ii => {
      this.setShowTabs(ii.IdeaCategoryId, true);
    });
    const ret2 = await this.db.get('process', 'getProcesses');
    this.processes = ret2.processes
    this.getIdeaTouchAndFeelRel();
    this.getIdeaSecTouchAndFeelRel();
    this.getIdeaPackSystemsSec();
    this.EconomyCycleIdeaRels = ret.EconomyCycleIdeaRels;
    this.IdeaRelationships = ret.IdeaRelationships;
    this.Url = ret.PicUrl == null ? "" : ret.PicUrl;
    this.Tasks = ret.Tasks;
    this.TaskStatus = ret.TaskStatus;

    const fav = await this.db.get("idea", "getFavorite", { ideaId: this.Idea.Id })
    this.Idea.IsFavorite = fav.isFavorite;
    this.formValidate(null, '');
  }

  async submit() {
    this.loader.show();
    const IdeaTemp = await this.db.post('idea', 'submitidea', this.Idea);
    //await this.db.post('idea', 'submitideacategories?IdeaId=' + IdeaTemp.Id, this.IdeaCategories);

    this.global.IdeaId = IdeaTemp.Id;
    this.getIdea(IdeaTemp.Id);
    this.loader.hide();
  }

  openPT(content) {
    this.modal.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  print() {
    if (this.Idea.TypeId == 4) {
      this.router.navigate(['/dashboard/productprint'], { queryParams: { 'IdeaId': this.Idea.Id } });
    } else {
      this.router.navigate(['/dashboard/print'], { queryParams: { 'IdeaId': this.Idea.Id } });
    }
  }

  showError(s: string) {
    this.errorMessage = s;
    this.showErrorMessage = true;
  }

  lookup(item) {
    const status = this.TaskStatus.find(x => x.Id == item.StatusId);
    return status.Name;
  }
  gotoCanvas() {
    this.router.navigate(['/dashboard/canvas'], { queryParams: { 'IdeaId': this.Idea.Id } });
  }
  gotoId($event) {
    this.IdeaId = $event.id;
    this.global.IdeaId = this.IdeaId;
    this.ngOnInit();
  }

  selectNextStep(item) {

    let date_ob = new Date();

    // adjust 0 before single digit date
    let date = ("0" + date_ob.getDate()).slice(-2);

    // current month
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // current year
    let year = date_ob.getFullYear();

    // prints date in YYYY-MM-DD format
    const sdt = year + "-" + month + "-" + date;
    this.nextStep = item != null ? item : {
      Id: 0,
      IdeaId: this.IdeaId,
      Name: "",
      Descrition: "",
      StatusId: 1,
      DateStart: sdt,
      DateFinish: null,
    };
    //$('#selectNextStep').show();
  }
  async saveTask() {
    this.loader.show();
    const resp = await this.db.post("task", "postTask", this.nextStep);
    this.Tasks = resp.Tasks;
    this.loader.hide();
  }
  async deleteStep(item) {
    this.loader.show();
    const resp = await this.db.delete("task", "deleteTask", { id: item.Id });
    this.Tasks = resp.Tasks;
    this.loader.hide();
  }
  changeType() {
    $('#changeTypeTag').show();
  }
  closeChangeTypeModal() {
    $('#changeTypeTag').hide();
  }
  async changeTypepTo(item) {
    await this.db.get("idea", "changeIdeaType", { IdeaId: this.Idea.Id, IdeaTypeId: item.Id })
    await this.getIdea(this.IdeaId);
    $('#changeTypeTag').hide();
  }
  async updateFavorite($event) {
    this.Idea.IsFavorite = $event;
    if ($event) {
      await this.db.get("idea", "setFavorite", { ideaId: this.Idea.Id });
    } else {
      await this.db.get("idea", "removeFavorite", { ideaId: this.Idea.Id });
    }
  }
  async getIdeaPackagingSystemRelationship() {
    this.lpackformFirstChecked = {name: ""};
    let foundFirstChecked = false;
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getPackagingSystemsForIdea", { ideaId: this.Idea.Id });
      this.lpackform = [];
      resp2.forEach(x => {
        let lpf = {
          id: x.id,
          name: x.name,
          description: x.description,
          isChecked: false,
          refId: 0
        }
        if (x.ideaPackRel != null) {
          lpf.isChecked = true;
          lpf.refId = x.ideaPackRel.id //primary key in table CompanyPackTechRel
          if (foundFirstChecked == false) {
            foundFirstChecked = true;
            this.lpackformFirstChecked = lpf;
          }
        }
        this.lpackform.push(lpf);
      })
    }
  }

  async getIdeaDosageFormRel() {
    this.lDosageFormFirstChecked = { name: "" };
    let foundChecked = false;
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getIdeaDosageFormRel", { ideaId: this.Idea.Id });
      this.lDosageForm = [];
      this.global.DosageForms.forEach(x => {
        let ldf = {
          id: x.Id,
          name: x.Name,
          description: x.Description,
          isChecked: false,
          refId: 0
        };
        let rel = resp2.find(r => r.dosageFormId == x.Id);
        if (rel != null) {
          ldf.isChecked = true;
          ldf.refId = rel.id;
          if (foundChecked == false) {
            foundChecked = true;
            this.lDosageFormFirstChecked = ldf;
          }
        }
        this.lDosageForm.push(ldf);
      });
    }
    let a = 5;
  }

  async getObservations() {
    if (this.Idea.Id > 0) {
      const resp = await this.db.get("lookup", "getObservations", { ideaId: this.Idea.Id });
      this.observations = resp.observations;
      let pics = resp.pics;
      this.observations.forEach(o => {
        let pic = pics.find(p => p.id == o.documentId);
        if (pic != null) {
          o.url = pic.url;
        } else {
          o.url = "";
        }
      });
    }
    let a = 5;
  }

  async getDistributions() {
    if (this.Idea.Id > 0) {
      const resp = await this.db.get("lookup", "getDistributions", { ideaId: this.Idea.Id });
      this.distributions = resp.distributions;
      let pics = resp.pics;
      this.distributions.forEach(o => {
        let pic = pics.find(p => p.id == o.documentId);
        if (pic != null) {
          o.url = pic.url;
        } else {
          o.url = "";
        }
      });
    }
    let a = 5;
  }

  async getPrintingEffectRel() {
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getPrintingEffectRel", { ideaId: this.Idea.Id });
      this.lPrintingEffects = [];
      this.global.PrintingEffects.forEach(x => {
        let ldf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        };
        let rel = resp2.find(r => r.printEffectId == x.Id);
        if (rel != null) {
          ldf.isChecked = true;
          ldf.refId = rel.id;
        }
        this.lPrintingEffects.push(ldf);
      });
    }
    let a = 5;
  }

  async getMaterialGroupRel() {
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getMaterialGroupRel", { ideaId: this.Idea.Id });
      this.lMaterialGroups = [];
      this.global.MaterialGroups.forEach(x => {
        let ldf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        };
        let rel = resp2.find(r => r.materialGroupId == x.Id);
        if (rel != null) {
          ldf.isChecked = true;
          ldf.refId = rel.id;
        }
        this.lMaterialGroups.push(ldf);
      });
    }
    let a = 5;
  }

  async getMaterialGroupSec() {
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getMaterialGroupsSecRel", { ideaId: this.Idea.Id });
      this.lMaterialGroupsSec = [];
      this.global.MaterialGroups.forEach(x => {
        let ldf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        };
        let rel = resp2.find(r => r.materialGroupId == x.Id);
        if (rel != null) {
          ldf.isChecked = true;
          ldf.refId = rel.id;
        }
        this.lMaterialGroupsSec.push(ldf);
      });
    }
    let a = 5;
  }

  async getPackPerceptionRel() {
    if (this.Idea.Id > 0) {
      const resp2 = await this.db.get("lookup", "getIdeaSecPackPerceptionRel", { ideaId: this.Idea.Id });
      this.lPackPerception = [];
      this.global.PackagePerception.forEach(x => {
        let ldf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        };
        let rel = resp2.find(r => r.packagingPerceptionId == x.Id);
        if (rel != null) {
          ldf.isChecked = true;
          ldf.refId = rel.id;
        }
        this.lPackPerception.push(ldf);
      });
    }
    let a = 5;
  }

  async getIdeaTouchAndFeelRel() {
    if (this.Idea.Id > 0) {
      let tfs = this.global.TouchAndFeels;
      let tfRels = await this.db.get("lookup", "getTouchAndFeelRel", { IdeaId: this.Idea.Id })
      this.IdeaTouchAndFeelPrim = [];
      tfs.forEach(x => {
        let tf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        }
        let tfRel = tfRels.find(x => x.touchAndFeelId === tf.id)
        if (tfRel != null) {
          tf.isChecked = true;
          tf.refId = tfRel.id
        }
        this.IdeaTouchAndFeelPrim.push(tf);
      })
    }
  }

  async getIdeaSecTouchAndFeelRel() {
    if (this.Idea.Id > 0) {
      let tfs = this.global.TouchAndFeels;
      let tfRels = await this.db.get("lookup", "getIdeaSecTouchAndFeelRel", { IdeaId: this.Idea.Id })
      this.IdeaTouchAndFeelSec = [];
      tfs.forEach(x => {
        let tf = {
          id: x.Id,
          name: x.Name,
          isChecked: false,
          refId: 0
        }
        let tfRel = tfRels.find(x => x.touchAndFeelId === tf.id)
        if (tfRel != null) {
          tf.isChecked = true;
          tf.refId = tfRel.id
        }
        this.IdeaTouchAndFeelSec.push(tf);
      })
    }
  }

  async getIdeaPackSystemsSec() {
    this.lsecpackformFirstChecked = { name: "" };
    let foundFirstChecked = false;
    if (this.Idea.Id > 0) {
      let tfs = this.global.PackagingTechnologies;
      let tfRels = await this.db.get("lookup", "getIdeaSecPackagingSystemRel", { IdeaId: this.Idea.Id })
      this.lsecpackform = [];
      tfs.forEach(x => {
        let tf = {
          id: x.Id,
          name: x.Name,
          description: x.Description,
          isChecked: false,
          refId: 0
        }
        let tfRel = tfRels.find(x => x.packagingTechnologyId === tf.id)
        if (tfRel != null) {
          tf.isChecked = true;
          tf.refId = tfRel.id
          if (foundFirstChecked == false) {
            foundFirstChecked = true;
            this.lsecpackformFirstChecked = tf;
          }
        }
        this.lsecpackform.push(tf);
      })
    }
  }
  async addSecPackForm($event) {
    this.loader.show();
    let newRel = { ideaId: this.Idea.Id, PackagingTechnologyId: $event.id };
    await this.db.post("lookup", "postIdeaSecPackagingSystemRel", newRel);
    await this.getIdeaPackSystemsSec();
    this.loader.hide();
  }

  async removeSecPackForm($event) {
    this.loader.show();
    await this.db.delete("lookup", "deleteIdeaSecPackagingSystemRel", { id: $event.refId });
    await this.getIdeaPackSystemsSec();
    this.loader.hide();
  }

  async removeTouchAndFeelPrim($event) {
    this.loader.show();
    await this.db.delete("lookup", "deleteIdeaTouchFeelRelPrim", { id: $event.refId });
    await this.getIdeaTouchAndFeelRel();
    this.loader.hide();
  }

  async addTouchAndFeelPrim($event) {
    this.loader.show();
    let newRel = { ideaId: this.Idea.Id, TouchAndFeelId: $event.id };
    await this.db.post("lookup", "postIdeaTouchFeelRelPrim", newRel);
    await this.getIdeaTouchAndFeelRel();
    this.loader.hide();
  }

  async removeTouchAndFeelSec($event) {
    this.loader.show();
    await this.db.delete("lookup", "deleteIdeaSecTouchAndFeelRel", { id: $event.refId });
    await this.getIdeaSecTouchAndFeelRel();
    this.loader.hide();
  }

  async addTouchAndFeelSec($event) {
    this.loader.show();
    let newRel = { ideaId: this.Idea.Id, TouchAndFeelId: $event.id };
    await this.db.post("lookup", "postIdeaSecTouchAndFeelRel", newRel);
    await this.getIdeaSecTouchAndFeelRel();
    this.loader.hide();
  }

  quickAddBrand() {
    $('#brandTag').show();
  }

  async brandSubmitted($event) { //call back by modal brand
    await this.global.getLookups();
    this.Idea.BrandId = $event.id;

    this.Idea.Brandwebsite = $event.website.startsWith('http') ? $event.website : "http://" + $event.website;
    $('#brandTag').hide();
  }
  closeModalBrand() {
    $('#brandTag').hide();
  }

  quickAddStore() {
    $('#storeTag').show();
  }
  async storeSubmitted($event) { //call back by modal brand
    await this.global.getLookups();
    this.Idea.StoreId = $event.id;

    this.Idea.Storewebsite = $event.website.startsWith('http') ? $event.website : "http://" + $event.website;
    $('#storeTag').hide();
  }
  closeModalStore() {
    $('#storeTag').hide();
  }

  async addPackForm(pf) {
    this.loader.show();
    let newRel = { ideaId: this.Idea.Id, PachTechId: pf.id };
    await this.db.post("lookup", "postIdeaPackTechRel", newRel);
    await this.getIdeaPackagingSystemRelationship();
    this.loader.hide();
  }
  async removePackForm(pf) {
    this.loader.show();
    await this.db.delete("lookup", "deleteIdeaPackTechRel", { id: pf.refId });
    await this.getIdeaPackagingSystemRelationship();
    this.loader.hide();
  }
  async addDosageForm(df) {
    this.loader.show();
    let newRel = { ideaId: this.Idea.Id, DosageFormId: df.id };
    await this.db.post("lookup", "postIdeaDosageFormRel", newRel);
    await this.getIdeaDosageFormRel();
    this.loader.hide();
  }
  async removeDosageForm(df) {
    this.loader.show();
    await this.db.delete("lookup", "deleteIdeaDosageFormRel", { id: df.refId });
    await this.getIdeaDosageFormRel();
    this.loader.hide();
  }

  async addPrintingEffect(df) {
    this.loader.show();
    let newRel = { ideaId: this.Idea.Id, PrintEffectId: df.id };
    await this.db.post("lookup", "postPrintingEffectRel", newRel);
    await this.getPrintingEffectRel();
    this.loader.hide();
  }
  async removePrintingEffect(df) {
    this.loader.show();
    await this.db.delete("lookup", "deletePrintingEffectRel", { id: df.refId });
    await this.getPrintingEffectRel();
    this.loader.hide();
  }

  async addMaterialGroup(df) {
    this.loader.show();
    let newRel = { ideaId: this.Idea.Id, MaterialGroupId: df.id };
    await this.db.post("lookup", "postMaterialGroupRel", newRel);
    await this.getMaterialGroupRel();
    this.loader.hide();
  }
  async removeMaterialGroup(df) {
    this.loader.show();
    await this.db.delete("lookup", "deleteMaterialGroupRel", { id: df.refId });
    await this.getMaterialGroupRel();
    this.loader.hide();
  }

  async addMaterialGroupSec(df) {
    this.loader.show();
    let newRel = { ideaId: this.Idea.Id, MaterialGroupId: df.id };
    await this.db.post("lookup", "postMaterialGroupSecRel", newRel);
    await this.getMaterialGroupSec();
    this.loader.hide();
  }
  async removeMaterialGroupSec(df) {
    this.loader.show();
    await this.db.delete("lookup", "deleteMaterialGroupSecRel", { id: df.refId });
    await this.getMaterialGroupSec();
    this.loader.hide();
  }

  async addPackPerceptionSec(df) {
    this.loader.show();
    let newRel = { ideaId: this.Idea.Id, PackagingPerceptionId: df.id };
    await this.db.post("lookup", "postIdeaSecPackPerceptionRel", newRel);
    await this.getPackPerceptionRel();
    this.loader.hide();
  }
  async removePackPerceptionSec(df) {
    this.loader.show();
    await this.db.delete("lookup", "deleteIdeaSecPackPerceptionRel", { id: df.refId });
    await this.getPackPerceptionRel();
    this.loader.hide();
  }

  async editModalObservation(obs) {
    if (obs == null) {
      this.observation = { id: 0, name: "", description: "", ideaid: this.Idea.Id, documentid: null };
    } else {
      this.observation = obs;
    }
    $('#observationTag').show();
  }
  async closeModalObservation() {
    $('#observationTag').hide();
  }
  async saveModalObservation() {
    this.loader.show();
    await this.db.post("lookup", "postObservation", this.observation);
    await this.getObservations();
    $('#observationTag').hide();
    this.loader.hide();
  }
  async deleteObservation(o) {
    this.loader.show();
    await this.db.delete("lookup", "deleteObservation", { id: o.id });
    await this.getObservations();
    this.loader.hide();
  }
  async emitSaveImage($event) {
    this.observation = $event.refObj;
    let index = $event.length - 1;
    this.observation.documentId = $event[index].Id;
    await this.db.post("lookup", "postObservation", this.observation);
    await this.getObservations();
  }
  async searchCompanyForPrimPackSys() {
    let listPacksysIds = [];
    this.lpackform.forEach(lps => {
      if (lps.isChecked == true) {
        listPacksysIds.push(lps.id);
      }
    });
    this.primpackrelcompanies = await this.db.post("company", "getCompaniesByPackagingSystemIds", listPacksysIds);
    $('#primpackrelcompaniesTag').show();
    //alert(resp);
  }
  closeprimpackrelcompaniesTag() {
    $('#primpackrelcompaniesTag').hide();
  }
  gotoCompany(c) {
    this.router.navigate(['/dashboard/companyedit'], { queryParams: { 'Id': c.companyId } });
  }

  async editModalDistribution(obs) {
    if (obs == null) {
      this.distribution = { id: 0, name: "", description: "", ideaid: this.Idea.Id, documentid: null };
    } else {
      this.distribution = obs;
    }
    $('#distributionTag').show();
  }
  async closeModalDistribution() {
    $('#distributionTag').hide();
  }
  async saveModalDistribution() {
    this.loader.show();
    await this.db.post("lookup", "postDistribution", this.distribution);
    await this.getDistributions();
    $('#distributionTag').hide();
    this.loader.hide();
  }
  async deleteDistribution(o) {
    this.loader.show();
    await this.db.delete("lookup", "deleteDistribution", { id: o.id });
    await this.getDistributions();
    this.loader.hide();
  }

  async emitSaveImageDist($event) {
    this.distribution = $event.refObj;
    let index = $event.length - 1;
    this.distribution.documentId = $event[index].Id;
    await this.db.post("lookup", "postDistribution", this.distribution);
    await this.getDistributions();
  }
  updateCO2Analyser() {
    this.co2analyser.refresh();
  }
  printTechData() {
    this.router.navigate(['/dashboard/printtechdata'], { queryParams: { 'ideaId': this.Idea.Id } });
  }

  async editModalCampaign() {
    this.surveytemplates = await this.db.get("survey", "getTemplates");
    $('#campaignTag').show();
  }
  async closeModalCampaign() {
    $('#campaignTag').hide();
  }
  async saveModalCampaign() {
    this.loader.show();
    //create a campaign
    //add idea
    if (this.campaignname.length < 1 || this.surveytemplateid < 1) {
      this.alert.info("Please, fill in a valid name and select a survey");
      this.loader.hide();
      return;
    }
    let campaign = {
      id: 0,
      Name: this.campaignname,
      SurveyTemplateId : this.surveytemplateid
    }
    campaign = await this.db.post("campaign", "postcampaign", campaign);
    let campaignidea = {
      id: 0,
      campaignid: campaign.id,
      ideaid: this.Idea.Id
    }

    await this.db.post("campaign", "postCampaignIdea", campaignidea);
    this.router.navigate(['/dashboard/campaign'], { queryParams: { 'campaignid': campaign.id } });

    $('#campaignTag').hide();
    this.loader.hide();
  }
}
