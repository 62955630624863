import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/components/shared/basecomponent';

@Component({
  selector: 'app-ideatags',
  templateUrl: './ideatags.component.html',
  styleUrls: ['./ideatags.component.scss']
})
export class IdeatagsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() ideaid = 0;
  @Input() ideatypeid = 7;
  @Input() catid = 1;
  category = "General"
  tag = ""
  tags: any = []
  taglist: any = []
  showlist = false
  constructor(public injector: Injector) { super(injector) }

  ngOnInit() {
    if (this.catid == 1) this.category = "General"
    if (this.catid == 2) this.category = "Technology and Processes"
    if (this.catid == 3) this.category = "Material"
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.ideaid.currentValue > 0) {
      await this.getData()

    }
  }
  async getData() {
    this.tags = await this.db.get("idea", "getAllTagsForIdeaAndCat", { ideaid: this.ideaid ,catid: this.catid})
    this.taglist = await this.db.get("idea", "getAllTagsGroupedByCatandIdeatype", { ideatypeid: this.ideatypeid, catid: this.catid })
  }

  async add() {
    if (this.tag.length > 0) {
      let tagobj = { id: 0, ideaid: this.ideaid,ideatypeid: this.ideatypeid, tag: this.tag, catid: this.catid }
      await this.db.post("idea", "postTag", tagobj)
      await this.getData()
      this.showlist = false
    }
  }
  async remove(tag: any) {
    await this.db.delete("idea", "deleteTag", { id: tag.id })
    await this.getData()
  }
}
