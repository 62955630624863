import { Component, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from './../../modules/loader/loader.service';
import { GlobalService } from '../../services/global.service';
import { DbServiceService } from '../../services/db-service.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  nIdeas = 0;
  nConcepts = 0;
  nProjects = 0;
  nProducts = 0;
  nPictures = 0;
  nRelationships = 0;
  rIdeas: any = [];
  private modalRef: NgbModalRef;
  selectedIdeaName = "";
  selectedDescription = "";
  selectedUrl = "";

  @ViewChild("carouselExampleIndicators") carouselExampleIndicators;

  constructor(private loader: LoaderService,
    private db: DbServiceService,
    public global: GlobalService,
    private router: Router,
    private modal: NgbModal
    ) { }

  async ngOnInit() {
    this.loader.show();
    //await this.global.getLookups();
    const resp = await this.db.get("idea", 'getsummary');
    this.nIdeas = resp.nIdeas;
    this.nConcepts = resp.nConcepts;
    this.nProjects = resp.nProjects;
    this.nProducts = resp.nProducts;
    this.nPictures = resp.nPictures;
    this.nRelationships = resp.nRelationships;
    this.rIdeas = await this.db.get("idea", 'getRandomIdeas', { nIdeas: 4 });


    this.loader.hide();
  }
  gotoList(type: string, route: string) {
    this.global.IdeaType = this.global.IdeaTypes.find(x => x.Name === type);
    this.router.navigate([route]);
  }
  async navigate(s: string, modalId) {
    const resp = await this.db.get("account", "isadmin");
    if (resp.isAdmin == true) {
      this.router.navigate([s]);
    } else {
      //$('#content').show();
      this.modalRef = this.modal.open(modalId);
    }
  }
  dismiss() {
    this.modalRef.close();
  }
  callback = function(record, type) {
    if (type == "mouseover") {
      this.selectedIdeaName = record.name;
      this.selectedDescription = record.description;
      this.selectedUrl = record.url;

    }
    if (type == "mouseout") {
      this.selectedIdeaName = "";
      this.selectedDescription = "";
      this.selectedUrl = "";
    }
  }.bind(this);
}
