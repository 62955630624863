import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-magnesiumcontent',
  templateUrl: './magnesiumcontent.component.html',
  styleUrls: ['./magnesiumcontent.component.scss']
})
export class MagnesiumcontentComponent implements OnInit {
  mgcontent = 0;
  tabletweight = 1;
  mgpertablet = 0;
  mgstearate = 0;


  exampleid = 1;
  examples = [
    { id: 1, name: 'Example 1' }, { id: 2, name: 'Example 2' }
  ]
  constructor() { }

  ngOnInit() {
    this.hasChanged(null);
  }
  calculate() {
    this.mgpertablet = this.tabletweight / 1000000 * this.mgcontent;
    this.mgstearate = this.mgcontent * 20.63;
  }
  clear() {
    this.mgcontent = 0;
    this.tabletweight = 0;
    this.mgpertablet = 0;
    this.mgstearate = 0;
  }

  hasChanged($event) {
    if (this.exampleid == 1) { this.mgcontent = 30; this.tabletweight = 3500; }
    if (this.exampleid == 2) { this.mgcontent = 10; this.tabletweight = 1500; }
    this.calculate();
  }
}
