import { Component, OnInit,Input} from '@angular/core';
import * as $ from 'jquery';
import { DbServiceService } from '../../services/db-service.service';
import { snaAlertService } from '../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../modules/loader/loader.service';

@Component({
  selector: 'sa-docu',
  templateUrl: './docu.component.html',
  styleUrls: ['./docu.component.scss'],

})
export class DocuComponent implements OnInit {
  @Input() Id: number; //record number of idea, concept or project
  @Input() tableName: string;

  croppedImage: any = '';
  Images: any = [];
  ImageGrid: any = [];
  Image: any = {};
  file: any = { 'name': 'undefined' };
  hasFile = false;
  columns = 4;

  constructor(
    private db: DbServiceService,
    private alert: snaAlertService,
    private loader: LoaderService) { }

  async ngOnInit() {
    this.getImages();
  }
  async ngOnChanges() {
    this.getImages();
  }
  async getImages() {
    if (this.Id != null) {
      this.Images = await this.db.get("document", "getImages", { Id: this.Id, TableName: this.tableName });
      this.ImageGrid = this.split(this.Images, this.columns);
    }
  }
  split(arr, n) {
    var res = [];
    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  }
  async saveImage($event) {
    this.croppedImage = $event.img;
    this.file = $event.file;

    let input = new FormData();
    input.append('file', $event.img);
    input.append('fileName', $event.file.name);
    input.append('thumbnail', $event.thumbnail);
    input.append('Id', this.Id.toString());
    input.append('Table', this.tableName);
    // P.S. in this case defined trhough the cropper
    input.append('FileType', $event.file.name.substr($event.file.name.lastIndexOf('.') + 1));
    input.append('Size', $event.img.length); // Size of base64 image

    let dummy = await this.db.post('document', 'uploadImage', input, { Id: this.Id, TableName: this.tableName });
    this.getImages();

    $('#myModalImage').hide();
  }
  async showImageDetails(image) {
    this.Image = await this.db.get('document', 'getImage', { Id: image.Id});
    $('#myModalImageDetail').show();
  }
  closeModalImageDetail = function () {
    $('#myModalImageDetail').hide();
  };
  openModalUploadImage() {
    this.hasFile = true;
    $('#myModalImage').show();
  }
  closeModal = function () {
    this.hasFile = false;
    $('#myModalImage').hide();
  };
  async delete(pic) {
    this.loader.show();
    let resp = await this.db.delete('document', 'deletedocument', { Id: pic.Id });
    this.getImages();
    this.loader.hide();
    this.alert.success(resp.message);
  }
  async setDefault(pic: any) {
    let resp = await this.db.get('document', 'setdefault', { Id: pic.Id });
    this.getImages();
  }
}
