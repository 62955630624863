import { Component, OnInit, Input, Output, } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'sa-score',
  templateUrl: './sa-score.component.html',
  styleUrls: ['./sa-score.component.scss']
})
export class SaScoreComponent implements OnInit {
  @Input() score: number;
  @Output() scoreChange = new EventEmitter();
  @Output() changeScore = new EventEmitter();
  scores: any = [];
  maxScore = 5;

  constructor() { }

  ngOnInit() {
    this.updateLeafs();
  }
  updateLeafs() {
    this.scores = [];
    for (var i = 0; i < this.maxScore; i++) {
      let sc = {};
      sc['Id'] = i + 1;
      sc['Value'] = i < this.score ? true : false;
      this.scores.push(sc);
    }
  }
  ngOnChanges(changes) {
    this.updateLeafs();
  }
  select(sc) {
    this.score = sc.Value ? sc.Id - 1 : sc.Id;
    for (var i = 0; i < this.maxScore; i++) {
      this.scores[i]['Value'] = i < this.score ? true : false;
    }
    this.scoreChange.emit(this.score);
    this.changeScore.emit(this.score);
  }
}
