import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-co2impactlist',
  templateUrl: './co2impactlist.component.html',
  styleUrls: ['./co2impactlist.component.scss']
})
export class Co2impactlistComponent extends BaseComponent implements OnInit {

  ideaco2eval: any = [];

  constructor(private injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.loader.hide()
  }

  async getData() {
    const resp = await this.db.get("material", "getideamaterialco2impact");
    this.ideaco2eval = [];
    resp.forEach(r => {
      let ideaName = r.ideaName;
      let record = this.ideaco2eval.find(x => x.ideaName == ideaName);
      if (record == null) {
        record = {};
        record.ideaName = r.ideaName;
        record.weightGram = r.weightGram
        record.co2contribution = 0;
        record.materials = [];
        this.ideaco2eval.push(record);
      }
      let mat: any = {};
      mat.materialName = r.materialName;
      mat.percentByWeight = r.percentByWeight;
      mat.co2perKg = r.co2perKg;
      mat.co2contribution = record.weightGram * mat.co2perKg * mat.percentByWeight;
      record.co2contribution += mat.co2contribution;
      record.materials.push(mat);
    })

    //now normalize co2, get max first
    let maxco2 = 0;
    this.ideaco2eval.forEach(i => {
      maxco2 = i.co2contribution > maxco2 ? i.co2contribution : maxco2;
      i.maxco2 = 0;
      i.materials.forEach(m => {
        i.maxco2 += m.co2contribution;
      })
    })

    //now calc normalized values
    this.ideaco2eval.forEach(i => {
      i.co2contributionnorm = i.co2contribution / maxco2;
      i.materials.forEach(m => {
        m.co2contributionnorm = m.co2contribution / i.maxco2;
      });
    })

    //now we sort lowest to highest
    this.ideaco2eval.sort((a, b) => {
      var x = a.co2contribution; var y = b.co2contribution;
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    })
  }
}
