import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-processshow',
  templateUrl: './processshow.component.html',
  styleUrls: ['./processshow.component.scss']
})
export class ProcessshowComponent implements OnInit {
  processes: any[];
  process: any;

  image: any;
  constructor(private db: DbServiceService,
    private alert: snaAlertService,
    private loader: LoaderService) { }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.loader.hide();
  }

  async getData() {
    const resp = await this.db.get("process", "getProcesses");
    this.processes = resp.processes;
    await this.selectProcess(this.processes[0]);

  }

  async selectProcess(proc) {
    this.image = null;
    if (proc.Images.length > 0) {
      this.image = await this.db.get("document", "getImage", { Id: proc.Images[0].Id });
    }
    this.process = proc;
  }
}
