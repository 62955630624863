import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LoaderService } from '../../../modules/loader/loader.service';
import { snaAlertService } from '../../../modules/sna-alert/snaAlertModule';
import { HtmlEditorService } from '../../../modules/html-editor/html-editor.service';
import * as $ from 'jquery';
import { DbServiceService } from '../../../services/db-service.service';
import { interval, Observable, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DeviceDetectorModule, DeviceDetectorService } from 'ngx-device-detector';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']

})

export class TestComponent implements OnInit {
  testClass = "fadeIn";
  text = "start";
  title = "First Title";
  score = 3;
  counter$: Observable<number>;
  randIdea$: Observable<any>;
  deviceInfo: any;
  filetestideaid = 5;

  constructor(private loader: LoaderService,
    private alert: snaAlertService,
    private htmledit: HtmlEditorService,
    public db: DbServiceService,
    private deviceService: DeviceDetectorService, public global: GlobalService) { }

  ngOnInit() {
    this.counter$ = interval(1000);
    this.randIdea$ = timer(0, 5000).pipe(switchMap(() => this.db.get("idea", "getRandomIdea")));
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  fadeIn() {
    this.testClass = "fadeIn";
    //alert("Fade In");
  }
  fadeOut() {
    this.testClass = "fadeOut";
    //alert("Fade Out");
  }
  showHtmlEditor() {
    this.htmledit.show(this.title,this.text);
  }
  hideHtmlEditor() {
    this.htmledit.hide();
  }
  test() {
    //this.testtext = "444 fgfhgfh";
  }

}
