import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sa-list-manager',
  templateUrl: './sa-list-manager.component.html',
  styleUrls: ['./sa-list-manager.component.scss']
})
export class SaListManagerComponent implements OnInit {
  @Input() options: any;
  @Input() fieldName: string;
  @Output() optionsChange = new EventEmitter();
  @Output() hasChanged = new EventEmitter();
  newField: any = '';

  constructor() { }

  ngOnInit() {
  }
  deleteElement(option) {
    let temp = [];
    this.options.forEach(x => {
      if (x[this.fieldName] != option[this.fieldName]) {
        temp.push(x);
      }
    });
    this.options = temp;
    this.optionsChange.emit(this.options);
    this.hasChanged.emit(this.options);
  }
  addItem() {
    let a = {};
    a[this.fieldName] = this.newField;
    this.options.push(a);
    this.optionsChange.emit(this.options);
    this.hasChanged.emit(this.options);
  }
}
