import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pressingspeed',
  templateUrl: './pressingspeed.component.html',
  styleUrls: ['./pressingspeed.component.scss']
})
export class PressingspeedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
