import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {
  @Input() isFavorite = false;
  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  check(isFavorite) {
    this.isFavorite = isFavorite;
    this.changed.emit(isFavorite);
  }

}
