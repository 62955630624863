import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../../services/db-service.service';

@Component({
  selector: 'app-spicodes',
  templateUrl: './spicodes.component.html',
  styleUrls: ['./spicodes.component.scss']
})
export class SPICodesComponent implements OnInit {

  constructor(private db: DbServiceService) { }
  codes = [];
  async ngOnInit() {
    this.codes = await this.db.get("idea","getspicodes") 
  }

}
