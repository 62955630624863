// P.S. should encapsulate all x-factor operations
// Input is the survey templateid
// output are an array of xfactor objects (for every idea 1)
//

import { Histogram } from "../components/menu-explore/xfactor/histogram";
import { DbServiceService } from "../services/db-service.service";

export class Xfactor2 {
  templateId: number;
  db: DbServiceService;
  xFactors: any = [];
  surveyresults: any;
  idealist: any;
  template: any;
  ideas: any;
  histograms: any;
  ideaId: number;
  xf: number;

  async init(templateId: number, db: DbServiceService) {
    console.time("xFactor process");
    this.templateId = templateId;
    this.db = db;
    this.surveyresults = await this.db.get("survey", "getSurveyResults", { templateid: this.templateId });
    this.idealist = await this.db.get("idea", "getallidealist");
    this.template = await this.db.get("survey", "getTemplate", { id: this.templateId });
    //console.timeLog("xFactor process", " after db calls");

    this.xFactors = [];
    let ideasTemp = new Map<string, object>(); //uniqe list of ideas ids
    this.surveyresults.forEach(r => {
      ideasTemp.set(r.RefId, {});
    });
    console.log(ideasTemp);
    this.ideas = []; //clear array for selection
    ideasTemp.forEach((value, key) => {
      const idea = this.idealist.find(x => x.Id == key);
      this.ideas.push(idea);
    });
    this.getAllXFactors();
    //console.timeLog("xFactor process", " after processing");
    return { xFactors: this.xFactors, ideas: this.ideas }
  }

  getAllXFactors() {
    this.xFactors = [];
    this.ideas.forEach(idea => {
      this.ideaId = idea.Id;
      this.getXFactor();
      let xfo = { key: idea.Name, value: this.xf, histograms: this.histograms,ideaId: this.ideaId };
      this.xFactors.push(xfo);
    })
  }

  getXFactor() {
    this.initializeHistograms();
    this.surveyresults.forEach(r => {
      if (r.RefId == this.ideaId) {
        const jSurvey = JSON.parse(r.DataJson);
        jSurvey.elements.forEach(el => {
          let hist = this.histograms.find(h => {
            let res = h.id == el.id;
            return res;
          });
          if (hist != null) {
            hist.add(el.result);
          }
        });
      }
    });

    //create data array for histograms to feed into graph
    this.histograms.forEach(x => { x.data = x.getData(); });

    // calculate the x-factor
    let sum = this.histograms.reduce((agg, val) => {
      let v = val.info.weight != null ? parseFloat(val.info.weight) : 0;
      let a = val.stats.average != null ? val.stats.average : 0;
      return agg + v * a;
    }, 0);

    let weight = this.histograms.reduce((agg, val) => {
      let v = val.info.weight != null ? parseFloat(val.info.weight) : 0;
      return agg + v;
    }, 0);
    this.xf = sum / weight;
  }

  initializeHistograms() {
    this.template.surveyJson = JSON.parse(this.template.Templatejson);
    const jSurvey = this.template.surveyJson; // just for convenience
    this.histograms = [];
    jSurvey.elements.forEach(el => {
      if (el.type == "rating") {
        const hist = new Histogram();

        hist.initialize(el.id, el, el.rateMin, el.rateMax);
        this.histograms.push(hist);
      }
    })
  }
}
