import { IdeaType } from './../../../models/IdeaCluster';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Idea, Impact, Reach, Length, Sustainability, IdeaLookups } from '../../../models/IdeaCluster';
import { GlobalService } from '../../../services/global.service';
import { SainputhtmlComponent } from '../../shared/formfields/sa-input-html/sa-input-html.component';
import { SaselectComponent } from '../../shared/formfields/sa-select/sa-select.component';
import { SainputtextComponent } from '../../shared/formfields/sa-input-text/sa-input-text.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { DbServiceService } from '../../../services/db-service.service';
import { LoaderService } from '../../../modules/loader/loader.service';

@Component({
  selector: 'app-ideaedit',
  templateUrl: './ideaedit.component.html',
  styleUrls: ['./ideaedit.component.scss']
})
export class IdeaeditComponent implements OnInit {
  IdeaId = 0;
  IdeaTypeId = 1; // P.S. Idea
  constructor(    private route: ActivatedRoute, private db: DbServiceService) { }

  async ngOnInit() {
    this.IdeaId = this.getIntQueryParamOrDefault('IdeaId', 0);
  }

  getIntQueryParamOrDefault(sQuery: string, iDefault: number){
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString !== null)  { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }
}
