import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';
import * as d3 from 'd3';
import * as $ from 'jquery';

var href;
let svg;
let width = 0;
let height = 0;
let allLinks: any = [];
let allLinkTexts: any = [];
let allGNodes: any = [];

@Component({
  selector: 'app-relideacompany',
  templateUrl: './relideacompany.component.html',
  styleUrls: ['./relideacompany.component.scss']
})
export class RelideacompanyComponent extends BaseComponent implements OnInit {
  nodes: any = [];
  links: any = [];
  IdeaCompanyRelByPackTech: any = [];
  IdeaCompanyRelByDosageform: any = [];

  constructor(private injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getPTData();
    //await this.getData();
    href = this;
    this.drawGraph();
    this.loader.hide();
  }

  //async getData() {
  //  const resp = await this.db.get("company", "getMaterialGroupRelationships");
  //  this.nodes = resp.nodes;
  //  this.links = resp.links;
  //  this.links.forEach(l => {
  //    l.source = this.nodes.find(x => x.id == l.sId);
  //    l.target = this.nodes.find(x => x.id == l.tId);
  //  });
  //}

  async getPTData() {
    this.IdeaCompanyRelByPackTech = await this.db.get("reporting", "getIdeaCompanyRelByPackTech");
    this.IdeaCompanyRelByDosageform = await this.db.get("reporting", "getIdeaCompanyRelByDosageform");
  }

  sort(field) {
    this.IdeaCompanyRelByPackTech.sort(this.compareValues(field));
  }
  graphBy(item, type) {
    href.nodes = [];
    href.links = [];

    this.IdeaCompanyRelByPackTech.forEach(x => {
      if (x[type] == item[type]) { this.addNodeAndLink(x); }
    });

    this.drawGraph();
  }

  graphBy2(item, type) {
    href.nodes = [];
    href.links = [];

    this.IdeaCompanyRelByDosageform.forEach(x => {
      if (x[type] == item[type]) { this.addNodeAndLink2(x); }
    });

    this.drawGraph();
  }

  addNodeAndLink(rel) {
    let nodeIdea = this.getNode(rel.ideaId, "Idea", rel.ideaName, "#b3d1ff");
    let nodeCompany = this.getNode(rel.companyId, "Company", rel.companyName, "#66ffe0");
    let nodePackTech = this.getNode(rel.packagingTechnologyId, "Packaging System", rel.packagingTechnologyName, "#ffcccc");

    href.links.push({ source: nodeIdea.id, target: nodePackTech.id });
    href.links.push({ source: nodeCompany.id, target: nodePackTech.id });
  }
  addNodeAndLink2(rel) {
    let nodeIdea = this.getNode(rel.ideaId, "Idea", rel.ideaName, "#b3d1ff");
    let nodeCompany = this.getNode(rel.companyId, "Company", rel.companyName, "#66ffe0");
    let nodePackTech = this.getNode(rel.dosageformId, "Dosage Form", rel.dosageformName, "#ffcccc");

    href.links.push({ source: nodeIdea.id, target: nodePackTech.id });
    href.links.push({ source: nodeCompany.id, target: nodePackTech.id });
  }

  getNode(idRef, type, nodeName, color) {
    let node = href.nodes.find(x => x.nodeType == type && x.idRef == idRef);
    if (node == null) {
      node = {
        id: href.nodes.length,
        nodeType: type,
        name: nodeName,
        idRef: idRef,
        color: color
      }
      href.nodes.push(node);
    }
    return node;
  }

  drawGraph() {
    svg = d3.select("#graph");
    width = $("#graph").width();
    height = $("#graph").height();

    svg.selectAll('g').remove();
    svg.selectAll('line').remove();

    let simulation = d3.forceSimulation(href.nodes)
      .force('link', d3.forceLink(href.links)
        .id(function (d: any) { return d.id; })
        .distance(50).strength(+0.01)) // standard distance and slight attraction
      .force('charge', d3.forceManyBody().strength(-50))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('bounds', boxingForce)
      .on('tick', ticked);

    // ---- links ------------------------------------------------------
    allLinks = svg.selectAll('.link')
      .data(href.links).enter().append('line').attr('class', 'link')
      .attr('stroke', 'gray')
      .attr('stroke-width', '3');

    const gNode = svg.append('g').attr('class', 'nodes');
    const allNodes = gNode.selectAll('.node').data(href.nodes, d => d.id);
    const nodeEnter = allNodes.enter().append('g').attr('class', 'node').attr('id', d => 'N' + d.id);
    allGNodes = nodeEnter;

    //--- circles ----------------------------------------------
    nodeEnter.append('circle')
      .merge(allNodes.select('circle'))
      .attr('r', function (d: any) { return 20; })
      .attr('fill', function (d: any) { return d.color })
      .attr('stroke', function (d: any) { return "gray" })
      .attr('stroke-width', function (d: any) { return 1; })
      .call(d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended));

    //--- text --------------------------------------------------------------
    nodeEnter.append('text')
      .merge(allNodes.select('text'))
      .text(d => {
        return d.nodeType + ": " + d.name;
      }
      )
      .attr('x', 15).attr('y', 5).attr('class', 'text-normal');

    //--- simulation ---------------------------------------
    simulation.nodes(href.nodes);
    simulation.force('link', d3.forceLink(href.links)
      .id(function (d: any) { return d.id; })
      .distance(50).strength(+0.01));
    simulation.alpha(0.5).restart();

    //--- inner functions ---------------------------------------------------
    function boxingForce() {
      for (const node of href.nodes) {
        node.x = Math.min(Math.max(node.x, 20), width - 100);
        node.y = Math.min(Math.max(node.y, 20), height - 20);
      }
    }

    function ticked() {
      allLinks
        .attr('x1', function (d: any) { return d.source.x; })
        .attr('y1', function (d: any) { return d.source.y; })
        .attr('x2', function (d: any) { return d.target.x; })
        .attr('y2', function (d: any) { return d.target.y; });

      allGNodes
        .attr('transform', function (d) {
          return 'translate(' + d['x'] + ',' + d['y'] + ')';
        });
    }
    function dragstarted(d) {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragended(d) {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
  }
}
