import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-companymaterialpicker',
  templateUrl: './companymaterialpicker.component.html',
  styleUrls: ['./companymaterialpicker.component.scss']
})
export class CompanymaterialpickerComponent extends BaseComponent implements OnInit {
  @Input() companyId = 0;

  material: any = {};
  companyMaterials: any = []; //list of materials augmented by checked is rel exist

  constructor(private injector: Injector) { super(injector) }

  ngOnInit() {
  }



  add() {
    $('#materialTag').show();
  }
  async addMat(m) {
    await this.db.post("material", "postCompanyMaterialRels", null, { companyId: this.companyId, materialId: m.id });
    await this.getData();
    $('#materialTag').hide();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (this.companyId > 0) {
      await this.getData();
    }
  }

  async getData() {
    const resp = await this.db.get("lookup", "getMaterials");
    let materials = resp.materials;
    let mateiralgroups = resp.materislgroups;

    const rels = await this.db.get("material", "getCompanyMaterialRels", { CompanyId: this.companyId });
    this.companyMaterials = [];
    materials.forEach(m => {
      let companymat = {
        id: m.id,
        name: m.name,
        description: m.description,
        groupId: m.groupId,
        materialgroupName: m.materialgroupName,
        images: m.images,
        mwtr: m.mwtr,
        q2tr: m.q2tr,
        co2perKg: m.co2perKg,
        percentByWeight: 0,
        isChecked: false,
        refId: 0 // the id of the relatonship table if exists
      };
      let mRel = rels.find(x => x.materialId === m.id);
      if (mRel != null) {
        companymat.isChecked = true;
        companymat.refId = mRel.id;
      }
      this.companyMaterials.push(companymat);
    });
  }
  filterIsChecked(item) {
    return item.isChecked == true ? true : false;
  }
  filterNotChecked(item) {
    return item.isChecked == true ? false : true;
  }
  closeMaterialModal() {
    $('#materialTag').hide();
  }
  async remove(m) {
    await this.db.delete("material", "deleteCompanyMaterialRels", { id: m.refId });
    await this.getData();
  }
  goto(m) {
    this.router.navigate(['/dashboard/materialtree'], { queryParams: { 'Id': m.id } });
  }
}
