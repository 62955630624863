import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../../services/db-service.service';

@Component({
  selector: 'app-takephoto',
  templateUrl: './takephoto.component.html',
  styleUrls: ['./takephoto.component.scss']
})
export class TakephotoComponent implements OnInit {
  name = "";
  description="";
  author="";
  fileName = "";
  fileToUpload: any;
  isFormValid = false;

  constructor(private db: DbServiceService) { }

  ngOnInit() {
  }

  async save() {
    let input: FormData = new FormData();
    input.append('fileKey', this.fileToUpload, this.fileToUpload.name);
    input.append('fileName', this.fileName);
    input.append('name', this.name);
    input.append('description', this.description);
    input.append('author', this.author);

    let res = await this.db.post("idea", "postPhoto", input);
  }

  formValidate() {
    this.isFormValid = true;
    if (this.name.length == 0) this.isFormValid = false;
    if (this.description.length == 0) this.isFormValid = false;
    if (this.author.length == 0) this.isFormValid = false;
    if (this.fileName.length == 0) this.isFormValid = false;
  }

  fileChangeEvent(files: FileList) {
    this.fileToUpload = files.item(0);
    this.fileName = files.item(0).name;
    this.formValidate();
  }
}
