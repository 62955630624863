import { Component, Injector, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from '../../shared/basecomponent';
import { GraphForcedComponent, Node, Link } from '../../graph-forced/graph-forced.component';
import * as $ from 'jquery';
var ref: any;

@Component({
  selector: 'app-relprint',
  templateUrl: './relprint.component.html',
  styleUrls: ['./relprint.component.scss']
})
export class RelprintComponent extends BaseComponent implements OnInit {
  @Input() Id = 0;
  Relationships: any = [];
  Records: any = [];
  dbNodes: any = [];
  dbLinks: any = [];
  @ViewChild(GraphForcedComponent) fg: GraphForcedComponent;

  constructor(private injector: Injector) { super(injector)}

  ngOnInit() {
    ref = this;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.Id > 0) {
      await this.createGraph();
      //await this.forcedgraphCallback({ refId: this.Id });
    }
  }
  async createGraph() {

    let response = await this.db.get("idea", "getrelationships", { Id: this.Id });
    this.Relationships = response.links;
    this.Records = response.nodes;

    let rels = response.links;
    let ideas = response.nodes;
    let nodes: Node[] = [];
    let links: Link[] = [];
    ideas.forEach((idea, i) => {
      let color = "red";
      let radius = 20;
      if (idea['type'] == "Project") { color = "lightblue"; }
      if (idea['type'] == "Idea") { color = "lightyellow"; }
      if (idea['type'] == "Concept") { color = "PeachPuff"; }
      if (idea['id'] == this.Id) { color = "lightgreen"; radius = 40; }

      let node = new Node(i, idea['id'], idea['name'], radius, color, idea['description'], "", idea['stepstretchseap'], idea['type']);
      nodes.push(node);
    });
    rels.forEach((rel, i) => {
      let n1 = nodes.find(x => x.refId == rel['IdeaSourceId']);
      let n2 = nodes.find(x => x.refId == rel['IdeaTargetId']);
      let link = new Link(n1.id, n2.id, n1.refId, n2.refId, n1.name, n2.name, rel['description'], "");
      links.push(link);
    });

    this.dbNodes = nodes;
    this.dbLinks = links;
    this.fg.createGraph(links, nodes, this.forcedgraphCallback);
  }

  async forcedgraphCallback($event) {
    let refId = $event.refId;
    let record = ref.Records.find(x => x.id == refId);
    //alert(record['name'] + "\n" + record['description'] + "\n" + record['type']);
    //ref.global.IdeaId = record['id'];
    if (record != null) {
      ref.recId = record['id'];
      ref.recName = record['name'];
      ref.recDescription = record['description'];

      const d = await ref.db.get("Idea", "getDefaultImage", { IdeaId: ref.recId });
      ref.recUrl = d.Url;
      ref.recType = record['type']
    } else {
      const idea = await ref.db.get("Idea", "getideashort", { Id: refId });
      ref.recId = idea.id;
      ref.recName = idea.name;
      ref.recDescription = idea.description;
      ref.recUrl = idea.url;
      ref.recType = idea.type;
    }
    //ref.navigate();
  }
}
