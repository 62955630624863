import { DbServiceService } from './../../../services/db-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-s3test',
  templateUrl: './s3test.component.html',
  styleUrls: ['./s3test.component.scss']
})
export class S3testComponent implements OnInit {
  ideaIdFrom = 171;
  ideaIdTo = 198;
  imagesFrom: any = [];
  imagesTo: any = [];

  constructor(private db: DbServiceService) { }

  ngOnInit() {
    this.getData();
  }
  async getData() {
    const query = {Id:this.ideaIdFrom, TableName: 'Idea'};
    this.imagesFrom = await this.db.get('document', 'getImages', query);
    const query2 = {Id:this.ideaIdTo, TableName: 'Idea'};
    this.imagesTo = await this.db.get('document', 'getImages', query2);
  }
  async copy() {
    const resp = await this.db.get('document', 'copyImages',{IdeaFromId: this.ideaIdFrom, IdeaToId:  this.ideaIdTo});
  }

}
