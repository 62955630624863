import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-manageprocess',
  templateUrl: './manageprocess.component.html',
  styleUrls: ['./manageprocess.component.scss']
})
export class ManageprocessComponent extends BaseComponent implements OnInit {
  processes: any = [];
  process: any = {};

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.add();
    this.loader.hide();
  }

  async getData() {
    const resp = await this.db.get("process", "getProcesses");
    this.processes = resp.processes
  }

  add() {
    this.process = {
      Id: 0,
      Name: "",
      Description: "",
    };
  }

  selectProcess(proc) {
    this.process = proc;
  }

  async saveProcess() {
    this.loader.show();
    await this.db.post("process", "postProcess", this.process);
    await this.getData();
    this.loader.hide();
  }
}
