import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list3-picker',
  templateUrl: './list3-picker.component.html',
  styleUrls: ['./list3-picker.component.scss']
})
export class List3PickerComponent implements OnInit, OnChanges {
  @Input() list: any; // P.S. list of objects that are or could be selected
  // {id:1, name="Item1", isChecked= false, ...+other fields like refNo}
  @Input() title = "";
  @Output() add = new EventEmitter();
  @Output() remove = new EventEmitter();

  listTrue: any = [];
  listFalse: any = [];
  showAdd = false;

  constructor() { }

  async ngOnInit() {
    this.listTrue = [];
    this.listFalse = [];

    this.list.forEach(item => {
      if (item.isChecked) {
        this.listTrue.push(item);
      } else {
        this.listFalse.push(item);
      }
    });
  }

  async ngOnChanges() {
    this.listTrue = [];
    this.listFalse = [];

    this.list.forEach(item => {
      if (item.isChecked) {
        this.listTrue.push(item);
      } else {
        this.listFalse.push(item);
      }
    });
  }

  changeCheckBox($event, o) {


    if (o.isChecked) {
      this.add.emit(o);
    } else {
      this.remove.emit(o);
    }

    this.listTrue = [];
    this.listFalse = [];

    this.list.forEach(item => {
      if (item.isChecked) {
        this.listTrue.push(item);
      } else {
        this.listFalse.push(item);
      }
    });
  }
}
