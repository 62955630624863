import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-punch',
  templateUrl: './punch.component.html',
  styleUrls: ['./punch.component.scss']
})
export class PunchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
