import { Component, OnInit, ViewChild } from '@angular/core';
import { Idea } from '../../../models/IdeaCluster';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { LoaderService } from '../../../modules/loader/loader.service';
import { DbServiceService } from '../../../services/db-service.service';

@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.scss']
})
export class ProductlistComponent implements OnInit {
  Ideas: Idea[];

  constructor(private db: DbServiceService,
    public global: GlobalService,
    private loader: LoaderService,
    private router: Router) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.submit();
  }
  async submit() {
    this.loader.show();
    if (this.global.IsPublic) {
      this.Ideas = await this.db.get("idea", "getPublicIdeas", { type: this.global.IdeaType.Name });
    } else {
      this.Ideas = await this.db.get("idea", "getPrivateIdeas", { type: this.global.IdeaType.Name });
      let a = 5;
    }
    this.loader.hide();

  }
  async checkChanged() {
    this.submit();
  }
  navigate(item) {
    this.global.IdeaId = item.Id;
    this.router.navigate(['/dashboard/productedit'], {queryParams: {'IdeaId': item.Id}});
  }
  newRecord() {
    this.global.IdeaId = 0;
    this.router.navigate(['/dashboard/productedit'], {queryParams: {'IdeaId': 0}});
  }

}
