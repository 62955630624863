import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../shared/basecomponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-ideamaterialpicker',
  templateUrl: './ideamaterialpicker.component.html',
  styleUrls: ['./ideamaterialpicker.component.scss']
})
export class IdeamaterialpickerComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() ideaId = 0;
  @Output() newPerc = new EventEmitter();

  material: any = {};
  ideaMaterials: any = []; //list of materials augmented by checked is rel exist

  constructor(private injector: Injector) { super(injector) }

  ngOnInit() {
  }

  edit(m) {
    this.material = m;
    this.material.percentByWeight100 = this.material.percentByWeight * 100;
    $('#relEditTag').show();
  }

  async saveRel() {
    await this.db.post("material", "postIdeaMaterialRels", null, { ideaId: this.ideaId, materialId: this.material.id, percentbyweight: this.material.percentByWeight100 / 100 });
    await this.getData();
    this.newPerc.emit();
    $('#relEditTag').hide();
  }

  closeEditModal() {
    $('#relEditTag').hide();
  }

  add() {
    $('#materialTag').show();
  }
  async addMat(m) {
    await this.db.post("material", "postIdeaMaterialRels", null, { ideaId: this.ideaId, materialId: m.id });
    await this.getData();
    $('#materialTag').hide();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (this.ideaId > 0) {
      await this.getData();
    }
  }

  async getData() {
    const resp = await this.db.get("lookup", "getMaterials");
    let materials = resp.materials;
    let mateiralgroups = resp.materislgroups;

    const rels = await this.db.get("material", "getIdeaMaterialRels", { ideaId: this.ideaId });
    this.ideaMaterials = [];
    materials.forEach(m => {
      let ideamat = {
        id: m.id,
        name: m.name,
        description: m.description,
        groupId: m.groupId,
        materialgroupName: m.materialgroupName,
        images: m.images,
        mwtr: m.mwtr,
        q2tr: m.q2tr,
        co2perKg: m.co2perKg,
        percentByWeight: 0,
        isChecked: false,
        refId: 0 // the id of the relatonship table if exists
      };
      let mRel = rels.find(x => x.materialId === m.id);
      if (mRel != null) {
        ideamat.isChecked = true;
        ideamat.refId = mRel.id;
        ideamat.percentByWeight = mRel.percentByWeight;
      }
      this.ideaMaterials.push(ideamat);
    });
  }
  filterIsChecked(item) {
    return item.isChecked == true ? true : false;
  }
  filterNotChecked(item) {
    return item.isChecked == true ? false : true;
  }
  closeMaterialModal() {
    $('#materialTag').hide();
  }
  async remove(m) {
    await this.db.delete("material", "deleteIdeaMaterialRels", { id: m.refId });
    await this.getData();
  }
}
