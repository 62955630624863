import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../../../../modules/loader/loader.service';
import { DbServiceService } from '../../../../services/db-service.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-companyedit',
  templateUrl: './companyedit.component.html',
  styleUrls: ['./companyedit.component.scss']
})
export class CompanyeditComponent implements OnInit {
  companyId = 0;
  company: any = {};
  companytypes: any = [];
  industries: any = [];
  materialgroups: any = [];
  lpackform: any = []; // { id: 1, name: 'bottle', isChecked: false, refId: 22 },
  ldosform: any = []; // { id: 1, name: 'bottle', isChecked: false, refId: 22 },
  ltreatment: any = []; // { id: 1, name: 'bottle', isChecked: false, refId: 22 },
  companySizes: any = [];
  businessAreas: any = [];
  treatmentFields: any = [];
  categories: any = [];
  companyCategory: any = {};
  activeIngredients: any = [];
  activeIngredient: any = {};
  priorities: any = [];
  constructor(private route: ActivatedRoute, private db: DbServiceService, private loader: LoaderService) { }

  async ngOnInit() {
    this.loader.show();
    this.industries = await this.db.get("lookup", "getIndustries");
    this.businessAreas = await this.db.get("lookup", "getBusinessAreas");
    this.materialgroups = await this.db.get("lookup", "getMaterialGroups");
    this.companySizes = await this.db.get("lookup", "getCompanySizes");
    this.treatmentFields = await this.db.get("lookup", "getTreatmentFields");
    this.categories = await this.db.get("lookup", "getCompanyCategories");
    this.activeIngredients = await this.db.get("lookup", "getActiveIngredients");
    this.priorities = await this.db.get("lookup", "getCompanyPriorites");

    this.companyId = this.getIntQueryParamOrDefault("Id", 0);
    if (this.companyId > 0) {
      const resp = await this.db.get("company", "getcompany", { id: this.companyId });
      this.company = resp.company;
      this.companytypes = resp.companytypes;
      await this.getCompanyPackagingSystemRelationship();
      await this.getCompanyDosageformRel();
      await this.getCompanyTreatmentFieldRel();
    } else {
      this.companytypes = await this.db.get("company", "getcompanytypes");
      this.company = { id: 0, isActive: true, comnpanyTypeId: 2, name: "" };
    }
    let a = 5;
    this.loader.hide();
  }

  async getCompanyPackagingSystemRelationship() {
    const resp2 = await this.db.get("lookup", "getPackagingSystemsForCompany", { companyId: this.companyId });
    this.lpackform = [];
    resp2.forEach(x => {
      let lpf = {
        id: x.id,
        name: x.name,
        description: x.description,
        isChecked: false,
        refId: 0
      }
      if (x.comPackRel != null) {
        lpf.isChecked = true;
        lpf.refId = x.comPackRel.id //primary key in table CompanyPackTechRel
      }
      this.lpackform.push(lpf);
    })
  }

  async getCompanyDosageformRel() {
    const resp2 = await this.db.get("lookup", "getDosageformsForCompany", { companyId: this.companyId });
    this.ldosform = [];
    resp2.forEach(x => {
      let lpf = {
        id: x.id,
        name: x.name,
        description: x.description,
        isChecked: false,
        refId: 0
      }
      if (x.compDosRel != null) {
        lpf.isChecked = true;
        lpf.refId = x.compDosRel.id //primary key in table CompanyPackTechRel
      }
      this.ldosform.push(lpf);
    })
  }
  async getCompanyTreatmentFieldRel() {
    const resp2 = await this.db.get("lookup", "getCompanyTreatmentFieldRel", { companyId: this.companyId });
    this.ltreatment = [];
    this.treatmentFields.forEach(x => {
      let ltf = {
        id: x.id,
        name: x.name,
        isChecked: false,
        refId: 0
      }
      let rel = resp2.find(r => r.treatmentFieldId == x.id);
      if (rel != null) {
        ltf.isChecked = true;
        ltf.refId = rel.id //primary key in table CompanyTreatmentFieldRel
      }
      this.ltreatment.push(ltf);
    })
  }
  async addTreatment($event) {
    this.loader.show();
    let newRel = { CompanyId: this.companyId, TreatmentFieldId: $event.id };
    await this.db.post("lookup", "postCompanyTreatmentFieldRel", newRel);
    await this.getCompanyPackagingSystemRelationship();
    this.loader.hide();
  }
  async removeTreatment($event) {
    this.loader.show();
    await this.db.delete("lookup", "deleteCompanyTreatmentFieldRel", { id: $event.refId });
    await this.getCompanyPackagingSystemRelationship();
    this.loader.hide();
  }
  getIntQueryParamOrDefault(sQuery: string, iDefault: number) {
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString !== null) { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }
  async getData() {
    const resp = await this.db.get("company", "getcompany", { id: this.companyId });
    this.company = resp.company;
    this.companytypes = resp.companytypes;

  }

  async save() {
    this.loader.show();
    this.company = await this.db.post("company", "postcompany", this.company);
    this.loader.hide();
  }
  async addPackForm(pf) {
    this.loader.show();
    let newRel = { CompanyId: this.companyId, PackagingTechnologyId: pf.id };
    await this.db.post("lookup", "postCompanyPackTechRel", newRel);
    await this.getCompanyPackagingSystemRelationship();
    this.loader.hide();
  }
  async removePackForm(pf) {
    this.loader.show();
    await this.db.delete("lookup", "deleteCompanyPackTechRel", { id: pf.refId });
    await this.getCompanyPackagingSystemRelationship();
    this.loader.hide();
  }

  async addDosForm(pf) {
    this.loader.show();
    let newRel = { CompanyId: this.companyId, DosageformId: pf.id };
    await this.db.post("lookup", "postCompanyDosageformRel", newRel);
    await this.getCompanyDosageformRel();
    this.loader.hide();
  }

  async removeDosForm(pf) {
    this.loader.show();
    await this.db.delete("lookup", "deleteCompanyDosageformRel", { id: pf.refId });
    await this.getCompanyDosageformRel();
    this.loader.hide();
  }
  quickAddCategory() {
    this.companyCategory = { id: 0, name: "", seqNo: 0 }
    $('#categoryTag').show();
  }
  async saveCompanyCategory() { //call back by modal brand
    await this.db.post("lookup", "postCompanyCategory", this.companyCategory);
    this.categories = await this.db.get("lookup", "getCompanyCategories");
    $('#categoryTag').hide();
  }
  closeModalCategory() {
    $('#categoryTag').hide();
  }

  quickAddIngredient() {
    this.activeIngredient = { id: 0, name: "", description: "" }
    $('#ingredientTag').show();
  }
  async saveIngredient() { //call back by modal brand
    await this.db.post("lookup", "postActiveIngredient", this.activeIngredient);
    this.activeIngredients = await this.db.get("lookup", "getActiveIngredients");
    $('#ingredientTag').hide();
  }
  closeModalIngredient() {
    $('#ingredientTag').hide();
  }
}
