import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-b2bproductedit',
  templateUrl: './b2bproductedit.component.html',
  styleUrls: ['./b2bproductedit.component.scss']
})
export class B2bproducteditComponent implements OnInit {
  IdeaId = 0;
  IdeaTypeId = 7; // P.S. B-to-B product
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.IdeaId = this.getIntQueryParamOrDefault('IdeaId', 0);
  }

  getIntQueryParamOrDefault(sQuery: string, iDefault: number) {
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString !== null) { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }
}
