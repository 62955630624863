
import { Component, Injector, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { forEach } from '@angular/router/src/utils/collection';
import { BaseComponent } from '../../shared/basecomponent';
import { MaterialsComponent } from './materials/materials.component';

@Component({
  selector: 'app-materialtree',
  templateUrl: './materialtree.component.html',
  styleUrls: ['./materialtree.component.scss']
})
export class MaterialtreeComponent extends BaseComponent implements OnInit,AfterViewInit {
  groups: any = [];
  tree: any = {};
  table: any = [];
  showIdeas = false;
  showCompanies = false;
  @ViewChild("matcomp") child: MaterialsComponent;
  @ViewChild('tbs') ngbTabSet;

  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();

    this.loader.hide();
  }

  ngAfterViewInit() {
    let matid = this.getIntQueryParamOrDefault('Id', 0);
    if (matid > 0) {
      this.ngbTabSet.select("mats");
    }
  }
  toggleShowIdeas(t) {
    t.showIdeas = !t.showIdeas;
  }

  toggleShowCompanies(t) {
    t.showCompanies = !t.showCompanies;
  }

  gotoIdea(idea) {
    this.router.navigate(['/dashboard/ideaedit'], { queryParams: { 'IdeaId': idea.ideaId } });
  }

  gotoCompany(company) {
    this.router.navigate(['/dashboard/companyedit'], { queryParams: { 'Id': company.companyId } });
  }

  async getData() {
    const resp = await this.db.get("material", "getmaterialtree");
    this.groups = resp.materialgroups;
    this.tree = resp.tree;
    this.table = [];
    this.tree.groups.forEach(g => {
      g.subgroups.forEach(sg => {
        sg.materials.forEach(m => {
          let tb: any = {};
          tb.materialname = m.name;
          tb.materialdescription = m.description;
          tb.subgroupname = sg.name;
          tb.groupname = g.name;
          tb.nideas = m.ideas.length;
          tb.ideas = m.ideas;
          tb.ideasG1 = this.getIdeaGroup(m.ideas, [1, 2, 3]);
          tb.nideasG1 = tb.ideasG1.length;
          tb.ideasG2 = this.getIdeaGroup(m.ideas, [4,7]);
          tb.nideasG2 = tb.ideasG2.length;
          tb.ncompanies = m.companies.length;
          tb.companies = m.companies;
          tb.showIdeas = false;
          tb.showCompanies = false;
          tb.pics = m.pics;
          this.table.push(tb);
        });
      });
    });

    this.groups.forEach(g => {
      g.subgroups = [];
      resp.materialsubgroups.forEach(sg => {
        if (sg.groupId == g.id) {
          sg.materials = [];
          resp.materials.forEach(m => {
            if (m.subGroupId == sg.id) sg.materials.push(m);
          });
          g.subgroups.push(sg);
        };       
      });
    });
  }

  getIdeaGroup(ideas, typeArray) {
    let ret = [];
    ideas.forEach(idea => {
      typeArray.forEach(typeid => {
        if (idea.ideaTypeId == typeid) {
          ret.push(idea);
        }
      });
    });
    return ret;
  }
}
