import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-conceptedit',
  templateUrl: './conceptedit.component.html',
  styleUrls: ['./conceptedit.component.scss']
})
export class ConcepteditComponent implements OnInit {
  IdeaId = 0;
  IdeaTypeId = 2; // P.S. Concept
  constructor(    private route: ActivatedRoute) { }

  ngOnInit() {
    this.IdeaId = this.getIntQueryParamOrDefault('IdeaId', 0);
  }

  getIntQueryParamOrDefault(sQuery: string, iDefault: number){
    const QueryString = this.route.snapshot.queryParams[sQuery];
    let ret = iDefault;
    if (QueryString !== null)  { // P.S. called with non-empty IdeaId
      ret = parseInt(QueryString, 10);
    }
    return ret;
  }

}
